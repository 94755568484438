<template>
  <div class="container create-link-page">
    <div class="row h-100">
      <div class="col d-flex justify-content-center align-items-center h-100">
        <new-link
          v-if="showCreateLink"
          :apikey="apikeyValue"
          @success="handleNewLinkSuccess"
        />

        <base-modal v-model="showSuccessLink">
          <new-link-success
            :link="paymentLink"
            :qr="qr"
            :apikey="apikeyValue"
            @close="showSuccessLink = false"
          />
        </base-modal>

        <base-modal v-model="showApikeyModal">
          <apikey-input @submit="handleApikeySubmit($event)" />
        </base-modal>
      </div>
    </div>
  </div>
</template>

<script>
import NewLink from '@/components/links/NewLink.vue';
import NewLinkSuccess from '@/components/links/NewLinkSuccess.vue';
import ApikeyInput from '@/components/links/ApikeyInput.vue';
import BaseModal from '@/components/base/BaseModal.vue';

import { ref } from 'vue';
import useService from '@/composables/common/services';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { API_KEY_LS_NAME } from '@/config/constants.js';
import { onMounted } from 'vue';

export default {
  name: 'CreateLink',
  components: {
    NewLink,
    NewLinkSuccess,
    BaseModal,
    ApikeyInput
  },
  setup() {
    // to avoid redirect to login
    useService();
    const store = useStore();
    const route = useRoute();

    const showSuccessLink = ref(false);
    const paymentLink = ref(null);
    const showApikeyModal = ref(false);
    const showCreateLink = ref(false);
    const apikeyValue = ref(null);
    const qr = ref(null);

    const handleNewLinkSuccess = ({ url, qrcode }) => {
      paymentLink.value = url;
      qr.value = 'data:image/png;base64, ' + qrcode;
      showSuccessLink.value = true;
    };

    const handleApikeySubmit = (apikey) => {
      localStorage.setItem(API_KEY_LS_NAME, apikey);
      showApikeyModal.value = false;
      showCreateLink.value = true;
      apikeyValue.value = apikey;
    };

    onMounted(() => {
      const storedApiKey = store.state.user?.clientid;
      const apiKeyFromLs =
        storedApiKey || localStorage.getItem(API_KEY_LS_NAME);

      if (!apiKeyFromLs && !route.params.apikey) {
        showApikeyModal.value = true;
      } else {
        const savedValue = route.params.apikey || apiKeyFromLs;
        localStorage.setItem(API_KEY_LS_NAME, savedValue);

        apikeyValue.value = savedValue;
        showCreateLink.value = true;
      }
    });

    return {
      showApikeyModal,
      showSuccessLink,
      paymentLink,
      qr,
      showCreateLink,
      apikeyValue,

      handleNewLinkSuccess,
      handleApikeySubmit
    };
  }
};
</script>

<style lang="scss" scoped>
.create-link-page {
  min-height: 80vh;
  height: auto;
}

@media screen and (orientation: landscape) and (max-width: 1024px) {
  .create-link-page {
    height: auto;
  }
}
</style>
