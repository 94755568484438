import httpService from '@/services/http';
import { useRouter, useRoute } from 'vue-router';
import useToast from '@/composables/common/toast';
import { SAVED_CRED_KEY } from '@/config/constants.js';
import { useStore } from 'vuex';
import { computed, ref } from 'vue';
import cookieService from '@/services/cookies';

import {
  linkWithCredential,
  GoogleAuthProvider,
  OAuthProvider,
  TwitterAuthProvider
} from 'firebase/auth';

const SIGN_IN_PROVIDERS = {
  'google.com': GoogleAuthProvider,
  'twitter.com': TwitterAuthProvider,
  'microsoft.com': OAuthProvider,
  emailLink: OAuthProvider
};

export default function (auth) {
  const router = useRouter();
  const route = useRoute();
  const { showMessage } = useToast();
  const store = useStore();
  const showRetryModal = ref(false);
  const retryDetails = ref({});
  const customErrorMessage = ref(false);

  const getCredential = (savedCredential) => {
    const parsedCredential = JSON.parse(savedCredential);
    const providerId = parsedCredential.providerId;
    const provider = SIGN_IN_PROVIDERS[providerId];

    if (providerId === 'google.com') {
      return provider.credential(parsedCredential);
    }

    if (providerId === 'twitter.com') {
      return provider.credential(
        parsedCredential.accessToken,
        parsedCredential.secret
      );
    }

    if (providerId === 'microsoft.com') {
      return provider.credentialFromJSON(parsedCredential);
    }

    return null;
  };

  const linkCredential = async (res) => {
    const savedCredential = sessionStorage.getItem(SAVED_CRED_KEY);
    sessionStorage.removeItem(SAVED_CRED_KEY);

    console.log(res);
    console.log('inside linkCredential with response above');

    console.log(savedCredential);
    console.log('inside linkCredential with saved credential above');

    if (!savedCredential || !res) {
      return;
    }

    const user = res.user;

    const cred = await getCredential(savedCredential);

    if (!cred) {
      console.log('no credential found from linkCredential method.');
      return null;
    }

    return await linkWithCredential(user, cred).catch((err) => {
      console.log(err);
      console.log('ERROR CREDENTIAL - SEE ABOVE');
    });
  };

  const systemLogin = async (responseData) => {
    console.log('systemLogin');
    console.log(responseData);
    console.log('responseData in systemLogin above');

    const linkCredentialResponse = await linkCredential(responseData);

    console.log(linkCredentialResponse);
    console.log('linkCredentialResponse logged above');

    try {
      const token = await auth.value.currentUser.getIdToken(true);

      console.log(store);
      console.log('about to add token to store above');
      console.log(token);
      console.log(
        'systemLogin - token is above from auth.value.currentUser.getIdToken'
      );
      store.commit('updateToken', token);

      console.log(store);
      console.log('added token to store');

      await httpService
        .post('/auth', {
          token
        })
        .then((response) => {
          const userDetails = response.data;

          console.log(response.data);
          console.log('res.data is above to our Auth call.');

          if (!response.data.clientid) {
            customErrorMessage.value =
              'Email address not recognized. Please try another.';

            return;
          }

          // Note it is still utype from this endpoint.
          if (userDetails.utype !== 'admin' && !userDetails.clientid) {
            window.location.href = '/contact';

            return;
          }

          store.commit('updateUser', response.data);

          // TODO: bring back when we need custom claims again
          // console.log(response.data.custom_claims_token.token);
          // console.log('Storing custom claims for data above');

          cookieService.handleAuthentication(response);

          router.push({ name: 'Home' });
        });
    } catch (e) {
      console.log(e);
      console.log('ERROR WITH LOGIN - SEE EXCEPTION DETAILS ABOVE.');

      showMessage({
        title: 'Error',
        text: 'Something went wrong.',
        type: 'danger'
      });

      auth?.value?.signOut();
    }
  };
  return {
    systemLogin,

    showRetryModal,
    retryDetails,
    customErrorMessage
  };
}
