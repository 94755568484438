<template>
  <label
    id="upload-doc"
    class="upload-document flex-column d-flex align-items-center justify-content-center position-relative"
    :class="{ highlighted }"
  >
    <input ref="input" type="file" multiple @input="handleFilesInput" />
    <span class="text"> Drag and drop here </span>
    <div class="text mt-3 or-text position-relative w-100">OR</div>
    <span class="upload-btn mt-3"> Choose files </span>
  </label>
</template>

<script>
import { onBeforeUnmount, onMounted, ref } from 'vue';
export default {
  name: 'UploadDocumentZone',
  emits: ['upload'],
  setup(_, { emit }) {
    const highlighted = ref(false);
    const input = ref(null);

    const formatFiles = (files) => {
      return [...files].map((el, index) => {
        return {
          file: el,
          id: +Date.now() - index * 100
        };
      });
    };

    const handleFilesInput = (e) => {
      emit('upload', formatFiles(e.target.files));
      input.value.value = '';
    };

    const handleDrop = (e) => {
      let dt = e.dataTransfer;
      let files = dt.files;
      const asArray = formatFiles(files);

      emit('upload', asArray);
    };

    const preventDefaults = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const toggleHighlight = (value) => {
      highlighted.value = value;
    };

    const highlight = () => {
      toggleHighlight(true);
    };

    const unhighlight = () => {
      toggleHighlight(false);
    };

    onMounted(() => {
      const dropArea = document.getElementById('upload-doc');

      ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
        dropArea.addEventListener(eventName, preventDefaults, false);
      });

      ['dragenter', 'dragover'].forEach((eventName) => {
        dropArea.addEventListener(eventName, highlight, false);
      });

      ['dragleave', 'drop'].forEach((eventName) => {
        dropArea.addEventListener(eventName, unhighlight, false);
      });

      dropArea.addEventListener('drop', handleDrop, false);
    });

    onBeforeUnmount(() => {
      const dropArea = document.getElementById('upload-doc');

      ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
        dropArea.removeEventListener(eventName, preventDefaults, false);
      });

      ['dragenter', 'dragover'].forEach((eventName) => {
        dropArea.removeEventListener(eventName, highlight, false);
      });

      ['dragleave', 'drop'].forEach((eventName) => {
        dropArea.removeEventListener(eventName, unhighlight, false);
      });

      dropArea.removeEventListener('drop', handleDrop, false);
    });

    return {
      input,
      highlighted,

      handleFilesInput
    };
  }
};
</script>

<style lang="scss" scoped>
.upload-document {
  height: 250px;
  background: var(--c-grey-light);
  border: 3px dashed var(--c-grey-medium_active);
  border-radius: 15px;
  overflow: hidden;

  &::before {
    display: block;
    width: 0;
    height: 0;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    transition: opacity 0.2s ease;
    background-color: var(--c-primary);
  }

  .text {
    font-size: 16px;
    font-weight: 500;
    color: var(--c-dark);
  }

  .or-text {
    font-weight: 600;
    text-align: center;
    color: var(--c-dark);
    font-size: 14px;

    &::before,
    &::after {
      content: '';
      display: block;
      height: 1px;
      background: var(--c-grey-medium_active);
      width: 25%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    &::before {
      left: 50%;
      transform: translateX(-120%);
    }

    &::after {
      right: 50%;
      transform: translateX(120%);
    }
  }

  .upload-btn {
    border-radius: 15px;
    background-color: var(--c-primary);
    color: var(--c-white);
    padding: 5px 10px;
    display: inline-block;
    text-align: center;
    transition: all 0.2s ease;

    &:hover {
      background-color: #e18f2b;
      cursor: pointer;
    }
  }

  input {
    opacity: 0;
    height: 0;
    width: 0;
    position: absolute;
  }
}

.highlighted {
  &::before {
    opacity: 0.5;
    height: 100%;
    width: 100%;
    z-index: 2;
  }
}
</style>
