<template>
  <div class="d-flex mobile-100 flex-row">
    <div class="align-items-end">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download mr-3" viewBox="0 0 16 16">
              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
      </svg>
      <a 
        href="#"
        style="text-decoration: none; font-weight: 600;"
        class="subs-btn mr-2"
        @click="generatePdf">
        download pdf
      </a>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/base/BaseButton.vue';
import MicrosoftExcel from 'vue-material-design-icons/MicrosoftExcel.vue';
import FilePdfBox from 'vue-material-design-icons/FilePdfBox.vue';
import useExcel from '@/composables/common/excel';
import { computed, toRefs } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'ReportButtons',
  components: { MicrosoftExcel, FilePdfBox, BaseButton },
  props: {
    name: {
      type: String,
      default: 'data'
    },
    hidePdf: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: () => []
    },
    details: {
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    const store = useStore();

    const { name, list, details } = toRefs(props);
    const { writePdf } = useExcel(name.value);

    const generatePdf = () => {
      writePdf(list.value, details.value);
    };

    const role = computed(() => {
      return store.getters['role'];
    });

    return {
      role,
      generatePdf
    };
  }
};
</script>

<style lang="scss" scoped>
.loader {
  z-index: 2;
}

.table {
  height: 75vh !important;
}

.subs-btn {
  height: 40px;
  padding: 0;
  background: #fff;
  border-color: #f6951e !important;
  color: #f6951e !important;

  &:hover {
    background: var(--c-grey-light) !important;
  }
}
</style>
