<template>
  <form @submit.prevent="submit">
    <h1 class="fz-14 fw-600 text-uppercase">Enter Apikey</h1>

    <base-input
      v-model="apikey"
      :error="apikeyError"
      placeholder="Enter apikey"
      class="mt-2"
    />

    <base-button type="submit" class="w-100 mt-2"> Submit </base-button>
  </form>
</template>

<script>
import BaseInput from '@/components/base/BaseInput.vue';
import BaseButton from '@/components/base/BaseButton.vue';

import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  apikey: yup.string().required('Field is required').nullable()
});

export default {
  name: 'ApikeyInput',
  components: {
    BaseInput,
    BaseButton
  },
  emits: ['submit'],
  setup(_, { emit }) {
    const { validate } = useForm({
      validationSchema
    });

    const { value: apikey, errorMessage: apikeyError } = useField(
      'apikey',
      null,
      {
        validateOnValueUpdate: false
      }
    );

    const submit = async () => {
      const result = await validate();

      if (!result.valid) {
        return;
      }

      emit('submit', apikey.value);
    };

    return {
      apikey,
      apikeyError,

      submit
    };
  }
};
</script>
