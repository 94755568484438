import { AgStackComponentsRegistry } from 'ag-grid-community';

export const copyArray = (arr) => {
  const result = [];

  for (let i = 0; i < arr.length; i++) {
    const el = arr[i];

    if (typeof el === 'object' && el) {
      result.push({ ...el });
      continue;
    }

    result.push(el);
  }

  return result;
};

export const deepCopy = (object) => {
  if (!object) {
    return object;
  }

  let result = {};

  for (const key in object) {
    if (Array.isArray(object[key])) {
      result = { ...result, [key]: copyArray(object[key]) };
      continue;
    }

    if (typeof object[key] === 'object' && object[key]) {
      result = { ...result, ...deepCopy(object[key]) };
      continue;
    }

    result[key] = object[key];
  }

  return result;
};

export const isNull = (value) => {
  if (value === null) {
    return true;
  }

  if (value === undefined) {
    return true;
  }

  if (typeof value === 'number' && isNaN(value)) {
    return true;
  }

  if (typeof value === 'string' && !value.trim()) {
    return true;
  }

  return false;
};

export const debounce = (f, delay) => {
  let timerId = null;

  return () => {
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      f();
    }, delay);
  };
};

export const getDollarsValueFromCents = (v) => {
  const asNumber = +v;

  if (!asNumber) {
    return null;
  }

  return (asNumber / 100).toFixed(2);
};

export const dollarsParser = (str) => {
  const v = str || '';
  const splitted = v.split('+');
  const dollars = splitted[0];
  const cents = getDollarsValueFromCents(splitted[1]);

  if (dollars && cents) {
    return `$${dollars} + $${cents}`;
  }

  if (dollars) {
    return `$${dollars}`;
  }

  return '';
};

export const downloadFile = ({ file, name }) => {
  console.log('downloadFile start');
  const link = document.createElement('a');
  document.body.appendChild(link);
  console.log(link);
  console.log('downloadFile - link is above');
  link.href = 'data:application/octet-stream;base64,' + file;
  link.download = name;
  link.click();
  console.log('downloadFile fin');
};

export const mccTextValue = (option) => `${option.value} - ${option.text}`;

export const lookByDropdownTextAndValue = (options, searchValue) => {
  return options.filter((v) => {
    const text = (`${v.text}` || '').toLowerCase();
    const value = `${v.value}`.toLowerCase();

    return (
      text.includes(searchValue.toLowerCase()) ||
      value.includes(searchValue.toLowerCase())
    );
  });
};

export const dropdownSelectedTextWithValue = (option) => {
  if (!option) {
    return null;
  }

  return `${option.value} - ${option.text}`;
};

export const copyOrDefaultObject = (defaultObject, copyObject) => {
  let res = {};

  for (let key in defaultObject) {
    if (copyObject[key] === undefined || copyObject[key] === null) {
      res[key] = defaultObject[key];
      continue;
    }

    if (
      typeof copyObject[key] !== typeof defaultObject[key] &&
      defaultObject[key]
    ) {
      res[key] = defaultObject[key];
      continue;
    }

    if (typeof copyObject[key] === 'object') {
      res = {
        ...res,
        [key]: copyOrDefaultObject(defaultObject[key], copyObject[key])
      };
    }

    res[key] = copyObject[key];
  }

  return res;
};

export const updateDeepData = (state, data) => {
  let keys = data.keys;
  if (!keys) {
    keys = data.key.split('.');
  }

  if (keys.length === 1) {
    state[keys[0]] = data.value;

    return state;
  }

  updateDeepData(state[keys[0]], {
    ...data,
    keys: keys.slice(1, keys.length)
  });
};

export const getClientEditableData = (obj) => {
  // const res = {};

  // res.name = obj.name;
  // res.dba_name = obj.billing?.dba_name;
  // res.legal_name = obj.billing?.legal_name;
  // res.email = obj.email;
  // res.phone = obj.phone;
  // res.country = obj.country;
  // res.address = obj.address;
  // res.address2 = obj.address2;
  // res.state = obj.state;
  // res.zipcode = obj.zipcode;
  // res.city = obj.city;
  // res.apikey = obj.apikey;
  // res.mid = obj.mid;

  // const pause_settlement =
  //   typeof obj.config?.pause_settlement === 'boolean'
  //     ? obj.config?.pause_settlement
  //     : obj.pause_settlement;

  // res.pause_settlement =
  //   typeof pause_settlement === 'boolean' ? pause_settlement : false;

  // res.enabled =
  //   typeof obj.config?.enabled === 'boolean' ? obj.config?.enabled : true;

  // res.onboard = obj.config?.onboard || false;

  // res.crypto = obj.config?.crypto || '';
  // res.payment = obj.config?.payment || '';
  // // res.payout = obj.config?.payout || '';
  // res.terminals = obj.config.terminals || '';
  // res.payout = obj.config.payout || '';

  // res.enroll = obj.config?.enroll;
  // res.high_ticket = obj.config?.high_ticket;
  // res.velocity = obj.config?.velocity;
  // res.timing = obj.config?.timing;
  // res.devices = obj.config?.devices;
  // res.transactions = obj.config?.transactions;
  // res.ccs = obj.config?.ccs;
  // res.ach = obj.config?.ach;
  // // res.pricing = {};

  // res.card_rate = obj.pricing?.card_rate || obj.pricing?.card;
  // res.crypto_rate = obj.pricing?.crypto_rate || obj.pricing?.crypto;
  // res.ach_rate = obj.pricing?.ach_rate;
  // res.low_rate = obj.pricing?.low_rate || obj.pricing?.low;
  // res.etf = obj.pricing?.etf;
  // res.card_p2c = obj.pricing?.card_p2c;
  // res.ach_p2c = obj.pricing?.ach_p2c;
  // res.crypto_p2c = obj.pricing?.crypto_p2c;
  // res.parent_card_rate = obj.pricing?.parent_card_rate || obj.parent?.card_rate;

  // res.mccCode = obj.billing?.mccCode;
  // res.parent_mccCode = obj.config?.parent_mccCode || obj.parent?.mccCode;
  // res.contacts = obj.contacts || [];

  // return res;
  const flattenObj = (obj) => {
    const res = {};
    for (const i in obj) {
      if (
        typeof obj[i] === 'object' &&
        !Array.isArray(obj[i]) &&
        ['config', 'pricing', 'billing', 'keystore'].includes(i)
      ) {
        const temp = flattenObj(obj[i]);
        for (const j in temp) {
          res[j] = temp[j];
        }
      } else {
        res[i] = obj[i];
      }
    }
    return res;
  };
  const res = flattenObj(obj);

  return res;
};

export default getClientEditableData;

export const agGridDateComparator = (filterLocalDateAtMidnight, cellValue) => {
  const dateAsString = cellValue || '';
  if (dateAsString == null) return -1;
  const dateValue = dateAsString.split(' ')[0];

  // yyyy-mm-dd
  const dateParts = dateValue.split('-');

  const cellDate = new Date(
    Number(dateParts[0]),
    Number(dateParts[1]) - 1,
    Number(dateParts[2])
  );

  if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
    return 0;
  }

  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  }

  if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  }
  return 0;
};

/**
 * Function to process and group the monthly volume data
 * @param {Array<Array<any>>} dataRows
 * @returns {
  Array<
  {month: string;
    transactions: number;
    total: number;
    fee: number;
  }>
} chartData
 */
export function processMonthlyVolume(dataRows) {
  if (!dataRows) return [];
  console.log(dataRows);
  console.log('Above are the datarows');

  // Extract headers and remove header row
  const headers = [
    'parentname',
    'parent',
    'client',
    'id',
    'mcc',
    'month',
    'transactions',
    'total',
    'avg',
    'fee'
  ];

  // Convert array of rows into array of objects
  const dataObjects = dataRows.map((row) => {
    const obj = {};
    headers.forEach((header, index) => {
      obj[header] = row[header];
    });
    return obj;
  });

  // Group by month and aggregate necessary fields
  const groupedData = dataObjects.reduce((acc, row) => {
    const date = new Date(row.month);
    const timestamp = date.getTime();

    const month = `${date.toLocaleString('default', {
      month: 'short'
    })} ${date.getFullYear()}`;

    // const month = `${String(date.getMonth() + 1).padStart(
    //   2,
    //   '0'
    // )}, ${date.getFullYear()}`; // 'MM, YYYY' format

    if (!acc[month]) {
      acc[month] = { transactions: 0, total: 0, fee: 0 };
    }
    acc[month].transactions += parseInt(row.transactions, 10);
    acc[month].total += parseFloat(row.total);
    acc[month].fee += parseFloat(row.fee);
    acc[month].timestamp = timestamp;
    return acc;
  }, {});

  // Convert grouped data into an array for charting, sorting by month
  const chartData = Object.keys(groupedData)
    .map((month) => ({
      month,
      transactions: groupedData[month].transactions,
      total: groupedData[month].total.toFixed(2), // Ensuring numerical fields are properly formatted
      fee: groupedData[month].fee.toFixed(2),
      timestamp: groupedData[month].timestamp
    }))
    .sort((a, b) => a.timestamp - b.timestamp);

  return chartData;
}

/**
 * Checks if user config payment method from DB value
 * @param {string | null} configurationValue
 */
export const paymentMethodAvailable = (configurationValue) => {
  if (!configurationValue) {
    return false;
  }
  if (typeof configurationValue === 'string') {
    const normalizedValue = configurationValue.toLowerCase();
    if (normalizedValue === 'false') {
      return false;
    }
    if (normalizedValue.length === 0) {
      return false;
    }
    return true;
  }
  return false;
};

/**
 * Creates a deep copy of an object or array.
 * @param {Object|Array} obj The object or array to copy.
 * @returns {Object|Array} A deep copy of the object or array.
 */
export const stringifiedDeepCopy = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};
