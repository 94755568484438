import { ref } from 'vue';
import { PAYMENT_TYPES } from '@/config/constants.js';

export default function ({ emailAddressOption }) {
  const disabledEmailOptions = ref([]);

  const moveEmailOptionTo = (value, state, emailStateCondition) => {
    if (!value) {
      return;
    }

    if (emailStateCondition === undefined) {
      emailAddressOption.value = state;
      return;
    }

    if (emailAddressOption.value === emailStateCondition) {
      emailAddressOption.value = state;
    }
  };

  const handlePaymentTypeChange = (type) => {
    if (type === PAYMENT_TYPES.SUBSCRIPTION) {
      emailAddressOption.value = 'required';
      disabledEmailOptions.value = [false, 'optional'];
      return;
    }

    disabledEmailOptions.value = [];
  };

  return {
    disabledEmailOptions,

    moveEmailOptionTo,
    handlePaymentTypeChange
  };
}
