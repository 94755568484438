<template>
  <div class="clients management-page">
    <div class="mt-1 position-relative">
      <div class="title" style="color: #383838; font-size: 24px;">Transactions 3x Over Standard Deviation</div>

<!--      <AgGrid-->
<!--        ref="agref"-->
<!--        :apiurl = "apiUrl"-->
<!--        :columns = "[]"-->
<!--        :defineDefs = "columnDefs"-->
<!--        :sideBar = true-->
<!--        pagination="false"-->
<!--        groupPanel = ''-->
<!--        :excelOptions = "{fileName:'Risk Report.xlsx'}"-->
<!--        height="640px"-->
<!--    ></AgGrid>-->
      <div class="risk-table">
        <BaseSpinner v-if="monitoredTransactionsLoading" />
        <table v-else class="table">
          <thead>
          <tr>
            <th>Merchant</th>
            <th>Transaction</th>
            <th>Amount</th>
            <th>Created At</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="t in monitoredTransactions" :key="t.transaction_id">
            <td>{{ t.merchant }}</td>
            <td>{{ t.transaction_id }}</td>
            <td>{{ t.amount }}</td>
            <td>{{ formatDate(t.created_at) }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import AgGrid from '@/components/AgGrid.vue'
import BaseSpinner from "@/components/base/BaseSpinner.vue";
import {router} from "@/router";
import CardTableItem from "@/components/cards/CardTableItem.vue";
import useService from "@/composables/common/services";
const { apiService } = useService();
import http from "@/services/http";

const { DateTime } = require('luxon');

const monitoredTransactionsLoading = ref(false);
const monitoredTransactions = ref([]);

// Date formatting function
function formatDate(dateString) {
  if (!dateString) return '';
  const date = DateTime.fromISO(dateString);
  return date.toFormat('yyyy-MM-dd HH:mm:ss');
}

async function fetchMonitoredTransactions() {
  monitoredTransactionsLoading.value = true;
  try {
    const response = await apiService.get(`api/transaction-monitoring`);
    monitoredTransactions.value = [...response.message.standard_deviation_list];
  } catch (error) {
    console.error('Error fetching risk information:', error);
  } finally {
    monitoredTransactionsLoading.value = false;
  }
}


onMounted(() => {
  fetchMonitoredTransactions();
});

const apiUrl = ref(`/api/transaction-monitoring`)
const columnDefs = [
    {
      headerName: 'Merchant',
      valueGetter: (p) => { if(!p.data) return ''; return p.data.message.standard_deviation_list.merchant;},
    },
    {
      headerName: 'Transaction',
      valueGetter: (p) => { if(!p.data) return ''; return p.data.message.standard_deviation_list.transaction_id;},
    },
    {
      headerName: 'Amount',
      valueGetter: (p) => { if(!p.data) return ''; return p.data.message.standard_deviation_list.amount;},
    },
    {
      headerName: 'Created At',
      valueGetter: (p) => { if(!p.data) return ''; return p.data.message.standard_deviation_list.created_at;},
      sort:'desc',
      valueFormatter: (params) => {
        if (!params.value) {
          return '';
        }

        const date = new Date(params.value);

        const v = DateTime.fromJSDate(date);

        return v.toFormat('yyyy-MM-dd hh:mm:ss');
      }
    }
  ]
</script>

<style lang="scss" scoped>
.loader {
  z-index: 2;
}
</style>

<style lang="scss">
.clients {
  .actions {
    height: 100% !important;
  }

  .action-btn {
    line-height: initial;
    width: 30px !important;
    height: 30px !important;
    padding: 2px 1px !important;
    font-size: 14px !important;
    border-radius: 50% !important;

    .icon-container {
      width: auto !important;

      svg {
        width: 19px !important;
      }
    }

    &.success {
      color: #fff !important;
    }
  }
}
</style>
