<template>
  <span class="tag" :class="{ value, minimized: minimized }">
    {{ value }}
  </span>
</template>

<script>
import { NOTE_CATEGORIES } from '@/config/constants.js';
import { toRef } from 'vue';

export default {
  name: 'NoteTag',
  props: {
    value: {
      type: String,
      default: null
    },
    minimized: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
  const value = toRef(props, 'value');

  const name = NOTE_CATEGORIES.find((el) => el.value === value.value);

  return {
    name
  };
  }
};
</script>

<style lang="scss" scoped>
.tag {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  border-radius: 10px;
  border: 1px solid #000;
  padding: 3px 10px;

  &.minimized {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &.risk,
  &.dnc {
    background: #fff6f6;
    color: #7b1919;
    border-color: #7b1919;
  }

  &.operations {
    background: #f6fff6;
    color: #375e14;
    border-color: #375e14;
  }

  &.note {
    background: #f2ffff;
    color: #0c6563;
    border-color: #0c6563;
  }

  &.cto {
    background: #f3f2ff;
    color: #1f195e;
    border-color: #1f195e;
  }

  &.system {
    background: #fff9f1;
    color: #ffb553;
    border-color: #ffb553;
  }
}
</style>
