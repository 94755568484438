import { useForm, useField } from 'vee-validate';
import { useRoute } from 'vue-router';
import {
  API_KEY_LS_NAME,
  PAYMENT_TYPES,
  PAYMENT_OPTIONS,
  ZIP_CODE_OPTIONS
} from '@/config/constants.js';
import * as yup from 'yup';
const { DateTime } = require('luxon');
import { useStore } from 'vuex';

const getStringFromQuill = (value) => {
  const currV = (value && value[0]) || '';
  const insert = (currV || {}).insert || '';

  let asString;

  if (typeof insert !== 'string' && insert) {
    asString = '';
  } else {
    asString = insert;
  }

  const newV = asString.replaceAll('\n', '');

  return newV;
};

const validationSchema = yup.object().shape({
  // apiKey: yup.string().when('showApiKey', (showApiKey, schema) => {
  //   if (showApiKey) {
  //     return schema.required('Field is required').nullable();
  //   }

  //   return schema.nullable();
  // }),
  paymentOptions: yup.array().min(1, 'Plese select available payment options'),
  amount: yup
    .number()
    .typeError('Amount is not valid')
    .required('Field is required')
    .nullable(true),
  startDate: yup.string().when('paymentType', (paymentType, schema) => {
    if (paymentType === PAYMENT_TYPES.CHARGE) {
      return schema.nullable();
    }

    return schema
      .test('not-past', 'Start date can not be in past', (v) => {
        const now = DateTime.now().startOf('day');
        const date = DateTime.fromISO(v).startOf('day');

        return date >= now;
      })
      .required('Field is required');
  }),
  initialStartDate: yup.string(),
  endDate: yup.string().nullable(),
  // .when(['paymentType', 'cycles'], (paymentType, cycles, schema) => {
  //   // if (paymentType === PAYMENT_TYPES.CHARGE) {
  //   //   return schema.nullable();
  //   // }

  //   // if (!cycles) {
  //   //   return schema.required('Field is required').nullable();
  //   // }

  //   return schema.nullable();
  // }),
  cycles: yup.number().when('paymentType', (paymentType, schema) => {
    // if (paymentType === PAYMENT_TYPES.CHARGE) {
    //   return schema.nullable(true);
    // }

    return schema.nullable(true).transform((_, value) => {
      return typeof value === 'number' && !isNaN(value) ? value : 0;
    });
  }),
  showTrialPeriod: yup.bool().nullable(),
  trialPeriod: yup.number().when('paymentType', (paymentType, schema) => {
    // if (paymentType === PAYMENT_TYPES.CHARGE) {
    //   return schema.nullable(true);
    // }

    return schema.nullable(true).transform((_, value) => {
      return typeof value === 'number' && !isNaN(value) ? value : 0;
    });
  }),
  interval: yup.string().when('paymentType', (paymentType, schema) => {
    if (paymentType === PAYMENT_TYPES.CHARGE) {
      return schema.nullable();
    }

    return schema.required('Field is required');
  }),
  frequency: yup.number().when('paymentType', (paymentType, schema) => {
    if (paymentType === PAYMENT_TYPES.CHARGE) {
      return schema.nullable(true);
    }

    return schema
      .typeError('Frequency is not valid')
      .required('Field is required')
      .nullable(true);
  }),
  email: yup.string().when('paymentType', (paymentType, schema) => {
    if (paymentType === PAYMENT_TYPES.CHARGE) {
      return schema.nullable();
    }

    return schema.email('Email is not valid').nullable();
  }),
  description: yup
    .array()
    .transform((_, value) => {
      return (value && value.ops) || [];
    })
    // .test('is-descr-valid', 'Field is required', () => {
    //   // const str = getStringFromQuill(value);

    //   // // if (!str) {
    //   // //   return false;
    //   // // }

    //   return true;
    // })
    .nullable(),
  allowTip: yup.boolean().nullable(true).notRequired(),
  showEmail: yup.boolean().nullable(true).notRequired(),
  zipMode: yup.string().nullable(true).notRequired()

  // cryptoWallets: yup.array().of(
  //   yup.object().shape({
  //     currency: yup.string().required('Field is required'),
  //     value: yup.string().required('Field is required')
  //   })
  // )
});

export default function () {
  const route = useRoute();
  const store = useStore();

  const { validate, resetForm } = useForm({
    validationSchema
  });

  // const { value: showApiKey } = useField('showApiKey', null, {
  //   initialValue: false
  // });

  // const {
  //   value: apiKey,
  //   errorMessage: apiKeyError,
  //   validate: validateApiKey
  // } = useField('apiKey', null, {
  //   validateOnValueUpdate: false
  // });

  const { value: paymentOptions, errorMessage: paymentOptionsError } = useField(
    'paymentOptions',
    null,
    {
      validateOnValueUpdate: false,
      initialValue: PAYMENT_OPTIONS.map((el) => el.value)
    }
  );

  const {
    value: amount,
    errorMessage: amountError,
    validate: validateAmount
  } = useField('amount', null, {
    validateOnValueUpdate: false
  });

  const { value: paymentType } = useField('paymentType', null, {
    initialValue: PAYMENT_TYPES.CHARGE
  });

  const {
    value: startDate,
    errorMessage: startDateError,
    validate: validateStartDate
  } = useField('startDate', null, {
    validateOnValueUpdate: false
  });

  const { value: initialStartDate } = useField('initialStartDate', null, {
    validateOnValueUpdate: false
  });

  const {
    value: endDate,
    errorMessage: endDateError,
    validate: validateEndDate
  } = useField('endDate', null, {
    validateOnValueUpdate: false
  });

  const {
    value: cycles,
    errorMessage: cyclesError,
    validate: validateCycles
  } = useField('cycles', null, {
    validateOnValueUpdate: false
  });

  const { value: showTrialPeriod } = useField('showTrialPeriod', null, {
    validateOnValueUpdate: false,
    initialValue: false
  });

  const {
    value: trialPeriod,
    errorMessage: trialPeriodError,
    validate: validateTrialPeriod
  } = useField('trialPeriod', null, {
    validateOnValueUpdate: false,
    initialValue: null
  });

  const {
    value: interval,
    errorMessage: intervalError,
    validate: validateInterval
  } = useField('interval', null, {
    validateOnValueUpdate: false,
    initialValue: 'monthly'
  });

  const {
    value: frequency,
    errorMessage: frequencyError,
    validate: validateFrequency
  } = useField('frequency', null, {
    validateOnValueUpdate: false,
    initialValue: 1
  });

  const {
    value: email,
    errorMessage: emailError,
    validate: validateEmail
  } = useField('email', null, {
    validateOnValueUpdate: false
  });

  const {
    value: description,
    errorMessage: descriptionError,
    validate: validateDescription
  } = useField('description', null, {
    validateOnValueUpdate: false
  });

  const { value: allowTip } = useField('allowTip', null, {
    validateOnValueUpdate: false,
    initialValue: false
  });

  const { value: showEmail } = useField('showEmail', null, {
    validateOnValueUpdate: false,
    initialValue: true
  });

  const { value: zipMode } = useField('zipMode', null, {
    validateOnValueUpdate: false,
    initialValue: ZIP_CODE_OPTIONS[0].value
  });

  // const storedApiKey = store.state.user?.details?.clientid;

  // const apiKeyFromLs = storedApiKey || localStorage.getItem(API_KEY_LS_NAME);

  // if (!apiKeyFromLs && !route.params.apikey) {
  //   showApiKey.value = true;
  // } else {
  //   showApiKey.value = false;
  // }

  // if (route.params.apikey) {
  //   apiKey.value = route.params.apikey;
  //   localStorage.setItem(API_KEY_LS_NAME, route.params.apikey);
  // }

  // if (apiKeyFromLs && !apiKey.value) {
  //   apiKey.value = apiKeyFromLs;
  // }

  return {
    amount,

    // amount value
    amountError,

    // Quill editor description text
    description,
    descriptionError,

    validateAmount,
    validateDescription,
    validate,
    resetForm,

    // apikey value
    // apiKey,
    // apiKeyError,
    // validateApiKey,

    // if no apikey in user object - show apikey input
    // showApiKey,

    // charge or subscription
    paymentType,

    // saved apikey in local storage
    // storedApiKey,

    // Activated Payment options (credit card, bank, crypto, terminal)
    paymentOptions,
    paymentOptionsError,

    // Show tip section to customer
    allowTip,

    // show email to customer
    showEmail,

    // Ask zipcode
    zipMode,

    // cryptoWallets,
    // cryptoWalletsError,

    // subscription
    trialPeriod,
    showTrialPeriod,
    trialPeriodError,
    validateTrialPeriod,
    startDate,
    initialStartDate,
    endDate,
    startDateError,
    validateStartDate,
    endDateError,
    validateEndDate,
    cycles,
    cyclesError,
    validateCycles,
    frequency,
    frequencyError,
    validateFrequency,
    interval,
    intervalError,
    validateInterval,
    email,
    emailError,
    validateEmail
  };
}
