<template>
  <div class="d-flex align-items-center h-100 status-container">
    <base-tooltip v-if="reason" :title="reason">
      <span :class="currentClass" class="status-value">
        {{ currentStatus }}
      </span>
    </base-tooltip>

    <span v-else :class="currentClass" class="status-value">
      {{ currentStatus }}
    </span>
  </div>
</template>

<script>
import { computed, toRef } from 'vue';
import BaseTooltip from '@/components/base/BaseTooltip.vue';

const getStatus = (status = '') => {
  const currV = status || ' ';
  let result = (currV[0] || '').toUpperCase();
  result = result + currV.toLowerCase().slice(1, currV.length);

  if (result === 'False') {
    return 'Failed';
  }

  if (result === 'True') {
    return 'Success';
  }

  return result;
};

export default {
  name: 'OnboardingStatus',
  components: {
    BaseTooltip
  },
  props: {
    params: {
      type: Object,
      default: null
    }
  },
  setup(props) {
    const params = toRef(props, 'params');

    const status = computed(() => {
      return params.value?.value;
    });

    const reason = computed(() => {
      return params.value?.data?._reject_reason;
    });

    const currentStatus = computed(() => {
      return getStatus(status.value);
    });

    const currentClass = computed(() => {
      const currV = currentStatus.value + '';
      return currV.replace(' ', '_');
    });

    return {
      currentStatus,
      currentClass,
      reason
    };
  }
};
</script>
