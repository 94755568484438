<template>
  <div class="d-flex flex-column align-items-center justify-content-center">
    <div class="color-success">
      <check :size="30" />
    </div>
    <p class="fz-16 fw-500 text-center color-dark mt-2">
      We have sent you a confirmation email. <br />
      Please check your inbox!
    </p>
  </div>
</template>

<script>
import Check from 'vue-material-design-icons/Check.vue';

export default {
  name: 'VerificationEmailSent',
  components: {
    Check
  }
};
</script>
