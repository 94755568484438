<template>
  <div class="w-100 position-relative">
    <base-modal v-model="showRetryModal">
      <login-retry :details="retryDetails" @submit="relogin" />
    </base-modal>
    <base-modal
      v-model="showVerificationEmailSentModal"
      :hide-close-btn="true"
      background-close
    >
      <verification-email-sent />
    </base-modal>

    <form class="w-100" @submit.prevent="sendMagicLink">
      <h2 class="text-center fs-3 mb-3">Welcome</h2>

      <p class="text-center">Securely log in using one of your accounts:</p>

      <social-networks-login
        class="my-3"
        @show-spinner="showSpinner = true"
        @hide-spinner="showSpinner = false"
        @retry="onRetry($event)"
        @show-verify-modal="showVerificationEmailSentModal = true"
        @custom-error="setCustomError($event)"
      />

      <div class="text-center mt-5">
        <p class="text-center">On a different device than usual?</p>
        <small class="text-center">
          Get a log-in link sent to your email.
        </small>
      </div>

      <div class="px-lg-5">
        <transition name="slide-y">
          <p v-if="customError" class="fz-14 color-danger fw-600 pb-2">
            {{ customError }}
          </p>
        </transition>

        <base-input
          v-model="email"
          placeholder="Email"
          class="mt-2 w-75 text-center mx-auto"
          :error="errors.email"
        />

        <base-button type="submit" class="w-75 mt-2 text-center mx-auto">
          Send Login Link
        </base-button>

        <div class="d-flex justify-content-center mt-5">
          <a
            href="https://3playpay.com/contact/"
            class="color-dark fw-bold account-btn"
          >
            Sign Up
          </a>
        </div>
      </div>

      <!-- <div class="text-center mx-auto mt-5">
          <p class="fz-12 fw-bold">Sandbox log-in instructions:</p>
          <p class="text-center mx-auto fz-12 w-50">Sandbox log-ins should use the email link 
            and change the URL in that link to sandbox.tripleplaypay.com.</p>
      </div> -->

      <base-modal v-model="showEmailModal" title="Please enter your email here">
        <form @submit.prevent="sendMagicLink">
          <base-spinner v-if="showEmailSpinner" />

          <p class="text-center color-grey-medium magic-descr pl-2">
            You'll receive login link there
          </p>

          <base-input
            v-model="magicEmail"
            placeholder="Email"
            label="Email"
            class="w-100 mt-2"
            :error="errors.magicEmail"
          />

          <div class="d-flex justify-content-center mt-4">
            <base-button
              class="px-3 w-100"
              type="submit"
              @click="showVerificationEmail = false"
            >
              Send
            </base-button>
          </div>
        </form>
      </base-modal>

      <base-spinner v-if="showSpinner" />
    </form>
  </div>
</template>

<script>
import { ref } from 'vue';
import {
  LoginWithEmailAndPassword,
  sendSsoLinkToEmail
} from '@/services/firebase/auth.js';
import useAuth from '@/composables/auth';
import useErrorHandler from '@/composables/common/error-handler';
import useToast from '@/composables/common/toast';
import useLogin from '@/composables/auth/useLogin.js';
import useRetry from '@/composables/auth/useRetry.js';
import { useStore } from 'vuex';
import BaseInput from '@/components/base/BaseInput.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import BaseSpinner from '@/components/base/BaseSpinner.vue';
import BaseModal from '@/components/base/BaseModal.vue';
import SocialNetworksLogin from '@/components/auth/SocialNetworksLogin.vue';
import LoginRetry from '@/components/auth/LoginRetry.vue';
import VerificationEmailSent from '@/components/auth/VerificationEmailSent.vue';

export default {
  name: 'LoginPage',
  components: {
    BaseInput,
    BaseButton,
    BaseSpinner,
    BaseModal,
    SocialNetworksLogin,
    LoginRetry,
    VerificationEmailSent
  },
  setup() {
    const store = useStore();
    store.dispatch('fetchUser');
    const email = ref(null);
    const magicEmail = ref(null);
    const password = ref(null);
    const showSpinner = ref(false);
    const showEmailSpinner = ref(false);
    const showEmailModal = ref(false);
    const showVerificationEmailSentModal = ref(false);
    const customError = ref(null);

    const { showMessage } = useToast();
    const { auth } = useAuth();

    const { systemLogin } = useLogin(auth);
    const { onRetry, relogin, showRetryModal, retryDetails } = useRetry(
      auth,
      showSpinner
    );

    const { errors, getFirebaseError, clearErrors } = useErrorHandler([
      'email',
      'password',
      'magicEmail'
    ]);

    const handleShowEmailModal = () => {
      showEmailModal.value = true;
      magicEmail.value = email.value;
    };

    const submit = async () => {
      clearErrors();
      customError.value = null;

      showSpinner.value = true;

      try {
        const userData = await LoginWithEmailAndPassword({
          email: email.value,
          password: password.value,
          auth: auth.value
        });

        if (!userData.user.emailVerified) {
          showMessage({
            title: 'Error',
            text: 'Please verify your email.',
            type: 'danger'
          });
          showSpinner.value = false;
          auth.value.signOut();

          return;
        }
      } catch (err) {
        auth.value.signOut();
        errors.password = getFirebaseError(err);
        showSpinner.value = false;

        return;
      }

      systemLogin();
    };

    const sendMagicLink = () => {
      // showEmailSpinner.value = true;
      clearErrors();
      showSpinner.value = true;

      sendSsoLinkToEmail({
        email: email.value,
        auth: auth.value
      })
        .then(() => {
          showSpinner.value = false;
          // showEmailSpinner.value = false;
          // showEmailModal.value = false;
          // localStorage.setItem('magic-email', magicEmail.value);
          email.value = null;

          showMessage({
            title: 'Success',
            text: 'Email was sent to you. Please check your inbox!',
            type: 'success'
          });
        })
        .catch((err) => {
          showSpinner.value = false;
          errors.email = getFirebaseError(err);
          showEmailSpinner.value = false;
        });
    };

    const setCustomError = (v) => {
      customError.value = v;
    };

    return {
      retryDetails,
      showRetryModal,
      customError,

      email,
      password,
      errors,
      showSpinner,
      showEmailModal,
      sendMagicLink,
      showEmailSpinner,
      handleShowEmailModal,
      magicEmail,
      showVerificationEmailSentModal,

      submit,
      onRetry,
      relogin,
      setCustomError
    };
  }
};
</script>

<style lang="scss" scoped>
.account-btn,
.create-link-text {
  @include transition;

  &:hover {
    color: var(--c-black);
  }
}

.magic-link {
  font-size: 14px;
}

.magic-descr {
  font-size: 14px !important;
}
</style>
