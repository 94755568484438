// usePageDesign.js
import { APPEARANCE_OPTIONS } from '@/config/constants';
import { reactive } from 'vue';
import { stringifiedDeepCopy } from '@/helpers/index.js';

const DEFAULT_PAGE_DESIGN = {
  appearance: APPEARANCE_OPTIONS.default,
  // layout: 'simple',
  // colorPrimary: '#000000',
  // colorPrimaryHover: '#30313d',
  // colorSecondary: '#f6951e',
  // colorSecondaryBackground: '##fffbf5',
  // colorBackground: '#ffffff',
  // colorText: '#30313d',
  // colorDanger: '#df1b41',
  // fontFamily: 'Ideal Sans, system-ui, sans-serif',
  // spacingUnit: '2px',
  // borderRadius: '4px',

  inputStyle: {
    appearance: APPEARANCE_OPTIONS.default, // (ie, default is "simple")
    mode: 'simple',
    focusBorderColor: '#f6951e'
    //   activeAccentColor: '#0570de',
    //   textColor: '#30313d',
    //   fontFamily: 'Ideal Sans, system-ui, sans-serif'
    // Add more universal input style properties here
  },
  /* checkboxStyle: {
    appearance: APPEARANCE_OPTIONS.default, // if default skip saving style params
    selectedColor: '#0570de'
  }, */
  paymentMethodStyle: {
    appearance: APPEARANCE_OPTIONS.default, // if default skip saving style params
    selectedBackgroundColor: '#fffbf5',
    selectedTextColor: '#f6951e',
    selectedBorderColor: '#f6951e',
    hoverBackgroundColor: '#f6951e',
    hoverTextColor: '#ffffff',
    hoverBorderColor: '#fffbf5',
    borderColor: '#f1f2f5',
    backgroundColor: '#f3f3f3',
    textColor: '#939393'
  },
  submitButtonStyle: {
    appearance: APPEARANCE_OPTIONS.default, // if default skip saving style params
    backgroundColor: '#000000',
    textColor: '#ffffff',
    // fontFamily: 'Ideal Sans, system-ui, sans-serif',
    // borderRadius: '4px',
    hoverBackgroundColor: '#30313d',
    hoverTextColor: '#ffffff'
  }
};

/**
 * General style behavior plans:
 * Primary color is used for selected items, buttons, and other interactive elements.
 * Secondary color used for hovered item backgrounds
 * and selected item border colors.
 * Background color is used for input backgrounds (???).
 * If certain elements such as inputs or checkboxes have more specific styles,
 * then those styles will be applied in addition to the general styles.
 * If an appearance is set to 'default', then the default styles will be used and
 * the styles will be omitted from the final URL params.
 *
 * TODO: create original objects for each element's style
 * and write restore-to-default functions for each element.
 *
 * If any element gets modified, the appearance will be set to 'custom'.
 */
export default function usePageDesign() {
  const pageDesign = reactive(stringifiedDeepCopy(DEFAULT_PAGE_DESIGN));

  const setStyles = (styles) => {
    Object.assign(pageDesign, stringifiedDeepCopy(styles));
  };

  /**
   * Sets specific component's styles in the pageDesign object.
   * @param {string} componentName One of inputStyle, checkboxStyle, paymentMethodStyle, submitButtonStyle
   * @param {*} styles
   */
  const setComponentStyles = (componentName, styles) => {
    // console.log('setComponentStyles', componentName, JSON.stringify(styles));
    // Check if the incoming styles differ from the default styles
    const defaultStyles = DEFAULT_PAGE_DESIGN[componentName];
    const isCustom = Object.keys(styles).some(
      (key) => styles[key] !== defaultStyles[key]
    );

    // If styles are custom, set appearance to 'custom', else keep it as is
    const updatedStyles = isCustom
      ? { ...styles, appearance: APPEARANCE_OPTIONS.custom }
      : styles;
    console.log('updatedStyles for', componentName, 'is custom:', isCustom);
    // Apply the updated styles
    Object.assign(
      pageDesign[componentName],
      stringifiedDeepCopy(updatedStyles)
    );
    if (isCustom) {
      pageDesign.appearance = APPEARANCE_OPTIONS.custom;
    }
  };

  /**
   * Returns a final style object to be encoded on iframe params.
   * Removes any nested style objects from the final object that
   * have an appearance set to 'default'.
   * Otherwise, the custom nested stylings are kept and the appearance
   * property is removed from the final object.
   * @param {*} pageDesign
   * @returns
   */
  const generateStylesObjectForIFrame = (pageDesign) => {
    if (pageDesign.appearance === APPEARANCE_OPTIONS.default) {
      return null;
    }

    const result = {};

    Object.keys(pageDesign).forEach((key) => {
      if (pageDesign[key] && typeof pageDesign[key] === 'object') {
        if (pageDesign[key].appearance !== APPEARANCE_OPTIONS.default) {
          const { appearance, ...rest } = pageDesign[key];
          result[key] = rest;
        }
      } else {
        result[key] = pageDesign[key];
      }
    });
    return result;
  };

  const getStyles = () => {
    // let inputStyle = {};
    // if (pageDesign.value === 'simple') {
    //   inputStyle = {
    //     borderLeftStyle: 'hidden',
    //     borderTopStyle: 'hidden',
    //     borderRightStyle: 'hidden',
    //     borderRadius: '0',
    //     background: 'transparent',
    //     borderColor: '#d2d2d2',
    //     borderStyle: 'solid',
    //     borderWidth: '1px'
    //   };
    // }
    // return {
    //   '--color-primary': pageDesign.colorPrimary,
    //   '--color-background': pageDesign.colorBackground,
    //   '--color-text': pageDesign.colorText,
    //   '--color-danger': pageDesign.colorDanger,
    //   '--font-family': pageDesign.fontFamily,
    //   '--spacing-unit': pageDesign.spacingUnit,
    //   '--border-radius': pageDesign.borderRadius
    // };
  };

  const resetStyles = () => {
    setStyles(DEFAULT_PAGE_DESIGN);
  };

  const resetComponentStyles = (componentName) => {
    setComponentStyles(componentName, DEFAULT_PAGE_DESIGN[componentName]);
  };

  return {
    pageDesign,
    getStyles,
    generateStylesObjectForIFrame,
    resetStyles,
    resetComponentStyles,
    setComponentStyles
  };
}
