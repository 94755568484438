<template>
  <div class="pt-3">
    <p class="fw-500 fz-14 color-dark mb-2">Selected files</p>
    <transition-group name="slide-y" tag="ul" class="list">
      <li
        v-for="(file, index) in selectedFiles"
        :key="file.id"
        class="mb-3 list-el py-2 px-3 d-flex align-items-center position-relative w-100"
      >
        <div class="img mr-2">
          <img :src="file.img" alt="" />
        </div>

        <p class="fz-14 color-dark fw-500 m-0 filename">
          {{ file.name }}
        </p>

        <div
          class="remove d-flex align-items-center justify-content-center"
          @click="handleRemoveFile(index)"
        >
          <close />
        </div>
      </li>
    </transition-group>
  </div>
</template>

<script>
import { ref, toRefs } from 'vue';
import Close from 'vue-material-design-icons/Close.vue';
import { watch } from 'vue';

const availableExtensions = ['doc', 'docx', 'jpg', 'jpeg', 'pdf', 'png', 'txt'];

const IMAGES = {
  doc: require('@/assets/icons/ext/doc.svg'),
  docx: require('@/assets/icons/ext/doc.svg'),
  file: require('@/assets/icons/ext/file.svg'),
  jpg: require('@/assets/icons/ext/jpg.svg'),
  jpeg: require('@/assets/icons/ext/jpg.svg'),
  pdf: require('@/assets/icons/ext/pdf.svg'),
  png: require('@/assets/icons/ext/png.svg'),
  txt: require('@/assets/icons/ext/txt.svg')
};

export default {
  name: 'SelectedFiles',
  components: {
    Close
  },
  props: {
    files: {
      type: Array,
      default: () => []
    }
  },
  emits: ['remove'],
  setup(props, { emit }) {
    const { files } = toRefs(props);
    const selectedFiles = ref([]);

    watch(
      () => {
        return files.value;
      },
      (newV) => {
        selectedFiles.value = files.value.map((fileEl) => {
          const ext = fileEl.file.type.split('/')[1];
          let img;

          if (availableExtensions.includes(ext)) {
            img = IMAGES[ext];
          } else {
            img = IMAGES.file;
          }

          return {
            id: fileEl.id,
            img,
            name: fileEl.file.name
          };
        });
      },
      {
        immediate: true,
        deep: true
      }
    );

    const handleRemoveFile = (index) => {
      emit('remove', index);
    };

    return {
      selectedFiles,
      handleRemoveFile
    };
  }
};
</script>

<style lang="scss" scoped>
.list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.img {
  width: 30px;
  min-width: 30px;

  img {
    width: 100%;
    height: auto;
  }
}

.remove {
  position: absolute;
  right: 0;
  height: 100%;
  z-index: 2;
  cursor: pointer;
  width: 0;
  transition: all 0.2s ease;
  color: var(--c-white);
  background-color: var(--c-danger);
  overflow: hidden;

  &:hover {
    background-color: var(--c-danger-dark);
  }
}

.list-el {
  transition: all 0.2s ease;

  &:hover {
    background-color: var(--c-grey-light);

    .remove {
      width: 50px;
    }
  }
}

.filename {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
