// import { useRoute } from 'vue-router';
import apiService from '@/services/api-service';
import HttpService from '@/services/api-service/http.js';
// import WebsocketService from '@/services/api-service/websocket.js';
import { store } from '@/store';

export default function () {
  apiService.setServices([HttpService]);

  apiService.init({
    token:
      store?.state?.user?.details?.clientid[0]
  });

  return {
    apiService
  };
}
