import { store } from '@/store';
import http from '@/services/http';

/**
 * This class handles cookies.
 * 
 * Just leaving a note here, the original login payload for a user was as follows: 
 * 
 {
    "status": true,
    "id": <ID_VALUE>,
    "message": {
        "tags": {},
        "hash": "ezeH8H",
        "name": "Jeremy Bolin",
        "alias": "jb",
        "avatar": null,
        "zipcode": null,
        "email": "jb@tripleplaypay.com",
        "phone": "",
        "url": null,
        "openticket": false,
        "position": null,
        "origin": "microsoft.com",
        "wallet": [],
        "_domain": ".tripleplaypay.com",
        "_path": "/",
        "_creation_time": 1701895008.9952762,
        "_accessed_time": 1701895026.3770678,
        "_expires": "2038-01-19 03:14:07",
        "userzip": 0,
        "ip": "127.0.0.1",
        "label": "",
        "utype": "admin",
        "clientid": [
            <CLIENT_ID>
        ],
        "clientname": {
            <CLIENT_ID>: "TriplePlayPay"
        },
        "version": "1.9.2"
    },
    "method": "user"
 }
 * 
 */
class CookieService {
  /**
   * Setup our initial set of elements.
   */
  constructor() {
    this.user = {};
    this.claims = {};
    this.identity = {};
    this.cookies = [];

    this.isAuthenticated = false;

    this.log('Online.');
  }

  getClaimsToken() {
    const claimsPrefix = 'claims=';
    this.log(`${this.claimsToken} was just accessed`);
    const listCookies = document.cookie.split(' ');

    try {
      let claimsCookie = listCookies.filter((c) => c.startsWith(claimsPrefix));
      this.log(`claimsCookie Processing: ${claimsCookie}`);
      if (claimsCookie && claimsCookie.length > 0) {
        const claimsToken = claimsCookie.substring[0](
          claimsPrefix.length
        ).slice(0, -1);
        this.log(`ClaimsCookie Processing: ${claimsToken}`);

        return claimsToken;
      }
    } catch (err) {
      console.error(err);
      console.error('Error with getting claimsToken from cookie value.');
    }
  }

  getIdentityToken() {
    const identityPrefix = 'identity=';
    const listCookies = document.cookie.split(' ');
    this.log(`getIdentityToken was just accessed`);

    try {
      let identityCookie = listCookies.filter((i) =>
        i.startsWith(identityPrefix)
      );
      this.log(`identityCookie Processing: ${identityCookie}`);
      if (identityCookie && identityCookie.length > 0) {
        let identityToken = identityCookie[0]
          .substring(identityPrefix.length)
          .slice(0, -1);
        this.log(`identityToken Processing: ${identityToken}`);

        if (!identityToken) {
          console.log('Returning token from store');
          identityToken = store.getters.getToken();
          console.log('Token returned from store: ', identityToken);
        }

        return identityToken;
      }
    } catch (err) {
      console.error(err);
      console.error('Error with getting identityToken from cookie value.');
    }
  }

  async logoutFromRouter() {
    try {
      await http.post('/logout');
      this.log(`${this.logout} was just accessed`);
      store.commit('updateUser', {});
      store.commit('updateClaims', null);
      store.commit('isAuthenticated', false);
      store.dispatch('logout');
    } catch (error) {
      console.log(error);
      console.log(
        'Error above in getting the API Response for isAuthenticated - setting to false.'
      );
      this.isAuthenticated = false;
      return this.isAuthenticated;
    }
  }

  /**
   * Getter for ensuring we're always staying in sync with our server.
   */
  async logout() {
    const isAuthenticated = store.getters.isAuthenticated;
    console.log(isAuthenticated);
    console.log('COOKIE SERVICE LOGOUT - isAuthenticated is above');

    /**
     * This code is here to ensure we
     * only logout a user that's actually
     * authenticated in the first place.
     */
    if (isAuthenticated) {
      try {
        await http.post('/logout');
        this.log(`${this.logout} was just accessed`);
        store.commit('updateUser', {});
        store.commit('updateClaims', null);
        store.commit('isAuthenticated', false);
        store.dispatch('logout');
      } catch (error) {
        console.log(error);
        console.log(
          'Error above in getting the API Response for isAuthenticated - setting to false.'
        );
        this.isAuthenticated = false;
        return this.isAuthenticated;
      }
    }
  }

  /**
   * Getter for ensuring we're always staying in sync with our server.
   */
  async checkAuthentication() {
    console.log('COOKIESERVICE: ISAUTHENTICATED');
    try {
      const apiResponse = await http.post('/authenticated');
      console.log(`COOKIESERVICE: ${this.isAuthenticated} was just accessed`);
      console.log(
        `COOKIESERVICE: ${apiResponse} was just gotten from an auth perspective`
      );
      this.isAuthenticated = apiResponse.data.message;
      store.commit('isAuthenticated', this.isAuthenticated);
      return this.isAuthenticated;
    } catch (error) {
      console.log(error);
      console.log(
        'Error above in getting the API Response for isAuthenticated - setting to false.'
      );
      this.isAuthenticated = false;
      store.commit('isAuthenticated', false);
      return this.isAuthenticated;
    }
  }

  /**
   *
   * Logging convenience method.
   *
   * @param {string} message
   */
  log(message) {
    // console.log(`COOKIE_SERVICE: ${message}`);
  }

  /**
   *
   * Logging convenience method.
   *
   * @param {object} message
   */
  logJson(objectMessage) {
    // console.log(`COOKIE_SERVICE: ${JSON.parse(objectMessage)}`);
  }

  /**
   *
   * Will be used to parse the return value of a token from the backend.
   * We will be able to parse the `claims` portion of a custom token, and get the `clientid` value within.
   * This `clientid` value will serve as our application's default going forward.
   *
   * @param {string} token
   * @returns
   */
  parseJWT(token) {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }

  /**
   * 
   * The job of this function is to ensure that a user 
   * is smoothly logged into the platform on authentication.
   * 
   * 
   * 
   * Shape of the response here is as follows:
   * 
      {
        "id": "<UUID_HERE>",
        "uid": "<GCLOUD_UID>",
        "name": "First Last",
        "email": "email@address.com",
        "clientid": "<UUID_HERE>",
        "clientname": "Name Goes Here",
        "email_verified": BOOLEAN,
        "sign_in_provider": STRING,
        "custom_claims_token": {
            "token": "VALUE_HERE"
        }
   * 
   *
   * 
   * @param {object} authenticationResponse 
   */
  handleAuthentication(authenticationResponse) {
    console.log(authenticationResponse);
    console.log('authenticationResponse');
    // establish our own relevant data store
    this.user = authenticationResponse.data;
    // this.claimsToken = authenticationResponse.data.custom_claims_token.token;

    // const parsedClaimsToken = this.parseJWT(this.claimsToken);

    // this.claims = parsedClaimsToken.claims;

    // store.commit('updateClaims', this.claims);
    store.commit('isAuthenticated', true);
    store.dispatch('fetchUser');
    store.dispatch('fetchClients');

    // ensure we store the state of the authentication layer
    this.isAuthenticated = true;
  }
}

const cookieService = new CookieService();

export default cookieService;
