<script setup>
import { ref, onMounted, computed, watch } from 'vue';
import axios from 'axios';
import BaseSpinner from '@/components/base/BaseSpinner.vue';
import { useRoute } from 'vue-router'; // Import useRoute
import { PERMISSION_PRIMITIVES } from '@/config/constants';
import { useRouter } from 'vue-router';

import { useToast } from 'vue-toastification';

const toast = useToast();

const router = useRouter();

const route = useRoute();

// const props = defineProps({
//   apikey: {
//     type: String,
//     required: true
//   }
// });
const isSwipeOnly = ref(false);

const apikey = route.params.apikey;
// Get the full URL of the current page
const windowUrl = window.location.href;

// Create a URL object
const urlObj = new URL(windowUrl);

// Use URLSearchParams to get the query parameters
const urlSearchParams = new URLSearchParams(urlObj.search);

console.log("urlSearchParams: ", urlSearchParams);

// Access specific parameters
isSwipeOnly.value = urlSearchParams.get('isSwipeOnly');

console.log("isSwipeOnly: ", isSwipeOnly);

const iframeLink = ref('');
const defaultClientId = ref('');
const loading = ref(true);
const params = ref('');
const url = ref('');
const userId = ref('');
const merchantId = ref('');

/**
 * @typedef {{
 *   id: string
 *   order: int
 *   name: string
 *   type: string
 *   values?: Array<string>
 *   required: boolean
 *   is_hidden: boolean
 *   name: string
 * }} CustomField
 */

/**
 * @type {import("vue").Ref<Array<CustomField>>}
 */
const customFields = ref([]);
const locations = ref([]);

import { useStore } from 'vuex';
// import http from "@/services/http";

const store = useStore();
const user = computed(() => store.state.user);
const permissions = computed(() => store.state.permissions);
defaultClientId.value = computed(() => store.getters.defaultClientId);

watch(permissions, (newPermissions) => {
  if (Array.isArray(newPermissions) && newPermissions.length > 0) {
    rerunLogic();
  }
});

const merchantHasPermissions = computed(
  () => store.getters.merchantHasPermissions
);

async function rerunLogic() {
  console.log('Permissions Rendered:', permissions.value);
  console.log('isSwipeOnly Rendered:', isSwipeOnly.value);

  try {
    if (!apikey) {
      throw new Error('API key is required');
    }
    if (!url.value) {
      const response = await axios.get('/api/virtualterminalurl', {
        headers: { Authorization: `Bearer ${apikey}` }
      });
      url.value = response.data?.message?.url || '';
      customFields.value = response.data?.message?.customFields || [];
    }

    /**
     * Formatting of the URL Value
     * @type {module:url.URL}
     */
    const urlObj = new URL(url.value);
    const newUrl = new URL(
      urlObj.pathname + urlObj.search,
      window.location.origin
    );
    console.log('New URL with updated origin:', newUrl.href);
    console.log('User role:', user, user.value.role);

    /**
     * Gets userId and merchantId for iFrame Context
     */
    userId.value = user.value.id;
    merchantId.value = user.value?.account?.default_client?.merchant?.id;

    /**
     * Add userId and merchantId to the iFrame parameters
     * @type {module:url.URLSearchParams}
     */
    const searchParams = new URLSearchParams(newUrl.search);
    const paramsBase64 = searchParams.get('params');
    const paramsObj = JSON.parse(atob(paramsBase64));
    paramsObj.merchantId = merchantId.value;
    paramsObj.userId = userId.value;
    console.log('ParamsObj:', paramsObj);


    if (merchantHasPermissions.value) {
      paramsObj.billingAddress = false;
      // const default_options = ['credit_card', 'bank', 'swipe'];
      const permissionSet = new Set(
        user.value?.role?.granted_permissions?.map(
          (permission) => permission.name
        ) || []
      );
      console.log(
        'ALL PERMISSIONS FOR ROLE:',
        user.value?.role?.granted_permissions?.map(
          (permission) => permission.name
        )
      );

      /**
       * These come in from the pre-configured virtual terminal options.
       * In order to render a payment option, all three must be true:
       *
       *  * It is not swipe only configuration (this only ever shows swipe)
       *  * The payment option is included in the originalPaymentOptions
       *  * The user has the relevant permission to view this option
       *
       * @type {*[]}
       */
      const originalPaymentOptions = paramsObj.paymentOptions;
      paramsObj.paymentOptions = [];

      if (!permissionSet.has(PERMISSION_PRIMITIVES.VIRTUAL_TERMINAL)) {
        toast.error('User does not have VIRTUAL_TERMINAL permission.');

        router.push('/');
      }

      if (permissionSet.has(PERMISSION_PRIMITIVES.TRANSACTION_CHARGE) && !isSwipeOnly.value && originalPaymentOptions.includes('credit_card')) {
        paramsObj.paymentOptions.push('credit_card');
      }
      if (
        permissionSet.has(PERMISSION_PRIMITIVES.TRANSACTION_DEBIT_ACH_CHARGE) && !isSwipeOnly.value && originalPaymentOptions.includes('bank')
      ) {
        paramsObj.paymentOptions.push('bank');
      }

      // TODO: add permission to get cash
      if (
          !isSwipeOnly.value && originalPaymentOptions.includes('cash')
      ) {
        paramsObj.paymentOptions.push('cash');
      }

      if (isSwipeOnly.value || permissionSet.has(PERMISSION_PRIMITIVES.TRANSACTION_SWIPE) && originalPaymentOptions.includes('swipe')) {
        paramsObj.paymentOptions.push('swipe');
      }

      if (permissionSet.has(PERMISSION_PRIMITIVES.TRANSACTION_APPLE_PAY) && !isSwipeOnly.value && originalPaymentOptions.includes('apple_pay')) {
        paramsObj.paymentOptions.push('apple_pay');
      }

      if (permissionSet.has(PERMISSION_PRIMITIVES.TRANSACTION_GOOGLE_PAY) && !isSwipeOnly.value && originalPaymentOptions.includes('google_pay')) {
        paramsObj.paymentOptions.push('google_pay');
      }

      if (paramsObj.paymentOptions.length === 0) {
        toast.error(
          'User does not have permissions to use any payment methods.'
        );

        router.push('/');
      }

      if (permissionSet.has(PERMISSION_PRIMITIVES.OMIT_CC_ZIP)) {
        paramsObj.zipMode = null;
      }
    }
    if (paramsBase64 && user.value.role) {
      if (user.value.role.omittable_fields) {
        if (customFields.value) {
          console.log('ParamsObj.customFields:', paramsObj, customFields);
          customFields.value.forEach((field) => {
            if (
              user.value.role.omittable_fields.some(
                (omittable_field) => omittable_field.id === field.id
              )
            ) {
              field.required = false;
            }
          });
          paramsObj.customFields = customFields.value;
        }
      }
      if (user.value.role.hideable_fields) {
        if (customFields.value) {
          console.log('ParamsObj.customFields:', paramsObj, customFields);
          customFields.value.forEach((field) => {
            if (
                user.value.role.hideable_fields.some(
                    (omittable_field) => omittable_field.id === field.id
                )
            ) {
              field.hidden = true;
            }
          });
          paramsObj.customFields = customFields.value;
        }
      }
      if (user.value.role.granted_permissions) {
        if (
          user.value.role.granted_permissions.some(
            (permission) => permission.name === 'CC Zip'
          )
        ) {
          console.log('User has CC Zip Override permission');
          paramsObj.zipMode = null;
        }
      }
    }

    const updatedParamsBase64 = btoa(JSON.stringify(paramsObj));
    searchParams.set('params', updatedParamsBase64);
    newUrl.search = searchParams.toString();
    iframeLink.value = newUrl.href;
  } catch (error) {
    console.error('Error:', error);

    params.value = btoa(JSON.stringify({ apikey, isVt: true })); // Use the apikey from the URL query
    iframeLink.value = `${window.location.origin}/responsive-iframe/payment?params=${params.value}`;
  } finally {
    loading.value = false;
  }
}
console.log('Permissions Rendered:', permissions.value);
onMounted(async () => {
  await rerunLogic();
});
</script>
<template>
  <div>
    <div class="top-panel d-flex align-items-center">
      <button class="btn" @click="$router.go(-1)">
        <svg
          viewBox="0 0 16 16"
          class="bi bi-arrow-left"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M15 8a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 0 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 0 1 .708.708L2.707 7.5H14.5A.5.5 0 0 1 15 8z"
          />
        </svg>
        Back
      </button>
      <div class="mb-3 ms-auto me-auto">
        <span class="dashboard-title">Virtual Terminal</span>
      </div>
    </div>
    <div v-if="loading || permissions === 'FETCHING'">
      <BaseSpinner />
    </div>

    <iframe
      v-else
      :src="iframeLink"
      style="width: 100%; height: 100vh"
      frameborder="0"
    ></iframe>
  </div>
</template>
<style lang="scss">
.top-panel {
  display: flex;
  /* justify-content: space-between;
  align-items: center; */
}
.dashboard-title {
  font-size: 24px;
  color: #383838;
  /* margin-bottom: 10px; */
}
</style>
