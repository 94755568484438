import { onMounted, ref } from 'vue';
import { getAuth } from 'firebase/auth';

export default function () {
  const auth = ref(null);

  onMounted(async () => {
    auth.value = getAuth();
  });

  return {
    auth
  };
}
