<template>
  <div>
    <p class="fz-16 color-dark fw-500 text-center">
      Your account is created with
      <span class="fw-600">{{ signInMethod }}</span> method.
      <br />
      Click "Continue" to add new sign in method
    </p>

    <base-button class="w-100 mt-2" @click="$emit('submit')">
      Continue
    </base-button>
  </div>
</template>

<script>
import { computed, toRefs } from 'vue';

import BaseButton from '@/components/base/BaseButton.vue';

export default {
  name: 'LoginRetry',
  components: {
    BaseButton
  },
  props: {
    details: {
      type: Object,
      default: null
    }
  },
  emits: ['submit'],
  setup(props) {
    const { details } = toRefs(props);

    const signInMethod = computed(() => {
      const name = details.value && details.value.method;

      if (!name) {
        return '';
      }
      const truncated = name.replace('.com', '');
      return truncated[0].toUpperCase() + truncated.slice(1, truncated.length);
    });

    return {
      signInMethod
    };
  }
};
</script>
