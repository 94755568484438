<template>
  <div>
    <div class="d-flex justify-content-center">
      <check :size="50" class="color-success" />
    </div>

    <div>
      <h2 class="text-center color-success fz-28 fw-400">Success</h2>
      <p class="m-0 fz-16 color-grey-dark text-center">
        Link was successfully generated. <br />
        You can copy it below.
      </p>
    </div>

    <base-input readonly class="mt-3" :model-value="link">
      <template #suffix>
        <span role="button" @click="handleCopy"> <content-copy /> </span>
      </template>
    </base-input>

    <div
      class="fz-14 color-dark fw-600 text-center mt-3 or position-relative"
    >
      OR
    </div>

    <div v-if="qr" class="d-flex justify-content-center mt-5 mb-5 qr-container">
      <img :src="qr" alt="" class="qr-image" />
      <!-- <QRCodeVue3
        :value="link"
        :width="180"
        :height="180"
        :dots-options="{ color: '#313a56', type: 'square' }"
        :margin="0"
        image="https://tripleplaypay.com/static/images/LogoIcon_Stroke.png"
      /> -->
    </div>

    <base-button class="w-100" @click="$emit('close')"> Close</base-button>
  </div>
</template>

<script>
import Check from 'vue-material-design-icons/Check.vue';
import ContentCopy from 'vue-material-design-icons/ContentCopy.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import BaseInput from '@/components/base/BaseInput.vue';

import useCopy from '@/composables/common/clickboard/index.js';
import { toRef } from 'vue';

export default {
  name: 'NewLinkSuccess',
  components: { Check, ContentCopy, BaseButton, BaseInput },
  props: {
    link: {
      type: String,
      default: null
    },
    qr: {
      type: String,
      default: null
    }
  },
  emits: ['close'],
  setup(props) {
    const { copy } = useCopy();
    const link = toRef(props, 'link');

    const handleCopy = () => {
      copy(link.value);
    };

    return {
      handleCopy
    };
  }
};
</script>

<style lang="scss" scoped>
.qr-container {
  width: 250px;
  margin: auto;

  .qr-image {
    width: 250px;
    height: 250px;
  }
}

.or {
  &::before,
  &::after {
    content: '';
    display: block;
    height: 1px;
    background: var(--c-dark);
    width: 30%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &::before {
    left: 50%;
    transform: translateX(-120%);
  }

  &::after {
    right: 50%;
    transform: translateX(120%);
  }
}
</style>
