<template>
    <div class="nav-container mt-2" style="background-color: var(--c-grey-light);">
        <nav class="navbar navbar-expand-sm">
            <div class="container-fluid d-flex justify-content-start gap-2 align-items-center">
                <h3>{{ clientData.dba_name }}</h3>
                <div class="vr d-none d-lg-block mx-2"></div>
                <div v-if="user.user_type === 'admin' || user.user_type === 'healnow'" class="jump-to d-none d-lg-block w-lg-50">
                    <div class="d-flex flex-column">
                        <p style="border-bottom: 1px solid black; font-size: 10px; font-weight: bold;"><span class="ms-2">Jump to:</span></p>
                        <div v-if="user.user_type === 'admin' || user.user_type === 'healnow'" id="navbarNav" style="font-size: 12px;">
                            <ul class="navbar-nav">
                                <li class="nav-item dropdown">
                                    <a class="nav-link dropdown-toggle py-1" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Account Details</a>
                                    <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <a class="nav-link" href="#businessDetails">Business Details</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="#address">Business Address</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="#contact">Business Contact</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="#ownerDetails">Ownership</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="#settlementAccount">Settlement</a>
                                    </li>
                                    </ul>
                                </li>
                                <li class="nav-item dropdown d-none d-lg-block">
                                    <a class="nav-link dropdown-toggle py-1" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Processing</a>
                                    <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <a class="nav-link" href="#cards">Cards</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="#ach">ACH</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="#crypto">Crypto</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="#terminals">Terminals</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="#addtnl">Settings & Fees</a>
                                    </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div v-if="user.user_type === 'admin' || user.user_type === 'healnow'" class="ms-auto d-flex gap-1 flex-md-shrink">
                    <button id="editBtn" class="btn btn-primary edit-btn" @click="editAccount">Edit</button>
                    <div class="vr"></div>
                    <button id="saveBtn" class="btn btn-success save-btn" @click="saveChanges">Save</button>
                </div>
            </div>
        </nav>
    </div>
    <div class="container mt-3">
        <div class="d-flex flex-row">
            <div class="d-flex flex-column w-100">
                <h3>Onboarding Data</h3>
                <div v-if="user.user_type === 'admin' || user.user_type === 'parent' || user.user_type === 'healnow'">
                    <div class="fz-14 d-flex flex-column flex-md-row">
                        <span> Parent: {{ clientData.parent_id }}</span>
                        <span class="d-none d-md-block"> |</span>
                        <span> Last activity: {{ clientData.updated_at }}</span>
                    </div>
                </div>
            </div>
            <div class="d-flex gap-1 flex-md-shrink">
                <div>
                    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown">
                        Actions
                    </button>
                    <ul class="dropdown-menu">
                        <li><a class="dropdown-item" v-if="user.user_type === 'admin' || user.user_type === 'healnow'" href="#ctaSide" data-bs-toggle="offcanvas" aria-controls="ctaSide">CTA Options</a></li>
                        <li><a class="dropdown-item" v-if="user.user_type === 'admin' || user.user_type === 'healnow'" href="#kycSide" data-bs-toggle="offcanvas" aria-controls="kycSide">Manage KYC</a></li>
                        <!-- <li><a class="dropdown-item" v-if="user.user_type === 'admin' || user.user_type === 'healnow'" aria-controls="ctaSide" @click="sendCta">Quick-Send CTA</a></li> -->
                        <!-- <li><a class="dropdown-item" v-if="user.user_type === 'admin' || user.user_type === 'healnow'" :href="'/report/kyc/'+p.id" target="_blank" aria-controls="kycView">View KYC</a></li> -->
                        <li><a class="dropdown-item" v-if="user.user_type === 'admin' || user.user_type === 'healnow'" href="#" aria-controls="kycRun" @click="runKYC">Run KYC</a></li> 
                        <!-- <li><a class="dropdown-item" v-if="user.user_type === 'admin' || user.user_type === 'healnow'" href="#" @click="createSandbox">Create Sandbox</a></li> -->
                        <!-- <li><a class="dropdown-item" v-if="user.user_type === 'admin' || user.user_type === 'parent' || user.user_type === 'healnow'" href="#certSide" data-bs-toggle="offcanvas" aria-controls="certSide">Manage Dev. Certification</a></li> -->
                        <li><a class="dropdown-item" v-if="user.user_type === 'admin' || user.user_type === 'healnow'" href="#approveSide" data-bs-toggle="offcanvas" aria-controls="approveSide">Approve or Reject Client</a></li>
                        <!-- <li><a class="dropdown-item" v-if="user.user_type === 'admin' || user.user_type === 'healnow'" href="#" aria-controls="reject" @click="rejectClient">Reject Client</a></li> -->
                        <li><a class="dropdown-item" v-if="user.user_type === 'admin' || user.user_type === 'healnow'" href="#hierarchySide" data-bs-toggle="offcanvas" aria-controls="hierarchySide" @click="getParentOptions()">Edit Hierarchy</a></li>
                        <!-- <li><a class="dropdown-item" v-if="user.user_type === 'admin' || user.user_type === 'parent' || user.user_type === 'healnow'" href="#deSide" data-bs-toggle="offcanvas" aria-controls="deSide">Submit a Ticket</a></li> -->
                    </ul>
                </div>
                <div>
                    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown">
                        Open
                    </button>
                    <ul class="dropdown-menu">
                        <li><a class="dropdown-item" v-if="user.user_type === 'admin' || user.user_type === 'healnow'" href="#dataSide" data-bs-toggle="offcanvas">IDs & Full Data</a></li>
                        <li><a class="dropdown-item" v-if="user.user_type === 'admin' || user.user_type === 'healnow'" :href="`/app/account/${clientData.parent_id}`"  target="_blank">Parent Account</a></li>
                        <li><a class="dropdown-item" v-if="user.user_type === 'admin' || user.user_type === 'healnow'" href="#notesSide" data-bs-toggle="offcanvas" aria-controls="notesSide">Notes</a></li>
                        <li><a class="dropdown-item" v-if="user.user_type === 'admin' || user.user_type === 'healnow'" href="#docsSide" data-bs-toggle="offcanvas" aria-controls="docsSide">Documents</a></li>
                        <li><a class="dropdown-item" href="#userSide" data-bs-toggle="offcanvas" aria-controls="userSide" @click="fetchUserData(clientData.id)">Users & Contacts</a></li>
                        <li><a class="dropdown-item" @click="openTerms">Terms and Conditions</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- Users/Contacts Offcanvas -->
        <div class="offcanvas offcanvas-end" tabindex="-1" id="userSide" aria-labelledby="userSide" data-bs-backdrop="false" data-bs-scroll="true">
            <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="userSide">Manage Users & Contacts</h5>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas"></button>
            </div>
            <div class="offcanvas-body">
                <div>
                    <!-- User Cards -->
                    <div v-for="(user, index) in users" :key="index" class="accordion-item">
                        <div class="accordion-body">
                            <!-- User Card Content -->
                            <div>
                            <strong>Name:</strong> {{ user[3].name }}
                            </div>
                            <div>
                            <strong>Email:</strong> {{ user[3].email }}
                            </div>
                            <div>
                            <strong>Phone:</strong> {{ user[3].phone }}
                            </div>
                            <div>
                            <strong>Role:</strong> {{ user[3].user_type }}
                            </div>
                            <div>
                            <strong>Category:</strong> {{ user[3].label }}
                            </div>
                        </div>
                    </div>
                    <!-- User Card for Adding New User -->
                    <div class="accordion-body mt-3">
                        <h6>Add New User or Contact</h6>
                        <form class="position-relative" @submit.prevent="submitNewUser">
                        <!-- User Type Dropdown -->
                        <div class="form-group mb-2">
                            <label for="user_type">User Type:</label>
                            <select class="form-select" id="user_type" v-model="user_type" required>
                            <option value="user" selected>User</option>
                            <option value="contact">Contact</option>
                            </select>
                        </div>
                        <!-- Category Dropdown -->
                        <div class="form-group mb-2">
                            <label for="label">Category:</label>
                            <select class="form-select" id="label" v-model="uLabel" required>
                            <option value="Principal" selected>Principal</option>
                            <option value="Technology">Technology</option>
                            <option value="Manager">Manager</option>
                            <option value="Marketing">Marketing</option>
                            </select>
                        </div>
                        <!-- Name Input -->
                        <div class="form-group mb-2">
                            <label for="userName">Name:</label>
                            <input type="text" class="form-control" id="userName" v-model="uName" required>
                        </div>
                        <!-- Email Input -->
                        <div class="form-group">
                            <label for="email">Email Address:</label>
                            <input type="email" class="form-control" id="email" v-model="uEmail" required>
                        </div>
                        <!-- Phone Input -->
                        <div class="form-group">
                            <label for="uPhone">Phone:</label>
                            <input type="phone" class="form-control" id="uPhone" v-model="uPhone" required>
                        </div>
                        <!-- Submit Button -->
                        <button class="mt-4 w-100 btn btn-primary" type="submit">Add User</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- IDs & Data Offcanvas -->
        <div class="offcanvas offcanvas-end" tabindex="-1" id="dataSide" aria-labelledby="dataSide" data-bs-backdrop="false" data-bs-scroll="true">
            <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="dataSide">IDs & Full Data</h5>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas"></button>
            </div>
            <div class="offcanvas-body">
                <div id="dataSide">
                    <div class="side-sub">Onboarding ID</div>
                    <div>{{ p.id }}</div>
                </div>
                <div class="side-sub">Full Account Data</div>
                <div>
                    <div>
                        <div v-for="(value, key) in clientData" :key="key">
                            {{ key }}: {{ value }}
                            <br>
                        </div>
                    </div>
                <!-- <JsonViewer :value="clientData" copyable theme="light"/> -->
            </div>
            </div>
        </div>
        <!-- CTA Offcanvas -->
        <div class="offcanvas offcanvas-end" tabindex="-1" id="ctaSide" aria-labelledby="ctaSide" data-bs-backdrop="false" data-bs-scroll="true">
            <div class="offcanvas-header">
                <h5 class="offcanvas-title" id="ctaSideTitle">Enrollment Click-to-Agree Form</h5>
                <button type="button" class="btn-close" data-bs-dismiss="offcanvas"></button>
            </div>
            <div class="offcanvas-body">
                <div>
                    <!-- Quick-Send CTA Button -->
                    <div class="side-sub">Quick-Send CTA</div>
                    <div class="small">Send the CTA to the primary email of this client with a single click.</div>
                    <button class="btn btn-sm btn-secondary" @click="sendCta">Quick-Send CTA</button>
                    <!-- CTA Link Section -->
                    <div class="side-sub">Access the CTA Link</div>
                    <div class="small">Each enrollment click-to-agree form and link is unique to its account and pre-populated with saved account data.</div>
                    <button class="btn btn-sm btn-secondary me-1" @click="showCtaLink = !showCtaLink">{{ showCtaLink ? 'Hide Link' : 'View Link' }}</button>
                    <button class="btn btn-sm btn-secondary copy-button" @click="copyToClipboard(ctaLink)">Copy Link</button>
                    <span :hidden="!showCtaLink">{{ ctaLink }}</span>

                    <!-- Send Custom CTA Section -->
                    <div class="side-sub mt-4">Send Custom CTA</div>
                    <div class="small">Customize and send the CTA to recipients. You can edit the email message and specify up to 3 recipients.</div>
                    <div class="mb-1">
                        <label for="email1" class="form-label">Email Addresses</label>
                        <input autocomplete="off" type="email" class="form-control" id="ctaRecipient1" placeholder="name@example.com">
                    </div>
                    <div class="mb-1">
                        <input autocomplete="off" type="email" class="form-control" id="ctaRecipient2" placeholder="name@example.com">
                    </div>
                    <div class="mb-3">
                        <input autocomplete="off" type="email" class="form-control" id="ctaRecipient3" placeholder="name@example.com">
                    </div>
                    <div class="mb-3 mt-2">
                        <label for="ctaMessage" class="form-label">Message</label>
                        <textarea class="form-control" id="ctaMessage" rows="3"></textarea>
                    </div>
                    <button class="btn btn-sm btn-secondary" id="ctaBtn" @click="sendCustomCta">Send Enrollment CTA</button>
                </div>
            </div>
        </div>
        <!-- KYC Offcanvas -->
        <div class="offcanvas offcanvas-end" tabindex="-1" id="kycSide" aria-labelledby="kycSide" data-bs-backdrop="false" data-bs-scroll="true">
            <div class="offcanvas-header">
                <h5 class="offcanvas-title" id="kycSideTitle">KYC Actions</h5>
                <button type="button" class="btn-close" data-bs-dismiss="offcanvas"></button>
            </div>
            <div class="offcanvas-body">
                <div>
                    <!-- Open KYC Link Section -->
                    <div class="side-sub">Open KYC</div>
                    <div class="small">Click the link below to open the KYC page for this account.</div>
                    <a class="btn btn-sm btn-secondary" :href="'/report/kyc/' + p.id" target="_blank">Open KYC</a>

                    <!-- Run KYC Button Section -->
                    <div class="side-sub mt-4">Run KYC</div>
                    <div class="small">Click the button below to run the KYC process for this account.</div>
                    <button class="btn btn-sm btn-secondary" @click="runKYC">Run KYC</button>
                </div>
            </div>
        </div>
        <!-- Approve for Production Offcanvas -->
        <div class="offcanvas offcanvas-end" tabindex="-1" id="approveSide" aria-labelledby="approveSide" data-bs-backdrop="false" data-bs-scroll="true">
            <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="approveSide">Approve or Reject Client</h5>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas"></button>
            </div>
            <div class="offcanvas-body">
                <div id="approveSide">
                    <div class="side-sub">Approve Client</div>
                    <div>
                        <p class="small">Approve the client to enroll them for a live production API key and full production capabilities.</p>
                    </div>
                    <button class="btn btn-sm btn-secondary" id="approveBtn" @click="approveProd">Approve for Payments in Production</button>
                  <div class="side-sub">Reject Client</div>
                  <div>
                    <p class="small">Rejects the client from being able to process payments in production.</p>
                  </div>
                  <button class="btn btn-sm btn-secondary" id="rejectBtn" @click="rejectProd">Reject for Payments in Production</button>
                    <div class="side-sub mt-4">Welcome Email</div>
                    <div v-if="!clientData.enroll_email">
                        <p class="small">The Welcome Email is Turned off at the Parent Level</p>
                    </div>
                    <div v-if="clientData.enroll_email">
                        <div>Per parent settings, below is a preview of the enrollment welcome email:</div>
                        <div v-if="!clientData.w_enrollMessage" class="alert alert-warning show" role="alert">Caution, the enrollment email is turned on at the parent level but the email has no content!</div>
                        <div>{{ emailContent }}</div>
                        <div class="small">Enter up to 3 recipients for the welcome email.</div>
                        <div class="mb-1">
                        <label for="email1" class="form-label">Email Addresses</label>
                        <input autocomplete="off" type="email" class="form-control" id="welcomeRecipient1" placeholder="name@example.com">
                        </div>
                        <div class="mb-1">
                        <input autocomplete="off" type="email" class="form-control" id="welcomeRecipient2" placeholder="name@example.com">
                        </div>
                        <div class="mb-3">
                        <input autocomplete="off" type="email" class="form-control" id="welcomeRecipient3" placeholder="name@example.com">
                        </div>
                    </div>
                    <!-- <button class="btn btn-sm btn-secondary" id="approveBtn" @click="sendWelcomeEmail" disabled>Send Welcome Email (COMING SOON)</button> -->
                    <!-- <div class="side-sub">Reject Client</div>
                    <button class="btn btn-sm btn-secondary" id="approveBtn" @click="rejectClient">Reject Client</button> -->
                </div>
            </div>
        </div>
        <!-- Hierarchy Offcanvas -->
        <div class="offcanvas offcanvas-end" tabindex="-1" id="hierarchySide" aria-labelledby="hierarchySide" data-bs-backdrop="false" data-bs-scroll="true">
            <div class="offcanvas-header">
                <h5 class="offcanvas-title" id="hierarchySideTitle">Edit Hierarchy</h5>
                <button type="button" class="btn-close" data-bs-dismiss="offcanvas"></button>
            </div>
            <div class="offcanvas-body">
                <form id="hierarchyForm">
                    <!-- Parent Selection -->
                    <div class="mb-3">
                        <label for="parentSelect" class="form-label">Select New Parent</label>
                        <select v-model="newParentName" class="form-select">
                            <option value="" disabled>Select a parent</option>
                            <option v-for="(option, index) in parentOptions" :key="index" :value="option">{{ option.legal_name }}</option>
                        </select>
                        <div class="form-text">Choose a new parent for this merchant.</div>
                    </div>
                    <!-- Enrollment Toggle -->
                    <!-- <div class="mb-3">
                        <label for="enrollmentToggle" class="form-label">Enrollment Capability</label>
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" id="enrollmentToggle" name="enrollment">
                            <label class="form-check-label" for="enrollmentToggle">Allow enrollment of child merchants</label>
                        </div>
                    </div> -->

                    <!-- Save Button -->
                    <button type="button" class="btn btn-primary" id="saveHierarchy" @click="saveNewParent">Save</button>
                </form>
            </div>
        </div>  
        <!-- Submit a Ticket Offcanvas -->
        <div class="offcanvas offcanvas-end" tabindex="-1" id="deSide" aria-labelledby="deSide" data-bs-backdrop="false" data-bs-scroll="true">
            <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="deSide">Submit a Ticket</h5>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas"></button>
            </div>
            <div class="offcanvas-body">
                <div id="deSide">
                    <div class="side-container">
                        <h6>Submit a Ticket to Deactivate</h6>
                        <div>If this account is no longer active under a contract with its parent, 
                        send an email to our team with a request to mark this account as inactive.</div>
                        <button class="btn btn-sm btn-secondary" @click="sendDeactivateEmail(p.id)">Request Deactivation</button>
                    </div>
                    <div class="side-container">
                        <h6>Submit a Ticket to Delete</h6>
                        <div>Submit a ticket to delete the client. We will archive the account data securely.</div>
                        <button class="btn btn-sm btn-secondary" @click="sendDeleteEmail(p.id)">Request Deletion</button>
                    </div>
                    <div class="side-container">
                        <h6>See some information that's not correct?</h6>
                        <p class="ticket-info">
                            Submit a ticket here and we'll get it taken care of. Please describe the information that needs to be corrected.
                        </p>
                        <textarea class="ticket-textarea" v-model="ticketMessage" id="ticketMessage" placeholder="Enter your message"></textarea>
                        <button class="btn btn-sm btn-secondary" @click="sendTicketEmail(p.id)">Submit Ticket</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Notes Offcanvas -->
        <div class="offcanvas offcanvas-end" tabindex="-1" id="notesSide" aria-labelledby="notesSide" data-bs-backdrop="false" data-bs-scroll="true">
            <div class="offcanvas-header">
            <h5 class="offcanvas-title">Notes</h5>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas"></button>
            </div>
            <div class="offcanvas-body">
                <view-enrollment-notes
                    :enrollment_id="p.id"
                    :clientDetails="clientData"
                    :initial-tags="notesInitialTags"
                />
            </div>
        </div>
        <!-- Docs Offcanvas -->
        <div class="offcanvas offcanvas-end" tabindex="-1" id="docsSide" aria-labelledby="docsSide" data-bs-backdrop="false" data-bs-scroll="true">
            <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="docsSide">Documents</h5>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas"></button>
            </div>
            <div class="offcanvas-body">
            <div id="docsSide">
                <view-enrollment-documents
                :clientView = true
                :enrollment_id="p.id"
                />
                <!-- <a :href="'/file/' + p.id" target="_blank" class="btn btn-outline-primary btn-sm mt-2">Upload File</a> -->
            </div>
            </div>
        </div>
        <!-- Dev Cert -->
        <div class="offcanvas offcanvas-end" tabindex="-1" id="certSide" aria-labelledby="certSide" data-bs-backdrop="false" data-bs-scroll="true">
            <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="certSide">Certification</h5>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas"></button>
            </div>
            <div class="offcanvas-body">
                <div class="side-container">
                    <h6>Lead Developer Enrollment</h6>
                    <div class="small">Enroll the lead developer in the development certification here. 
                        Enrollment will create the sandbox account, 
                        add the developer as a user, and email them with steps to get started.</div>
                        <div class="form-group">
                    <label for="userName">Developer Name:</label>
                    <input type="text" class="form-control" id="devName" v-model="devName">
                    </div>
                    <div class="form-group mb-2">
                        <label for="devE">Developer Email:</label>
                        <input type="email" class="form-control" id="devE" v-model="devEmail">
                    </div>
                    <button class="btn btn-primary mt-2" @click="enrollDev(p.id)">Enroll Developer</button>
                </div>
                <hr>
                <h5 class="mt-2">Completed Tests</h5>
                <!-- <div class="mt-4">
                    <h6>Completion Progress ({{ completionPercentage }}%)</h6>
                    <div class="progress">
                    <div class="progress-bar" role="progressbar" :style="{ width: completionPercentage + '%' }"></div>
                    </div>
                </div> -->
                <table class="table mt-2">
                    <thead>
                    <tr>
                        <th>Endpoint</th>
                        <th>Date Completed</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(endpoint, index) in endpoints" :key="index">
                        <td>{{ endpoint.requiredEndpoint }}</td>
                        <td>{{ endpoint.testResult }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="container mt-3 mb-5">
        <hr>
        <div id="businessDetails" v-if="user.user_type === 'admin' || user.user_type === 'parent' || user.user_type === 'healnow'" class="section row">
            <h5>Business Information</h5>
            <div class="form-group col-md-4 col-sm-6">
            <label for="name">Legal Name:</label>
            <input autocomplete="off" type="text" class="form-control" id="name" v-model="clientData.legal_name" :disabled="!isEditing">
            </div>
            <div class="form-group col-md-4 col-sm-6">
            <label for="name">DBA:</label>
            <input autocomplete="off" type="text" class="form-control" id="name" v-model="clientData.dba_name" :disabled="!isEditing">
            </div>
            <div class="form-group col-md-4 col-sm-6">
            <label for="ownershipType">Business Structure:</label>
            <select class="form-select" id="ownershipType" v-model="clientData.ownership_type" :disabled="!isEditing">
                <option value="Sole Proprietor">Sole Proprietor</option>
                <option value="LLC Private">LLC Private</option>
                <option value="LLC Public">LLC Public</option>
                <option value="S-Corp Public">S-Corp Public</option>
                <option value="S-Corp Private">S-Corp Private</option>
                <option value="C-Corp Public">C-Corp Public</option>
                <option value="C-Corp Private">C-Corp Private</option>
                <option value="Not For Profit">Not For Profit</option>
                <option value="Partnership Private">Partnership Private</option>
                <option value="Partnership">Partnership</option>
                <option value="Government">Government</option>
            </select>
            </div>
            <div class="form-group col-md-4 col-sm-6">
            <label for="taxid">Tax ID:</label>
            <input autocomplete="off" type="text" class="form-control" id="taxid" v-model="clientData.fed_tx_id" :disabled="!isEditing">
            </div>
            <div class="form-group col-md-4 col-sm-6">
            <label for="businessDetails">Description of Activities:</label>
            <input autocomplete="off" type="text" class="form-control" id="businessDetails" v-model="clientData.business_description" :disabled="!isEditing">
            </div>
        </div>
        <div id="address" class="section row">
            <h5>Business Address</h5>
            <div class="form-group col-md-4 col-sm-6">
            <label for="businessA">Address:</label>
            <input autocomplete="off" type="text" class="form-control" id="businessA" v-model="clientData.business_address_one" :disabled="!isEditing">
            </div>
            <div class="form-group col-md-4 col-sm-6">
            <label for="businessA2">Address 2:</label>
            <input autocomplete="off" type="text" class="form-control" id="businessA2" v-model="clientData.business_address_two" :disabled="!isEditing">
            </div>
            <div class="form-group col-md-4 col-sm-6">
            <label for="businessC">City:</label>
            <input autocomplete="off" type="text" class="form-control" id="businessC" v-model="clientData.business_city" :disabled="!isEditing">
            </div>
            <div class="form-group col-md-4 col-sm-6">
            <label for="businessS">State:</label>
            <input autocomplete="off" type="text" class="form-control" id="businessS" v-model="clientData.business_state_province" :disabled="!isEditing">
            </div>
            <div class="form-group col-md-4 col-sm-6">
            <label for="businessZ">Zipcode:</label>
            <input autocomplete="off" type="text" class="form-control" id="businessZ" v-model="clientData.business_postal_code" :disabled="!isEditing">
            </div>
            <!-- <div class="form-group col-md-4 col-sm-6">
            <label for="businessCountry">Country:</label>
            <input autocomplete="off" type="text" class="form-control" id="businessCountry" v-model="clientData.country" :disabled="!isEditing">
            </div> -->
        </div>
        <div id="contact" class="section row">
            <h5>Business Contact</h5>
            <div class="form-group col-md-4 col-sm-6">
            <label for="E">Email:</label>
            <input autocomplete="off" type="email" class="form-control" id="E" v-model="clientData.email" :disabled="!isEditing">
            </div>
            <div class="form-group col-md-4 col-sm-6">
            <label for="ph">Phone:</label>
            <input autocomplete="off" type="text" class="form-control" id="ph" v-model="clientData.business_phone_number" :disabled="!isEditing">
            </div>
            <div class="form-group col-md-4 col-sm-6">
            <label for="website">Website:</label>
            <input autocomplete="off" type="text" class="form-control" id="website" v-model="clientData.website" :disabled="!isEditing">
            </div>
        </div>
        <div id="ownerDetails" class="section row">
            <h5>Owner Details</h5>
            <div class="form-group col-md-4 col-sm-6">
            <label for="ownerFirstName">First Name:</label>
            <input autocomplete="off" type="text" class="form-control" id="ownerFirstName" v-model="clientData.principal_first_name" :disabled="!isEditing">
            </div>
            <div class="form-group col-md-4 col-sm-6">
            <label for="ownerLastName">Last Name:</label>
            <input autocomplete="off" type="text" class="form-control" id="ownerLastName" v-model="clientData.principal_last_name" :disabled="!isEditing">
            </div>
            <div class="form-group col-md-4 col-sm-6">
            <label for="ownerLast4SSN">SSN:</label>
            <input autocomplete="off" type="text" class="form-control" id="ownerLast4SSN" v-model="clientData.principal_ssn" :disabled="!isEditing">
            </div>
            <div class="form-group col-md-4 col-sm-6">
            <label for="ownerDOB">Date of Birth:</label>
            <input autocomplete="off" type="date" class="form-control" id="ownerDOB" v-model="clientData.principal_date_of_birth" :disabled="!isEditing">
            </div>
            <div class="form-group col-md-4 col-sm-6">
            <label for="ownerOwnershipPercentage">Ownership Percentage:</label>
            <input autocomplete="off" type="text" class="form-control" id="ownerOwnershipPercentage" v-model="clientData.principal_ownership_percent" :disabled="!isEditing">
            </div>
            <div class="form-group col-md-4 col-sm-6">
            <label for="ownerTitle">Title:</label>
            <input autocomplete="off" type="text" class="form-control" id="ownerTitle" v-model="clientData.principal_title" :disabled="!isEditing">
            </div>
            <div class="form-group col-md-4 col-sm-6">
            <label for="ownerPh">Phone:</label>
            <input autocomplete="off" type="text" class="form-control" id="ownerPh" v-model="clientData.principal_phone_number" :disabled="!isEditing">
            </div>
            <div class="form-group col-md-4 col-sm-6">
            <label for="ownerStreetA">Address Line 1:</label>
            <input autocomplete="off" type="text" class="form-control" id="ownerStreetA" v-model="clientData.principal_address_line_one" :disabled="!isEditing">
            </div>
            <div class="form-group col-md-4 col-sm-6">
            <label for="ownerSuite">Address Line 2:</label>
            <input autocomplete="off" type="text" class="form-control" id="ownerSuite" v-model="clientData.principal_address_line_two" :disabled="!isEditing">
            </div>
            <div class="form-group col-md-4 col-sm-6">
            <label for="ownerC">City:</label>
            <input autocomplete="off" type="text" class="form-control" id="ownerC" v-model="clientData.principal_city" :disabled="!isEditing">
            </div>
            <div class="form-group col-md-4 col-sm-6">
            <label for="ownerS">State:</label>
            <input autocomplete="off" type="text" class="form-control" id="ownerS" v-model="clientData.principal_state_province" :disabled="!isEditing">
            </div>
            <div class="form-group col-md-4 col-sm-6">
            <label for="ownerPostalCode">Postal Code:</label>
            <input autocomplete="off" type="text" class="form-control" id="ownerPostalCode" v-model="clientData.principal_postal_code" :disabled="!isEditing">
            </div>
        </div>
        <div id="settlementAccount" class="section row">
            <h5>Settlement/Payout Account</h5>
            <div class="form-group col-md-4 col-sm-6">
            <label for="accountNumber">Account Number:</label>
            <input autocomplete="off" type="text" class="form-control" id="accountNumber" v-model="clientData.account_number" :disabled="!isEditing">
            </div>
            <div class="form-group col-md-4 col-sm-6">
            <label for="routingNumber">Routing Number:</label>
            <input autocomplete="off" type="text" class="form-control" id="routingNumber" v-model="clientData.routing_number" :disabled="!isEditing">
            </div>
            <div class="form-group col-md-4 col-sm-6">
            <label for="accountType">Account Type:</label>
            <select class="form-select" id="accountType" v-model="clientData.account_type" :disabled="!isEditing">
                <option value="checking">Checking</option>
                <option value="savings">Savings</option>
            </select>
            </div>
        </div>
        <hr v-if="user.user_type === 'admin' || user.user_type === 'healnow'">
        <div id="processing" v-if="user.user_type === 'admin' || user.user_type === 'healnow'" class="section">
            <h3 class="mb-0">Processing</h3>
            <div class="section row">
                <h5 class="mb-0 mt-2" id="cards">Cards</h5>
                <div class="form-group col-md-4 col-sm-6">
                    <label for="processorSelect">Card Processor:</label>
                    <select class="form-select" id="processorSelect" v-model="clientData.payment" :disabled="!isEditing">
                        <option value="FIS">FIS</option>
                        <option value="MerchantE">Merchant E</option>
                        <option value="Bluesnap">Bluesnap</option>
                        <option value="EPX">EPX</option>
                        <option value="propay">ProPay (Heartland)</option>
                    </select>
                </div>
                <div class="form-group col-md-4 col-sm-6">
                <label>Card Rate:</label>
                <input class="form-control" v-model="clientData.card_rate" :disabled="!isEditing">
                </div>
                <div class="form-group col-md-4 col-sm-6">
                <label>Card Pass-to-Consumer:</label>
                <select class="form-select" v-model="clientData.card_p2c" :disabled="!isEditing">
                    <option value="false" selected>Disabled</option>
                    <option value="true">Enabled</option>
                </select>
                </div>
            </div>
            <div class="section row">
                <h5 class="mb-0 mt-4" id="ach">ACH</h5>
                <div class="form-group col-md-4 col-sm-6 ">
                <label>ACH Processor:</label>
                <select class="form-select" v-model="clientData.ach" :disabled="!isEditing">
                    <option value="false" selected>Disabled</option>
                    <option value="xpress">Xpress</option>
                </select>
                </div>
                <div class="form-group col-md-4 col-sm-6">
                <label>ACH Rate:</label>
                <input class="form-control" v-model="clientData.ach_rate" :disabled="!isEditing">
                </div>
                <div class="form-group col-md-4 col-sm-6">
                <label>ACH Pass-to-Consumer:</label>
                <select class="form-select" v-model="clientData.ach_p2c" :disabled="!isEditing">
                    <option value="false" selected>Disabled</option>
                    <option value="true">Enabled</option>
                </select>
                </div>
            </div>
            <div class="section row">
                <h5 class="mb-0 mt-4" id="crypto">Crypto</h5>
                <div class="form-group col-md-4 col-sm-6">
                <label>Crypto:</label>
                <select class="form-select" v-model="clientData.crypto" :disabled="!isEditing">
                    <option value="false" selected>Disabled</option>
                    <option value="curpay">Curpay</option>
                </select>
                </div>
                <div class="form-group col-md-4 col-sm-6">
                <label>Crypto Rate:</label>
                <input class="form-control" v-model="clientData.crypto_rate" :disabled="!isEditing">
                </div>
                <div class="form-group col-md-4 col-sm-6">
                <label>Crypto Pass-to-Consumer:</label>
                <select class="form-select" v-model="clientData.crypto_p2c" :disabled="!isEditing">
                    <option value="false" selected>Disabled</option>
                    <option value="true">Enabled</option>
                </select>
                </div>
            </div>
            <div class="section row">
                <h5 class="mb-0 mt-4" id="percent_of_business_transactions">Percent of Business Transactions</h5>
                <div class="form-group col-md-3 col-sm-6">
                    <label>Card Swiped:</label>
                    <input class="form-control" v-model="clientData.card_swiped" :disabled="!isEditing">
                </div>
                <div class="form-group col-md-3 col-sm-6">
                <label>Keyed Card Present Not Imprinted:</label>
                <input class="form-control" v-model="clientData.keyed_card_present_not_imprinted" :disabled="!isEditing">
                </div>
                <div class="form-group col-md-3 col-sm-6">
                <label>Mail or Phone Order:</label>
                <input class="form-control" v-model="clientData.mail_or_phone_order" :disabled="!isEditing">
                </div>
                <div class="form-group col-md-3 col-sm-6">
                <label>Internet:</label>
                <input class="form-control" v-model="clientData.internet" :disabled="!isEditing">
                </div>
            </div>
            <div class="section row">
                <h5 class="mb-0 mt-4" id="terminals">Terminals</h5>
                <div class="form-group col-md-4 col-sm-6">
                <label>Terminal Brand:</label>
                <select class="form-select" v-model="clientData.devices" :disabled="!isEditing">
                    <option value="Ingenico"></option>
                    <option value="Dejavoo"></option>
                </select>
                </div>
            </div>
            <div class="section row">
                <h5 class="mb-0 mt-4" id="addtnl">Additional Processing Settings & Fees</h5>
                <div class="form-group col-md-4 col-sm-6">
                <label>Settlement:</label>
                    <select class="form-select" v-model="clientData.settlement" :disabled="!isEditing">
                        <option value="split_settlement">Split Settlement</option>
                    </select>
                </div>
                <div class="form-group col-md-4 col-sm-6">
                    <label>MCC Code: </label>
                    <small class="small ms-2"><a :href="'/reports/mcc/'+clientData.parent_id" target="_blank">Open Approved Codes</a></small>
                    <input autocomplete="off" type="text" class="form-control" v-model="clientData.mcc_code" :disabled="!isEditing" placeholder="Enter 4-Digit Code">
                </div>
                <div class="form-group col-md-4 col-sm-6">
                    <label>MCC Label: </label>
                    <input autocomplete="off" type="text" class="form-control" v-model="clientData.label" :disabled="!isEditing" placeholder="Paste MCC Label (Optional)">
                </div>
                <div class="form-group col-md-4 col-sm-6">
                    <label>Mark Up: </label>
                    <input autocomplete="off" type="text" class="form-control" v-model="clientData.mark_up" :disabled="!isEditing">
                </div>
                <!-- <div class="form-group col-md-4 col-sm-6">
                <label>MCC code:</label>
                <base-dropdown
                    v-model="clientData.mccCode"
                    autocomplete
                    search-by-value
                    :options="mccOptions"
                    :allow-custom-value="false"
                    :option-text-func="mccTextValue"
                    :look-by="lookByDropdownTextAndValue"
                    :selected-text-value="dropdownSelectedTextWithValue"
                    placeholder="Select"
                    class="mcc-dropdown"
                />
                </div> -->
                <div class="form-group col-md-4 col-sm-6">
                    <label>Revenue Share:</label>
                    <input autocomplete="off" v-model="clientData.rev_share" type="text" class="form-control" :disabled="!isEditing">
                </div>

                <h5 class="mb-0 mt-4">Risk Settings</h5>

                <div class="form-group col-md-4 col-sm-6">
                <label>High ticket:</label>
                <input autocomplete="off" type="text" class="form-control" v-model="clientData.high_ticket" :disabled="!isEditing">
                </div>
                <div class="form-group col-md-4 col-sm-6">
                <label>Average ticket:</label>
                <input autocomplete="off" type="text" class="form-control" v-model="clientData.avg_ticket" :disabled="!isEditing">
                </div>

                <div class="form-group col-md-4 col-sm-6">
                <label>Average Monthly Volume:</label>
                <input autocomplete="off" type="text" class="form-control" v-model="clientData.monthly_volume" :disabled="!isEditing">
                </div>

                <div class="form-group col-md-4 col-sm-6">
                <label>High Monthly Volume:</label>
                <input autocomplete="off" type="text" class="form-control" v-model="clientData.monthly_high_volume" :disabled="!isEditing">
                </div>

                <h5 class="mb-0 mt-4">Schedule A Fees</h5>
                <div class="form-group col-md-4 col-sm-6">
                    <label>Low Rate:</label>
                    <input autocomplete="off" v-model="clientData.low_rate" type="text" class="form-control" :disabled="!isEditing">
                </div>
                <div class="form-group col-md-4 col-sm-6">
                    <label>Early Termination:</label>
                    <input autocomplete="off" v-model="clientData.etf" type="text" class="form-control" :disabled="!isEditing">
                </div>
                <div class="form-group col-md-4 col-sm-6">
                    <label>Regulatory, 1099k:</label>
                    <input autocomplete="off" v-model="clientData.reg_fee" type="text" class="form-control" :disabled="!isEditing">
                </div>
                <div class="form-group col-md-4 col-sm-6">
                    <label>PCI Non-Compliance:</label>
                    <input autocomplete="off" v-model="clientData.pci_fee" type="text" class="form-control" :disabled="!isEditing">
                </div>
                <div class="form-group col-md-4 col-sm-6">
                    <label>Monthly:</label>
                    <input autocomplete="off" v-model="clientData.monthly_fee" type="text" class="form-control" :disabled="!isEditing">
                </div>
                <div class="form-group col-md-4 col-sm-6">
                    <label>Monthly Minimum Transaction Volume:</label>
                    <input autocomplete="off" v-model="clientData.monthly_min" type="text" class="form-control" :disabled="!isEditing">
                </div>
                <div class="form-group col-md-4 col-sm-6">
                    <label>Annual:</label>
                    <input autocomplete="off" v-model="clientData.annual_fee" type="text" class="form-control" :disabled="!isEditing">
                </div>
                <div class="form-group col-md-4 col-sm-6">
                    <label>Chargeback:</label>
                    <input autocomplete="off" v-model="clientData.chargeback_fee" type="text" class="form-control" :disabled="!isEditing">
                </div>
                <div class="form-group col-md-4 col-sm-6">
                    <label>Retrieval:</label>
                    <input autocomplete="off" v-model="clientData.retrieval_fee" type="text" class="form-control" :disabled="!isEditing">
                </div>
                <div class="form-group col-md-4 col-sm-6">
                    <label>ACH Notification of Change:</label>
                    <input autocomplete="off" v-model="clientData.ach_noc" type="text" class="form-control" :disabled="!isEditing">
                </div>
                <div class="form-group col-md-4 col-sm-6">
                    <label>ACH Return:</label>
                    <input autocomplete="off" v-model="clientData.ach_return" type="text" class="form-control" :disabled="!isEditing">
                </div>
                <div class="form-group col-md-4 col-sm-6">
                    <label>TIN Mismatch:</label>
                    <input autocomplete="off" v-model="clientData.tin_mismatch" type="text" class="form-control" :disabled="!isEditing">
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
  import { onMounted, onBeforeMount, computed, ref, reactive, defineProps } from 'vue'
  import http from '@/services/http'
//   import '/static/js/json-viewer.js';
  import "bootstrap/dist/css/bootstrap.min.css"
  import "bootstrap"
  import { useStore } from 'vuex'
  import ViewEnrollmentNotes from '@/components/clients/ViewEnrollmentNotes.vue'
  import ViewEnrollmentDocuments from '@/components/clients/ViewEnrollmentDocuments.vue'
  import { useToast } from "vue-toastification"
  import Confirm from '@/components/ConfirmComponent.vue'
//   import {JsonViewer} from "vue3-json-viewer"
//   import "vue3-json-viewer/dist/index.css"

  const toast = useToast()
  let toastdefault = { position: "top-center",
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: "button",
    icon: true,
    rtl: false}

    const p = defineProps({
        id : {type:String},
    })

    const store = useStore();
    const user = store.getters['getUser']

    const devName = ref('');
    const devEmail = ref('');
    const isEditing = ref(false);
    const showCtaLink = ref(false);
    const ctaLink= ref('');
    const agentLink= ref('');
    const termsLink= ref('');
    const endpoints = ref([]);
    const notesInitialTags = ref([])
    const clientData = reactive({
        "payment":"",
        "status":"",
        "ccs": "",
        "etf": "",
        "ssn": "",
        "smtp": {},
        "email": "",
        "label": "",
        "parent_id": "",
        "ach_p2c": "",
        "devices": "",
        "mcc_code": "",
        "primary": "",
        "website": "",
        "ach_rate": "0",
        "callback": "",
        "card_p2c": "",
        "dba_name": "",
        "low_rate": "",
        "tax_rate": "",
        "velocity": "",
        "card_rate": "",
        "fed_tx_id": "",
        "rev_share":"",
        "crypto_p2c": "",
        "legal_name": "",
        "start_date": "",
        "crypto_rate": "",

        "avg_ticket": "",
        "high_ticket": "",
        "monthly_volume": "",
        "monthly_high_volume": "",

        "account_type": "",
        "enroll_email": true,
        "stock_symbol": "",
        "transactions": "",
        "business_city": "",
        "principal_ssn": "",
        "account_number": "",
        "ownership_type": "",
        "principal_city": "",
        "routing_number": "",
        "principal_title": "",
        "business_address_one": "",
        "business_address_two": "",
        "principal_last_name": "",
        "business_description": "",
        "business_postal_code": "",
        "principal_first_name": "",
        "business_phone_number": "",
        "principal_postal_code": "",
        "principal_phone_number": "",
        "business_state_province": "",
        "principal_date_of_birth": "",
        "principal_address_line_one": "",
        "principal_address_line_two": "",
        "principal_state_province": "",
        "principal_ownership_percent": "",
        "two_principal_ssn":"",
        "two_principal_city": "",
        "two_principal_title": "",
        "two_principal_last_name": "",
        "two_principal_first_name": "",
        "two_principal_postal_code": "",
        "two_principal_phone_number": "",
        "two_principal_date_of_birth": "",
        "two_principal_address_line_one": "",
        "two_principal_address_line_two": "",
        "two_principal_state_province": "",
        "two_principal_ownership_percent": "",
        "three_principal_ssn":"",
        "three_principal_city": "",
        "three_principal_title": "",
        "three_principal_last_name": "",
        "three_principal_first_name": "",
        "three_principal_postal_code": "",
        "three_principal_phone_number": "",
        "three_principal_date_of_birth": "",
        "three_principal_address_line_one": "",
        "three_principal_address_line_two": "",
        "three_principal_state_province": "",
        "three_principal_ownership_percent": "",
        "four_principal_ssn":"",
        "four_principal_city": "",
        "four_principal_title": "",
        "four_principal_last_name": "",
        "four_principal_first_name": "",
        "four_principal_postal_code": "",
        "four_principal_phone_number": "",
        "four_principal_date_of_birth": "",
        "four_principal_address_line_one": "",
        "four_principal_address_line_two": "",
        "four_principal_state_province": "",
        "four_principal_ownership_percent": "",
        "mail_or_phone_order": "",
        "internet": "",
        "keyed_card_present_not_imprinted": "",
        "card_swiped": ""
    })

    onBeforeMount(()=>
    http.get('/api/enroll?id='+p.id).then((data) => {
        if (data.data.message) {data['data'] = data.data.message}
        Object.assign(clientData, {...clientData, ...data.data})
        ctaLink.value = window.location.origin + `/enroll/${clientData.parent_id}/${p.id}`
        agentLink.value = `/contact/${p.id}`
        termsLink.value = `/terms/${p.id}`
    })
    )

    const editAccount = () => {
        isEditing.value = true;
    }
    const saveChanges = () => {
    toast({
            component: Confirm,
            props:{
                body: 'Are you sure?',
                btext:'SAVE'
            },
            listeners: {
                confirm: () => {
                    clientData.id = p.id;
                    const sendData =  JSON.stringify(clientData)
                    console.log("Sending the following clientData: ", sendData)
                    http.patch(`/api/enroll/edit`, {meta:sendData} )
                        .then(response => { toast.success('Account Updated!', toastdefault)})
                        .catch(error => {toast.error('An error occurred while updating data:', toastdefault)});
                }
            }}, toastdefault)
       

    isEditing.value = false;
    }

    const cancelChanges = () => {
    isEditing.value = false;
    }
    onMounted(() => { 
    // Attach event listeners
    })
    const copyToClipboard = (text) => {
        const input = document.createElement('textarea');
        input.value = text;
        document.body.appendChild(input);
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);
    }

    function runKYC() {
        toast({
            component: Confirm,
            listeners: {
                confirm: () => {
                    http.post(`/api/kyc`, {id: p.id}).then(
                        toast.success("KYC Run!", toastdefault)
        )} 
    }}, toastdefault)}

    function approveProd() {  toast({
        component: Confirm,
        listeners: {
            confirm: () => {
                http
                    .patch(`/api/enroll/approve`, {id: p.id})
                    .then(toast.success("Approved!", toastdefault))
            }
        }}, toastdefault)}

  function rejectProd() {  toast({
    component: Confirm,
    listeners: {
      confirm: () => {
        http
            .patch(`/api/enroll/reject`, {id: p.id})
            .then(toast.success("Reject Successful", toastdefault))
      }
    }}, toastdefault)}


  // Deactivate, Delete, and Ticket Emails
    function sendDeactivateEmail() {
        const deactivateEmail = `${user.name} has requested to DEACTIVATE ${p.id}`;
        const endpoint = '/api/email';
        const data = {
            body: deactivateEmail,
            to: 'devteam@tripleplaypay.com,csm@tripleplaypay.com',
            subject: 'Account Deactivation Request'
        };

        toast({
            component: Confirm,
            listeners: {
                confirm: () => {
                    http
                        .post(endpoint, data)
                        .then(response => {
                        if (response.status === 200) {
                            console.log('Email sent successfully');
                        } else {
                            console.error('Failed to send the email');
                        }
                        })
                        .catch(error => {
                        console.error('An error occurred while sending the email:', error);
                        });
            }}}, toastdefault)
    }

    function sendDeleteEmail() {
        const deleteEmail = `${user.name} has requested to DELETE ${p.id}`;
        const endpoint = '/api/email';
        const data = {
            body: deleteEmail,
            to: 'devteam@tripleplaypay.com,csm@tripleplaypay.com',
            subject: 'Account Delete Request'
        };

        toast({
            component: Confirm,
            listeners: {
                confirm: () => {
                    http
                        .post(endpoint, data)
                        .then(response => {
                        if (response.status === 200) {
                            console.log('Email sent successfully');
                        } else {
                            console.error('Failed to send the email');
                        }
                        })
                        .catch(error => {
                        console.error('An error occurred while sending the email:', error);
                        });
            }}}, toastdefault)
    }

    function sendTicketEmail() {
        const ticketMessage = document.getElementById("ticketMessage").value;
        const endpoint = '/api/email';
        const data = {
            body: `Ticket submitted for ${clientData.dba_name} by ${user.name}: ` + ticketMessage,
            to: 'devteam@tripleplaypay.com,csm@tripleplaypay.com',
            subject: 'Account Ticket Submission'
        };

        http
            .post(endpoint, data)
            .then(response => {
            if (response.status === 200) {
                console.log('Email sent successfully');
            } else {
                console.error('Failed to send the email');
            }
            })
            .catch(error => {
            console.error('An error occurred while sending the email:', error);
            });
    }

    const mccOptions = computed(() => {
        return store.getters['mccOptions'];
        });

    // Email contents will be set at parent level in w_enrollEmail: "default", "off", or "custom". If "default", will pull from TPP settings. If "custom", pull from the parent settings.
    function sendWelcomeEmail() {
        const welcomeRecipient1 = document.getElementById('welcomeRecipient1').value;
        const welcomeRecipient2 = document.getElementById('welcomeRecipient2').value;
        const welcomeRecipient3 = document.getElementById('welcomeRecipient3').value;
        const welcomeIntro = `Hello, ${clientData.dba_name}.`;
        const welcomeApiKey = clientData.w_enrollApiKey ? `Your API key is ${clientData.apikey}.` : '';
        const welcomeVtLink = clientData.w_enrollVtLink ? `Your Virtual Terminal is located at https://www.tripleplaypay.com/${clientData.apikey}.` : '';
        const welcomeMessage = clientData.w_enrollMessage;
        const welcomeContact = clientData.w_enrollContact;
        
        let recipients = [];
        if (welcomeRecipient1) recipients.push(welcomeRecipient1);
        if (welcomeRecipient2) recipients.push(welcomeRecipient2);
        if (welcomeRecipient3) recipients.push(welcomeRecipient3);
        
        const emailContent = `${welcomeIntro}\n\n${welcomeMessage}\n\n${welcomeApiKey}\n${welcomeVtLink}\n\n${welcomeContact}`;
        const to = recipients.join(',');
        
        const endpoint = `/api/email?body=${encodeURIComponent(emailContent)}&to=${to}&subject=TEST`;
        
        toast({
        component: Confirm,
        listeners: {
            confirm: () => {
            http.get(endpoint)
                .then(response => {
                if (response.ok) {
                    console.log('Email sent successfully');
                    toast.success("Welcome Email Sent!", toastdefault);
                } else {
                    console.error('Failed to send the email');
                }
                })
                .catch(error => {
                    console.error('An error occurred while sending the email:', error);
                });
            }}}, toastdefault)
        }
    // Quick-Send CTA:
    function sendCta() {
        
        const emailAddress = clientData.email;

        if (!emailAddress) {
            toast.error("No email address available to send CTA to");
            return;
        }

        const welcomeIntro = `Hello, ${clientData.dba_name}`;

        let emailContent = `${welcomeIntro}<br><br>Click the following link to fill out the enrollment form: <a href="https://tripleplaypay.com/enroll/${clientData.parent_id}/${p.id}">https://tripleplaypay.com/enroll/${clientData.parent_id}/${p.id}</a>`;


        const data = {
            body: emailContent,
            to: emailAddress,
            subject: 'Payment Processing Enrollment Form',
        };

        toast({
            component: Confirm,
            listeners: {
                confirm: () => {
                    toast.clear()
                    http.post(`/api/email`, data).then(toast.success("CTA Sent!", toastdefault))
                }
            }}, toastdefault)
    }

    function openTerms() {
        window.open(termsLink.value, '_blank'); // Open termsLink in a new tab
    }

    // Create Sandbox Account from Production
    function createSandbox() {
        toast({
            component: Confirm,
            listeners: {
                confirm: () => {
                    toast.clear()
                    http.post(`/api/createsandbox?id=${p.id}`).then(toast.success("Sandbox Created!", toastdefault))
                }
            }}, toastdefault)
    }

    // Send Custom CTA email:
    function sendCustomCta() {
        const ctaRecipients = [
            document.getElementById('ctaRecipient1').value,
            document.getElementById('ctaRecipient2').value,
            document.getElementById('ctaRecipient3').value,
        ].filter(Boolean); // Filter out blank recipients

        if (ctaRecipients.length === 0) {
            console.error('No recipients provided. Aborting email send.');
            return;
        }

        const ctaMessage = document.getElementById('ctaMessage').value;

        const welcomeIntro = `Hello, ${clientData.dba_name}`;

        let emailContent = `${welcomeIntro}<br><br>${ctaMessage}<br><br>Click the following link to fill out the enrollment form: <a href="https://tripleplaypay.com/enroll/${clientData.parent_id}/${p.id}">https://tripleplaypay.com/enroll/${clientData.parent_id}/${p.id}</a>`;

        const endpoint = '/api/email';
        const data = {
            body: emailContent,
            to: ctaRecipients.join(','),
            subject: 'Payment Processing Enrollment Form',
        };

        // Date for the Status
        let currentDate = new Date();
        let formattedDate = `${currentDate.getMonth() + 1}/${currentDate.getDate()}/${currentDate.getFullYear()}`;

        toast({
        component: Confirm,
        listeners: {
            confirm: () => {
                http
                    .post(endpoint, data)
                    .then(response => {
                    if (response.status === 200) {
                        console.log('Email sent successfully');
                        toast.clear()
                        // Clear input values
                        document.getElementById('ctaRecipient1').value = "";
                        document.getElementById('ctaRecipient2').value = "";
                        document.getElementById('ctaRecipient3').value = "";
                        document.getElementById('ctaMessage').value = "";
                    } else {
                        toast.error("Failed to send the email");
                        console.error('Failed to send the email');
                    }
                    })
                    .catch(error => {
                        toast.error("An error occurred while the update took place.");
                        console.error('An error occurred while sending the email:', error);
                    });
                }
            }}, toastdefault)
        }

        function rejectClient() {
            // Date for the Status
            let currentDate = new Date();
            let formattedDate = `${currentDate.getMonth() + 1}/${currentDate.getDate()}/${currentDate.getFullYear()}`;

            toast({
                component: Confirm,
                listeners: {
                confirm: () => {
                    http
                    .post(`/api/enroll?id=${p.id}&status=REJECTED:${formattedDate}`)
                    .then(response => {
                        if (response.status === 200) {
                            toast.clear()
                            console.log("Status Updated: REJECTED");
                        }
                    });
                }
                }, toastdefault});
            };


    // Enroll Developer to begin Cert process
    function enrollDev() {
        const sandUserEndpoint = 'https://sandbox.tripleplaypay.com/api/user/new?';
        const prodUserEndpoint = '/api/user/new?';
        const emailEndpoint = '/api/email';
        const userData = {
            name: devName.value,
            email: devEmail.value,
            user_type: "user",
            client: p.id
        };
        const emailData = {
            body: 
                `<p style="font-size: 18px;">${devName.value},</p>
                <p style="font-size: 16px;">You have been enrolled in the Triple Play Pay Development Certification process by ${clientData.dba_name}.</p>
                <p style="font-size: 16px;">Please complete <a href="https://tripleplaypay.com/registration.html" target="_blank">this form</a> to get started.</p>`,
            to: `devteam@tripleplaypay.com, ${devEmail.value}`,
            subject: 'Enrollment in Development Certification: Triple Play Pay'
        };

        toast({
            component: Confirm,
            listeners: {
                confirm: () => {
                    http
                        .post(prodUserEndpoint, userData)
                        .then(response => {
                        if (response.status === 200) {
                            console.log('Dev enrolled successfully');
                            toast.clear()
                            toast.success("Dev enrolled in Production", toastdefault)
                        } else {
                            console.error('Failed to enroll');
                        }
                        })
                        .catch(error => {
                        console.error('An error occurred while enrolling:', error);
                        });
                    
                    http
                        .post(`/api/createsandbox?id=${p.id}`)
                        .then(response => {
                        if (response.status === 200) {
                            console.log('Sandbox created');
                            toast.clear()
                            toast.success("Sandbox created", toastdefault)
                            http
                                .post(sandUserEndpoint, userData)
                                .then(response => {
                                
                                if (response.status === 200) {
                                    console.log('Dev enrolled in Sandbox successfully');
                                    toast.clear()
                                    toast.success("Dev enrolled in Sandbox", toastdefault)
                                } else {
                                    console.error('Failed to enroll in Sandbox ');
                                }
                                })
                                .catch(error => {
                                console.error('An error occurred while enrolling in Sandbox:', error);
                                })
                        } else {
                            console.error('Failed to enroll');
                        }
                        })
                        .catch(error => {
                        console.error('An error occurred while enrolling:', error);
                        });

                    http
                        .post(emailEndpoint, emailData)
                        .then(response => {
                        if (response.status === 200) {
                            toast.clear()
                            toast.success("Dev email sent!", toastdefault)
                        } else {
                            console.error('Failed to send email');
                        }
                        })
                        .catch(error => {
                            toast.clear()
                            toast.error("Dev email not sent!", toastdefault)
                        });
                }
        }}, toastdefault)
    }

    const getCert = () => {
        const devCertUrl = `https://sandbox.tripleplaypay.com/api/stats/cert?id=${p.id}`;

        http.get(devCertUrl)
            .then((response) => {
            endpoints.value = response.data.map((item) => ({
                requiredEndpoint: item[2],
                testResult: item[3],
            }));
            })
            .catch((error) => {
            console.error('An error occurred while fetching test results:', error);
            });
        };

    
    // Users/Contacts
    const uName = ref('');
    const uEmail = ref('');
    const user_type = ref('');
    const uLabel = ref('');
    const uPhone = ref('');
    const users = ref([]);

    const submitData = computed(() => {
      const data = {
        name: uName.value,
        email: uEmail.value.toLowerCase(),
        user_type: user_type.value,
        clientid: p.id,
        label: uLabel.value,
        disabled: false
      };

      return data;
    });

    const submitNewUser = () => {
        const data = submitData.value;
        toast({
                component: Confirm,
                listeners: {
                    confirm: () => {
                        http.post(`/api/user/new`, data)
                        .then(response => {
                            if (response.status === 200) {
                                toast.clear()
                                console.log('Success', response)
                                toast.success("Successfully added!", toastdefault)

                                // Clear form fields
                                uName.value = '';
                                uEmail.value = '';
                                uPhone.value = '';
                            } else {
                                console.error('Failed to add');
                            }
                            })
                            .catch(error => {
                            console.error('An error occurred while adding:', error);
                            });
        }}}, toastdefault)
    };

    // Fetch user data from the API and populate the users ref array
    const fetchUserData = () => {
    http.get(`/api/client/users?id=${clientData.id}`)
        .then((response) => {
        if (Array.isArray(response.data)) {
            users.value = response.data;
        }
        })
        .catch((error) => {
        console.error('Error fetching user data:', error);
        });
    };


    // New parent selection
    const newParentName = ref(''); // Store the selected parent name
    const parentOptions = ref([]); // Store the parent options from the API response

    // Get the parent options
    const getParentOptions = () => {
        http.get('/api/client/parent').then((response) => {
            parentOptions.value = response.data.message;
        });
    };

    // Update data with the selected parent
    const saveNewParent = () => {
        if (newParentName.value) {
            const requestData = {
                enrollid: p.id,
                parent_legal_name: newParentName.value.legal_name,
            };
            http.post('/api/enroll/hierarchy', requestData).then((response) => {
                if (response.status === 200) {
                    toast.success('Parent changed successfully!', toastdefault);
                    console.log(response);
                    // window.location.reload();
                } else {
                    toast.error('Failed to change parent. Please try again.', toastdefault);
                }
            });
        }
    };

</script>
<style scoped>

.nav-container {
    height: 60px;
    margin: 0;
    border-radius: 6px 6px 0 0;
    padding: 0 6px;
}

.nav-link {
    color: black;
}

.jump-to {
    height: 30px;
}
.section {
  margin-top: 20px;
}

h3 {
    font-size: 22px;
}

h5 {
  margin-top: 20px;
  margin-bottom: 0px;
  font-size: 18px;
}

p {
  margin-bottom: 0;
}

label {
    font-size: 14px;
}
.form-group {
  margin-top: 10px;
}

.save-btn .edit-btn {
  padding: 5px;
  width: 70px;
  font-size: 10px;
  margin: 0;
}

.vr {
  border-left: 1px solid black;
  background: none;
  padding: 0;
}

.side-sub {
  margin-top: 15px;
  font-weight: 600;
  text-decoration: underline;
}

.small {
  color: gray;
  font-size: 14px;
  line-height: 110%;
  margin-bottom: 10px;
}

.side-container {
    margin-top: 20px;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f5f5f5;
}

.ticket-info {
    font-size: 14px;
    margin-bottom: 10px;
}

.ticket-textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: vertical;
}

</style>
