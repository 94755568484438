import { Quill } from '@vueup/vue-quill';

// import Quill from 'quill';

const Embed = Quill.import('blots/block/embed');

class Hr extends Embed {
  static create(value) {
    let node = super.create(value);
    // give it some margin
    node.setAttribute(
      'style',
      'height:1px; margin-top:5px; margin-bottom:5px;background: #b1b1b1'
    );
    return node;
  }
}

Hr.blotName = 'hr'; //now you can use .ql-hr classname in your toolbar
Hr.className = 'my-hr';
Hr.tagName = 'hr';

export { Hr };
