<template>
  <div>
    <base-spinner solid fixed />
  </div>
</template>

<script>
import { useRouter, useRoute } from 'vue-router';
import { LoginWithEmailLink } from '@/services/firebase/auth.js';
import useAuth from '@/composables/auth';
import { watch } from 'vue';
import useToast from '@/composables/common/toast';
import useLogin from '@/composables/auth/useLogin.js';

import BaseSpinner from '@/components/base/BaseSpinner.vue';

export default {
  name: 'SingleSignOn',
  components: {
    BaseSpinner
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { showMessage } = useToast();
    const { auth } = useAuth();
    const { systemLogin } = useLogin(auth);

    const unwatch = watch(
      () => auth.value,
      (newV) => {
        if (!newV) {
          return;
        }

        unwatch();

        const continueUrl = route.query.continueUrl || '';
        const email = continueUrl.split('email=')[1];

        setTimeout(() => {
          LoginWithEmailLink({
            link: window.location.href.replace('/sso', '/verify-email'),
            email: email,
            auth: auth.value
          })
            .then((res) => {
              systemLogin(res);
            })
            .catch((e) => {
              console.log(e);
              showMessage({
                title: 'Error',
                text: 'Something went wrong.',
                type: 'danger'
              });

              router.push({name:'Login'});
            });
        }, 1000);
      },
      {
        immediate: true
      }
    );
  }
};
</script>
