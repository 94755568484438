<template>
  <div class="reporting management-page">
    <div class="title" style="color: #383838; font-size: 24px">
      Revenue Sharing Admin
    </div>
    <AgGrid
      :apiurl="apiUrl"
      :sideBar="true"
      :aggFunc="aggFunc"
      :defineDefs="columnDefs"
      groupPanel=""
      :excelOptions="{ fileName: 'RevShare.xlsx' }"
      height="88%"
      @filterChanged="filterModified"
    ></AgGrid>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import AgGrid from '@/components/AgGrid.vue';
import { useStore } from 'vuex';

const store = useStore();

const defaultClientid = computed(() => store.getters.defaultClientid);

const apiUrl = computed(
  () => `/api/report/revshare_all?clientid=${defaultClientid.value}`
);
const agref = ref();

onMounted(() => {});

const filterModified = (model) => {};
//['Parent', 'Child (Sub Merchant)','ID', 'Month', 'Transactions', 'Total','Avg','Rate', 'Collected Fees', 'Interchange', 'Surcharge', 'IC', 'Profit', '%', 'Payout','Client Fee', 'Auto', 'Owed']
const columnDefs = [
  {
    headerName: `Merchant`,
    children: [
      {
        headerName: 'Parent',
        filter: 'agSetColumnFilter',
        valueGetter: (p) => {
          if (!p.data) return '';
          return p.data[0];
        }
      },
      {
        headerName: 'Merchant',
        filter: 'agSetColumnFilter',
        valueGetter: (p) => {
          if (!p.data) return '';
          return p.data[1];
        }
      },
      {
        headerName: 'ID',
        filter: 'agTextColumnFilter',
        valueGetter: (p) => {
          if (!p.data) return '';
          return p.data[2];
        },
        hide: true
      }
    ]
  },
  {
    headerName: 'Performance',
    children: [
      {
        headerName: 'Month',
        filter: 'agSetColumnFilter',
        valueGetter: (p) => {
          if (!p.data) return '';
          return p.data[3];
        }
      },
      {
        headerName: 'Transactions',
        filter: 'agNumberColumnFilter',
        valueGetter: (p) => {
          if (!p.data) return null;
          return parseInt(p.data[4]) || 0;
        },
        valueFormatter: (p) => {
          if (!p.data) return null;
          return parseInt(p.data[4]) || 0;
        }
      },
      {
        headerName: 'Total',
        filter: 'agNumberColumnFilter',
        aggFunc: 'total',
        valueGetter: (p) => {
          if (!p.data) return null;
          return parseFloat(p.data[5]) || 0.0;
        },
        valueFormatter: (p) => {
          if (!p.data) return null;
          return parseFloat(p.data[5]).toFixed(2) || 0.0;
        }
      },
      {
        headerName: 'Average',
        filter: 'agNumberColumnFilter',
        valueGetter: (p) => {
          if (!p.data) return null;
          return parseFloat(p.data[6]) || 0.0;
        },
        valueFormatter: (p) => {
          if (!p.data) return null;
          return parseFloat(p.data[6]).toFixed(2) || 0.0;
        }
      },
      {
        headerName: 'Rate',
        filter: 'agTextColumnFilter',
        valueGetter: (p) => {
          if (!p.data) return '';
          return p.data[7];
        }
      },
      {
        headerName: 'Collected Fees',
        filter: 'agNumberColumnFilter',
        valueGetter: (p) => {
          if (!p.data) return null;
          return parseFloat(p.data[8]) || 0.0;
        },
        valueFormatter: (p) => {
          if (!p.data) return null;
          return parseFloat(p.data[8]).toFixed(2) || 0.0;
        }
      },
      {
        headerName: 'Interchange',
        filter: 'agNumberColumnFilter',
        valueGetter: (p) => {
          if (!p.data) return null;
          return parseFloat(p.data[9] || 0.0) || 0.0;
        },
        valueFormatter: (p) => {
          if (!p.data) return null;
          return parseFloat(p.data[9] || 0.0).toFixed(2) || 0.0;
        }
      },
      {
        headerName: 'IC',
        filter: 'agNumberColumnFilter',
        valueGetter: (p) => {
          if (!p.data) return null;
          return parseFloat(p.data[11]) || 0.0;
        },
        valueFormatter: (p) => {
          if (!p.data) return null;
          return parseFloat(p.data[11]).toFixed(2) || 0.0;
        }
      },
      {
        headerName: 'Profit',
        filter: 'agNumberColumnFilter',
        valueGetter: (p) => {
          if (!p.data) return null;
          return parseFloat(p.data[12]) || 0.0;
        },
        valueFormatter: (p) => {
          if (!p.data) return null;
          return parseFloat(p.data[12]).toFixed(2) || 0.0;
        }
      },
      {
        headerName: '%',
        filter: 'agTextColumnFilter',
        valueGetter: (p) => {
          if (!p.data) return '';
          return p.data[13];
        }
      },
      {
        headerName: 'Payout',
        filter: 'agNumberColumnFilter',
        valueGetter: (p) => {
          if (!p.data) return null;
          return parseFloat(p.data[14]) || 0.0;
        },
        valueFormatter: (p) => {
          if (!p.data) return null;
          return parseFloat(p.data[14]).toFixed(2) || 0.0;
        }
      },
      {
        headerName: 'Client Fees',
        filter: 'agNumberColumnFilter',
        valueGetter: (p) => {
          if (!p.data) return null;
          return parseFloat(p.data[15]) || 0.0;
        },
        valueFormatter: (p) => {
          if (!p.data) return null;
          return parseFloat(p.data[15]).toFixed(2) || 0.0;
        }
      },
      {
        headerName: 'Owed',
        filter: 'agNumberColumnFilter',
        valueGetter: (p) => {
          if (!p.data) return null;
          return parseFloat(p.data[17]) || 0.0;
        },
        valueFormatter: (p) => {
          if (!p.data) return null;
          return parseFloat(p.data[17]).toFixed(2) || 0.0;
        }
      }
    ]
  }
];
</script>

<style lang="scss" scoped>
[v-cloak] {
  display: none;
}
.loader {
  z-index: 2;
}

.table {
  height: 70vh !important;
}

.subs-btn {
  height: 40px;
  padding: 0;
  background: #fff;
  border-color: #f6951e !important;
  color: #f6951e !important;

  &:hover {
    background: var(--c-grey-light) !important;
  }
}

@media screen and (max-width: 768px) {
  .reporting-filters {
    flex-direction: column;
  }

  .reporting-filters-container {
    flex-direction: column-reverse;
  }

  .report-input {
    width: 50%;

    &:last-child {
      margin-right: 0 !important;
    }
  }
}

::v-deep(.report-buttons) {
  @media screen and (max-width: 768px) {
    .btn {
      width: 50%;
    }
  }
}
</style>

<style lang="scss">
@import '~ag-grid-community/styles/ag-grid.css';
@import '~ag-grid-community/styles/ag-theme-balham.min.css';
.reporting {
  .status-value {
    &.Failed {
      background: #ffe4e4;
      color: #b3322c;
    }

    &.Success {
      color: var(--c-success);
      background: #ddffef;
    }
  }

  .actions {
    height: 100% !important;
  }

  .action-btn {
    line-height: initial;
    width: 30px !important;
    height: 30px !important;
    padding: 2px 1px !important;
    font-size: 14px !important;
    border-radius: 50% !important;

    .icon-container {
      width: auto !important;

      svg {
        width: 19px !important;
      }
    }

    &.success {
      color: #fff !important;
    }
  }
}
</style>
