<template>
  <div>
    <div class="mb-3 ms-auto me-auto">
      <span class="dashboard-title">Vault</span>
    </div>
    <div class="row">
      <div class="col-md-9">
        <div class="form-group">
          <label for="searchParameter">Enter Search Parameter:</label>
          <input type="text" class="form-control" id="searchParameter" v-model="searchParameter" required>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <base-button class="ms-auto search-payee-email" @click="runSearch">
            <span class="fz-16 fw-500 vertical-align-middle"> + </span>
            <span class="vertical-align-middle">Run Search</span></base-button
          >
        </div>
      </div>
    </div>
    <div class="customer-table">
      <BaseSpinner v-if="customersLoading" />
      <table v-else class="table">
        <thead>
          <tr>
            <th>Customer Name</th>
            <th>Email</th>
            <th>Merchant</th>
            <th>Payment Methods</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="customer in customers" :key="customer.id">
            <td>
              {{ customer.first_name || '-' }} {{ customer.last_name || '-' }}
            </td>
            <td>{{ customer.email || '-' }}</td>
            <td>{{ customer.merchant_name || '-' }}</td>
            <td>
              <CardTableItem
                v-if="customer?.payment_methods?.length"
                :key="getFirstPaymentMethod(customer)?.id"
                :card-issuer="getFirstPaymentMethod(customer)?.cardType"
                :last-four-digits="getFirstPaymentMethod(customer)?.last4"
                :expiration-date="
                  getFirstPaymentMethod(customer)?.expirationDate
                "
              />
              <span v-else> No payment methods saved </span>
              <span v-if="customer.payment_methods.length > 1">
                {{ customer.payment_methods.length }} methods saved
              </span>
              <span
                v-if="customer.payment_methods.length > 1"
                style="padding-left: 8px"
              >
                {{ customer.payment_methods.length }} methods saved
              </span>
            </td>
            <td v-if="virtualTerminalPermissions">
                <button
                    :disabled="!defaultApiKey"
                    type="button"
                    class="btn btn-dark"
                    @click="
              () => {
                router.push(`/virtual-terminal/${defaultApiKey}?isSwipeOnly=true`);
              }
            "
                >
                  Swipe
                </button>
            </td>
            <td>
              <button
                class="btn btn-primary"
                @click="openCustomerModal(customer)"
              >
                View All
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <base-modal v-model="isCustomerModalOpen" title="Customer Payment Methods">
      <div name="default">
        <div v-if="selectedCustomer">
          <div class="customer-info">
            <h5>
              {{ selectedCustomer.first_name }} {{ selectedCustomer.last_name }}
            </h5>
            <p><strong>Email:</strong> {{ selectedCustomer.email }}</p>
            <p>
              <strong>Phone:</strong> {{ selectedCustomer.phone_number || '-' }}
            </p>

            <div
              v-if="selectedCustomer.billing_address_line_1"
              class="address-info"
            >
              <h6>Customer Billing Address:</h6>
              <p>
                {{ selectedCustomer.billing_address_line_1 }}<br />
                {{ selectedCustomer.billing_address_line_2 }}<br />
                {{ selectedCustomer.billing_locality }},
                {{ selectedCustomer.billing_region }}
                {{ selectedCustomer.billing_postal_code }}<br />
                {{ selectedCustomer.billing_country }}
              </p>
            </div>
            <div
              v-if="selectedCustomer.shipping_address_line_1"
              class="address-info"
            >
              <h6>Customer Shipping Address:</h6>
              <p>
                {{ selectedCustomer.shipping_address_line_1 }}<br />
                {{ selectedCustomer.shipping_address_line_2 }}<br />
                {{ selectedCustomer.shipping_locality }},
                {{ selectedCustomer.shipping_region }}
                {{ selectedCustomer.shipping_postal_code }}<br />
                {{ selectedCustomer.shipping_country }}
              </p>
            </div>
            <transition name="slide-y">
              <div v-if="selectedPaymentMethod" class="pb-3 pt-2">
                <h5>Selected Payment Method:</h5>
                <div style="display: flex; align-items: center">
                  <CardTableItem
                    :card-issuer="
                      parsePaymentMethod(selectedPaymentMethod).cardType
                    "
                    :last-four-digits="
                      parsePaymentMethod(selectedPaymentMethod).last4
                    "
                    :expiration-date="
                      parsePaymentMethod(selectedPaymentMethod).expirationDate
                    "
                  />
                  <button
                    type="button"
                    class="btn-close"
                    style="
                      margin-left: 8px;
                      color: red;
                      font-size: 20px;
                      cursor: pointer;
                    "
                    @click="selectedPaymentMethod = null"
                  ></button>
                </div>

                <div
                  v-if="selectedPaymentMethod.billing_address_line_1"
                  class="address-info"
                >
                  <h6>Method Billing Address:</h6>
                  <p>
                    {{ selectedPaymentMethod.billing_address_line_1 }}<br />
                    {{ selectedPaymentMethod.billing_address_line_2 }}<br />
                    {{ selectedPaymentMethod.billing_locality }},
                    {{ selectedPaymentMethod.billing_region }}
                    {{ selectedPaymentMethod.billing_postal_code }}<br />
                    {{ selectedPaymentMethod.billing_country }}
                  </p>
                </div>
              </div>
            </transition>
            <div
              v-if="selectedCustomer.billing_address_line_1 && false"
              class="address-info"
            >
              <h6>Default ACH Billing Address:</h6>
              <p>
                {{ selectedCustomer.billing_address_line_1 }}<br />
                {{ selectedCustomer.billing_address_line_2 }}<br />
                {{ selectedCustomer.billing_locality }},
                {{ selectedCustomer.billing_region }}
                {{ selectedCustomer.billing_postal_code }}<br />
                {{ selectedCustomer.billing_country }}
              </p>
            </div>
            <div
              v-if="selectedCustomer.shipping_address_line_1"
              class="address-info"
            >
              <h6>Shipping Address:</h6>
              <p>
                {{ selectedCustomer.shipping_address_line_1 }}<br />
                {{ selectedCustomer.shipping_address_line_2 }}<br />
                {{ selectedCustomer.shipping_locality }},
                {{ selectedCustomer.shipping_region }}
                {{ selectedCustomer.shipping_postal_code }}<br />
                {{ selectedCustomer.shipping_country }}
              </p>
            </div>
          </div>
          <hr />
          <h6>Payment Methods:</h6>
          <ul class="list-group">
            <li
              v-for="method in selectedCustomer.payment_methods"
              :key="method.id"
              class="list-group-item"
              :class="{
                selectedCard: selectedPaymentMethod === method,
                hoveredCard: false
              }"
            >
              <CardTableItem
                :card-issuer="parsePaymentMethod(method).cardType"
                :last-four-digits="parsePaymentMethod(method).last4"
                :expiration-date="parsePaymentMethod(method).expirationDate"
              />
            </li>
          </ul>
        </div>
      </div>
      <div name="footer">
        <div class="modal-footer">
          <button class="btn btn-secondary" @click="closeCustomerModal">
            Close
          </button>
        </div>
      </div>
    </base-modal>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import useService from '@/composables/common/services';
import CardTableItem from '@/components/cards/CardTableItem.vue';
const { apiService } = useService();
import BaseModal from '@/components/base/BaseModal.vue';
import BaseSpinner from '@/components/base/BaseSpinner.vue';
import BaseButton from "@/components/base/BaseButton.vue";

// Existing code
const searchParameter = ref('');
import { useStore } from 'vuex';
import { PERMISSION_PRIMITIVES } from '@/config/constants';
import http from "@/services/http";
import {router} from "@/router";

// Setup Stuff for Virtual Terminal
// Get user data
const store = useStore();
const defaultClientName = computed(() => store.getters.defaultClientid);
const defaultClientId = computed(() => store.getters.defaultClientId);
const user = computed(() => store.state.user);
const userHasVirtualTerminalPermission = computed(() => {
  return user.value?.role?.granted_permissions?.some(
      (permission) => permission.name === PERMISSION_PRIMITIVES.VIRTUAL_TERMINAL
  );
});
const permissions = computed(() => store.state.permissions);
const hasPermissions = computed(() => store.getters.merchantHasPermissions);

/**
 * Marks if the user has permissions to use the virtual terminal.
 * True if merchant has no permissions configured or
 * if the user has the virtual terminal permission.
 */
const virtualTerminalPermissions = computed(() => {
  if (userHasVirtualTerminalPermission.value) {
    return true;
  } else {
    return !hasPermissions.value;
  }
});

const role = computed(() => store.getters.role);

const userMerchants = ref([]);

const fetchUserMerchants = () => {
  http
      .get('/api/user/apikeys')
      .then((response) => {
        // test for empty list
        const merchantData = response.data.message;

        if (merchantData) {
          userMerchants.value = merchantData
              .map((merchant) => ({
                id: merchant.id,
                name: merchant.name,
                apikey: merchant.apikey
              }))
              .sort((a, b) =>
                  a.name && b.name ? a.name.localeCompare(b.name) : 0
              );
        }
      })
      .catch((error) => {
        console.error('Error fetching user merchant data:', error);
      });
};

fetchUserMerchants();

const defaultApiKey = computed(() => {
  console.log(
      'Fetching default API key...',
      userMerchants.value,
      defaultClientId.value
  );
  const defaultMerchant = userMerchants.value.find(
      (merchant) => merchant.id === defaultClientId.value
  );
  if (defaultMerchant) {
    console.log(`Default API key found: ${defaultMerchant.apikey}`);
    return defaultMerchant.apikey;
  } else {
    console.log('No default API key found.');
    return '';
  }
});
console.log('Default Client Name:', defaultClientName.value);
console.log('Default Client ID:', defaultClientId.value);
console.log('Role:', role.value);
console.log('Default API Key:', defaultApiKey.value);

// Existing code Specific To Vault

const isCustomerModalOpen = ref(false);
const selectedCustomer = ref(null);
const customersLoading = ref(false);

const selectedPaymentMethod = ref(null);

async function runSearch() {
  const param = searchParameter.value;
  customersLoading.value = true;
  try {
    const response = await apiService.get(
        `api/payee/all?external_id=${param}&first_name=${param}&last_name=${param}`
    );
    customers.value = [...response.message];
  } catch (error) {
    console.error('Error fetching customers:', error);
  } finally {
    customersLoading.value = false;
  }
}

function openCustomerModal(customer) {
  selectedCustomer.value = customer;
  selectedPaymentMethod.value = null;
  isCustomerModalOpen.value = true;
}

/**
 * TODO: add to modal's payment-method @click event
 * when we allow viewing card-specific details
 */
function selectCustomerPaymentMethod(method) {
  selectedPaymentMethod.value = method;
}

function closeCustomerModal() {
  selectedCustomer.value = null;
  isCustomerModalOpen.value = false;
}

const customers = ref([]);

async function fetchCustomers() {
  customersLoading.value = true;
  try {
    const response = await apiService.get('api/payee/all');
    customers.value = [...response.message];
  } catch (error) {
    console.error('Error fetching customers:', error);
  } finally {
    customersLoading.value = false;
  }
}

function parsePaymentMethod(method) {
  const [_, cardType, last4, exp_month, exp_year] = method.token.split('-');
  return {
    id: method.id,
    cardType,
    last4,
    expirationDate: `${exp_month}/${exp_year}`
  };
}

function getPaymentMethods(customer) {
  return customer.payment_methods.map(parsePaymentMethod);
}

function getFirstPaymentMethod(customer) {
  const firstMethod = customer.payment_methods[0];
  return firstMethod ? parsePaymentMethod(firstMethod) : null;
}

onMounted(() => {
  fetchCustomers();
});
</script>

<style lang="scss">
@import '@/styles/scss_variables.scss';

.dashboard-title {
  font-size: 24px;
  color: #383838;
}

.customer-table {
  margin-top: 20px;
}

.customer-info {
  margin-bottom: 20px;

  h5 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 5px;
  }

  .address-info {
    margin-top: 15px;

    h6 {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 5px;
    }
  }
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
}

.selectedCard {
  background-color: #f6951e; //var(--primary);
}

/* TODO: this class is now marked false on the payment
method card. mark it as true when viewing a specific card's
payment details becomes available */
.hoveredCard:hover {
  background-color: #3a68ff; // var(--blue-primary);
  cursor: 'pointer';
}
</style>
