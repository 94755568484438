import { computed } from 'vue';
import { useStore } from 'vuex';

export default function () {
  const store = useStore();

  const role = computed(() => {
    return store.getters['role'];
  });

  const customApikey = computed(() => {

    return {};
  });

  return {
    customApikey
  };
}
