<template>
    <div class="container">
      <span>{{ p.body }} &nbsp;&nbsp;&nbsp;</span>
      <button
        class="btn btn-outline-light"
        @click.stop="clicked"
      >{{ p.btext }}</button>
    </div>
  </template>
  
  <script setup>
  import { defineEmits, defineProps } from 'vue'
  const p = defineProps({
    body : {type:String, default:'Confirm?'}, 
    btext: {type:String, default:'OK!'}
  })
  const emit = defineEmits(['confirm'])
  function clicked() { emit("confirm")}
  </script>