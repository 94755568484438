<template>
  <div>
    <base-spinner fixed />
  </div>
</template>

<script>
import { useRouter, useRoute } from 'vue-router';
import { verifyEmailCode } from '@/services/firebase/auth.js';
import useAuth from '@/composables/auth';
import { watch } from 'vue';
import useToast from '@/composables/common/toast';
import { FIREBASE_LINK_MODES } from '@/config/constants.js';
import BaseSpinner from '@/components/base/BaseSpinner.vue';
import useLogin from '@/composables/auth/useLogin.js';

export default {
  name: 'VerifyEmail',
  components: {
    BaseSpinner
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { showMessage } = useToast();
    const { auth } = useAuth();

    const { systemLogin } = useLogin(auth);

    const { oobCode, mode } = route.query;

    //We need sso
    if (mode === FIREBASE_LINK_MODES.SIGN_IN) {
      router.push(`/sso${route.fullPath.split('/verify-email')[1]}`);
      return;
    }

    const redirect = route.fullPath.split('continueUrl')[1];

    watch(
      () => auth.value,
      (newV) => {
        if (!newV) {
          return;
        }

        verifyEmailCode({
          code: oobCode,
          auth: auth.value
        })
          .then((response) => {
            console.log(response);
            console.log('response above from verifyEmailCode');

            showMessage({
              title: 'Success',
              text: 'Email was verified',
              type: 'success'
            });

            systemLogin();
          })
          .catch((err) => {
            console.log(err);

            showMessage({
              title: 'Error',
              text: 'Something went wrong.',
              type: 'danger'
            });

            auth.value.signOut();

            router.push('/sign-up');
          });
      },
      {
        immediate: true
      }
    );
  }
};
</script>
