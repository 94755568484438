import http from '@/services/http';
import {
  createUserWithEmailAndPassword,
  sendSignInLinkToEmail,
  applyActionCode,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
  TwitterAuthProvider,
  EmailAuthProvider,
  fetchSignInMethodsForEmail,
  fetchProvidersForEmail,
  signInWithRedirect,
  linkWithCredential
} from 'firebase/auth';

export const SignUpWithEmailAndPassword = ({ email, password, auth }) => {
  return createUserWithEmailAndPassword(auth, email, password);
};

export const sendEmailVerificationLink = ({ user, params = '' }) => {
  return sendEmailVerification(user, {
    url: `${window.location.origin}/verify-email?${params}`,
    handleCodeInApp: true
  });
};

export const verifyEmailCode = ({ code, auth }) => {
  return applyActionCode(auth, code);
};

export const LoginWithEmailAndPassword = ({ email, password, auth }) => {
  return signInWithEmailAndPassword(auth, email, password);
};

export const sendSsoLinkToEmail = ({ auth, email }) => {
  let url = `${window.location.origin}/sso?email=${email}`;

  return sendSignInLinkToEmail(auth, email, {
    url: url,
    handleCodeInApp: true
  });
};

export const LoginWithEmailLink = ({ email, auth, link }) => {
  console.log(email);
  console.log(auth);
  console.log(link);
  console.log("Email, auth and link are above");
  const result = signInWithEmailLink(auth, email, link);
  console.log(result);
  console.log("result is above");
  return result
};

export const LoginWithGoogle = ({ auth }) => {
  const provider = new GoogleAuthProvider();
  provider.addScope('profile');
  provider.addScope('email');
  return signInWithPopup(auth, provider);
};

export const getGoogleCredential = (result) => {
  return GoogleAuthProvider.credentialFromResult(result);
};

export const LoginWithFacebook = ({ auth }) => {
  const provider = new FacebookAuthProvider();
  provider.addScope('email');
  return signInWithPopup(auth, provider);
};

export const getFacebookCredential = (result) => {
  return FacebookAuthProvider.credentialFromResult(result);
};

export const GetUserLoginLinkByEmail = async (customData) => {
  const postBody = {
    email: customData.email,
    accessToken: customData._tokenResponse.oauthAccessToken
  }
// // // Serialize the object to a JSON string
//   const jsonData = JSON.stringify(customData);
//   const data = JSON.parse(jsonData);
  const apiResponse = await http.post('/firebase', postBody);
  console.log("apiResponse: ", apiResponse);
  return apiResponse.data.message;
}

export const LoginWithMicrosoft = async ({ auth }) => {
  const provider = new OAuthProvider('microsoft.com');

  provider.setCustomParameters({
    // Force re-consent.
    prompt: 'consent'
  });

  let result;
  let errorCred;

  try {
    result = await signInWithPopup(auth, provider);
  } catch (error) {
    if (error.code === "auth/account-exists-with-different-credential") {
      console.log("auth/account-exists-with-different-credential: ", error);
      errorCred = error.customData.email;
      console.log("errorCred is here: ", errorCred);
      const emailLink = await GetUserLoginLinkByEmail(error.customData);
      console.log("emailLink here: ", emailLink);
      const emailAuthCredential = EmailAuthProvider.credentialWithLink(
          error.customData.email, emailLink);
      console.log("emailAuthCredential here: ", emailAuthCredential);
      result = await signInWithEmailLink(auth, errorCred, emailLink)
    }
  }
  return result;
};

export const getMicrosoftCredential = (result) => {
  return OAuthProvider.credentialFromResult(result);
};

export const LoginWithTwitter = ({ auth }) => {
  const provider = new TwitterAuthProvider();
  return signInWithPopup(auth, provider);
};

export const getTwitterCredential = (result) => {
  return TwitterAuthProvider.credentialFromResult(result);
};
