import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';

const LOGO_SIZE_WIDTH = 111;
const LOGO_SIZE_HEIGHT = 108;

const loadImage = (imagePath) => {
  const img = document.createElement('img');

  img.src = imagePath;

  img.width = LOGO_SIZE_WIDTH;
  img.height = LOGO_SIZE_HEIGHT;

  return new Promise((resolve) => {
    img.addEventListener('load', (e) => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      const context = canvas.getContext('2d');
      context.drawImage(img, 0, 0, img.width, img.height);

      const dataURL = canvas.toDataURL('image/png');

      resolve(dataURL);
    });
  });
};

export default function (name = 'data') {
  const writePdf = async (gridData, details = {}) => {
    const doc = new jsPDF({
      orientation: 'landscape'
    });

    const image = require('@/assets/images/LogoIconGradient.png');

    let marginTop = 10;

    if (details.name) {
      const imgData = await loadImage(image);
      doc.addImage(imgData, 'png', 15, 5, 15, 13);
      doc.setTextColor('#383838');
      doc.text(details.name, 32, 13);
      marginTop += 15;
    }

    if (details.subtitle) {
      doc.setTextColor('#9096aa');
      doc.setFontSize(10);
      doc.setFont('times', 'italic');
      doc.text(details.subtitle, 15, 26);

      marginTop += 5;
    }

    // const headers = [];
  
    autoTable(doc, {
      headStyles: {
        fillColor: '#f6951e'
      },
      margin: { top: marginTop },
      head: [details.header],
      body: gridData
      // ['Castille', 'castille@example.com', 'Spain']
    });

    doc.save(`${name}.pdf`);
  };

  return {
    writePdf
  };
}
