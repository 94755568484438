<template>
  <div>
    <div
      class="fw-normal color-grey-medium fz-14 mb-0 d-flex align-items-center ask-container"
    >
      <slot name="label">
        <p class="fw-400 mr-2 color-dark label">{{ label }}</p>
      </slot>
      <!-- <div
        class="vertical-align-top ml-2 color-primary cursor-pointer fz-12 question"
      >
        <base-tooltip v-if="tooltip" :title="tooltip">
          <help-circle-outline :size="16" />
        </base-tooltip>
      </div> -->

      <div class="d-flex">
        <div
          v-for="(option, index) in options"
          :key="index"
          class="choose-btn w-100"
          :class="{
            'choose-btn_active': modelValue === option.value,
            'mr-2': index < options.length - 1,
            disabled: disabled.includes(option.value)
          }"
          @click="handleSelect(option.value)"
        >
          {{ option.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseTooltip from '@/components/base/BaseTooltip.vue';
import { toRefs } from 'vue';
import HelpCircleOutline from 'vue-material-design-icons/HelpCircleOutline.vue';

const DEFAULT_OPTIONS = [
  {
    text: 'Off',
    value: false
  },
  {
    text: 'On',
    value: true
  }
];

export default {
  components: {
    BaseTooltip,
    HelpCircleOutline
  },
  props: {
    modelValue: {
      type: [Boolean, String, null],
      required: true
    },
    label: {
      type: String,
      default: null
    },
    tooltip: {
      type: String,
      default: null
    },
    options: {
      type: Array,
      default: () => DEFAULT_OPTIONS
    },
    disabled: {
      type: Array,
      default: () => []
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { disabled } = toRefs(props);

    const handleSelect = (optionValue) => {
      if (disabled.value.includes(optionValue)) {
        return;
      }

      emit('update:modelValue', optionValue);
    };

    return {
      handleSelect
    };
  }
};
</script>

<style lang="scss" scoped>
.label {
  width: 135px;
}

.choose-btn {
  height: 40px;
  min-width: 54px;
  padding: 0 17px;

  &.last_el {
    margin-right: 0 !important;
  }

  &.disabled {
    cursor: not-allowed;
  }
}
</style>
