import {
  fetchSignInMethodsForEmail,
  OAuthProvider,
  linkWithCredential
} from 'firebase/auth';
import { ref } from 'vue';
import { SAVED_CRED_KEY } from '@/config/constants.js';
import useLogin from '@/composables/auth/useLogin.js';
import useToast from '@/composables/common/toast';

import {
  LoginWithGoogle,
  LoginWithFacebook,
  LoginWithMicrosoft,
  LoginWithTwitter,
  sendSsoLinkToEmail
} from '@/services/firebase/auth.js';

const SIGN_IN_FUNCS = {
  'facebook.com': LoginWithFacebook,
  'google.com': LoginWithGoogle,
  'twitter.com': LoginWithTwitter,
  'microsoft.com': LoginWithMicrosoft,
  emailLink: (args) => {
    return sendSsoLinkToEmail({ ...args });
  }
};

export default function (auth, showSpinner) {
  const { systemLogin } = useLogin(auth);
  const { showMessage } = useToast();

  const retryDetails = ref({});
  const showRetryModal = ref(false);

  const getLoginFunc = (provider) => {
    const func = SIGN_IN_FUNCS[provider];

    if (!func) {
      return null;
    }

    return func;
  };

  const handleAccountExistsError = (err) => {
    const email = err?.customData?.email;
    const credential = OAuthProvider.credentialFromError(err);
    sessionStorage.setItem(SAVED_CRED_KEY, JSON.stringify(credential));

    fetchSignInMethodsForEmail(auth.value, email)
      .then((methods) => {
        const bestApproachMethod = methods[0];

        retryDetails.value = {
          method: bestApproachMethod,
          email
        };

        showRetryModal.value = true;
      })
      .catch(() => {
        showSpinner.value = false;
      });
  };

  const onRetry = (error) => {
    handleAccountExistsError(error);
  };

  const relogin = () => {
    showRetryModal.value = false;
    const method = retryDetails.value?.method;

    if (!method) {
      return;
    }

    const func = getLoginFunc(method);

    if (!func) {
      return;
    }

    showSpinner.value = true;

    const result = func({
      auth: auth.value,
      email: retryDetails.value?.email
    });

    if (!result) {
      return;
    }

    result
      .then(() => {
        retryDetails.value = {};
        showSpinner.value = false;

        if (method === 'emailLink') {
          showMessage({
            title: 'Success',
            text: 'Email was sent to you. Please check your inbox!',
            type: 'success'
          });
        }
      })
      .then((result) => {
        // no result - email was sent
        if (!result) {
          return;
        }

        // otherwise it's social network login = continue
        // important to paste result here
        return systemLogin(result);
      })
      .catch((err) => {
        console.log('LOGIN ERROR');
        console.log(err);
        showSpinner.value = false;
      });
  };

  return {
    showRetryModal,
    retryDetails,

    handleAccountExistsError,
    onRetry,
    relogin
  };
}
