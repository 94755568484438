<!-- PageDesign.vue -->
<template>
  <div>
    <p class="fz-20 color-dark fw-700 mt-3 mb-2">Page Design</p>
    <div class="d-flex mb-3">
      <div
        class="choose-btn w-100 mr-2"
        :class="{
          'choose-btn_active': pageDesign.inputStyle.mode === 'simple'
        }"
        @click="updateComponentStyles('inputStyle', { mode: 'simple' })"
      >
        Simple
      </div>
      <div
        class="choose-btn w-100"
        :class="{ 'choose-btn_active': pageDesign.inputStyle.mode === 'boxed' }"
        @click="updateComponentStyles('inputStyle', { mode: 'boxed' })"
      >
        Boxed
      </div>
    </div>

    <!-- Advanced Settings Accordion -->
    <details>
      <summary class="accordion-header fw-700 fz-14 color-dark">
        Advanced Settings
      </summary>
      <div class="accordion-content">
        <button class="btn btn-secondary fz-14 fw-700" @click="resetStyles">
          Reset All to Default
        </button>

        <div class="mt-4">
          <h4 class="fw-700 fz-16 color-dark mb-2">Input Style</h4>
          <button
            class="btn btn-secondary fz-14 fw-700"
            @click="resetComponentStyles('inputStyle')"
          >
            Reset to Default
          </button>
          <div class="d-flex justify-content-between align-items-center mt-3">
            <label
              for="checkboxStyleSelectedColor"
              class="fw-400 fz-14 color-dark"
              >Focused Border Color:</label
            >
            <input
              id="inputStyleSelectedColor"
              :value="pageDesign.inputStyle.focusBorderColor"
              type="color"
              class="default-input"
              @input="
                updateComponentStyles('inputStyle', {
                  focusBorderColor: $event.target.value
                })
              "
            />
          </div>
        </div>

        <!-- Checkbox Style Controls -->
        <!-- <div class="mt-4">
          <h4 class="fw-700 fz-16 color-dark mb-2">Checkbox Style</h4>
          <button
            class="btn btn-secondary fz-14 fw-700"
            @click="resetComponentStyles('checkboxStyle')"
          >
            Reset to Default
          </button>
          <div class="d-flex justify-content-between align-items-center mt-3">
            <label
              for="checkboxStyleSelectedColor"
              class="fw-400 fz-14 color-dark"
              >Selected Color:</label
            >
            <input
              id="checkboxStyleSelectedColor"
              :value="pageDesign.checkboxStyle.selectedColor"
              type="color"
              class="default-input"
              @input="
                updateComponentStyles('checkboxStyle', {
                  selectedColor: $event.target.value
                })
              "
            />
          </div>
        </div> -->

        <!-- Payment Method Style Controls -->
        <div class="mt-4">
          <h4 class="fw-700 fz-16 color-dark mb-2">Payment Method Style</h4>
          <button
            class="btn btn-secondary fz-14 fw-700"
            @click="resetComponentStyles('paymentMethodStyle')"
          >
            Reset to Default
          </button>
          <div class="d-flex justify-content-between align-items-center mt-3">
            <label
              for="paymentMethodStyleSelectedBackgroundColor"
              class="fw-400 fz-14 color-dark"
              >Selected Background Color:</label
            >
            <input
              id="paymentMethodStyleSelectedBackgroundColor"
              :value="pageDesign.paymentMethodStyle.selectedBackgroundColor"
              type="color"
              class="default-input"
              @input="
                updateComponentStyles('paymentMethodStyle', {
                  selectedBackgroundColor: $event.target.value
                })
              "
            />
          </div>
          <div class="d-flex justify-content-between align-items-center mt-3">
            <label
              for="paymentMethodStyleSelectedTextColor"
              class="fw-400 fz-14 color-dark"
              >Selected Text Color:</label
            >
            <input
              id="paymentMethodStyleSelectedTextColor"
              :value="pageDesign.paymentMethodStyle.selectedTextColor"
              type="color"
              class="default-input"
              @input="
                updateComponentStyles('paymentMethodStyle', {
                  selectedTextColor: $event.target.value
                })
              "
            />
          </div>
          <div class="d-flex justify-content-between align-items-center mt-3">
            <label
              for="paymentMethodStyleSelectedBorderColor"
              class="fw-400 fz-14 color-dark"
              >Selected Border Color:</label
            >
            <input
              id="paymentMethodStyleSelectedBorderColor"
              :value="pageDesign.paymentMethodStyle.selectedBorderColor"
              type="color"
              class="default-input"
              @input="
                updateComponentStyles('paymentMethodStyle', {
                  selectedBorderColor: $event.target.value
                })
              "
            />
          </div>
          <div class="d-flex justify-content-between align-items-center mt-3">
            <label
              for="paymentMethodStyleHoverBackgroundColor"
              class="fw-400 fz-14 color-dark"
              >Hover Background Color:</label
            >
            <input
              id="paymentMethodStyleHoverBackgroundColor"
              :value="pageDesign.paymentMethodStyle.hoverBackgroundColor"
              type="color"
              class="default-input"
              @input="
                updateComponentStyles('paymentMethodStyle', {
                  hoverBackgroundColor: $event.target.value
                })
              "
            />
          </div>
          <div class="d-flex justify-content-between align-items-center mt-3">
            <label
              for="paymentMethodStyleHoverTextColor"
              class="fw-400 fz-14 color-dark"
              >Hover Text Color:</label
            >
            <input
              id="paymentMethodStyleHoverTextColor"
              :value="pageDesign.paymentMethodStyle.hoverTextColor"
              type="color"
              class="default-input"
              @input="
                updateComponentStyles('paymentMethodStyle', {
                  hoverTextColor: $event.target.value
                })
              "
            />
          </div>
          <div class="d-flex justify-content-between align-items-center mt-3">
            <label
              for="paymentMethodStyleHoverBorderColor"
              class="fw-400 fz-14 color-dark"
              >Hover Border Color:</label
            >
            <input
              id="paymentMethodStyleHoverBorderColor"
              :value="pageDesign.paymentMethodStyle.hoverBorderColor"
              type="color"
              class="default-input"
              @input="
                updateComponentStyles('paymentMethodStyle', {
                  hoverBorderColor: $event.target.value
                })
              "
            />
          </div>
          <div class="d-flex justify-content-between align-items-center mt-3">
            <label
              for="paymentMethodStyleBackgroundColor"
              class="fw-400 fz-14 color-dark"
              >Background Color:</label
            >
            <input
              id="paymentMethodStyleBackgroundColor"
              :value="pageDesign.paymentMethodStyle.backgroundColor"
              type="color"
              class="default-input"
              @input="
                updateComponentStyles('paymentMethodStyle', {
                  backgroundColor: $event.target.value
                })
              "
            />
          </div>
          <div class="d-flex justify-content-between align-items-center mt-3">
            <label
              for="paymentMethodStyleTextColor"
              class="fw-400 fz-14 color-dark"
              >Text Color:</label
            >
            <input
              id="paymentMethodStyleTextColor"
              :value="pageDesign.paymentMethodStyle.textColor"
              type="color"
              class="default-input"
              @input="
                updateComponentStyles('paymentMethodStyle', {
                  textColor: $event.target.value
                })
              "
            />
          </div>
          <div class="d-flex justify-content-between align-items-center mt-3">
            <label
              for="paymentMethodStyleTextColor"
              class="fw-400 fz-14 color-dark"
              >Border Color:</label
            >
            <input
              id="paymentMethodStyleTextColor"
              :value="pageDesign.paymentMethodStyle.borderColor"
              type="color"
              class="default-input"
              @input="
                updateComponentStyles('paymentMethodStyle', {
                  borderColor: $event.target.value
                })
              "
            />
          </div>
        </div>

        <!-- Submit Button Style Controls -->
        <div class="mt-4">
          <h4 class="fw-700 fz-16 color-dark mb-2">Submit Button Style</h4>
          <button
            class="btn btn-secondary fz-14 fw-700"
            @click="resetComponentStyles('submitButtonStyle')"
          >
            Reset to Default
          </button>
          <div class="d-flex justify-content-between align-items-center mt-3">
            <label
              for="submitButtonStyleBackgroundColor"
              class="fw-400 fz-14 color-dark"
              >Background Color:</label
            >
            <input
              id="submitButtonStyleBackgroundColor"
              :value="pageDesign.submitButtonStyle.backgroundColor"
              type="color"
              class="default-input"
              @input="
                updateComponentStyles('submitButtonStyle', {
                  backgroundColor: $event.target.value
                })
              "
            />
          </div>
          <div class="d-flex justify-content-between align-items-center mt-3">
            <label
              for="submitButtonStyleTextColor"
              class="fw-400 fz-14 color-dark"
              >Text Color:</label
            >
            <input
              id="submitButtonStyleTextColor"
              :value="pageDesign.submitButtonStyle.textColor"
              type="color"
              class="default-input"
              @input="
                updateComponentStyles('submitButtonStyle', {
                  textColor: $event.target.value
                })
              "
            />
          </div>
          <div class="d-flex justify-content-between align-items-center mt-3">
            <label
              for="submitButtonStyleHoverBackgroundColor"
              class="fw-400 fz-14 color-dark"
              >Hover Background Color:</label
            >
            <input
              id="submitButtonStyleHoverBackgroundColor"
              :value="pageDesign.submitButtonStyle.hoverBackgroundColor"
              type="color"
              class="default-input"
              @input="
                updateComponentStyles('submitButtonStyle', {
                  hoverBackgroundColor: $event.target.value
                })
              "
            />
          </div>
          <div class="d-flex justify-content-between align-items-center mt-3">
            <label
              for="submitButtonStyleHoverTextColor"
              class="fw-400 fz-14 color-dark"
              >Hover Text Color:</label
            >
            <input
              id="submitButtonStyleHoverTextColor"
              :value="pageDesign.submitButtonStyle.hoverTextColor"
              type="color"
              class="default-input"
              @input="
                updateComponentStyles('submitButtonStyle', {
                  hoverTextColor: $event.target.value
                })
              "
            />
          </div>
        </div>
      </div>
    </details>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PageDesign',
  components: {},
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    resetStyles: {
      type: Function,
      required: true
    },
    resetComponentStyles: {
      type: Function,
      required: true
    },
    setComponentStyles: {
      type: Function,
      required: true
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const updateComponentStyles = (componentName, styles) => {
      props.setComponentStyles(componentName, styles);
      // emit('update:modelValue', props.modelValue);
    };

    return {
      updateComponentStyles,
      pageDesign: props.modelValue
    };
  }
});
</script>

<style lang="scss" scoped>
.choose-btn {
  padding: 10px 25px;
  cursor: pointer;
  border-radius: 4px;
  background-color: #f5f5f5;
  color: #333;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
}

.choose-btn_active {
  background-color: #0570de;
  color: #fff;
}

.accordion-header {
  cursor: pointer;
  margin-top: 1rem;
}

.accordion-content {
  margin-top: 1rem;
}
</style>
