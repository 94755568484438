<template>
  <div class="container-fluid">
    <strong class="text-muted">Payouts:</strong>
    <br />

    <table
      class="table table-sm table-striped dt-responsive nowrap"
      width="100%"
    >
      <thead>
        <tr>
          <th v-for="column in columns" :key="column">
            {{ column.toUpperCase() }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in dataSet" :key="row.id">
          <td v-for="(value, index) in row" :key="value">
            <span v-if="index !== 6 && index !== 11">{{ value }}</span>
            <span v-else>
              {{ value }}
              <button
                v-if="index === 6"
                class="btn btn-outline-primary btn-xs"
                @click="adjustment(`${row[0]}:${row[1]}:${row[2]}`)"
              >
                add
              </button>
              <button
                v-if="index === 11"
                class="btn btn-outline-success btn-xs"
                @click="payout(`${row[0]}:${row[1]}:${row[2]}:${row[10]}`)"
              >
                payout
              </button>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <base-modal v-model="payoutModal" class="view-modal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="payouttitle" class="modal-title">Payout</h5>
          </div>
          <div class="modal-body">
            <p id="payouttext" v-html="payoutModalHTML"></p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              @click="payoutModal = false"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-success"
              :disabled="payoutLoading"
              @click="completePayout"
            >
              Complete Payout
            </button>
          </div>
        </div>
      </div>
    </base-modal>
    <base-modal v-model="addModal" class="view-modal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="addtitle" class="modal-title">Adjustment</h5>
          </div>
          <div class="modal-body">
            <p id="addtext" v-html="addModalHTML"></p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              @click="addModal = false"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary"
              :disabled="addAdjustmentLoading"
              @click="addAdjustment"
            >
              Add Adjustment
            </button>
          </div>
        </div>
      </div>
    </base-modal>
  </div>
</template>

<script setup>
import axios from '@/services/http';
import { ref } from 'vue';
import { onMounted } from 'vue';
import BaseModal from '@/components/base/BaseModal.vue';

const components = {
  BaseModal
};

const payoutLoading = ref(false);
const addAdjustmentLoading = ref(false);

const payoutModal = ref(false);
const addModal = ref(false);
const payoutModalHTML = ref('');
const addModalHTML = ref('');
const dataSet = ref([]);
const wurl = ref('/api/report/payout');
const amount = ref(0.0);
const id = ref('');
const month = ref('');
const name = ref('');

const columns = ref([
  'id',
  'Parent',
  'Month',
  'Transactions',
  'Total',
  'Collected Fees',
  'Adjustments',
  'All In Cost',
  'Profit',
  '%',
  'Payout',
  'Paid'
]);

onMounted(() => {
  generate();
});

async function postData(url = '', data = {}) {
  const response = await axios.post(url, data);
  return response.data;
}
function payout(e) {
  const data = e.split(':');
  id.value = data[0];
  name.value = data[1];
  month.value = data[2];
  amount.value = data[3];
  let txt =
    '<p><strong>' +
    name.value +
    ' to be paid $' +
    amount.value +
    ' for ' +
    month.value +
    '</strong></p>';
  payoutModalHTML.value = txt;
  payoutModal.value = true;
}
function adjustment(e) {
  const data = e.split(':');
  id.value = data[0];
  name.value = data[1];
  month.value = data[2];
  amount.value = '0.00';
  let txt =
    '<p><strong>Add ' +
    month.value +
    ' adjustment for ' +
    name.value +
    ' <input id="adjustAmount" class="form-control" type="number" min="1" placeholder="10.00"/><br><input id="adjustNotes" class="form-control form-control-lg mb-2" type="text" placeholder="Notes"/></strong></p>';
  addModalHTML.value = txt;
  addModal.value = true;
}
async function addAdjustment() {
  amount.value = document.getElementById('adjustAmount').value;
  let notes = document.getElementById('adjustNotes').value;
  let x = {
    month: month.value,
    id: id.value,
    amount: amount.value,
    notes: notes
  };
  console.log(x);
  addAdjustmentLoading.value = true;
  try {
    let data = await postData('/api/adjustment', x);

    if (data['status']) {
      await generate();
      addModalHTML.value = 'Adjustment complete';
    } else {
      addModalHTML.value = JSON.stringify(data);
    }
  } catch (error) {
    console.error('Error:', error);
    addModalHTML.value = 'An error occurred while adding the adjustment.';
  } finally {
    addAdjustmentLoading.value = false;
  }
}
async function completePayout() {
  let x = { month: month.value, id: id.value, amount: amount.value };
  console.log(x);
  payoutLoading.value = true;
  try {
    let data = await postData('/api/payout', x);
    if (data['status']) {
      await generate();
      payoutModalHTML.value = 'Payout complete';
    } else {
      payoutModalHTML.value = JSON.stringify(data);
    }
  } catch (error) {
    console.error('Error:', error);
    payoutModalHTML.value = 'An error occurred while completing the payout.';
  } finally {
    payoutLoading.value = false;
  }
}
async function generate() {
  return axios.get(wurl.value).then((response) => {
    dataSet.value = response.data;
  });
}

defineExpose({
  generate,
  adjustment,
  payout,
  completePayout,
  addAdjustment,
  postData,
  payoutModal,
  addModal,
  dataSet,
  wurl,
  amount,
  id,
  month,
  name,
  columns,
  BaseModal
});
</script>

<style scoped>
/* CSS styles... */
</style>
