import { reactive } from 'vue';
import { FIREBASE_ERROR_CODES } from '@/config/constants.js';

export default function (fields = []) {
  if (!Array.isArray(fields)) {
    throw new Error('List of fields should be an array!');
  }

  const fieldsList = fields.reduce((prev, curr) => {
    return { ...prev, [curr]: null };
  }, {});

  const fieldsAsReactive = reactive(fieldsList);

  const getFirebaseError = (error) => {
    if (error.response && error.response.status >= 500) {
      return 'Something went wrong';
    }

    return FIREBASE_ERROR_CODES[error.code] || error.message;
  };

  const clearErrors = () => {
    for (let i in fieldsAsReactive) {
      fieldsAsReactive[i] = null;
    }
  };

  return {
    errors: fieldsAsReactive,
    getFirebaseError,
    clearErrors
  };
}
