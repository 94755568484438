<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col col-lg-6">
        <h1 class="fz-18 color-dark fw-500">
          <span>Upload documents</span>
          <span v-if="details && details.name"
            ><br />
            for {{ details.name }}</span
          >
        </h1>
        <upload-documents class="mt-4" :client-details="details" remove-tag />
      </div>
    </div>
  </div>
</template>

<script>
import useService from '@/composables/common/services';
import { useRoute } from 'vue-router';

import UploadDocuments from '@/components/clients/UploadDocuments.vue';
import { ref } from 'vue';
import { onMounted } from 'vue';

export default {
  name: 'UploadClientDocuments',
  components: {
    UploadDocuments
  },
  setup() {
    const { apiService } = useService();
    const route = useRoute();
    const details = ref(null);

    const getClientDetails = () => {
      return apiService
        .get(`api/client`, {
          headers: {
          }
        })
        .then((res) => {
          details.value = { ...res.message, id: res.id };
        })
        .catch(() => {});
    };

    onMounted(() => {
      getClientDetails();
    });

    return {
      details
    };
  }
};
</script>
