<template>
  <div class="view-client-documents-container pt-3">
    <base-tab
      v-if="!p.clientView"
      v-model="uploadDocumentsIsVisible"
      hide-hr
      title="Upload Document"
      class="docs-tab mb-2"
    >
      <upload-documents
        class="pt-2 pb-4"
        :client-details="p.clientDetails"
        @success="updateList"
      />
    </base-tab>

    <base-tab
      v-if="!p.clientView"
      v-model="documentsIsVisible"
      hide-hr
      title="Documents"
      class="docs-tab"
    >
      <div class="pt-2">
        <AgGrid
            :apiurl = "apiUrl"
            :defineDefs = "colDefs"
            :sideBar = false
            :statusBar = "statusBar"
            groupPanel = ''
            @row2Selected = "downloadFile"
            :excelOptions = "{fileName:'Documents.xlsx'}"
            height="500px"
        ></AgGrid>
      </div>
    </base-tab>

    <div v-else class="pt-2">
      <base-spinner v-if="loadingList" class="loader" />
      <AgGrid
            :apiurl = "apiUrl"
            :defineDefs = "columnDefs"
            :sideBar = false
            :statusBar = "statusBar"
            groupPanel = ''
            @row2Selected = "downloadFile"
            :excelOptions = "{fileName:'Documents.xlsx'}"
            height="500px"
        ></AgGrid>
    </div>
  </div>
</template>

<script setup>
import useDocuments from '@/composables/documents/documents.js'
import { onMounted, ref, toRefs, defineProps } from 'vue'
const { DateTime } = require('luxon')
import AgGrid from '@/components/AgGrid.vue'
import BaseSpinner from '@/components/base/BaseSpinner.vue'
import BaseTab from '@/components/base/BaseTab.vue'
import UploadDocuments from '@/components/clients/UploadDocuments.vue'
import { computed } from 'vue'

  const p = defineProps({
    clientid: {
      type: String,
    },
    clientView: {
      type: Boolean,
      default: true
    }
  })
    const apiUrl = '/api/documents?client_id='+p.clientid
    const colDefs = ref([])
    const loadingList = ref(false);
    const loading = ref(false);
    const documentsIsVisible = ref(true);
    const uploadDocumentsIsVisible = ref(true);
    const { getDocuments, downloadDocument, documents } = useDocuments();

    function downloadFile(row) {
      console.log("downloading document from row: ", row)
      console.log("downloading document from row: ", row[0].data)
      downloadDocument(row[0].data);
}

    const columnDefs = computed(() => {
      if (p.clientView===true) {
        return [
        {
            headerName: 'Filename',
            valueGetter: (p) => { 
              if (!p.data) return '';
              console.log("valueGetter for filename: ", p)
             return p.data?.file_name;},
            sortable: true,
            filter: true,
            wrapText: true,
            autoHeight: true,
            minWidth: 120
          },
          {
            headerName: 'Uploaded',
            valueGetter: (p) => { if(!p.data) return ''; return p.data?.date_time_uploaded;},
            sortable: true,
            filter: 'agDateColumnFilter',
            wrapText: true,
            autoHeight: true,
            minWidth: 120,
            sort: 'desc'
          }
        ];
      } else {

      return [
      {
          headerName: 'Type',
          valueGetter: (p) => { 
            console.log("value getter inside documents: ", p)
            if(!p.data) return ''; return p.data?.extension;
          },
          sortable: true,
          filter: true,
          autoHeight: true,
          minWidth: 120
        },
        {
          headerName: 'Filename',
          valueGetter: (p) => { if(!p.data) return ''; return p.data?.file_name;},
          sortable: true,
          filter: true,
          wrapText: true,
          autoHeight: true,
          minWidth: 120
        },
        {
          headerName: 'Uploaded',
          valueGetter: (p) => { if(!p.data) return ''; return p.data?.date_time_uploaded;},
          sortable: true,
          filter: 'agDateColumnFilter',
          wrapText: true,
          autoHeight: true,
          minWidth: 120,
          sort: 'desc'
        },

      ];}
    });


    // const onSearch = () => {
    //   handleSearch(clientDetails.value.id);
    // };

    onMounted(() => {
    });
</script>

<style lang="scss" scoped>
.view-client-documents {
  max-width: 100%;
  width: 100%;
}

::v-deep(.docs-tab) {
  .title-container {
    background: var(--c-grey-light);
    padding: 5px 10px;
    border-radius: 5px;
  }

  .section-title {
    font-size: 16px;
  }
}

.upload-btn {
  border-radius: 55px !important;
}

.upload-spinner {
  z-index: 2;
}
</style>

<style lang="scss">
// ["kyc", "license", "risk", "document", "compliance"]

.view-client-documents-container {
  .type-tag {
    padding: 5px 10px;
    border-radius: 15px;
    background-color: var(--c-grey-medium);
    color: var(--c-white);
    font-weight: 500;
    font-size: 12px;
    line-height: 12px !important;
  }

  .type-tag-container {
    height: 50px;
    display: flex;
    align-items: center;
  }

  .kyc {
    background-color: #dce4ff;
    color: #3e5190;
  }

  .license {
    background-color: #dcf3ff;
    color: #375f74;
  }

  .document {
    background-color: #dcfff9;
    color: #387641;
  }

  .risk {
    background-color: #ffdcdc;
    color: #763838;
  }

  .compliance {
    background-color: #fff7dc;
    color: #917f44;
  }
}
</style>
