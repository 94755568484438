<template>
  <div
    ref="actions"
    data-bs-toggle="tooltip"
    :data-bs-placement="placement"
    data-bs-html="true"
    :title="title"
    :data-bs-custom-class="multipleLines ? ' ' : 'noWrap'"
  >
    <slot />
  </div>
</template>

<script>
import { onBeforeUnmount, onMounted, ref } from 'vue';
import Tooltip from 'bootstrap/js/dist/tooltip';

export default {
  name: 'BaseTooltip',
  props: {
    title: {
      type: String,
      default: null
    },
    placement: {
      type: String,
      default: 'bottom'
    },
    multipleLines: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    const actions = ref(null);
    const tooltip = ref(null);

    onMounted(() => {
      tooltip.value = new Tooltip(actions.value);
    });

    onBeforeUnmount(() => {
      if (!tooltip.value) {
        return;
      }

      try {
        tooltip.value.dispose();
      } catch (e) {
        console.warn('No tooltip element found on unmount');
      }
    });

    return {
      actions
    };
  }
};
</script>

<style lang="scss">
.noWrap.tooltip {
  white-space: nowrap;
  .tooltip-inner {
    max-width: initial;
  }
}
</style>
