<template>
  <div class="position-relative">
    <h2 class="fz-14 fw-600 text-uppercase">Iframe code</h2>
    <code class="code">
      <pre>{{ value }}</pre>
    </code>

    <div class="copy" @click="handleCopy">
      <content-copy />
    </div>
  </div>
</template>

<script>
import ContentCopy from 'vue-material-design-icons/ContentCopy.vue';
import useCopy from '@/composables/common/clickboard/index.js';
import { toRefs } from 'vue';

export default {
  name: 'SourceCode',
  components: {
    ContentCopy
  },
  props: {
    value: {
      type: String,
      default: null
    }
  },
  setup(props) {
    const { value } = toRefs(props);
    const { copy } = useCopy();

    const handleCopy = () => {
      copy(value.value);
    };

    return {
      handleCopy
    };
  }
};
</script>

<style lang="scss" scoped>
.copy {
  position: absolute;
  right: 10px;
  bottom: 15px;
  color: #5f5f5f;
  cursor: pointer;
}

pre {
  background-color: #f9f9f9;
  border-radius: 5px;
  padding: 15px;
  border: 1px solid #eaeaea;
}
</style>
