<template>
  <div class="quill-component-container">
    <div>
      <div class="position-relative">
        <color-picker
          v-if="colorPickerVisible"
          class="picker"
          :color="currentColor"
          :visible-formats="['hex']"
          default-format="hex"
          @color-change="handleColorChange"
        />
      </div>
    </div>

    <div :class="{ errorQuill: descriptionError }">
      <!-- v-model:content="description" -->

      <QuillEditor
        ref="editor"
        :content="modelValue"
        :options="globalOptions"
        theme="snow"
        class="editor"
        @update:content="handleUpdate"
      >
        <template #toolbar>
          <div id="toolbar" class="toolbar">
            <button class="ql-bold"></button>
            <button class="ql-italic"></button>
            <button class="ql-underline"></button>
            <button class="ql-strike"></button>

            <button class="ql-list" value="ordered"></button>
            <button class="ql-list" value="bullet"></button>

            <select class="ql-size">
              <option>Font size</option>
              <option value="12px">12</option>
              <option value="14px" selected>14</option>
              <option value="16px">16</option>
              <option value="18px">18</option>
              <option value="20px">20</option>
              <option value="22px">22</option>
              <option value="24px">24</option>
              <option value="26px">26</option>
              <option value="28px">28</option>
              <option value="30px">30</option>
            </select>

            <select class="ql-align"></select>
            <button class="ql-hr"><minus /></button>

            <div>
              <button class="ql-toggleColorPicker"><palette /></button>

              <button
                ref="triggerColorChangeButton"
                class="ql-handleColorPickerChange invisible"
              ></button>
            </div>
          </div>
        </template>
      </QuillEditor>
    </div>

    <transition name="slide-y">
      <p
        v-if="descriptionError"
        class="mt-1 color-danger fz-14 pl-2 mt-1 fw-bolder"
      >
        {{ descriptionError }}
      </p>
    </transition>
  </div>
</template>

<script>
import { QuillEditor } from '@vueup/vue-quill';
import { ColorPicker } from 'vue-accessible-color-picker';
import useQuillModule from '@/composables/new-link/quillModule.js';

import Palette from 'vue-material-design-icons/Palette.vue';
import Minus from 'vue-material-design-icons/Minus.vue';
import { toRefs } from 'vue';

export default {
  name: 'DescriptionSection',
  components: {
    QuillEditor,
    ColorPicker,
    Palette,
    Minus
  },
  props: {
    modelValue: {
      type: Object,
      default: null
    },
    descriptionError: {
      type: String,
      default: null
    },
    savedDescriptionValue: {
      type: Object,
      default: () => {}
    },
    clearDescription: {
      type: Function,
      default: () => {}
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { modelValue, savedDescriptionValue, clearDescription } =
      toRefs(props);

    const {
      editor,
      globalOptions,
      colorPickerVisible,
      currentColor,
      triggerColorChangeButton,
      handleColorChange,
      handleMousePositionOnEditor
    } = useQuillModule(modelValue, savedDescriptionValue, clearDescription);

    const handleUpdate = (v) => {
      emit('update:modelValue', v);
    };

    return {
      editor,
      globalOptions,
      colorPickerVisible,
      currentColor,
      triggerColorChangeButton,
      handleColorChange,
      handleMousePositionOnEditor,
      handleUpdate
    };
  }
};
</script>

<style lang="scss" scoped>
::v-deep(.editor) {
  &.ql-container {
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
    overflow: hidden !important;
    border: 1px solid var(--c-grey-light) !important;
    border-top: none !important;
  }

  .ql-editor {
    font-size: 14px;
    background-color: var(--c-grey-lighten) !important;
    border: none !important;
  }

  .ql-editor {
    min-height: 200px;
    font-family: 'Open Sans', sans-serif !important;
  }

  .ql-blank {
    &::before {
      color: var(--c-grey-medium);
      font-style: initial;
    }
  }
}

.toolbar {
  background-color: var(--c-grey-lighten) !important;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border: 1px solid var(--c-grey-light);
  border-bottom: none;
}

.picker {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  transform: translateX(100%);
}

::v-deep(.picker) {
  .vacp-copy-button,
  .vacp-range-input-label--alpha {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .picker {
    right: initial;
    top: 0;
    left: 0;
    transform: translateY(-100%);
  }
}
</style>

<style lang="scss">
.quill-component-container {
  .errorQuill {
    .editor {
      background-color: var(--c-danger-light) !important;
      border: 1px solid var(--c-danger) !important;
    }
  }

  .ql-fontSize {
    .ql-picker-label {
      width: 85px;
      &::before {
        content: attr(data-label);
        //   content: 'Font size';
      }
    }

    .ql-picker-item {
      &::before {
        content: attr(data-label);
      }
    }
  }
}
</style>
