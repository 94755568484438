import { createLogger, createStore } from 'vuex';
import http from '@/services/http';
import { LicenseManager } from 'ag-grid-enterprise';
import { router } from '@/router';
import { withAccessMenu } from '@/store/withAccessMenu';

LicenseManager.setLicenseKey(
  'Using_this_AG_Grid_Enterprise_key_( AG-043994 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Triple Play Pay )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Triple Play Pay )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( Triple Play Pay )_need_to_be_licensed___( Triple Play Pay )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 21 June 2024 )____[v2]_MTcxODkyNDQwMDAwMA==2715c856a3cb3ab5c966698c55c41fac'
);

const DEFAULT_TYPE = 'warning';
const DEFAULT_DELAY = 3000;

/**
 * @typedef {EditingUser} StoreUser
 */

/**
 * @typedef {{
 *   id: string
 *   name: string
 *   merchant: {
 *     id: string
 *     name: string
 *   }
 *   roles_granted: Array<{
 *     id: string
 *     name: string
 *   }>
 * }} StorePermission
 */

/**
 * @typedef {{
 *   user: StoreUser
 *   permissions: string | Array<StorePermission>
 * }} StoreState
 */

/**
 * @type {Store<StoreState>}
 */
const store = createStore({
  plugins: [createLogger()],
  state: {
    /**
     * @type {StoreUser}
     */
    user: {},
    isAuthenticated: false,
    viewAs: null,
    clients: [],
    clientDetails: {},
    visible: false,
    showToast: false,
    options: {
      title: null,
      text: null,
      delay: null,
      type: null,
      autohide: true,
      canClose: true
    },
    app: null,
    auth: null,
    token: null,
    claims: null,
    /**
     * @type {string | Array<StorePermission>}
     */
    permissions: 'FETCHING'
  },
  mutations: {
    updateUser(state, value) {
      console.log(value);
      console.log('updateUser - value is above');
      state.user = value;
    },
    isAuthenticated(state, value) {
      console.log(value);
      console.log('isAuthenticated - value is above');
      state.isAuthenticated = value;
    },
    updateToken(state, value) {
      console.log(state);
      console.log('store - update token - state is above');
      console.log(value);
      console.log('store - update token - value is above');
      state.token = value;
    },
    updateClaims(state, claims) {
      console.log(claims);
      console.log('store - update claims - value is above');
      state.claims = claims;
    },
    updatePermissions(state, permissions) {
      console.log('Updating permissions', permissions);
      state.permissions = permissions;
    },
    setViewAs(state, id) {
      state.viewAs = id;
    },
    updateClients(state, value) {
      state.clients = value;
    },
    updateClient(state, value) {
      state.clientDetails = value;
    },
    show(
      state,
      {
        title,
        text,
        delay = DEFAULT_DELAY,
        autohide = true,
        canClose = true,
        type = DEFAULT_TYPE
      }
    ) {
      state.options = {
        title,
        text,
        delay,
        autohide,
        canClose,
        type
      };

      state.showToast = true;
    },
    close(state) {
      state.options = {
        title: null,
        text: null,
        delay: null,
        type: null,
        autohide: true,
        canClose: true
      };
      state.showToast = false;
    },
    updateMcc(state, value) {
      state.mccCodes = value;
    },
    notes(state, value) {
      state.visible = value;
    },
    updateAppInstance(state, value) {
      state.app = value;
    },
    updateAuthInstance(state, value) {
      state.auth = value;
    }
  },
  actions: {
    fetchUser({ commit }) {
      console.log('CreateStore: fetch');
      console.log('Getting login value');

      try {
        http.get('/api/user').then((res) => {
          console.log('response from /api/user', res);
          commit('updateUser', res.data.message);

          this.dispatch('fetchPermissions');
          if (window.location.pathname.includes('/login') && res.data.status) {
            console.log('Re-Routing to app/home');

            router.push('/home');
          } else if (
            !res.data.message &&
            !window.location.pathname.includes('/login')
          ) {
            router.push('/login');
          }
        });
      } catch (e) {
        console.error(e);
        router.push('/login');
      }
    },
    fetchClients({ commit }) {
      try {
        console.log('fetch clients');

        http.get('/api/clients').then((res) => {
          commit('updateClients', res.data.message);
        });
      } catch (e) {
        console.error(e);
        router.push('/login');
      }
    },
    fetchClient({ commit }) {
      return http.get('/api/client').then((res) => {
        commit('updateClient', res.data.message);
      });
    },
    fetchMcc({ commit }) {
      http.get('/api/mcc').then((res) => {
        commit('updateMcc', res.data);
      });
    },

    fetchPermissions({ commit, state, getters }) {
      const defaultClientId = getters.defaultClientId;

      if (!defaultClientId) {
        console.error('No default client ID found for the user.');
        return;
      }

      http
        .get(`/api/rbac/${defaultClientId}/permissions`)
        .then((res) => {
          commit('updatePermissions', res.data.message);
        })
        .catch((error) => {
          console.error('Error fetching permissions:', error);
          commit('updatePermissions', 'FAILED');
        });
    },
    checkAuthentication({ commit }) {
      console.log('insideIsAuthenticated');
      http.post('/authenticated').then((res) => {
        console.log(res);
        console.log('committing res from isAuthenticated call - see above');
        commit('isAuthenticated', res.data.message);
        console.log('comitted res from isAuthenticated call');
      });
    },
    logout({ commit }) {
      router.push('/login');
    }
  },
  getters: {
    getUser(state) {
      return state.user;
    },
    getUserEmail(state) {
      return state.user?.email;
    },
    isAuthenticated(state) {
      return state.isAuthenticated;
    },
    getToken(state) {
      return state.token;
    },
    defaultClientName(state) {
      return state.user?.account?.default_client?.merchant?.name;
    },
    defaultClientId(state) {
      return state.user?.account?.default_client?.id;
    },
    defaultClientid(state) {
      return state.user?.account?.default_client?.id;
    },
    getPermissions(state) {
      return state.permissions;
    },
    /**
     * Convenience getter for if a merchant has permissions configured.
     * Defaults to strict enforcement. Returns true
     * while fetching permissions or if permissions endpoint failed.
     * @param {*} state
     * @returns
     */
    merchantHasPermissions(state) {
      if (state.user?.role?.granted_permissions?.length > 0) {
        return true;
      } else if (
        state.permissions === 'FETCHING' ||
        state.permissions === 'FAILED'
      ) {
        return true;
      } else {
        return Array.isArray(state.permissions) && state.permissions.length > 0;
      }
    },
    role(state) {
      if (state.viewAs) {
        return 'user';
      }
      return state.user?.user_type || '';
    },
    mccOptions(state) {
      const arr = [];

      for (const mccCode in state.mccCodes) {
        arr.push({
          text: state.mccCodes[mccCode]['description'],
          value: mccCode
        });
      }

      return arr;
    },
    clientList: (state) => state.clients,
    withAccessMenu: (state) => withAccessMenu(state, store),
  }
});

export { store };
