import httpService from '@/services/http';
import { store } from '@/store';
import { router } from '@/router';

class HttpService {
  constructor({ token }) {
    this.service = httpService;
    this.token = token;
    this.available = true;
  }

  init() {
    return new Promise((resolve) => {
      const headers = {};

      const viewAsId = store?.state?.viewAs?.id;

      if (viewAsId) {
        headers.clientid = viewAsId;
      }

      this.service.interceptors.request.use(
        (config) => {

          console.log(config);
          console.log("Alternative interceptor use - config availability above");

          console.log(store?.state?.token);
          console.log("Alternative interceptor use - token availability above");

          const hideClientView =
            router.currentRoute.value.meta.clientView === false;

          if (
            viewAsId &&
            !config.url.includes('clientid=') &&
            !hideClientView
          ) {
            let url = config.url;

            if (url.includes('?')) {
              url = url + `&clientid=${viewAsId}`;
            } else {
              url = url + `?clientid=${viewAsId}`;
            }

            config.url = url;
          }

          config.headers = {
            ...headers,
            ...config.headers
          };
          return config;
        },
        function (error) {
          // Do something with request error
          return Promise.reject(error);
        }
      );

      resolve();
    });
  }

  post(url, data) {
    return new Promise((resolve, reject) => {
      return this.service
        .post(`/${url}`, data)
        .then((res) => {
          const data = res?.data || res;

          if (Array.isArray(data)) {
            resolve(data);
            return;
          }

          if (typeof data !== 'object') {
            resolve(data);
            return;
          }

          if (typeof data.status === 'undefined') {
            resolve(data);
            return;
          }

          if (data.status) {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  patch(url, data) {
    return new Promise((resolve, reject) => {
      return this.service
        .patch(`/${url}`, data)
        .then((res) => {
          const data = res?.data || res;

          if (Array.isArray(data)) {
            resolve(data);
            return;
          }

          if (typeof data !== 'object') {
            resolve(data);
            return;
          }

          if (typeof data.status === 'undefined') {
            resolve(data);
            return;
          }

          if (data.status) {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }


  get(url, config) {
    let currUrl = url.includes('https://') ? url : `/${url}`;
    return new Promise((resolve, reject) => {
      return this.service
        .get(currUrl, config)
        .then((res) => {
          const data = res?.data;

          if (typeof data !== 'object') {
            resolve(data);
            return;
          }

          let status;

          if (typeof data.status === 'boolean') {
            status = data.status;
          } else {
            status = true;
          }

          if (status) {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}

export default HttpService;
