<template>
  <div
    class="management-menu-container position-relative"
    :class="{ collapsed }"
  >
    <aside
      class="management-menu pt-4 d-flex flex-column justify-content-between"
      :class="{ collapsed }"
    >
      <base-modal v-model="showUserConfig">
        <user-config />
      </base-modal>
      <div>
        <div class="collapse-btn" @click="handleCollapse">
          <chevron-left :size="16" class="chevron" />
        </div>

        <div class="px-3 pt-3 pb-3">
          <p class="menu-text m-0">Menu</p>
        </div>

        <transition name="slide-raw">
          <ul v-if="!$route.meta.navbar" class="links mx-2">
            <li
              v-for="(item, index) in withAccessMenu"
              :key="index"
              class="menu-item mt-1"
              :class="{ active: $route.path === item.path.split('?')[0] }"
            >
              <router-link
                v-if="!item.external"
                :to="item.path"
                class="link py-2 d-flex align-items-center"
                :disabled="role !== 'admin'"
              >
                <span
                  class="icon mr-2 d-flex align-items-center justify-content-center"
                >
                  <component :is="item.icon" :size="25"
                /></span>
                <span class="text">
                  {{ item.title }}
                </span>
              </router-link>
              <a
                v-else
                :href="item.path"
                class="link py-2 d-flex align-items-center"
                target="_blank"
              >
                <span
                  class="icon mr-2 d-flex align-items-center justify-content-center"
                >
                  <component :is="item.icon" :size="25"
                /></span>
                <span class="text">
                  {{ item.title }}
                </span>
              </a>
            </li>
          </ul>
        </transition>

        <transition name="slide-raw">
          <ul v-if="$route.meta.navbar" class="links mx-2">
            <li
              v-for="(item, index) in withAccessMenu"
              :key="index"
              class="menu-item mt-1"
              :class="{ active: $route.path === item.path.split('?')[0] }"
            >
              <router-link
                v-if="!item.external"
                :to="item.path"
                class="link py-2 d-flex align-items-center"
              >
                <span
                  class="icon mr-2 d-flex align-items-center justify-content-center"
                >
                  <component :is="item.icon" :size="25"
                /></span>
                <span class="text">
                  {{ item.title }}
                </span>
              </router-link>
              <a
                v-else
                :href="item.path"
                class="link py-2 d-flex align-items-center"
                target="_blank"
              >
                <span
                  class="icon mr-2 d-flex align-items-center justify-content-center"
                >
                  <component :is="item.icon" :size="25"
                /></span>
                <span class="text">
                  {{ item.title }}
                </span>
              </a>
            </li>
          </ul>
        </transition>
      </div>
      <div class="menu-item pb-4">
        <div v-if="!collapsed" class="mx-2 logged-details">
          <p
            v-if="userFirstName || userEmail"
            class="fz-14 logged-in-text fw-500"
          >
            Logged in as {{ userFirstName || userEmail }}
          </p>

          <p v-if="userFirstName && userEmail" class="fz-10 logged-in-text">
            {{ userEmail }}
          </p>

          <p class="fz-10 logged-in-text">Role: {{ userType }}</p>

        </div>
        <div class="collapse-btn" @click="handleCollapse">
          <logout-variant :size="15" />
        </div>
        <!-- <a style="text-decoration: none;" @click="handleClick">
          <div
            class="link py-2 mx-2 mb-2 mt-2 logout d-flex align-items-center" @click="handleLogout"
          >
            <span
              class="icon mr-2 d-flex align-items-center justify-content-center"
            >
              <logout-variant :size="25" />
            </span>
            <span class="text"> Logout </span>
          </div>
        </a> -->
      </div>
    </aside>
  </div>
</template>

<script>
import { MANAGEMENT_MENU } from '@/config/constants.js';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import ChevronLeft from 'vue-material-design-icons/ChevronLeft.vue';
import AccountCog from 'vue-material-design-icons/AccountCog.vue';
import BaseDropdown from '@/components/base/BaseDropdown.vue';
import BaseModal from '@/components/base/BaseModal.vue';
import BaseInput from '@/components/base/BaseInput.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import UserConfig from '@/components/general/UserConfig.vue';
import LogoutVariant from 'vue-material-design-icons/LogoutVariant.vue';
import useLogout from '@/composables/auth/useLogout.js';
import { useRoute } from 'vue-router';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';

export default {
  name: 'ManagementSidebar',
  components: {
    ChevronLeft,
    LogoutVariant,
    BaseDropdown,
    BaseInput,
    BaseModal,
    BaseButton,
    UserConfig,
    AccountCog
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const { logout } = useLogout();

    const clients = ref([]);
    const search = ref('');
    const showUserConfig = ref(false);

    const collapsed = ref(false);

    const role = computed(() => {
      return store.getters.role;
    });

    /**
     * @type {import("vue").Ref<string>}
     */
    const email = computed(() => {
      return store.getters.getUserEmail;
    });

    const permissions = computed(() => {
      return store.state.permissions;
    });

    const hasPermissions = computed(() => {
      if (store.state.user?.role?.granted_permissions?.length > 0) {
        return true;
      }
      return store.getters.merchantHasPermissions;
    });

    const withAccessMenu = computed(() => store.getters.withAccessMenu);

    const userDetails = computed(() => {
      return store.state.user;
    });

    const userFirstName = computed(() => {
      const name = userDetails.value?.name || '';

      return name.split(' ')[0];
    });

    const userEmail = computed(() => {
      return userDetails.value?.email;
    });

    const userType = computed(() => {
      return userDetails.value?.user_type;
    });

    // const clientNames = computed(() => {
    //   const names = Object.values(userDetails.value.clientname);
    //   return names[0];
    // });

    const handleCollapse = () => {
      logout().then((r) => console.log(r));
      console.log('handleLogout logging after logout().then');
    };

    const isSandboxTour = computed(() => {
      return route.fullPath.includes('sandbox');
    });

    const isPartnerTour = computed(() => {
      return (
        (userType.value === 'parent' || role.value === 'admin') &&
        !isSandboxTour.value
      );
    });

    const isMerchantTour = computed(() => {
      return (
        (userType.value === 'user' || role.value === 'admin') &&
        !isSandboxTour.value
      );
    });

    return {
      withAccessMenu,
      showUserConfig,
      role,
      search,
      clients,
      collapsed,
      userFirstName,
      userEmail,
      userType,
      //clientNames,
      isSandboxTour,
      isPartnerTour,
      isMerchantTour,

      logout,
      handleCollapse
    };
  }
};
</script>

<style lang="scss" scoped>
$light-grey: #f9f8f8;

.config-btn {
  background-color: #f7a540 !important;
  width: 40px !important;
  height: 40px !important;
}

::v-deep(.config-btn) {
  svg {
    width: 20px !important;
    height: 20px !important;
  }
}

::v-deep(.view-as-input) {
  .label {
    color: var(--c-white);
    // font-weight: 600 !important;
    text-transform: uppercase;
    font-size: 14px !important;
  }

  .value {
    height: 40px;
    max-width: 152px !important;
  }

  .option {
    font-size: 14px;
  }
}

::v-deep(.search-input) {
  .input {
    height: 40px;
  }
}

.logged-in-text {
  color: var(--c-white);
}

.links {
  padding: 0;
  margin: 0;

  li {
    padding: 0;
    list-style: none;
  }
}

.logo-text {
  font-weight: 400;
  font-size: 14px;
}

.menu-text {
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  // margin-top: 30px;
}

.text {
  font-weight: 400;
  white-space: nowrap;
}

.link {
  text-decoration: none;
  color: #fff;
  transition: all 0.2s;
  border-radius: 15px;
  padding-left: 10px;

  &:hover {
    background: #fff;
    color: var(--c-primary);
  }
}

.active {
  .link {
    background: #fff;
    color: var(--c-primary);
  }
}

.management-menu-container {
  min-width: 210px;
  transition: all 0.2s ease;

  &.collapsed {
    min-width: 80px;
    width: 80px;
  }
}

.logged-details {
  padding-left: 10px;
}

.management-menu {
  min-width: 210px;
  max-width: 210px;
  min-height: 100vh;
  height: 100%;
  // background: #2c2c2c;
  background: linear-gradient(
    150deg,
    var(--c-tertiary-color),
    var(--c-secondary-color),
    var(--c-primary-color)
  );
  // border-top-right-radius: 20px;
  // border-bottom-right-radius: 20px;
  transition: all 0.2s ease;
  position: fixed;
  z-index: 10;
  box-shadow: 0px 0px 10px -2px rgba(#000, 0.2);

  &.collapsed {
    min-width: 80px;
    width: 80px;
  }
}

.collapse-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  bottom: calc(30px + 1rem);
  transform: translate(45%, -50%);
  background: var(--c-primary);
  border: 2px solid #fff;
  border-radius: 50%;
  color: var(--c-white);
  height: 25px;
  width: 25px;
  cursor: pointer;
  transition: all 0.2s ease-in;
}

.chevron {
  height: 16px;
  width: 16px;
  line-height: 16px;
  transform-origin: center;
  transition: all 0.2s ease;
  margin-top: -3px;
  font-weight: 600;
}

.collapsed {
  // padding-top: 60px !important;

  .chevron {
    transform: rotate(180deg);
    margin-top: 3px;
  }

  .menu-text {
    opacity: 0;
  }

  .menu-item {
    .text {
      display: none;
    }

    .icon {
      margin: 0;
    }

    .link {
      padding: 0;
      justify-content: center;
    }
  }
}

.logout {
  cursor: pointer;
}
</style>
