<template>
  <div class="clients management-page">
    
    <div class="title" style="color: #383838; font-size: 24px;">Terminals: {{ TERMINAL_NAMES[terminalName] }}</div>

    <div class="mt-1 position-relative">
      <base-spinner v-if="loadingList" class="loader" />

      <base-modal v-model="showAddTerminalModal">
        <add-terminal @update="onNewTerminal" />
      </base-modal>

      <div class="d-flex justify-content-between top">
        <div class="d-flex">
          <base-button
            :disabled="false"
            class="add-terminal px-3 mr-2 d-flex align-items-center"
            @click="showAddTerminalModal = true"
          >
            <span class="fz-24 fw-600 mr-2"> + </span>
            <span> Add Terminal</span>
          </base-button>

          <base-button
            class="add-terminal order-terminal px-3"
            href="/order"
          >
            Order Terminals</base-button
          >
        </div>

        <report-buttons :list="itemsList" :schema="schema" name="terminals" />
      </div>

      <ag-grid-vue
        class="ag-theme-balham table mt-2"
        :column-defs="columns"
        :row-data="itemsList"
        :header-height="40"
        :row-height="50"
        :pagination="true"
        :pagination-page-size="100"
        @first-data-rendered="onFirstDataRendered"
      >
      </ag-grid-vue>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { onMounted } from 'vue';
import useService from '@/composables/common/services';
import useToast from '@/composables/common/toast';
import useResizeTable from '@/composables/common/table';
import { useStore } from 'vuex';
import { AgGridVue } from 'ag-grid-vue3';
import BaseSpinner from '@/components/base/BaseSpinner.vue';
import Actions from '@/components/onboarding/table/OnboardingActions.vue';
import ReportButtons from '@/components/common/ReportButtons.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import BaseModal from '@/components/base/BaseModal.vue';
import AddTerminal from '@/components/terminals/AddTerminal.vue';
import Status from '@/components/onboarding/table/OnboardingStatus.vue';
import { computed } from 'vue';

const schema = [
  {
    column: 'Serial number',
    type: String,
    value: (data) => data.serialNumber
  },
  {
    column: 'Model number',
    type: String,
    value: (data) => data.modelNumber
  },
  {
    column: 'Lane ID',
    type: String,
    value: (data) => '' + data.laneId
  },
  {
    column: 'Description',
    type: String,
    value: (data) => data.description
  }
];

const TERMINAL_NAMES = {
  fis: 'Ingenico',
  dejavooo: 'Dejavoo',
  tpp: 'TPP'
};

export default {
  name: 'TerminalsPage',
  components: {
    AgGridVue,
    BaseSpinner,
    ReportButtons,
    BaseButton,
    AddTerminal,
    BaseModal
  },
  setup() {
    const { apiService } = useService();
    const { showMessage } = useToast();
    const { gridApi } = useResizeTable();
    const store = useStore();

    const loadingList = ref(false);
    const showAddTerminalModal = ref(false);
    const itemsList = ref([]);

    const updateList = () => {
      loadingList.value = true;

      apiService
        .get('api/terminal')
        .then((res) => {
          const data = res.message;
          delete data.ip;
          const els = [];
          let index = 0;

          for (let key in data) {
            els.push({
              ...data[key],
              status: data[key]?.connectionStatus?.status,
              value: index,
              name: key,
              description: `SN: ${data[key]?.serialNumber}. MN: ${data[key]?.modelNumber}`
            });

            index++;
          }

          itemsList.value = els;
          loadingList.value = false;
        })
        .catch(() => {
          loadingList.value = false;
        });
    };

    const terminalName = computed(() => {
      return store.state.clientDetails?.config?.terminals;
    });

    const addTerminalActive = computed(() => {
      return !!store.state.clientDetails?.config;
    });

    const deleteTerminal = (index) => {
      const terminal = itemsList.value[index];

      apiService
        .post('api/terminal/remove', {
          laneId: terminal.laneId
        })
        .then(() => {
          showMessage({
            title: 'Success',
            text: 'Terminal was successfully removed',
            type: 'success'
          });

          updateList();
        })
        .catch(() => {
          showMessage({
            title: 'Error',
            text: 'Something went wrong',
            type: 'danger'
          });
        });
    };

    const onNewTerminal = () => {
      showAddTerminalModal.value = false;
      updateList();
    };

    const onFirstDataRendered = (params) => {
      params.api.sizeColumnsToFit();
      gridApi.value = params.api;
    };

    onMounted(() => {
      updateList();
    });

    const columns = computed(() => {
      if (terminalName.value === 'dejavooo') {
        return [
          {
            headerName: 'Terminal ID',
            field: 'terminalId',
            sortable: true,
            filter: true,
            minWidth: 80,
            wrapText: true,
            autoHeight: true
          },
          {
            headerName: 'Lane ID',
            field: 'laneId',
            sortable: true,
            filter: true,
            minWidth: 80,
            wrapText: true,
            autoHeight: true
          },
          {
            headerName: 'Description',
            field: 'description',
            sortable: true,
            filter: true,
            minWidth: 80,
            wrapText: true,
            autoHeight: true
          },
          {
            headerName: 'Status',
            field: 'status',
            sortable: true,
            filter: true,
            minWidth: 80,
            wrapText: true,
            autoHeight: true,
            cellRendererFramework: Status
          },
          {
            headerName: '',
            field: 'actions',
            minWidth: 200,
            cellRendererParams: {
              actions: [
                {
                  name: 'Delete',
                  type: 'delete',
                  icon: 'Close'
                }
              ],
              onClick: function (index, type, data) {
                deleteTerminal(data.rowIndex);
              }
            }
          }
        ];
      }

      return [
        {
          headerName: 'Lane ID',
          field: 'laneId',
          sortable: true,
          filter: true,
          minWidth: 80,
          wrapText: true,
          autoHeight: true
        },
        {
          headerName: 'Terminal ID',
          field: 'name',
          sortable: true,
          filter: true,
          minWidth: 80,
          wrapText: true,
          autoHeight: true
        },
        {
          headerName: 'Description',
          field: 'description',
          sortable: true,
          filter: true,
          minWidth: 80,
          wrapText: true,
          autoHeight: true
        },
        {
          headerName: 'Status',
          field: 'status',
          sortable: true,
          filter: true,
          minWidth: 80,
          wrapText: true,
          autoHeight: true,
          cellRendererFramework: Status
        },
        {
          headerName: '',
          field: 'actions',
          minWidth: 200,
          cellRendererParams: {
            actions: [
              {
                name: 'Delete',
                type: 'delete',
                icon: 'Close'
              }
            ],
            onClick: function (index, type, data) {
              deleteTerminal(data.rowIndex);
            }
          }
        }
      ];
    });

    return {
      columns,
      loadingList,
      onFirstDataRendered,
      itemsList,
      updateList,
      onNewTerminal,
      schema,
      showAddTerminalModal,
      terminalName,
      addTerminalActive,
      TERMINAL_NAMES
    };
  }
};
</script>

<style lang="scss" scoped>
.title {
  color: #383838;
  font-size: 24px;
}

.loader {
  z-index: 2;
}

::v-deep(.client-notes-modal) {
  .modal-dialog {
    max-width: 700px !important;
  }

  .modal-content {
    min-height: 500px;
  }
}

.add-terminal {
  height: 40px;
  padding: 0;
  background: #fff;
  border-color: #f6951e !important;
  color: #f6951e !important;

  &:hover,
  &:active,
  &:focus {
    background: var(--c-grey-light) !important;
  }
}

.order-terminal {
  padding-bottom: 10px !important;
}
</style>

<style lang="scss">
.clients {
  .actions {
    height: 100% !important;
  }

  .action-btn {
    line-height: initial;
    width: 30px !important;
    height: 30px !important;
    padding: 2px 1px !important;
    font-size: 14px !important;
    border-radius: 50% !important;

    .icon-container {
      width: auto !important;

      svg {
        width: 19px !important;
      }
    }

    &.success {
      color: #fff !important;
    }
  }
}
</style>
