<template>
  <div id="app" style="height: 100%">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script setup>
import { computed, watch, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import { useStore } from 'vuex';

const store = useStore();

onMounted(() => {
  store.dispatch('fetchUser');
});
const route = useRoute();
const layout = computed(() => {
  return route.meta.layout || DefaultLayout;
});

watch(
  () => route.path,
  () => {
    document.title = route.meta.title || 'Triple';
  },
  {
    immediate: true
  }
);
</script>

<style lang="scss">
@import '~ag-grid-community/styles/ag-grid.css';
@import '~ag-grid-community/styles/ag-theme-balham.css';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

* {
  font-family: 'Open Sans', sans-serif;
}
</style>
