import { computed } from 'vue';
import { INTERVAL_OPTIONS_KEYS, INTERVAL_NOUNS } from '@/config/constants.js';
const { DateTime } = require('luxon');

const PLURALS = {
  SINGLE: {
    [INTERVAL_OPTIONS_KEYS.DAILY]: 'every day',
    [INTERVAL_OPTIONS_KEYS.WEEKLY]: 'every week',
    [INTERVAL_OPTIONS_KEYS.MONTHLY]: 'every month',
    [INTERVAL_OPTIONS_KEYS.YEARLY]: 'every year'
  },
  MANY: {
    [INTERVAL_OPTIONS_KEYS.DAILY]: 'every {n} days',
    [INTERVAL_OPTIONS_KEYS.WEEKLY]: 'every {n} weeks',
    [INTERVAL_OPTIONS_KEYS.MONTHLY]: 'every {n} months',
    [INTERVAL_OPTIONS_KEYS.YEARLY]: 'every {n} years'
  }
};

const getPluralText = ({ frequency, interval }) => {
  if (+frequency === 1) {
    return PLURALS.SINGLE[interval];
  }

  return PLURALS.MANY[interval].replace('{n}', frequency);
};

const getSubscriptionDate = ({
  startDate,
  cycles,
  frequency,
  interval,
  trialPeriod = 0
}) => {
  if (!startDate || !cycles || !frequency || !interval) {
    return null;
  }

  let date = DateTime.fromFormat(startDate, 'yyyy-MM-dd');
  let startDateValue = DateTime.fromFormat(startDate, 'yyyy-MM-dd');
  // YYYY-MM-DD

  for (let i = 0; i < cycles; i++) {
    for (let j = 0; j < frequency; j++) {
      const addOptions = {
        [INTERVAL_NOUNS[interval]]: 1
      };

      date = date.plus(addOptions);
    }
  }

  if (trialPeriod) {
    date = date.plus({
      days: +trialPeriod
    });

    startDateValue = startDateValue.plus({
      days: +trialPeriod
    });
  }

  return {
    end: date.toFormat('yyyy-MM-dd'),
    start: startDateValue.toFormat('yyyy-MM-dd')
  };
};

export default function ({ amount, frequency, interval }) {
  const plurals = computed(() => {
    return getPluralText({
      frequency: frequency.value,
      interval: interval.value
    });
  });

  const amountFormatted = computed(() => {
    if (!amount.value) {
      return '';
    }

    return `$${Number(amount.value).toFixed(2)}`;
  });

  const frequencyText = computed(() => {
    return `${amountFormatted.value} ${plurals.value}`;
  });

  return {
    frequencyText,
    getSubscriptionDate
  };
}
