<template>
  <div class="d-flex justify-content-center">
    <base-button
      class="login-btn"
      type="button"
      color="grey-light"
      rounded
      @click="handleSocialLogin('google')"
    >
      <template #icon-only>
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48"><defs><path id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/></defs><clipPath id="b"><use xlink:href="#a" overflow="visible"/></clipPath><path clip-path="url(#b)" fill="#FBBC05" d="M0 37V11l17 13z"/><path clip-path="url(#b)" fill="#EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/><path clip-path="url(#b)" fill="#34A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/><path clip-path="url(#b)" fill="#4285F4" d="M48 48L17 24l-4-3 35-10z"/></svg>
      </template>
    </base-button>

    <base-button
      class="login-btn ml-4"
      type="button"
      color="grey-light"
      rounded
      @click="handleSocialLogin('microsoft')"
    >
      <template #icon-only>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23"><path fill="#f3f3f3" d="M0 0h23v23H0z"/><path fill="#f35325" d="M1 1h10v10H1z"/><path fill="#81bc06" d="M12 1h10v10H12z"/><path fill="#05a6f0" d="M1 12h10v10H1z"/><path fill="#ffba08" d="M12 12h10v10H12z"/></svg>
      </template>
    </base-button>
  </div>
</template>

<script>
import {
  LoginWithGoogle,
  LoginWithMicrosoft,
  sendEmailVerificationLink
} from '@/services/firebase/auth.js';
import useAuth from '@/composables/auth';
import useToast from '@/composables/common/toast';
import useErrorHandler from '@/composables/common/error-handler/index.js';
import useLogin from '@/composables/auth/useLogin.js';

import BaseButton from '@/components/base/BaseButton.vue';

export default {
  name: 'SocialNetworksLogin',
  components: {
    BaseButton
  },
  emits: [
    'show-spinner',
    'hide-spinner',
    'retry',
    'show-verify-modal',
    'custom-error'
  ],
  setup(_, { emit }) {
    const { auth } = useAuth();
    const { systemLogin, customErrorMessage } = useLogin(auth);
    const { showMessage } = useToast();
    const { getFirebaseError } = useErrorHandler();

    const checkUserVerification = (user) => {
      console.log(user);
      console.log("checkUserVerification - user is above")
      return user.emailVerified;
    };

    const sendEmail = (user) => {
      emit('show-spinner');
      const params = 'isloggedin=true';

      return sendEmailVerificationLink({ user, params })
        .then(() => {
          emit('hide-spinner');
          emit('show-verify-modal');
        })
        .catch((err) => {
          emit('hide-spinner');

          console.log('err sent');
          console.log(err);
        });
    };

    const handleError = (err) => {
      emit('hide-spinner');

      console.log(err);
      console.log("Firebase Error Above")

      /**
       * The popup closed by user pops up with Microsoft auth - it isn't a valid error. 
       */
      if (err.code !== 'auth/account-exists-with-different-credential' || err.code !== 'auth/popup-closed-by-user') {
        const error = getFirebaseError(err);
        
        console.log(error);
        console.log("Firebase Error Above")

        showMessage({
          title: 'Error',
          text: error,
          type: 'danger'
        });
        return;
      }

      emit('retry', err);
    };

    const handleSocialNetworkLogin = (user) => {

      console.log(user);
      console.log('handleSocialNetworkLogin - user above');

      const isVerified = checkUserVerification(user);

      if (!isVerified) {
        console.log('The user is not email verified. Sending an email and continuing.')
        sendEmail(user);
        return;
      }

      systemLogin().then(() => {
        if (customErrorMessage.value) {
          emit('hide-spinner');
          emit('custom-error', customErrorMessage.value);
        }
      });
    };

    const handleGoogleLogin = () => {
      emit('show-spinner');
      console.log("Calling handleGoogleLogin")

      LoginWithGoogle({
        auth: auth.value
      })
        .then((response) => {
          console.log(response);
          console.log("Login with microsoft initial value complete, result above")
          handleSocialNetworkLogin(response.user);
        })
        .catch((e) => {
          console.error(e);
          console.error("Error caught with handleGoogleLogin, see above")
          if (!e) {
            return;
          }

          handleError({ ...e, provider: 'google.com' });
        });
    };

    const handleMicrosoftLogin = () => {
      emit('show-spinner');

      console.log("Calling LoginWithMicrosoft")
      LoginWithMicrosoft({
        auth: auth.value
      })
        .then((result) => {
          console.log(result);
          console.log("Login with microsoft initial value complete, result above")
          handleSocialNetworkLogin(result.user);
        })
        .catch((e) => {
          console.error(e);
          console.error("Error caught with handleMicrosoftLogin, see above")
          if (!e) {
            return;
          }

          handleError({ ...e, provider: 'microsoft.com' });
        });
    };

    const handleSocialLogin = (type) => {
      customErrorMessage.value = null;
      emit('custom-error', null);

      emit('show-spinner');

      if (type === 'google') {
        handleGoogleLogin();
      }

      if (type === 'microsoft') {
        handleMicrosoftLogin();
      }
    };

    return {
      handleGoogleLogin,
      handleMicrosoftLogin,
      handleSocialLogin,

      customErrorMessage
    };
  }
};
</script>

<style lang="scss" scoped>
.login-btn {
  @include scale-on-hover;
}
</style>
