<template>
  <div class="base-tab">
    <div :class="{ disabled }" class="tab-default" @click="handleClick">
      <div
        class="title-container d-flex justify-content-between align-items-center"
        :class="{ active: opened }"
      >
        <h2 class="section-title d-flex w-100 align-items-center">
          <span class="w-100">
            {{ title }}
          </span>

          <slot name="top"></slot>
        </h2>

        <chevron-down class="icon" :size="30" />
      </div>
      <hr v-if="!hideHr" />
    </div>

    <div ref="collapse" class="content collapse show">
      <slot name="default" />
    </div>
  </div>
</template>

<script>
import Collapse from 'bootstrap/js/dist/collapse';
import { onMounted, ref, toRefs } from 'vue';
import ChevronDown from 'vue-material-design-icons/ChevronDown.vue';

export default {
  name: 'BaseTab',
  components: {
    ChevronDown
  },
  props: {
    title: {
      type: String,
      default: null
    },
    hideHr: {
      type: Boolean,
      default: false
    },
    initialOpen: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const collapse = ref(null);
    const collapseEl = ref(null);
    const { initialOpen, disabled } = toRefs(props);
    const opened = ref(initialOpen.value);

    onMounted(() => {
      collapseEl.value = new Collapse(collapse.value, {
        toggle: !initialOpen.value
      });
    });

    const handleClick = () => {
      if (disabled.value) {
        return;
      }

      if (collapseEl.value._isTransitioning) {
        return;
      }

      if (opened.value) {
        collapseEl.value.hide();
      } else {
        collapseEl.value.show();
      }

      opened.value = !opened.value;
    };

    return {
      opened,
      collapse,
      handleClick
    };
  }
};
</script>

<style lang="scss" scoped>
.section-title {
  font-size: 20px;
  color: #121212;
  margin: 0;
}

.title-container {
  color: #121212;
  cursor: pointer;
}

.title-container {
  .icon {
    transition: all 0.2s ease;
  }

  &:not(.active) {
    .icon {
      transform: rotate(180deg);
    }
  }
}

.disabled {
  background: #f5f5f5;
  cursor: not-allowed;

  .section-title {
    color: #505050;
    cursor: not-allowed;
  }
}
</style>
