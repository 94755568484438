import { useStore } from 'vuex';

export default function () {
  const store = useStore();

  const showMessage = (props) => {
    store.commit('show', props);
  };

  return {
    showMessage
  };
}
