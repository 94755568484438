<!-- HTML:
<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Cancel Subscription</title>
  <link rel="stylesheet" href="path-to-your-bootstrap-css/bootstrap.min.css">
</head>
<body>
  <div class="container">
    <div class="row justify-content-center mt-5">
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <h2 class="card-title">Cancel Subscription</h2>
            <p class="card-text" id="cancelText">
              Are you sure you want to cancel your subscription?
            </p>
            <p class="text-danger mt-2" id="confirmationText" style="display: none;">Cancellation confirmed</p>
            <button id="cancelButton" class="btn btn-danger mt-2" onclick="cancelSubscription()" style="display: inline;">
              Cancel Subscription
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <script>
    const seriesId = '';
    const merchantName = '';
    const amount = '';

    const cancelText = document.getElementById('cancelText');
    const confirmationText = document.getElementById('confirmationText');
    const cancelButton = document.getElementById('cancelButton');

    function cancelSubscription() {
      const apiUrl = '/api/unsubscribe?apikey=
      const data = {
        cancelId: seriesId,
        reason: 'Ended by user',
      };

      const xhr = new XMLHttpRequest();
      xhr.open('POST', apiUrl, true);
      xhr.setRequestHeader('Content-Type', 'application/json');

      xhr.onload = function () {
        if (xhr.status === 200) {
          // Successful cancellation
          cancelText.style.display = 'none';
          confirmationText.style.display = 'block';
          cancelButton.style.display = 'none';
        } else {
          // Handle other HTTP status codes here
          console.error('Cancellation failed with status:', xhr.status);
        }
      };

      xhr.onerror = function () {
        console.error('Error canceling subscription:', xhr.statusText);
        // Handle the error
      };

      xhr.send(JSON.stringify(data));
    }
  </script>
</body>
</html>
 -->
<!-- Vue.js: -->
<template>
  <div class="container">
    <div class="row justify-content-center mt-5">
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <h2 class="card-title">Cancel Subscription</h2>
            <p class="card-text" v-if="!cancellationConfirmed">
              Are you sure you want to cancel your subscription?
            </p>
            <p class="text-danger mt-2" v-else>Cancellation confirmed</p>
            <button @click="cancelSubscription" class="btn btn-danger mt-2" v-if="!cancellationConfirmed">
              Cancel Subscription
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import http from '@/services/http';
import { ref, toRefs, onMounted, defineProps } from 'vue';

const props = defineProps({
  seriesId: String,
  merchantName: String,
  amount: String
});

const cancellationConfirmed = ref(false);

const cancelSubscription = async () => {
  try {
    const response = await http.post('/api/unsubscribe', {
      cancelId: props.seriesId,
      reason: 'Ended by user',
    });
    console.log('Subscription cancellation response:', response.data);
    if (response.status === 200) {
      cancellationConfirmed.value = true;
    } else {
      // Handle other HTTP status codes here
      console.error('Cancellation failed with status:', response.status);
    }
  } catch (error) {
    console.error('Error canceling subscription:', error);
    // Handle the error
  };
};
</script>
