<template>
  <div class="content mx-auto">
    <form class="needs-validation" id="registrationForm" @submit="submitForm">
      <!-- Card 1: Developer Information -->
      <div class="card w-75 w-md-50 mx-auto main-shadow">
        <div class="card-body">
          <div class="mx-auto w-auto">
            <div class="card-title">Developer Information</div>
            <div class="card-subtitle">Step 1 of 4</div>
          </div>
          <div class="form-group">
            <label for="firstName" class="reg-label">First Name</label>
            <input v-model="firstName" type="text" class="form-control" id="firstName" required>
            <div class="invalid-feedback">Your full name is required.</div>
          </div>
          <div class="form-group">
            <label for="lastName" class="reg-label">Last Name</label>
            <input type="text" class="form-control" id="lastName" required>
            <div class="invalid-feedback">Your full name is required.</div>
          </div>
          <div class="form-group">
            <label for="email" class="reg-label">Work Email Address</label>
            <input type="email" class="form-control" id="email" required>
            <div class="invalid-feedback">Your work email address is required.</div>
          </div>
          <div class="form-group">
            <label for="company" class="reg-label">Company Name</label>
            <input type="text" class="form-control" id="company" required>
            <div class="invalid-feedback">Your company name is required.</div>
          </div>
        </div>
      </div>

      <!-- Card 2: Integration Requirements -->
      <div class="card w-75 w-md-50 mx-auto main-shadow">
        <div class="card-body">
          <div class="mx-auto w-auto">
            <div class="card-title">Integration Requirements</div>
            <div class="card-subtitle">Step 2 of 4</div>
          </div>
          <p>Select all features that you will utilize in your application. Features must be developed and tested within the sandbox environment and certified before live use is enabled. Testing instructions will be sent upon submitting this form.</p>
          <div class="form-group">
            <label>Required:</label>
            <ul>
              <li>Enrolling & Managing Merchants</li>
              <li>Transaction History & Report</li>
              <li>Monthly Statements</li>
              <li>Voiding & Refunding Transactions</li>
              <li>Webhook Callbacks</li>
            </ul>
          </div>
          <div class="form-group">
            <label><strong>Iframe</strong></label>
            <ul style="list-style-type: none;">
                <li><input type="checkbox" name="iframeCardCharge" value="ChargeCard" id="iframeCardCharge"> Charging Cards</li>
                <li><input type="checkbox" name="iframeAchCharge" value="ChargeACH" id="iframeAchCharge"> Charging ACH</li>
                <li><input type="checkbox" name="iframeCryptoCharge" value="ChargeCrypto" id="iframeCryptoCharge"> Charging with Crypto</li>
                <li><input type="checkbox" name="iframeAuthSet" value="AuthorizeSettleCard" id="iframeAuthSet"> Authorizing and Settling Cards</li>
                <li><input type="checkbox" name="iframeCardTokenize" value="TokenizeCard" id="iframeCardTokenize"> Tokenizing Cards (saving encrypted cards on file for a future transactions)</li>
                <li><input type="checkbox" name="iframeAchTokenize" value="TokenizeACH" id="iframeAchTokenize"> Tokenizing ACH (saving encrypted ACHs on file for a future transaction)</li>
                <li><input type="checkbox" name="iframeSubs" value="BeginSubscription" id="iframeSubs"> Enrolling Customers in Subscriptions (includes managing and cancelling subscriptions)</li>
                <li><input type="checkbox" name="iframeSubs" value="BeginPlan" id="iframePlans"> Enrolling Customers in Payment Plans (includes managing and cancelling payment plans)</li>
                <li><input type="checkbox" name="iframeMeta" value="IncludeMetaData" id="iframeMeta"> Passing in Meta Data (such as Patient or Customer ID or Account Number)</li>
            </ul>
          </div>
          <div class="form-group">
              <label><strong>Terminals (Card Present)</strong></label>
              <ul style="list-style-type: none;">
                  <li><input type="checkbox" name="terminalCharge" value="Charge" id="terminalCharge"> Charging Cards</li>
                  <li><input type="checkbox" name="terminalAuthSet" value="AuthorizeSettle" id="terminalAuthSet"> Authorizing and Settling</li>
                  <li><input type="checkbox" name="terminalSignature" value="Signature" id="terminalSignature"> Getting Customer Signatures</li>
                  <li><input type="checkbox" name="terminalSignature" value="Receipt" id="terminalReceipt"> Printing Receipts</li>
              </ul>
          </div>
          <div class="form-group">
              <label><strong>API</strong></label>
              <ul style="list-style-type: none;">
                  <li><input type="checkbox" name="apiCardCharge" value="ChargeCard" id="apiCardCharge"> Charging Cards</li>
                  <li><input type="checkbox" name="apiAchCharge" value="ChargeACH" id="apiAchCharge"> Charging ACH</li>
                  <li><input type="checkbox" name="apiAuthSet" value="AuthorizeSettle" id="apiAuthSet"> Authorizing and Settling Cards</li>
                  <li><input type="checkbox" name="apiCardTokenize" value="TokenizeCard" id="apiCardTokenize"> Tokenizing Cards (saving encrypted cards on file for a future transactions)</li>
                  <li><input type="checkbox" name="apiAchTokenize" value="TokenizeACH" id="apiAchTokenize"> Tokenizing ACH (saving encrypted ACHs on file for a future transaction)</li>
                  <li><input type="checkbox" name="apiSubs" value="BeginSubscription" id="apiSubs"> Enrolling Customers in Subscriptions (includes managing and cancelling subscriptions)</li>
                  <li><input type="checkbox" name="apiSubs" value="BeginPlan" id="apiPlans"> Enrolling Customers in Payment Plans (includes managing and cancelling payment plans)</li>
                  <li><input type="checkbox" name="apiMeta" value="IncludeMetaData" id="apiMeta"> Passing in Meta Data (such as Customer Name, Account Number, etc.)</li>
              </ul>
          </div>
        </div>
      </div>

      <!-- Card 3: Enrollment Expectations -->
      <div class="card w-75 w-md-50 mx-auto main-shadow">
        <div class="card-body">
          <div class="mx-auto w-auto">
            <div class="card-title">Enrollment Expectations</div>
            <div class="card-subtitle">Step 3 of 4</div>
          </div>
          <div>
            <p>Provide the number of merchants that you expect to enroll. We’ll provide the appropriate enrollment resources accordingly.</p>
          </div>
          <div class="card-input mt-1">
            <p>Expected number of merchants to enroll <strong>immediately</strong> upon going live:</p>
            <select v-model="merchantsImmediately" class="form-select">
              <option value="" disabled selected>Select</option>
              <option value="0-50">0-50</option>
              <option value="51-200">51-200</option>
              <option value="201-500">201-500</option>
              <option value="501-1000">501-1000</option>
              <option value="1000+">1000+</option>
            </select>
          </div>
          <div class="card-input mt-1">
            <p>Expected additional merchants to enroll <strong>within 90 days</strong> of going live:</p>
            <select v-model="merchantsWithin90Days" class="form-select">
              <option value="" disabled selected>Select</option>
              <option value="0-50">0-50</option>
              <option value="51-200">51-200</option>
              <option value="201-500">201-500</option>
              <option value="501-1000">501-1000</option>
              <option value="1000+">1000+</option>
            </select>
          </div>
        </div>
      </div>

      <!-- Card 4: Webhook Registration -->
      <div class="card w-75 w-md-50 mx-auto main-shadow">
        <div class="card-body">
          <div class="mx-auto w-auto">
            <div class="card-title">Webhook Registration</div>
            <div class="card-subtitle">Step 4 of 4</div>
          </div>
          <div>
            <p>Please enter a web address to which webhook callbacks can be sent during your integration development and testing. Please provide up to 24 hours to begin receiving callbacks. This URL can be changed later if needed.</p>
          </div>
          <div class="form-group">
            <label for="callbackUrl" class="reg-label">Callback URL</label>
            <input v-model="callbackURL" type="text" class="form-control" id="callbackUrl">
          </div>
        </div>
      </div>

      <!-- Submit Button and Confirmation Message -->
      <div class="d-flex flex-column justify-content-center align-items-center">
        <p class="mt-3 mb-0" :class="{ 'd-none': !formSubmitted && formValid }">Please make sure you have selected your integration needs.</p>
        <button type="submit" class="btn btn-primary complete-step">Submit</button>
      </div>
    </form>
    <div class="confirmation" :class="{ 'd-none': !formSubmitted || !formValid }">
      <h2 class="mb-4">Thank You</h2>
      <div class="text-success mb-3">
        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
          <path d="M12.186 3.186a.5.5 0 0 1 .628.628l-5 10a.5.5 0 0 1-.8.07l-3.5-3.5a.5.5 0 1 1 .708-.708L6 12.293l4.186-8.187z"/>
        </svg>
      </div>
      <p class="mb-0">Your registration has been completed. Check your inbox for further instructions.</p>
    </div>
  </div>
</template>
  
<script setup>
  import { computed, ref, onMounted } from 'vue';
  import http from '@/services/http';
  import { useStore } from 'vuex';
  
  // Get user data
  const store = useStore();
  const defaultClientName = computed(() => store.getters.defaultClientName);
  const defaultClientid = computed(() => store.getters.defaultClientid);
  const role = computed(() => store.getters.role);

  const validateForm = () => {
    if (firstName.value && lastName.value && email.value && company.value) {
      return true;
    } else {
      return false;
    }
  };
  
  const firstName = ref('');
  const lastName = ref('');
  const email = ref('');
  const company = ref('');

  const formSubmitted = ref(false);
  const formValid = ref(false);
  
  const submitForm = () => {
    // Perform validation on the form fields
    if (validateForm()) {
      // Create the email content
      const emailContent = {
        subject: "Development Certification Instructions",
        instructions: [],
      };

      // Prepare instructions for each selected checkbox
      const checkboxInstructions = {
        iframeCardCharge: "Iframe: Charge a card for $4.23. Refund $2.11 of the transaction.",
        iframeAchCharge: "Iframe: Charge an ACH account for $5.26. Refund $2.12 of the transaction.",
        iframeCryptoCharge: "Iframe: There are currently no sandbox simulations for Crypto.",
        iframeCardTokenize: "Iframe: Tokenize a card without charging it. Retrieve the token. Charge that tokenized card $7.23.",
        iframeAchTokenize: "Iframe: Tokenize a bank account without charging it. Retrieve the token. Charge that tokenized account $7.24.",
        iframeSubs: "Iframe: Set a subscription that will start 30 days from the date of set-up and be charged monthly. Set up another subscription that will begin today and charge the card weekly. Cancel the weekly subscription. Refund half of the payment that was made.",
        iframeMeta: "Iframe: Include an invoice number of 12345 with a card transaction.",
        iframeAuthSet: "Iframe: Authorize a card for $50. Settle the authorized card for $23.22.",
        terminalCharge: "Terminal: Charge a card for $4.24. Refund the transaction for $2.13.",
        terminalAuthSet: "Terminal: Authorize a card for $30. Settle the card for $20.22. Refund the transaction for $2.14.",
        terminalSignature: "Terminal: Retrieve a signature with a charge of $40.99.",
        apiCardCharge: "API: Charge a card for $4.25. Refund the transaction for $2.21.",
        apiAchCharge: "API: Charge an ACH account for $5.27. Refund the transaction for $2.18.",
        apiAuthSet: "API: Authorize a card for $60. Settle the authorized card for $25.99. Refund the transaction for $2.29.",
        apiSubs: "API: Set a subscription that will start 15 days from the date of set-up and be charged monthly. Set up another subscription that will begin today and charge the card weekly. Cancel the weekly subscription. Refund half of the payment that was made.",
        apiCardTokenize: "API: Tokenize a card without charging it. Retrieve the token. Charge that tokenized card $7.25.",
        apiAchTokenize: "API: Tokenize a bank account without charging it. Retrieve the token. Charge that tokenized account $7.26.",
      };

      // Loop through the checkboxes
      for (const checkboxId in checkboxInstructions) {
        const checkbox = document.getElementById(checkboxId);
        if (checkbox.checked) {
          const checkboxInstruction = checkboxInstructions[checkboxId];
          emailContent.instructions.push(checkboxInstruction);
        }
      }

      if (emailContent.instructions.length > 0) {
        // Call sendEmail function with API call
        sendEmail(emailContent);

        // Update formValid and formSubmitted
        formValid.value = true;
        formSubmitted.value = true;
      }
    }
  };
  
  const sendEmail = (emailContent) => {
    const to = `devteam@tripleplaypay.com,${email.value}`;
    
    const emailBody = emailContent.instructions.join('<br>');
    const welcome = encodeURIComponent(`Hello and welcome to Triple Play Pay's development certificate process.<br><br>
      Please save this email - below are the tests that need to be submitted to receive a certificate of readiness and ability to use a production API key to process live transactions.<br><br>
      To find your sandbox API key:<br>
      1. Log in to the <a href="https://sandbox.tripleplaypay.com" target="_blank">sandbox environment</a> using the email address
      that received the original registration form link<br>
      2. Open "Merchant Accounts" in the menu<br>
      3. Open the account of which you wish to retrieve the API key for integration<br>
      4. Navigate to the "Open" dropdown toward the right side of the page and select "IDs"<br><br>
      Test and integrate the API into your platform using the following URL in your API calls: https://sandbox.tripleplaypay.com/api.<br>
      Test and integrate the iframe by implementing the Sandbox script code and configuring the iframe per the <a href="https://tripleplaypay.com/iframe.html" target="_blank">iframe documentation</a>. Sandbox script source: src="https://sandbox.tripleplaypay.com/static/js/sandbox.js".<br><br>
      Sandbox URLs, scripts, and API keys save no sensitive data, and dummy data and card details can be used. Dummy card numbers must begin with "1".
      It is a true practice environment that exactly mimics our production environment. Once you are ready, please complete the tests below.<br><br>
      Upon completion, please email devteam@tripleplaypay.com.<br><br>`);
    
    const required = encodeURIComponent(`API: Retrieve your report for the past week of transactions.<br>API: Retrieve a receipt for a transaction that you have completed.<br>API: Fully refund a transaction that you have submitted.<br>API (or contact link, if provided): Enroll a merchant with any dummy details that you choose.<br>`);
    
    const details = encodeURIComponent(`<br><br>Lead Developer: ${firstName.value} ${lastName.value}<br>Email: ${email.value}<br>Company: ${company.value}`);
    
    const contact = encodeURIComponent(`<br><br>Please reach out to devteam@tripleplaypay.com with questions.`);

    // Construct the final URL for the email API call
    const emailURL = `https://tripleplaypay.com/api/email?to=${to}&subject=${emailContent.subject}&body=${welcome}${emailBody}${required}${details}${contact}`;

    // Send the email using an API call
    http.get(emailURL)
      .then((response) => {
        if (response.status === 200) {
          // Email sent successfully
          // You can handle any further actions here
        } else {
          // Email sending failed
          console.error('Failed to send the email.');
        }
      })
      .catch((error) => {
        console.error('Error sending email:', error);
      });
  };
</script>
<style scoped>
.card {
    width: 100%;
    padding: 50px 61px;
    background: rgba(70, 80, 130, 0.20);
    border-radius: 12px;
    margin-top: 50px;
}

.card .card-title {
    color: white;
    font-size: 32px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 800;
    word-wrap: break-word;
    margin-bottom: 5px;
}

.card .card-subtitle {
    color: white;
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    word-wrap: break-word;
    margin-bottom: 15px;
}

.form-group {
  margin-top: 10px;
}

.card-input {
  margin-top: 15px;
}

</style>
  