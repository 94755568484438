<template>
  <div>
    <div class="d-flex align-items-center">
      <a
        href="#"
        role="button"
        class="menu-button d-flex flex-column justify-content-between d-inline"
        @click.prevent="handleClick"
      >
        <span></span>
        <span></span>
        <span></span>
      </a>
      <span class="d-inline ms-2">Menu</span>
    </div>

    <transition name="fade">
      <div v-if="modelValue" class="overlay" @click="close"></div>
    </transition>

    <ul class="menu-items py-3" :class="{ 'menu-active': modelValue }">
      <div class="d-flex justify-content-end px-2 mb-4">
        <a href="#" role="button" class="menu-button" @click.prevent="close">
          <img src="@/assets/icons/close.svg" alt="" />
        </a>
      </div>

      <li
        v-for="(item, index) in items"
        :key="index"
        class="mb-2"
        @click="close"
      >
        <a
          v-if="item.external || item.callback"
          :href="item.path || '#'"
          target="_blank"
          class="menu-item px-3 d-flex align-items-center"
          @click="handleMenuClick($event, item)"
        >
          <span
            class="icon mr-2 d-flex align-items-center justify-content-center"
          >
            <component :is="item.icon" v-if="item.icon" :size="25" />
          </span>

          <span>
            {{ item.title }}
          </span>
        </a>
        <router-link
          v-else
          :to="item.path"
          class="menu-item px-3 d-flex align-items-center"
        >
          <span
            class="icon mr-2 d-flex align-items-center justify-content-center"
          >
            <component :is="item.icon" v-if="item.icon" :size="25" />
          </span>

          <span>
            {{ item.title }}
          </span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { toRef } from 'vue';
export default {
  name: 'MobileMenu',
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const modelValue = toRef(props, 'modelValue');

    const handleClick = () => {
      emit('update:modelValue', !modelValue.value);
    };

    const close = () => {
      emit('update:modelValue', false);
    };

    const handleMenuClick = (e, item) => {
      if (!item.callback) {
        return;
      }

      item.callback(e);
    };

    return {
      handleClick,
      handleMenuClick,
      close
    };
  }
};
</script>

<style lang="scss" scoped>
.menu-button {
  border: none;
  outline: none;
  background: none;
  width: 25px;
  height: 16px;

  span {
    min-height: 2px;
    max-height: 2px;
    background-color: var(--c-grey-medium);
    width: 100%;
    border-radius: 2px;
  }
}

.close {
  span {
    background-color: var(--c-grey-medium);

    &:first-child {
      transform: rotate(50deg);
    }

    &:last-child {
      transform: rotate(100deg);
    }
  }
}

.overlay {
  transition: all 0.2s ease;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.menu-items {
  padding: 0;
  margin: 0;
  position: fixed;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  z-index: 11;
  list-style: none;
  background-color: var(--c-white);
  transition: all 0.2s ease;
  transform: translateX(105%);

  li {
    width: 100%;
  }
}

.menu-active {
  transform: translateX(0);
}

.menu-item {
  text-decoration: none;
  color: var(--c-dark) !important;
  font-size: 16px;
  border-bottom: 1px solid var(--c-grey-medium);
  padding-bottom: 5px;
  display: block;
  font-weight: 500;
}
</style>
