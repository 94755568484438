import { ref } from 'vue';
import { PAYMENT_TYPES } from '@/config/constants.js';

const value = `
const triple = new Triple('{apikey}');

triple.generatePaymentForm({
    // payment form container here
    containerSelector: '#example',
{values}
})
`;

const getProperValue = (value) => {
  if (typeof value === 'string') {
    return JSON.stringify(value);
  }

  if (typeof value === 'boolean') {
    return `${value}`;
  }

  if (typeof value === 'number') {
    return `${value}`;
  }

  if (Array.isArray(value)) {
    return `[${value.reduce((prev, curr) => {
      if (typeof curr === 'number') {
        return prev + curr;
      }

      return prev + `'${curr}',`;
    }, '')}]`;
  }

  if (typeof value === 'object' && value) {
    let result = JSON.stringify(value);

    return result.replace(/{/g, '{\n    ');
  }

  return value;
};

const SUBSCRIPTION_KEYS = ['start', 'frequency', 'interval', 'trial', 'cycles'];

export default function () {
  const showSourceCodeModal = ref(false);
  const sourceCodeValue = ref(null);

  const getDataAsString = (data) => {
    // let result = value.replace(/\s{4,}/g, '');
    let result = value.trim();

    let dataValues = '';
    const dataFormatted = { ...data };

    // remove redundant fields
    delete dataFormatted.newWindow;
    delete dataFormatted.fromLink;
    delete dataFormatted.apikey;
    delete dataFormatted.end;
    delete dataFormatted.source;

    const filtered = Object.keys(dataFormatted)
      .filter((e) => {
        if (
          data.paymentType === PAYMENT_TYPES.CHARGE &&
          SUBSCRIPTION_KEYS.includes(e)
        ) {
          return false;
        }

        if (
          typeof dataFormatted[e] === 'object' &&
          dataFormatted[e] &&
          !Object.keys(dataFormatted[e]).length
        ) {
          return false;
        }

        if (typeof dataFormatted[e] === 'boolean') {
          return true;
        }

        return dataFormatted[e];
      })
      .reduce((prev, curr) => {
        return {
          ...prev,
          [curr]: dataFormatted[curr]
        };
      }, {});

    let asKeys = Object.keys(filtered);

    asKeys.forEach((key, index) => {
      let ending = index < asKeys.length - 1 ? ', \n' : '';

      dataValues += `    ${key}: ${getProperValue(filtered[key])}${ending}`;
    });

    result = result.replace('{apikey}', data.apikey || 'testapikey');
    result = result.replace('{values}', dataValues);

    return result;
  };

  const handleShowSourceCode = (data) => {
    sourceCodeValue.value = getDataAsString(data);

    showSourceCodeModal.value = true;
  };

  return {
    showSourceCodeModal,
    sourceCodeValue,

    handleShowSourceCode
  };
}
