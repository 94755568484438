<template>
  <div>
    <main>
      <slot />
    </main>
  </div>
</template>

<script setup>
</script>
