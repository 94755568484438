<template>
  <div
      ref="modal"
      :class="['modal', 'fade', { 'modal-xl': isLarge }]"
      class="modal fade"
      tabindex="-1"
      aria-hidden="true"
      data-test="modal"
      :data-bs-backdrop="backgroundClose ? 'static' : true"
      :data-bs-keyboard="false"
  >
    <div
        v-if="!backgroundClose"
        class="modal-custom-overlay"
        @click="handleCustomOverlayClick"
    ></div>
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header justify-content-center">
          <h5 class="modal-title" data-test="title">{{ title }}</h5>
        </div>

        <button
            v-if="!hideCloseBtn"
            type="button"
            class="btn-close"
            @click="handleClose"
        ></button>

        <div class="modal-body" data-test="default">
          <slot v-if="showContent"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, toRef } from 'vue';
import { onMounted, watch } from 'vue';
import Modal from 'bootstrap/js/dist/modal';

/** Component used for modals
 *
 *  @example
 *  <base-modal
 *   v-model="showModal"
 *   title="This is modal"
 *  >
 *   Modal content goes here
 *  </base-modal>
 *
 */

const HIDE_MODAL_CONTENT_AFTER_CLOSE_DELAY = 500;

export default {
  name: 'BaseModal',
  props: {
    title: {
      type: String,
      default: null
    },
    isLarge: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: Boolean,
      default: false
    },
    backgroundClose: {
      type: Boolean,
      default: false
    },
    hideCloseBtn: {
      type: Boolean,
      default: false
    },
    beforeCloseConfirm: {
      type: Function,
      default: null
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const modal = ref(null);
    const modelValue = toRef(props, 'modelValue');
    const beforeCloseConfirm = toRef(props, 'beforeCloseConfirm');
    const currentModal = ref(null);
    const showContent = ref(false);
    const timerId = ref(null);

    const confirmClose = async () => {
      return await beforeCloseConfirm.value();
    };

    watch(
        () => modelValue.value,
        (newV) => {
          if (newV) {
            showContent.value = true;
            currentModal.value.show();
            clearTimeout(timerId.value);
          } else {
            currentModal.value.hide();

            timerId.value = setTimeout(() => {
              showContent.value = false;
            }, HIDE_MODAL_CONTENT_AFTER_CLOSE_DELAY);
          }
        }
    );

    const handleClose = async () => {
      if (!beforeCloseConfirm.value) {
        emit('update:modelValue', false);
        return;
      }

      const result = await confirmClose();

      if (result) {
        emit('update:modelValue', false);
      }
    };

    onMounted(() => {
      currentModal.value = new Modal(modal.value);

      modal.value.addEventListener('hide.bs.modal', () => {
        emit('update:modelValue', false);
      });

      modal.value.addEventListener('show.bs.modal', () => {
        setTimeout(() => {
          const el = currentModal.value._backdrop._element;

          el.addEventListener('click', () => {
            console.log(5);
          });
        }, 100);

        emit('update:modelValue', true);
      });

      if (!beforeCloseConfirm.value) {
        return;
      }
    });

    const handleCustomOverlayClick = () => {
      handleClose();
    };

    return {
      modal,
      showContent,

      handleClose,
      handleCustomOverlayClick
    };
  }
};
</script>

<style lang="scss" scoped>
.modal-header {
  border-bottom: none !important;
  padding-bottom: 5px;
}

.modal-content {
  @include has-border-radius;
}

.btn-close {
  position: absolute;
  right: 10px;
  top: 10px;
  opacity: 0.3 !important;
  z-index: 3;
}

.modal-body {
  padding-top: 0;
}

.modal-dialog {
  z-index: 3;
}

.modal-custom-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 2;
  opacity: 0;
}
</style>
