<template>
    <div class="management-page">     
      <span style="color: #383838; font-size: 24px;">Accounts</span>
        <br>
        <AgGrid
            apiurl = "/api/portal/clients"
            :columns = "['id', 'hashcode', 'PARENT','DBA NAME', 'LEGAL NAME', 'LIVE', 'UPDATED']"
            :sideBar = true
            :statusBar = "statusBar"
            groupPanel = ''
            :moveFront = "['name',]"
            @row2Selected = "rowDetails"
            :excelOptions = "{fileName:'Clients.xlsx'}"
            height="90vh"
        ></AgGrid>
    </div>
  </template>
  
 <script setup>
  import { computed, ref, reactive, defineProps, onMounted } from 'vue';
  import { useStore } from 'vuex';
  import { useRoute, useRouter } from 'vue-router';
  import useService from '@/composables/common/services';
  import AgGrid from '@/components/AgGrid.vue';
  
      const updatingData = ref(false);
      const statusBar =  {
        statusPanels: [
            {
              statusPanel: 'agAggregationComponent',
              align: 'right',
            }
        ]
    }
      const route = useRoute();
      const router = useRouter();
      const { apiService } = useService();
  
      const store = useStore();
      const role = computed(() => {
        return store.getters['role'];
      });
  
      
      const rowDetails = (row) => { 
        router.push({name:'Account', params: {clientid: row[0].data.id} })}
      onMounted(async () => {
      });

  </script>
  
  <style lang="scss" scoped>
  .loader {
    z-index: 2;
  }
  
  ::v-deep(.client-docs-modal) {
    .modal-dialog {
      max-width: 750px !important;
    }
  
    .modal-content {
      min-height: 500px;
    }
  }
  
  ::v-deep(.clients-onboarding-modal) {
    .modal-dialog {
      max-width: 1050px !important;
    }
  
    .modal-content {
      min-height: 500px;
    }
  }
  
  ::v-deep(.client-notes-modal) {
    .modal-dialog {
      max-width: 700px !important;
    }
  
    .modal-content {
      min-height: 500px;
    }
  }
  
  .page-mode-dropdown {
    display: inline-block;
    min-width: 200px;
  }
  
  ::v-deep(.page-mode-dropdown) {
    .value {
      font-size: 24px;
      color: var(--c-dark);
      border: none !important;
      background: transparent !important;
    }
  
    .text {
      font-weight: 600;
      width: auto;
      margin-right: 10px;
    }
  }
  
  </style>
  
  <style lang="scss">
  .clients {
    .ag-row {
      cursor: pointer;
    }
  
    .actions {
      height: 100% !important;
    }
  
    .action-btn {
      line-height: initial;
      width: 30px !important;
      height: 30px !important;
      padding: 2px 1px !important;
      font-size: 14px !important;
      border-radius: 50% !important;
  
      .icon-container {
        width: auto !important;
  
        svg {
          width: 19px !important;
        }
      }
  
      &.success {
        color: #fff !important;
      }
    }
  
    .status-value {
      &.Rejected {
        background: #ffe4e4 !important;
        color: #b3322c !important;
      }
  
      &.Approved,
      &.Boarded,
      &.Active {
        color: var(--c-success);
        background: #ddffef;
      }
    }
  }
  </style>
  