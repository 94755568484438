import { useStore } from 'vuex';
import { getAuth } from 'firebase/auth';
import { useRouter, useRoute } from 'vue-router';
import cookieService from '@/services/cookies';

export default function () {
  const store = useStore();
  const router = useRouter();

  const user = store.getters['getUser'];

  const logout = async () => {
    console.log('inside logout command');
    try {
      console.log('getAuth.signout()');
      await getAuth().signOut();
      console.log('getAuth.signout() complete');
      console.log('cookieService.logout()');
      await cookieService.logout();
      console.log('cookieService.logout() complete');
    } catch (e) {
      console.error(e);
      console.error("Error inside logout method - see above for further detail")
    } finally {
      console.log('Inside finally block of logout, pushing router to Login');
      router.push({name:'Login'});
      console.log('Inside finally block of logout, concluded all operations.');
    }
  };

  return {
    logout
  };
}
