<template>
  <div class="nav-container mt-2" style="background-color: var(--c-grey-light)">
    <nav class="navbar navbar-expand-sm">
      <div
        class="container-fluid d-flex justify-content-start gap-2 align-items-center"
      >
        <h3>{{ clientData.name }}</h3>
        <div
          v-if="user.user_type === 'admin' || user.user_type === 'healnow'"
          class="vr d-none d-lg-block mx-2"
        ></div>
        <div
          v-if="user.user_type === 'admin' || user.user_type === 'healnow'"
          class="jump-to d-none d-lg-block w-lg-50"
        >
          <div class="d-flex flex-column">
            <p
              style="
                border-bottom: 1px solid black;
                font-size: 10px;
                font-weight: bold;
              "
            >
              <span class="ms-2">Jump to:</span>
            </p>
            <div id="navbarNav" style="font-size: 12px">
              <ul class="navbar-nav">
                <li class="nav-item dropdown">
                  <a
                    class="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                    href="#"
                    role="button"
                    aria-expanded="false"
                    >Account Details</a
                  >
                  <ul class="dropdown-menu">
                    <li class="nav-item">
                      <a class="nav-link" href="#merchantDetails"
                        >Merchant Information</a
                      >
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#address">Merchant Address</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#contact">Merchant Contact</a>
                    </li>
                  </ul>
                </li>
                <li class="nav-item dropdown">
                  <a
                    class="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                    href="#"
                    role="button"
                    aria-expanded="false"
                    >Processing</a
                  >
                  <ul class="dropdown-menu">
                    <li class="nav-item">
                      <a class="nav-link" href="#cards">Cards</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#ach">ACH</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#terminals">Terminals</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#addtnl">Settings & Fees</a>
                    </li>
                  </ul>
                </li>
                <li
                  v-if="clientData.level === 'Parent'"
                  class="nav-item dropdown"
                >
                  <a
                    class="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                    href="#"
                    role="button"
                    aria-expanded="false"
                    >Submerchant Settings</a
                  >
                  <ul class="dropdown-menu">
                    <li class="nav-item">
                      <a class="nav-link" href="#enroll">Enrollment</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#currentProcessing">Pricing</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#subProcessing">Subpricing</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#whitelabel">Whitelabel</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          v-if="user.user_type === 'admin' || userHasEditMerchantPermissions"
          class="ms-auto d-flex gap-1 flex-md-shrink"
        >
          <button
            id="editBtn"
            class="btn btn-primary edit-btn"
            @click="editAccount"
          >
            Edit
          </button>
          <div class="vr"></div>
          <button
            id="saveBtn"
            class="btn btn-success save-btn"
            @click="saveChanges"
          >
            Save
          </button>
        </div>
      </div>
    </nav>
  </div>
  <div class="container mt-3">
    <div class="d-flex flex-row">
      <div class="d-flex flex-column w-100">
        <h3>Account Data</h3>
        <div
          v-if="
            user.user_type === 'admin' ||
            user.user_type === 'parent' ||
            user.user_type === 'healnow'
          "
        >
          <div class="fz-14 d-flex flex-column flex-md-row">
            <span>Level: {{ clientData.level }} |</span>
            <span> Parent: {{ clientData.parent.name }} |</span>
            <span> Last activity: {{ clientData.updated_at }}</span>
          </div>
        </div>
      </div>
      <div class="d-flex gap-1 flex-md-shrink">
        <div
          v-if="
            user.user_type === 'admin' ||
            user.user_type === 'parent' ||
            user.user_type === 'healnow'
          "
        >
          <button
            class="btn btn-secondary dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
          >
            Actions
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                v-if="
                  user.user_type === 'admin' || user.user_type === 'healnow'
                "
                class="dropdown-item"
                href="#settlementSide"
                data-bs-toggle="offcanvas"
                aria-controls="settlementSide"
                @click="getSettlementData()"
                >Add MCC Code</a
              >
            </li>
            <!-- <li><a class="dropdown-item" v-if="user.user_type === 'admin' || user.user_type === 'healnow'" href="#" @click="returnToOnboarding">Return to Onboarding</a></li> -->
            <!-- <li><a class="dropdown-item" href="#" v-if="user.user_type === 'admin' || user.user_type === 'healnow'" @click="createSandbox">Create Sandbox</a></li> -->
            <!-- <li><a v-if="user.user_type === 'admin' || user.user_type === 'parent' || user.user_type === 'healnow'" class="dropdown-item" href="#certSide" data-bs-toggle="offcanvas" aria-controls="certSide" @click="getCert(clientData.id)">Manage Dev. Certification</a></li> -->
            <li>
              <a
                v-if="
                  user.user_type === 'admin' || user.user_type === 'healnow'
                "
                class="dropdown-item"
                href="#hierarchySide"
                data-bs-toggle="offcanvas"
                aria-controls="hierarchySide"
                @click="getParentOptions()"
                >Edit Hierarchy</a
              >
            </li>
            <!-- <li><a class="dropdown-item" v-if="user.user_type === 'admin' || user.user_type === 'parent' || user.user_type === 'healnow'"  href="#deSide" data-bs-toggle="offcanvas" aria-controls="deSide">Submit a Ticket</a></li> -->
          </ul>
        </div>
        <div>
          <button
            class="btn btn-secondary dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
          >
            Open
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item"
                href="#dataSide"
                data-bs-toggle="offcanvas"
                aria-controls="dataSide"
                >IDs<span
                  v-if="
                    user.user_type === 'admin' || user.user_type === 'healnow'
                  "
                >
                  & Full Data</span
                ></a
              >
            </li>
            <li>
              <a
                v-if="
                  (clientData.level === 'Merchant' &&
                    user.user_type === 'admin') ||
                  user.user_type === 'parent' ||
                  user.user_type === 'healnow'
                "
                class="dropdown-item"
                :href="`/app/account/${clientData.parent.id}`"
                target="_blank"
                >Parent Account</a
              >
            </li>
            <li>
              <a
                v-if="
                  user.user_type === 'admin' || user.user_type === 'healnow'
                "
                class="dropdown-item"
                href="#notesSide"
                data-bs-toggle="offcanvas"
                aria-controls="notesSide"
                >Notes</a
              >
            </li>
            <li>
              <a
                v-if="
                  user.user_type === 'admin' || user.user_type === 'healnow'
                "
                class="dropdown-item"
                href="#docsSide"
                data-bs-toggle="offcanvas"
                aria-controls="docsSide"
                >Documents</a
              >
            </li>
            <li>
              <a
                v-if="
                  user.user_type === 'admin' ||
                  user.user_type === 'parent' ||
                  user.user_type === 'healnow'
                "
                class="dropdown-item"
                href="#userSide"
                data-bs-toggle="offcanvas"
                aria-controls="userSide"
                @click="fetchUserData(clientData.id)"
                >Users & Contacts</a
              >
            </li>
            <li>
              <a
                v-if="user.user_type === 'admin'"
                class="dropdown-item"
                href="#enablementSide"
                data-bs-toggle="offcanvas"
                aria-controls="enablementSide"
              >Enablement Options</a>
<!--  @click="fetchEnablementData(clientData.id)"-->
            </li>
            <li>
              <a
                class="dropdown-item"
                :href="`/terms/${clientData.id}/${clientData.enrollment_id}`"
                target="_blank"
                >Terms and Conditions</a
              >
            </li>
            <li v-if="userHasPaymentPagePermission">
              <a class="dropdown-item" :href="paymentLink" target="_blank"
                >Payment Page</a
              >
            </li>
          </ul>
        </div>
      </div>
      <!-- Enablement Offcanvas -->
      <div
        id="enablementSide"
        class="offcanvas offcanvas-end"
        tabindex="-1"
        aria-labelledby="enablementSide"
        data-bs-backdrop="false"
        data-bs-scroll="true"
      >
        <div class="offcanvas-header">
          <h5 id="userSide" class="offcanvas-title">
            Manage Processor Enablement
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="offcanvas"
          ></button>
        </div>
        <div class="offcanvas-body">
          <div>
            <!-- User Card for Adding New User -->
            <div class="accordion-body mt-3">
              <h6>Enroll Into EPX for Payments</h6>
              <base-button
                class="px-3 w-100"
                type="submit"
                @click="enrollIntoEPXForPayments"
              >
                Enroll Into EPX For Payments
              </base-button>
            </div>
            <div class="accordion-body mt-3">
              <h6>Do this if EPX is repeatedly sending a messed up value</h6>
              <base-button
                class="px-3 w-100"
                type="submit"
                @click="reApplyIntoEPXForPayments"
              >
                Re-Enroll To EPX
              </base-button>
            </div>
            <!-- User Card for Adding New User -->
            <div class="accordion-body mt-3">
              <h6>Enroll Into ProPay (ACH Only)</h6>
              <base-button
                class="px-3 w-100"
                type="submit"
                @click="enrollIntoProPayForACH"
              >
                Enroll Into ProPay ACH Only
              </base-button>
            </div>
            <!-- User Cards -->
            <div v-for="event in events" :key="event" class="accordion-item">
              <div class="accordion-body">
                <!-- User Card Content -->
                <div><strong>Domain:</strong> {{ event.domain }}</div>
                <div><strong>Sub Domain:</strong> {{ event.sub_domain }}</div>
                <div><strong>Status:</strong> {{ event.status }}</div>
                <div><strong>Created At:</strong> {{ event.created_at }}</div>
                <div>
                  <strong>Data:</strong
                  ><vue-json-pretty :data="event.data" class="pretty" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Users/Contacts Offcanvas -->
      <div
        id="userSide"
        class="offcanvas offcanvas-end"
        tabindex="-1"
        aria-labelledby="userSide"
        data-bs-backdrop="false"
        data-bs-scroll="true"
      >
        <div class="offcanvas-header">
          <h5 id="userSide" class="offcanvas-title">Manage Users & Contacts</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="offcanvas"
          ></button>
        </div>
        <div class="offcanvas-body">
          <div>
            <!-- User Cards -->
            <div
              v-for="(user, index) in users"
              :key="index"
              class="accordion-item"
            >
              <div class="accordion-body">
                <!-- User Card Content -->
                <div>
                  <strong>First Name:</strong> {{ user.account.first_name }}
                </div>
                <div>
                  <strong>Last Name:</strong> {{ user.account.last_name }}
                </div>
                <div><strong>Email:</strong> {{ user.email }}</div>
                <div>
                  <strong>Phone:</strong> {{ user.account.phone_number }}
                </div>
                <div><strong>Type:</strong> {{ user.user_type }}</div>
                <div><strong>Label:</strong> {{ user.label }}</div>
              </div>
            </div>
            <!-- User Card for Adding New User -->
            <div class="accordion-body mt-3">
              <h6>Add New User or Contact</h6>
              <form class="position-relative" @submit.prevent="submitNewUser">
                <!-- User Type Dropdown -->
                <div class="form-group mb-2">
                  <label for="user_type">User Type:</label>
                  <select
                    id="user_type"
                    v-model="user_type"
                    class="form-select"
                    required
                  >
                    <option value="user" selected>User</option>
                    <option value="contact">Contact</option>
                  </select>
                </div>
                <!-- Category Dropdown -->
                <div class="form-group mb-2">
                  <label for="label">Category:</label>
                  <select
                    id="label"
                    v-model="uLabel"
                    class="form-select"
                    required
                  >
                    <option value="Manager" selected>Manager</option>
                    <option value="Technology">Technology</option>
                    <option value="Principal">Principal</option>
                    <option value="Marketing">Marketing</option>
                  </select>
                </div>
                <!-- Name Input -->
                <div class="form-group mb-2">
                  <label for="firstName">First Name:</label>
                  <input
                    id="firstName"
                    v-model="firstName"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
                <div class="form-group">
                  <label for="lastName">Last Name:</label>
                  <input
                    id="lastName"
                    v-model="lastName"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
                <!-- Email Input -->
                <div class="form-group">
                  <label for="email"
                    >Email Address (will submit all lowercase):</label
                  >
                  <input
                    id="email"
                    v-model="uEmail"
                    type="email"
                    class="form-control"
                    required
                  />
                </div>
                <!-- Phone Input -->
                <div class="form-group">
                  <label for="uPhone">Phone:</label>
                  <input
                    id="uPhone"
                    v-model="uPhone"
                    type="phone"
                    class="form-control"
                    required
                  />
                </div>
                <!-- Submit Button -->
                <button class="mt-4 w-100 btn btn-primary" type="submit">
                  Add User
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- IDs & Data Offcanvas -->
      <div
        id="dataSide"
        class="offcanvas offcanvas-end"
        tabindex="-1"
        aria-labelledby="dataSide"
        data-bs-backdrop="false"
        data-bs-scroll="true"
      >
        <div class="offcanvas-header">
          <h5 id="dataSide" class="offcanvas-title">
            IDs<span
              v-if="user.user_type === 'admin' || user.user_type === 'healnow'"
            >
              & Full Data</span
            >
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="offcanvas"
          ></button>
        </div>
        <div class="offcanvas-body">
          <div id="dataSide">
            <div class="side-sub">ID</div>
            <div>{{ clientData.id }}</div>
            <div class="side-sub">API Key</div>
            <div>{{ clientData.api_key }}</div>
            <div
              v-if="user.user_type === 'admin' || user.user_type === 'healnow'"
              class="side-sub"
            >
              NMI Login
            </div>
            <div
              v-if="user.user_type === 'admin' || user.user_type === 'healnow'"
              v-html="bluesnapDecode(clientData?.keystore?.b_id)"
            ></div>
          </div>
          <div
            v-if="user.user_type === 'admin' || user.user_type === 'healnow'"
            class="side-sub"
          >
            Full Account Data
          </div>
          <div
            v-if="user.user_type === 'admin' || user.user_type === 'healnow'"
          >
            <div v-for="(value, key) in clientData" :key="key">
              {{ key }}: {{ value }}
              <br />
            </div>
          </div>
        </div>
      </div>
      <!-- Settlement Account Offcanvas -->
      <div
        v-if="user.user_type === 'admin' || user.user_type === 'healnow'"
        id="settlementSide"
        class="offcanvas offcanvas-end"
        tabindex="-1"
        aria-labelledby="settlementSide"
        data-bs-backdrop="false"
        data-bs-scroll="true"
      >
        <div class="offcanvas-header">
          <h5 id="settlementSide" class="offcanvas-title">Setup MCC Code</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="offcanvas"
          ></button>
        </div>
        <div class="offcanvas-body">
          <div id="settlementSide">
            <h5>Add MCC Code</h5>
            <div class="form-group">
              <label>MCC Codes:</label>
              <select v-model="otherSelectedMccCode" class="form-select">
                <option
                  v-for="code in otherMccCodes"
                  :key="code.mcc_code"
                  :value="code.mcc_code"
                >
                  {{ code.label }} ({{ code.mcc_code }})
                </option>
              </select>
            </div>
            <div class="form-group">
              <label>Low Rate:</label>
              <input
                v-model="createdMccConfig.low_rate"
                autocomplete="off"
                type="text"
                class="form-control"
              />
            </div>
            <div class="form-group">
              <label>ETF:</label>
              <input
                v-model="createdMccConfig.etf"
                autocomplete="off"
                type="text"
                class="form-control"
              />
            </div>
            <div class="form-group">
              <label>Regulatory, 1099k:</label>
              <input
                v-model="createdMccConfig.reg_fee"
                autocomplete="off"
                type="text"
                class="form-control"
              />
            </div>
          </div>
          <div class="form-group">
            <label>PCI Non-Compliance:</label>
            <input
              v-model="createdMccConfig.pci_fee"
              autocomplete="off"
              type="text"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label>Monthly:</label>
            <input
              v-model="createdMccConfig.monthly_fee"
              autocomplete="off"
              type="text"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label>Monthly Minimum Transaction Volume:</label>
            <input
              v-model="createdMccConfig.monthly_minimum"
              autocomplete="off"
              type="text"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label>Card Rate:</label>
            <input
              v-model="createdMccConfig.card_rate"
              autocomplete="off"
              type="text"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label>ACH Rate:</label>
            <input
              v-model="createdMccConfig.ach_rate"
              autocomplete="off"
              type="text"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label>Crypto Rate:</label>
            <input
              v-model="createdMccConfig.crypto_rate"
              autocomplete="off"
              type="text"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label>Annual:</label>
            <input
              v-model="createdMccConfig.annual_fee"
              autocomplete="off"
              type="text"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label>Chargeback:</label>
            <input
              v-model="createdMccConfig.chargeback_fee"
              autocomplete="off"
              type="text"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label>ACH Return:</label>
            <input
              v-model="createdMccConfig.ach_return"
              autocomplete="off"
              type="text"
              class="form-control"
            />
          </div>
          <button class="btn btn-sm btn-secondary mt-2" @click="addSubpricing">
            Add Subpricing
          </button>
        </div>
      </div>
    </div>
    <!-- Hierarchy Offcanvas -->
    <div
      id="hierarchySide"
      class="offcanvas offcanvas-end"
      tabindex="-1"
      aria-labelledby="hierarchySide"
      data-bs-backdrop="false"
      data-bs-scroll="true"
    >
      <div class="offcanvas-header">
        <h5 id="hierarchySideTitle" class="offcanvas-title">Edit Hierarchy</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="offcanvas"
        ></button>
      </div>
      <div class="offcanvas-body">
        <form id="hierarchyForm">
          <!-- Parent Selection -->
          <div class="mb-3">
            <label for="parentSelect" class="form-label"
              >Select New Parent</label
            >
            <select v-model="newParentName" class="form-select">
              <option value="" disabled>Select a parent</option>
              <option
                v-for="(option, index) in parentOptions"
                :key="index"
                :value="option"
              >
                {{ option.legal_name }}
              </option>
            </select>
            <div class="form-text">Choose a new parent for this merchant.</div>
          </div>
          <!-- Save Button -->
          <button
            id="saveHierarchy"
            type="button"
            class="btn btn-primary"
            @click="saveNewParent"
          >
            Save
          </button>
        </form>
      </div>
    </div>
    <!-- Submit Ticket Offcanvas -->
    <div
      id="deSide"
      class="offcanvas offcanvas-end"
      tabindex="-1"
      aria-labelledby="deSide"
      data-bs-backdrop="false"
      data-bs-scroll="true"
    >
      <div class="offcanvas-header">
        <h5 id="deSide" class="offcanvas-title">Submit a Ticket</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="offcanvas"
        ></button>
      </div>
      <div class="offcanvas-body">
        <div id="deSide">
          <div class="ticket-container">
            <h6>Submit a Ticket to Deactivate</h6>
            <div>
              If this account is no longer active under a contract with its
              parent account, submit a ticket here to mark this account as
              inactive.
            </div>
            <button
              class="btn btn-sm btn-secondary mt-1"
              @click="sendDeactivateEmail(clientData.id)"
            >
              Request Deactivation
            </button>
          </div>
          <div class="ticket-container">
            <h6>Submit a Ticket to Delete</h6>
            <div>
              Submit a ticket to delete this account. It will be removed from
              all views. We will archive the account data securely.
            </div>
            <button
              class="btn btn-sm btn-secondary mt-1"
              @click="sendDeleteEmail(clientData.id)"
            >
              Request Deletion
            </button>
          </div>
          <div class="ticket-container">
            <h6>See some information that's not correct?</h6>
            <p class="ticket-info">
              Submit a ticket here and we'll get it taken care of. Please
              describe the information that needs to be corrected.
            </p>
            <textarea
              id="ticketMessage"
              v-model="ticketMessage"
              class="ticket-textarea"
              placeholder="Enter your message"
            ></textarea>
            <button
              class="btn btn-sm btn-secondary mt-1"
              @click="sendTicketEmail(clientData.id)"
            >
              Submit Ticket
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Notes Offcanvas -->
    <div
      id="notesSide"
      class="offcanvas offcanvas-end"
      tabindex="-1"
      aria-labelledby="notesSide"
      data-bs-backdrop="false"
      data-bs-scroll="true"
    >
      <div class="offcanvas-header">
        <h5 class="offcanvas-title">Notes</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="offcanvas"
        ></button>
      </div>
      <div class="offcanvas-body">
        <view-client-notes
          :clientid="p.clientid"
          :client-details="clientData"
          :initial-tags="notesInitialTags"
        />
      </div>
    </div>
    <!-- Docs Offcanvas -->
    <div
      id="docsSide"
      class="offcanvas offcanvas-end"
      tabindex="-1"
      aria-labelledby="docsSide"
      data-bs-backdrop="false"
      data-bs-scroll="true"
    >
      <div class="offcanvas-header">
        <h5 id="docsSide" class="offcanvas-title">Documents</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="offcanvas"
        ></button>
      </div>
      <div class="offcanvas-body">
        <div id="docsSide">
          <view-client-documents :client-view="true" :clientid="p.clientid" />
          <!-- <a :href="'/file/' + p.clientid" target="_blank" class="btn btn-outline-primary btn-sm mt-2">Upload File</a> -->
        </div>
      </div>
    </div>
    <!-- Dev Cert -->
    <div
      id="certSide"
      class="offcanvas offcanvas-end"
      tabindex="-1"
      aria-labelledby="certSide"
      data-bs-backdrop="false"
      data-bs-scroll="true"
    >
      <div class="offcanvas-header">
        <h5 id="certSide" class="offcanvas-title">Certification</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="offcanvas"
        ></button>
      </div>
      <div class="offcanvas-body">
        <div class="side-container">
          <h6>Lead Developer Enrollment</h6>
          <div class="small">
            Enroll the lead developer in the development certification here.
            Enrollment will create the sandbox account, add the developer as a
            user, and email them with steps to get started.
          </div>
          <div class="form-group">
            <label for="userName">Developer Name:</label>
            <input
              id="devName"
              v-model="devName"
              type="text"
              class="form-control"
            />
          </div>
          <div class="form-group mb-2">
            <label for="email">Developer Email:</label>
            <input
              id="devEmail"
              v-model="devEmail"
              type="email"
              class="form-control"
            />
          </div>
          <button
            class="btn btn-primary mt-2"
            @click="enrollDev(clientData.id)"
          >
            Enroll Developer
          </button>
        </div>
        <hr />
        <h5 class="mt-2">Completed Tests</h5>
        <table class="table mt-2">
          <thead>
            <tr>
              <th>Endpoint</th>
              <th>Date Completed</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(endpoint, index) in endpoints" :key="index">
              <td>{{ endpoint.requiredEndpoint }}</td>
              <td>{{ endpoint.testResult }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="container mt-3 mb-5">
    <hr />
    <div id="merchantDetails" class="row">
      <h5>Merchant Information</h5>
      <div
        v-if="user.user_type === 'admin'"
        class="form-group col-md-4 col-sm-6"
      >
        <label for="level">Level:</label>
        <select
          id="level"
          v-model="clientData.level"
          class="form-select"
          required
        >
          <option value="Merchant">Merchant</option>
          <option value="Parent">Parent</option>
        </select>
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="legalName">Billing Legal Name:</label>
        <input
          id="legalName"
          v-model="clientData.billing.legal_name"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="legal_name">Merchant Legal Name:</label>
        <input
          id="legal_name"
          v-model="clientData.legal_name"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="dba_name">DBA Name:</label>
        <input
          id="dba_name"
          v-model="clientData.dba_name"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="name">Name:</label>
        <input
          id="name"
          v-model="clientData.name"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="ownershipType">Business Structure:</label>
        <select
          id="ownershipType"
          v-model="clientData.billing.ownership_type"
          class="form-select"
          :disabled="!isEditing"
        >
          <option value="Sole Proprietor">Sole Proprietor</option>
          <option value="LLC Private">LLC Private</option>
          <option value="LLC Public">LLC Public</option>
          <option value="S-Corp Public">S-Corp Public</option>
          <option value="S-Corp Private">S-Corp Private</option>
          <option value="C-Corp Public">C-Corp Public</option>
          <option value="C-Corp Private">C-Corp Private</option>
          <option value="Not For Profit">Not For Profit</option>
          <option value="Partnership Private">Partnership Private</option>
          <option value="Partnership">Partnership</option>
          <option value="Government">Government</option>
        </select>
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="merchantDetails">Business Type:</label>
        <input
          id="merchantDetails"
          v-model="clientData.billing.business_type"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="tax_id">Tax Identifier:</label>
        <input
          id="tax_id"
          v-model="clientData.tax_id"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="start_date">Start Date:</label>
        <input
          id="start_date"
          v-model="clientData.start_date"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
    </div>
    <div id="address" class="section row">
      <h5>Merchant Address</h5>
      <div class="form-group col-md-4 col-sm-6">
        <label for="businessA">Address:</label>
        <input
          id="businessA"
          v-model="clientData.address_line_1"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="businessA2">Address 2:</label>
        <input
          id="businessA2"
          v-model="clientData.address_line_2"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="businessC">City:</label>
        <input
          id="businessC"
          v-model="clientData.locality"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="businessS">State:</label>
        <input
          id="businessS"
          v-model="clientData.region"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="businessZ">Zipcode:</label>
        <input
          id="businessZ"
          v-model="clientData.postal_code"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="businessCo">Country:</label>
        <input
          id="businessCo"
          v-model="clientData.country"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
    </div>
    <div id="contact" class="section row">
      <h5>Merchant Contact</h5>
      <div class="form-group col-md-4 col-sm-6">
        <label for="email">Email:</label>
        <input
          id="email"
          v-model="clientData.email"
          autocomplete="off"
          type="email"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="ssn">SSN:</label>
        <input
          id="ssn"
          v-model="clientData.ssn"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="ph">Phone:</label>
        <input
          id="ph"
          v-model="clientData.phone"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="website">Website:</label>
        <input
          id="website"
          v-model="clientData.website"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
    </div>
    <div id="businessDetails" class="row">
      <h5>Business Information</h5>
      <div class="form-group col-md-4 col-sm-6">
        <label for="business_region"
          >Business State (two letters, uppercase):</label
        >
        <input
          id="business_region"
          v-model="clientData.business.business_region"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="business_locality">Business City:</label>
        <input
          id="business_locality"
          v-model="clientData.business.business_locality"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="business_address_1">Business Address Line 1:</label>
        <input
          id="business_address_1"
          v-model="clientData.business.business_address_1"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="business_address_2">Business Address Line 2:</label>
        <input
          id="business_address_2"
          v-model="clientData.business.business_address_2"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="business_postal_code">Business Postal Code:</label>
        <input
          id="business_postal_code"
          v-model="clientData.business.business_postal_code"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="business_phone_number">Business Phone Number:</label>
        <input
          id="business_phone_number"
          v-model="clientData.business.business_phone_number"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="business_description">Business Description:</label>
        <input
          id="business_description"
          v-model="clientData.business.business_description"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
    </div>
    <div id="principalPositionOne" class="row">
      <h5>Primary Principal Information</h5>
      <div class="form-group col-md-4 col-sm-6">
        <label for="principal_first_name">Principal First Name:</label>
        <input
          id="principal_first_name"
          v-model="clientData.principal_first_name"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="principal_last_name">Principal Last Name:</label>
        <input
          id="principal_last_name"
          v-model="clientData.principal_last_name"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="principal_ssn">Principal SSN:</label>
        <input
          id="principal_ssn"
          v-model="clientData.principal_ssn"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="principal_date_of_birth">Principal DOB (YYYY-MM-DD):</label>
        <input
          id="principal_date_of_birth"
          v-model="clientData.principal_date_of_birth"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="principal_title">Principal Title:</label>
        <input
          id="principal_title"
          v-model="clientData.principal_title"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="principal_state_province"
          >Principal State (two letters, uppercase):</label
        >
        <input
          id="principal_state_province"
          v-model="clientData.principal_state_province"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="principal_city">Principal City:</label>
        <input
          id="principal_city"
          v-model="clientData.principal_city"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="principal_address_line_1">Principal Address Line 1:</label>
        <input
          id="principal_address_line_1"
          v-model="clientData.principal_address_line_1"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="principal_address_line_2">Principal Address Line 2:</label>
        <input
          id="principal_address_line_2"
          v-model="clientData.principal_address_line_2"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="principal_postal_code">Principal Postal Code:</label>
        <input
          id="principal_postal_code"
          v-model="clientData.principal_postal_code"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="principal_phone_number">Principal Phone Number:</label>
        <input
          id="principal_phone_number"
          v-model="clientData.principal_phone_number"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <!-- <div class="form-group col-md-4 col-sm-6">
          <label for="principal_ownership_percentage">Principal Percentage of Ownership:</label>
          <input
            autocomplete="off"
            type="text"
            id="principal_ownership_percentage"
            class="form-control"
            v-model="clientData.principal_ownership_percentage"
            :disabled="!isEditing"
          />
        </div> -->
      <div class="form-group col-md-4 col-sm-6">
        <label for="principal_ownership_type"
          >Principal Type of Ownership:</label
        >
        <input
          id="principal_ownership_type"
          v-model="clientData.principal_ownership_type"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
    </div>
    <div id="principalPositionTwo" class="row">
      <h5>Two Principal Information</h5>
      <div class="form-group col-md-4 col-sm-6">
        <label for="twoprincipal_first_name">Principal First Name:</label>
        <input
          id="twoprincipal_first_name"
          v-model="clientData.twoprincipal_first_name"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="twoprincipal_last_name">Principal Last Name:</label>
        <input
          id="twoprincipal_last_name"
          v-model="clientData.twoprincipal_last_name"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="twoprincipal_ssn">Principal SSN:</label>
        <input
          id="twoprincipal_ssn"
          v-model="clientData.twoprincipal_ssn"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="twoprincipal_date_of_birth"
          >Principal DOB (YYYY-MM-DD):</label
        >
        <input
          id="twoprincipal_date_of_birth"
          v-model="clientData.twoprincipal_date_of_birth"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="twoprincipal_title">Principal Title:</label>
        <input
          id="twoprincipal_title"
          v-model="clientData.twoprincipal_title"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="twoprincipal_state_province"
          >Principal State (two letters, uppercase):</label
        >
        <input
          id="twoprincipal_state_province"
          v-model="clientData.twoprincipal_state_province"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="twoprincipal_city">Principal City:</label>
        <input
          id="twoprincipal_city"
          v-model="clientData.twoprincipal_city"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="twoprincipal_address_line_1"
          >Principal Address Line 1:</label
        >
        <input
          id="twoprincipal_address_line_1"
          v-model="clientData.twoprincipal_address_line_1"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="twoprincipal_address_line_2"
          >Principal Address Line 2:</label
        >
        <input
          id="twoprincipal_address_line_2"
          v-model="clientData.twoprincipal_address_line_2"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="twoprincipal_postal_code">Principal Postal Code:</label>
        <input
          id="twoprincipal_postal_code"
          v-model="clientData.twoprincipal_postal_code"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="twoprincipal_phone_number">Principal Phone Number:</label>
        <input
          id="twoprincipal_phone_number"
          v-model="clientData.twoprincipal_phone_number"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <!-- <div class="form-group col-md-4 col-sm-6">
          <label for="twoprincipal_ownership_percentage">Principal Percentage of Ownership:</label>
          <input
            autocomplete="off"
            type="text"
            id="twoprincipal_ownership_percentage"
            class="form-control"
            v-model="clientData.twoprincipal_ownership_percentage"
            :disabled="!isEditing"
          />
        </div> -->
      <div class="form-group col-md-4 col-sm-6">
        <label for="twoprincipal_ownership_type"
          >Principal Type of Ownership:</label
        >
        <input
          id="twoprincipal_ownership_type"
          v-model="clientData.twoprincipal_ownership_type"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
    </div>
    <div id="principalPositionThree" class="row">
      <h5>Three Principal Information</h5>
      <div class="form-group col-md-4 col-sm-6">
        <label for="threeprincipal_first_name">Principal First Name:</label>
        <input
          id="threeprincipal_first_name"
          v-model="clientData.threeprincipal_first_name"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="threeprincipal_last_name">Principal Last Name:</label>
        <input
          id="threeprincipal_last_name"
          v-model="clientData.threeprincipal_last_name"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="threeprincipal_ssn">Principal SSN:</label>
        <input
          id="threeprincipal_ssn"
          v-model="clientData.threeprincipal_ssn"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="threeprincipal_date_of_birth"
          >Principal DOB (YYYY-MM-DD):</label
        >
        <input
          id="threeprincipal_date_of_birth"
          v-model="clientData.threeprincipal_date_of_birth"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="threeprincipal_title">Principal Title:</label>
        <input
          id="threeprincipal_title"
          v-model="clientData.threeprincipal_title"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="threeprincipal_state_province"
          >Principal State (two letters, uppercase):</label
        >
        <input
          id="threeprincipal_state_province"
          v-model="clientData.threeprincipal_state_province"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="threeprincipal_city">Principal City:</label>
        <input
          id="threeprincipal_city"
          v-model="clientData.threeprincipal_city"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="threeprincipal_address_line_1"
          >Principal Address Line 1:</label
        >
        <input
          id="threeprincipal_address_line_1"
          v-model="clientData.threeprincipal_address_line_1"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="threeprincipal_address_line_2"
          >Principal Address Line 2:</label
        >
        <input
          id="threeprincipal_address_line_2"
          v-model="clientData.threeprincipal_address_line_2"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="threeprincipal_postal_code">Principal Postal Code:</label>
        <input
          id="threeprincipal_postal_code"
          v-model="clientData.threeprincipal_postal_code"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="threeprincipal_phone_number">Principal Phone Number:</label>
        <input
          id="threeprincipal_phone_number"
          v-model="clientData.threeprincipal_phone_number"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <!-- <div class="form-group col-md-4 col-sm-6">
          <label for="threeprincipal_ownership_percentage">Principal Percentage of Ownership:</label>
          <input
            autocomplete="off"
            type="text"
            id="threeprincipal_ownership_percentage"
            class="form-control"
            v-model="clientData.threeprincipal_ownership_percentage"
            :disabled="!isEditing"
          />
        </div> -->
      <div class="form-group col-md-4 col-sm-6">
        <label for="threeprincipal_ownership_type"
          >Principal Type of Ownership:</label
        >
        <input
          id="threeprincipal_ownership_type"
          v-model="clientData.threeprincipal_ownership_type"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
    </div>
    <div id="principalPositionFour" class="row">
      <h5>Four Principal Information</h5>
      <div class="form-group col-md-4 col-sm-6">
        <label for="fourprincipal_first_name">Principal First Name:</label>
        <input
          id="fourprincipal_first_name"
          v-model="clientData.fourprincipal_first_name"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="fourprincipal_last_name">Principal Last Name:</label>
        <input
          id="fourprincipal_last_name"
          v-model="clientData.fourprincipal_last_name"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="fourprincipal_ssn">Principal SSN:</label>
        <input
          id="fourprincipal_ssn"
          v-model="clientData.fourprincipal_ssn"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="fourprincipal_date_of_birth"
          >Principal DOB (YYYY-MM-DD):</label
        >
        <input
          id="fourprincipal_date_of_birth"
          v-model="clientData.fourprincipal_date_of_birth"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="fourprincipal_title">Principal Title:</label>
        <input
          id="fourprincipal_title"
          v-model="clientData.fourprincipal_title"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="fourprincipal_state_province"
          >Principal State (two letters, uppercase):</label
        >
        <input
          id="fourprincipal_state_province"
          v-model="clientData.fourprincipal_state_province"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="fourprincipal_city">Principal City:</label>
        <input
          id="fourprincipal_city"
          v-model="clientData.fourprincipal_city"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="fourprincipal_address_line_1"
          >Principal Address Line 1:</label
        >
        <input
          id="fourprincipal_address_line_1"
          v-model="clientData.fourprincipal_address_line_1"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="fourprincipal_address_line_2"
          >Principal Address Line 2:</label
        >
        <input
          id="fourprincipal_address_line_2"
          v-model="clientData.fourprincipal_address_line_2"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="fourprincipal_postal_code">Principal Postal Code:</label>
        <input
          id="fourprincipal_postal_code"
          v-model="clientData.fourprincipal_postal_code"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="fourprincipal_phone_number">Principal Phone Number:</label>
        <input
          id="fourprincipal_phone_number"
          v-model="clientData.fourprincipal_phone_number"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <!-- <div class="form-group col-md-4 col-sm-6">
          <label for="fourprincipal_ownership_percentage">Principal Percentage of Ownership:</label>
          <input
            autocomplete="off"
            type="text"
            id="fourprincipal_ownership_percentage"
            class="form-control"
            v-model="clientData.fourprincipal_ownership_percentage"
            :disabled="!isEditing"
          />
        </div> -->
      <div class="form-group col-md-4 col-sm-6">
        <label for="4principal_ownership_type"
          >Principal Type of Ownership:</label
        >
        <input
          id="fourprincipal_ownership_type"
          v-model="clientData.fourprincipal_ownership_type"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
    </div>
    <hr
      v-if="
        user.user_type === 'admin' ||
        user.user_type === 'parent' ||
        user.user_type === 'healnow'
      "
    />
    <div
      v-if="
        user.user_type === 'admin' ||
        user.user_type === 'parent' ||
        user.user_type === 'healnow'
      "
      id="processing"
      class="section row"
    >
      <h3 class="mb-0">Processing</h3>
      <h5 id="cards" class="mb-0 mt-2">Cards</h5>
      <!-- Add text that says "Not Processing" if the partner is not processing payments as a merchant. -->
      <p v-if="clientData.config.payment === 'merchante'">
        Merchant E IDs should be manually entered when provided upon approval by
        Merchant E.
      </p>
      <p v-if="clientData.config.payment === 'bluesnap'">
        Bluesnap MID will autopopulate upon approval by Bluesnap.
      </p>
      <p v-if="clientData.config.payment === 'epx'">
        EPX MID will autopopulate upon approval by EPX.
      </p>
      <p v-if="clientData.config.payment === 'propay'">
        Propay (Heartland) Data Will Populate Once Approved by Propay
        (Heartland)
      </p>
      <div class="form-group col-md-4 col-sm-6">
        <label for="processorSelect">Card Processor:</label>
        <select
          id="processorSelect"
          v-model="clientData.config.payment"
          class="form-select"
          :disabled="!isEditing"
        >
          <option value="fis">FIS</option>
          <option value="merchante">Merchant E</option>
          <option value="bluesnap">Bluesnap</option>
          <option value="epx">EPX</option>
          <option value="propay">ProPay (Heartland)</option>
        </select>
      </div>

      <div
        v-if="
          clientData.config.payment !== 'merchante' &&
          clientData.config.payment !== 'bluesnap' &&
          clientData.config.payment !== 'epx' &&
          clientData.config.payment !== 'propay'
        "
        class="form-group col-md-4 col-sm-6"
      >
        <label for="processorMid"
          >{{ clientData.config.payment }} Merchant ID:</label
        >
        <input
          id="processorMid"
          v-model="clientData.mid"
          autocomplete="off"
          type="number"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>

      <div
        v-if="clientData.config.payment === 'propay'"
        class="form-group col-md-4 col-sm-6"
      >
        <label for="propay_mid"
          >ProPay/Heartland Account Number (Credentials may not be live):</label
        >
        <input
          id="propay_mid"
          v-model="clientData.keystore.propay_mid"
          autocomplete="off"
          type="number"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>

      <div
        v-if="clientData.config.payment === 'propay'"
        class="form-group col-md-4 col-sm-6"
      >
        <label for="propay_merchant_identifier"
          >Heartland Public API Key (Live Credentials)</label
        >
        <input
          id="propay_merchant_identifier"
          v-model="clientData.keystore.heartland_public_key"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>

      <div
        v-if="clientData.config.payment === 'epx'"
        class="form-group col-md-4 col-sm-6"
      >
        <label for="epx_mid"
          >{{ clientData.config.payment }} EPX Merchant ID:</label
        >
        <input
          id="epx_mid"
          v-model="clientData.keystore.epx_external_key"
          autocomplete="off"
          type="number"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>

      <div
        v-if="clientData.config.payment === 'epx'"
        class="form-group col-md-4 col-sm-6"
      >
        <label for="epx_merchant_identifier"
          >{{ clientData.config.payment }} EPX Merchant Identifier:</label
        >
        <input
          id="epx_merchant_identifier"
          v-model="clientData.keystore.epx_merchant_identifier"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>

      <div
        v-if="clientData.config.payment === 'merchante'"
        class="form-group col-md-4 col-sm-6"
      >
        <label for="mmid">{{ clientData.config.payment }} Merchant ID:</label>
        <input
          id="mmid"
          v-model="clientData.keystore.m_mid"
          autocomplete="off"
          type="number"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>

      <div
        v-if="clientData.config.payment === 'merchante'"
        class="form-group col-md-4 col-sm-6"
      >
        <label for="mpid">{{ clientData.config.payment }} Profile ID:</label>
        <input
          id="mpid"
          v-model="clientData.keystore.m_pid"
          autocomplete="off"
          type="number"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>

      <div
        v-if="clientData.config.payment === 'bluesnap'"
        class="form-group col-md-4 col-sm-6"
      >
        <label for="bmid">{{ clientData.config.payment }} Merchant ID:</label>
        <input
          id="bmid"
          v-model="clientData.keystore.b_mid"
          autocomplete="off"
          type="number"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>

      <div
        v-if="clientData.config.payment === 'bluesnap'"
        class="form-group col-md-4 col-sm-6"
      ></div>

      <div class="form-group col-md-4 col-sm-6">
        <label data-toggle="tooltip" title="Tooltip text here"
          >Card Rate:</label
        >
        <input
          v-model="clientData.pricing.card_rate"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label>Card Pass-to-Consumer:</label>
        <select
          v-model="clientData.pricing.card_p2c"
          class="form-select"
          :disabled="!isEditing"
        >
          <option value="False" selected>Disabled</option>
          <option value="false" selected>Disabled</option>
          <option value="true">Enabled</option>
          <option value="True">Enabled</option>
        </select>
      </div>
      <h5 id="ach" class="mb-0 mt-4">ACH</h5>
      <div class="form-group col-md-4 col-sm-6">
        <label>ACH:</label>
        <select
          v-model="clientData.config.ach"
          class="form-select"
          :disabled="!isEditing"
        >
          <option value="false" selected>Disabled</option>
          <option value="xpress">Xpress</option>
        </select>
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label>ACH Rate:</label>
        <input
          v-model="clientData.pricing.ach_rate"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label>ACH Pass-to-Consumer:</label>
        <select
          v-model="clientData.pricing.ach_p2c"
          class="form-select"
          :disabled="!isEditing"
        >
          <option value="false" selected>Disabled</option>
          <option value="true">Enabled</option>
        </select>
      </div>
      <h5 id="crypto" class="mb-0 mt-4">Crypto</h5>
      <div class="form-group col-md-4 col-sm-6">
        <label>Crypto:</label>
        <select
          v-model="clientData.config.crypto"
          class="form-select"
          :disabled="!isEditing"
        >
          <option value="false" selected>Disabled</option>
          <option value="curpay">Curpay</option>
        </select>
      </div>
      <div
        v-if="clientData.config.crypto === 'curpay'"
        class="form-group col-md-4 col-sm-6"
      >
        <label for="cryptoId">Curpay ID:</label>
        <input
          id="cryptoId"
          v-model="clientData.keystore.curpay_id"
          autocomplete="off"
          type="number"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label>Crypto Rate:</label>
        <input
          v-model="clientData.pricing.crypto_rate"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label>Crypto Pass-to-Consumer:</label>
        <select
          v-model="clientData.pricing.crypto_p2c"
          class="form-select"
          :disabled="!isEditing"
        >
          <option value="false" selected>Disabled</option>
          <option value="true">Enabled</option>
        </select>
      </div>
      <!-- <h5 class="mb-0 mt-4" id="terminals">Terminals</h5>
            <div class="form-group col-md-4 col-sm-6">
            <label>Terminals:</label>
            <select class="form-select" v-model="clientData.pricing.terminals" :disabled="!isEditing">
                <option value="false" selected>Disabled</option>
                <option value="true">Enabled</option>
            </select>
            </div> -->
      <h5 id="addtnl" class="mb-0 mt-4">
        Additional Processing Settings & Fees
      </h5>
      <div class="form-group col-md-4 col-sm-6">
        <label>Check EMAF:</label>
        <select
          v-model="clientData.config.check_emaf"
          class="form-select"
          :disabled="!isEditing"
        >
          <option value="false" selected>Don't check EMAF</option>
          <option value="true">Check EMAF for transactions</option>
        </select>
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label>Settlement Activity:</label>
        <select
          v-model="clientData.config.pause_settlement"
          class="form-select"
          :disabled="!isEditing || clientData.config.payment === 'bluesnap'"
        >
          <option value="false" selected>Actively Settling</option>
          <option value="true">Pause Account's Settlements</option>
        </select>
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label>Settlement Method:</label>
        <select
          v-model="clientData.config.payout"
          class="form-select"
          :disabled="!isEditing"
        >
          <option value="split_settlement">Split Settlement</option>
        </select>
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label>MCC Code:</label>
        <!-- <small class="small ms-2"><a :href="'/reports/mcc/'+clientData.parent.id" target="_blank">Open Approved Codes</a></small> -->
        <input
          v-model="clientData.billing.mcc_code"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <!-- <div class="form-group col-md-4 col-sm-6">
                <label>Balance Due Before Payout:</label>
                <input autocomplete="off" type="text" class="form-control" v-model="clientData.pricing.client_balance" :disabled="!isEditing">
            </div> -->
      <div class="form-group col-md-4 col-sm-6">
        <label>Revenue Share:</label>
        <input
          v-model="clientData.child.rev_share"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <h5 id="addtnl" class="mb-0 mt-4">
        Risk Settings
      </h5>
      <div class="form-group col-md-4 col-sm-6">
        <label>Average Ticket:</label>
        <input
          v-model="clientData.child.avg_ticket"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label>High Ticket:</label>
        <input
          v-model="clientData.child.high_ticket"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label>Average Monthly Volume:</label>
        <input
          v-model="clientData.child.monthly_volume"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label>High Monthly Volume:</label>
        <input
          v-model="clientData.child.monthly_high_volume"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <h5 class="mb-0 mt-4">Schedule A Fees</h5>
      <div class="form-group col-md-4 col-sm-6">
        <label>Low Rate:</label>
        <input
          v-model="clientData.pricing.low_rate"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label>ETF:</label>
        <input
          v-model="clientData.pricing.etf"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label>Regulatory, 1099k:</label>
        <input
          v-model="clientData.pricing.reg_fee"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label>PCI Non-Compliance:</label>
        <input
          v-model="clientData.pricing.pci_fee"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label>Monthly:</label>
        <input
          v-model="clientData.pricing.monthly_fee"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label>Monthly Minimum Transaction Volume:</label>
        <input
          v-model="clientData.pricing.monthly_min"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label>Annual:</label>
        <input
          v-model="clientData.pricing.annual_fee"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label>Chargeback:</label>
        <input
          v-model="clientData.pricing.chargeback_fee"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label>Retrieval:</label>
        <input
          v-model="clientData.pricing.retrieval_fee"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <!-- <div class="form-group col-md-4 col-sm-6">
                <label>ACH Notification of Change:</label>
                <input autocomplete="off" v-model="clientData.pricing.ach_noc" type="text" class="form-control" :disabled="!isEditing">
            </div> -->
      <div class="form-group col-md-4 col-sm-6">
        <label>ACH Return:</label>
        <input
          v-model="clientData.pricing.ach_return"
          autocomplete="off"
          type="text"
          class="form-control"
          :disabled="!isEditing"
        />
      </div>
      <!-- <div class="form-group col-md-4 col-sm-6">
                <label>TIN Mismatch:</label>
                <input autocomplete="off" v-model="clientData.pricing.tin_mismatch" type="text" class="form-control" :disabled="!isEditing">
            </div> -->
    </div>
    <hr v-if="user.user_type === 'admin' || user.user_type === 'healnow'" />
    <div
      v-if="user.user_type === 'admin'"
      id="settlementAccount"
      class="section row"
    >
      <h5>Settlement/Payout Account</h5>
      <div class="form-group col-md-4 col-sm-6">
        <label for="accountNumber">Account Number:</label>
        <input
          autocomplete="off"
          type="text"
          class="form-control"
          id="accountNumber"
          v-model="clientData.bank.account_number"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="routingNumber">Routing Number:</label>
        <input
          autocomplete="off"
          type="text"
          class="form-control"
          id="routingNumber"
          v-model="clientData.bank.routing_number"
          :disabled="!isEditing"
        />
      </div>
      <div class="form-group col-md-4 col-sm-6">
        <label for="accountType">Account Type:</label>
        <select
          class="form-select"
          id="accountType"
          v-model="clientData.bank.account_type"
          :disabled="!isEditing"
        >
          <option value="checking">Checking</option>
          <option value="savings">Savings</option>
        </select>
      </div>
    </div>
    <hr v-if="user.user_type === 'admin' || user.user_type === 'healnow'" />
    <div
      v-if="user.user_type === 'admin' || user.user_type === 'healnow'"
      id="subSettings"
      class="section"
    >
      <div v-if="clientData.level === 'Parent'" class="section">
        <h3 class="mb-0">Submerchant Settings</h3>
        <p class="mt-0">
          Submerchant (child) accounts will inherit the below settings, rates,
          and fees.
        </p>
        <h5 id="enroll">Enrollment</h5>
        <div class="enroll-link">
          <label class="bold me-1">Link to enroll submerchants:</label>
          <span class="me-2" style="font-size: 14px">{{ agentLink }}</span>
          <button
            class="btn btn-sm btn-secondary copy-button"
            @click="copyToClipboard(agentLink)"
          >
            Copy
          </button>
        </div>
        <div class="form-group col-md-4 col-sm-6">
          <label>Auto-Onboard:</label>
          <select
            v-model="clientData.config.onboard"
            autocomplete="off"
            type="text"
            class="form-control"
            :disabled="!isEditing"
          >
            <option value="false">Disabled</option>
            <option value="true">Enabled</option>
          </select>
        </div>
        <h5 id="currentProcessing">Current Entity Pricing</h5>
        <table class="table table-striped table-responsive">
          <thead>
            <tr>
              <th>Card Rate</th>
              <th>ACH Rate</th>
              <th>Crypto Rate</th>
              <th>Card P2C</th>
              <th>ACH P2C</th>
              <th>Crypto P2C</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ clientData?.pricing?.card_rate }}</td>
              <td>{{ clientData?.pricing?.ach_rate }}</td>
              <td>{{ clientData?.pricing?.crypto_rate }}</td>
              <td>{{ clientData?.pricing?.card_p2c }}</td>
              <td>{{ clientData?.pricing?.ach_p2c }}</td>
              <td>{{ clientData?.pricing?.crypto_p2c }}</td>
            </tr>
          </tbody>
        </table>
        <h5 id="subProcessing">Subpricing Setup</h5>
        <table class="table table-striped table-sm table-responsive">
          <thead>
            <tr>
              <th>mcc_code</th>
              <th>card_p2c</th>
              <th>card_rate</th>
              <th>ach_p2c</th>
              <th>ach_rate</th>
              <th>low_rate</th>
              <th>etf</th>
              <th>reg_fee</th>
              <th>pci_fee</th>
              <th>tax_rate</th>
              <th>monthly_fee</th>
              <th>ach_return</th>
              <th>annual_fee</th>
              <th>monthly_minimum</th>
              <th>chargeback_fee</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(config, rowIndex) in clientData?.mccConfigs"
              :key="rowIndex"
            >
              <td>{{ config?.mcc_code }}</td>
              <td>{{ config?.card_p2c }}</td>
              <td>{{ config?.card_rate }}</td>
              <td>{{ config?.ach_p2c }}</td>
              <td>{{ config?.ach_rate }}</td>
              <td>{{ config?.low_rate }}</td>
              <td>{{ config?.etf }}</td>
              <td>{{ config?.reg_fee }}</td>
              <td>{{ config?.pci_fee }}</td>
              <td>{{ config?.tax_rate }}</td>
              <td>{{ config?.monthly_fee }}</td>
              <td>{{ config?.ach_return }}</td>
              <td>{{ config?.annual_fee }}</td>
              <td>{{ config?.monthly_minimum }}</td>
              <td>{{ config?.chargeback_fee }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="clientData.level === 'Parent'" class="section row">
        <h5 id="cards" class="mb-0 mt-2">Default Processors</h5>
        <div class="form-group col-md-4 col-sm-6">
          <label for="processorSelect">Card Processor:</label>
          <select
            id="processorSelect"
            v-model="clientData.child.payment"
            class="form-select"
            :disabled="!isEditing"
          >
            <option value="fis">FIS</option>
            <option value="merchante">Merchant E</option>
            <option value="bluesnap">Bluesnap</option>
            <option value="epx">EPX</option>
          </select>
        </div>
        <div class="form-group col-md-4 col-sm-6">
          <label>ACH:</label>
          <select
            v-model="clientData.config.ach"
            class="form-select"
            :disabled="!isEditing"
          >
            <option value="false" selected>Disabled</option>
            <option value="xpress">Xpress</option>
          </select>
        </div>
        <div class="form-group col-md-4 col-sm-6">
          <label>Crypto:</label>
          <select
            v-model="clientData.config.crypto"
            class="form-select"
            :disabled="!isEditing"
          >
            <option value="false" selected>Disabled</option>
            <option value="curpay">Curpay</option>
          </select>
        </div>
        <h5 class="mb-0 mt-4">Terminals</h5>
        <div class="form-group col-md-4 col-sm-6">
          <label>Terminals:</label>
          <select
            v-model="clientData.config.terminals"
            class="form-select"
            :disabled="!isEditing"
          >
            <option value="dejavoo">Dejavoo</option>
            <option value="pax">Pax</option>
            <option value="magtek">Magtek</option>
          </select>
        </div>
        <h5 class="mb-0 mt-4">Additional Processing Settings & Fees</h5>
        <div class="form-group col-md-4 col-sm-6">
          <label>Rev Share % (Decimal Format):</label>
          <input
              v-model="clientData.config.rev_share_percentage"
              autocomplete="off"
              type="text"
              class="form-control"
              :disabled="!isEditing"
          />
        </div>
        <div class="form-group col-md-4 col-sm-6">
          <label>Revenue Share Formula:</label>
          <input
            v-model="clientData.config.rev_share_formula"
            autocomplete="off"
            type="text"
            class="form-control"
            :disabled="!isEditing"
          />
        </div>
        <div id="mccConfig">
          <h5 class="mb-0 mt-4">MCC Configuration</h5>
          <div class="form-group col-md-4 col-sm-6">
            <label>MCC Codes:</label>
            <select
              v-model="selectedMccCode"
              class="form-select"
              @change="onMccCodeChange"
            >
              <option
                v-for="code in mccCodes"
                :key="code.mcc_code"
                :value="code.mcc_code"
              >
                {{ code.label }} ({{ code.mcc_code }})
              </option>
            </select>
          </div>
          <div v-if="selectedMccConfig">
            <h5 class="mb-0 mt-4">Schedule A Fees for Selected MCC</h5>
            <div class="row">
              <!-- Wrap inputs in a row -->
              <div class="form-group col-md-4 col-sm-6">
                <label>Low Rate:</label>
                <input
                  v-model="selectedMccConfig.low_rate"
                  autocomplete="off"
                  type="text"
                  class="form-control"
                />
              </div>
              <div class="form-group col-md-4 col-sm-6">
                <label>ETF:</label>
                <input
                  v-model="selectedMccConfig.etf"
                  autocomplete="off"
                  type="text"
                  class="form-control"
                />
              </div>
              <div class="form-group col-md-4 col-sm-6">
                <label>Regulatory, 1099k:</label>
                <input
                  v-model="selectedMccConfig.reg_fee"
                  autocomplete="off"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
            <div class="row">
              <!-- Another row for the next set of inputs -->
              <div class="form-group col-md-4 col-sm-6">
                <label>PCI Non-Compliance:</label>
                <input
                  v-model="selectedMccConfig.pci_fee"
                  autocomplete="off"
                  type="text"
                  class="form-control"
                />
              </div>
              <div class="form-group col-md-4 col-sm-6">
                <label>Monthly:</label>
                <input
                  v-model="selectedMccConfig.monthly_fee"
                  autocomplete="off"
                  type="text"
                  class="form-control"
                />
              </div>
              <div class="form-group col-md-4 col-sm-6">
                <label>Monthly Minimum Transaction Volume:</label>
                <input
                  v-model="selectedMccConfig.monthly_minimum"
                  autocomplete="off"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
            <!-- Continue wrapping each set of three inputs in a row -->
            <div class="row">
              <div class="form-group col-md-4 col-sm-6">
                <label>Annual:</label>
                <input
                  v-model="selectedMccConfig.annual_fee"
                  autocomplete="off"
                  type="text"
                  class="form-control"
                />
              </div>
              <div class="form-group col-md-4 col-sm-6">
                <label>Chargeback:</label>
                <input
                  v-model="selectedMccConfig.chargeback_fee"
                  autocomplete="off"
                  type="text"
                  class="form-control"
                />
              </div>
              <div class="form-group col-md-4 col-sm-6">
                <label>ACH Return:</label>
                <input
                  v-model="selectedMccConfig.ach_return"
                  autocomplete="off"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>

            <div class="row">
              <div class="form-group col-md-4 col-sm-6">
                <label>Card Rate:</label>
                <input
                  v-model="selectedMccConfig.card_rate"
                  autocomplete="off"
                  type="text"
                  class="form-control"
                  @change="
                    () => {
                      const foundCode = mccCodes.find(
                        (code) => code.mcc_code === selectedMccConfig.mcc_code
                      );
                      selectedMccConfig.card_rate =
                        parseFloat(selectedMccConfig.card_rate) <
                        parseFloat(foundCode.card_rate)
                          ? foundCode.card_rate
                          : selectedMccConfig.card_rate;
                    }
                  "
                />
              </div>
              <div class="form-group col-md-4 col-sm-6">
                <label>ACH Rate:</label>
                <input
                  v-model="selectedMccConfig.ach_rate"
                  autocomplete="off"
                  type="text"
                  class="form-control"
                />
              </div>
              <div class="form-group col-md-4 col-sm-6">
                <label>Crypto Rate:</label>
                <input
                  v-model="selectedMccConfig.crypto_rate"
                  autocomplete="off"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="form-group col-md-4 col-sm-6">
                    <label>TIN Mismatch:</label>
                    <input autocomplete="off" v-model="clientData.child.tin_mismatch" type="text" class="form-control" :disabled="!isEditing">
                </div> -->
      </div>
      <div
        v-if="
          (clientData.level === 'Parent' && user.user_type === 'admin') ||
          user.user_type === 'healnow'
        "
        id="whitelabel"
        class="section"
      >
        <h5>Whitelabel</h5>
        <div class="row">
          <!-- <div class="col-md-4">
                    <div class="form-group">
                        <label for="logo">Logo:</label>
                        <div class="input-group">
                        <input autocomplete="off" type="text" class="form-control" id="logo" v-model="clientData.logo" :disabled="!isEditing">
                        <button class="btn btn-secondary" type="button" :disabled="!isEditing">Upload</button>
                        </div>
                    </div>
                    </div> -->
          <!-- <div class="d-flex flex-column justify-content-start mt-3">
                    <p class="mb-0">Color:</p>
                    <p class="mt-0 mb-1">This selected color will become the default for the portal, virtual terminal, and email communications.</p> -->
          <!-- <div class="form-group col-2 mt-0">
                        <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" id="customColorSwitch" v-model="clientData.w_customColor" :disabled="!isEditing">
                        <label class="form-check-label ml-2" for="customColorSwitch">{{ clientData.w_customColor ? 'Use Custom Color' : 'Use Default Colors' }}</label>
                        </div>
                    </div> -->
          <!-- This input below only needs to show if the toggle is on above. w_customColor can be a boolean (true = custom / false = default) -->
          <!-- <div class="form-group col-2" v-if="clientData.w_customColor">
                        <label>Enter Hex Code:</label>
                        <input type="text" class="form-control" v-model="w_color" :disabled="!isEditing">
                    </div> -->
          <!-- </div>  -->
        </div>
        <div class="mt-4">
          <h6>Welcome Email to Merchants</h6>
          <div class="form-group">
            <label for="email-type">Email Option:</label>
            <select
              id="email-type"
              v-model="clientData.child.enroll_email"
              class="form-select"
              :disabled="!isEditing"
            >
              <option value="false">No Email</option>
              <option value="true">Default (Adopted from TPP)</option>
              <option value="custom">Custom</option>
            </select>
          </div>
          <!-- <div class="mt-3">
                        <label>Message:</label>
                        <textarea class="form-control" rows="3" v-model="clientData.w_enrollMessage" :disabled="!isEditing"></textarea>
                    </div> -->
          <!-- <div class="mt-3">
                        <label>Contact Details:</label>
                        <input autocomplete="off" type="text" class="form-control" v-model="clientData.w_enrollContact" :disabled="!isEditing">
                    </div> -->
          <!-- <div class="form-check mt-3">
                        <input autocomplete="off" type="checkbox" class="form-check-input" v-model="clientData.w_enrollVtLink" :disabled="!isEditing">
                        <label class="form-check-label">Include Link to Virtual Terminal</label>
                    </div> -->
          <!-- <div class="form-check">
                        <input autocomplete="off" type="checkbox" class="form-check-input" v-model="clientData.w_enrollApiKey" :disabled="!isEditing">
                        <label class="form-check-label">Include API Key</label>
                    </div> -->
        </div>
        <div class="mt-4">
          <!-- NOTE: WHITELABEL SMTP DATA HAS BEEN FLATTENED BELOW -->
          <h6>SMTP</h6>
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label>Entity Name (What appears in the header of CTAs):</label>
                <input
                  v-model="clientData.whitelabel.entity_name"
                  autocomplete="off"
                  type="text"
                  class="form-control"
                  :disabled="!isEditing"
                />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Color (hex value for the color):</label>
                <input
                  v-model="clientData.whitelabel.color"
                  autocomplete="off"
                  type="text"
                  class="form-control"
                  :disabled="!isEditing"
                />
              </div>
              <div class="form-group">
                <label>Logo URL:</label>
                <input
                  v-model="clientData.whitelabel.logo_url"
                  autocomplete="off"
                  type="text"
                  class="form-control"
                  :disabled="!isEditing"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
        v-if="
          user.user_type === 'admin'
        "
        id="features"
        class="section"
    >
      <h5>Features</h5>
      <div class="row">

      </div>
      <div class="mt-4">
        <h6>Level Three Data Collection</h6>
        <div class="form-group">
          <div class="form-check">
            <input
                class="form-check-input"
                type="checkbox"
                v-model="clientData.config.level_three_data_enabled"
                id="levelThreeDataEnabled">
            <label class="form-check-label" for="levelThreeDataEnabled">
              Level 3 Data Collection Enabled
            </label>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <h6>Meta Field Reporting Search</h6>
        <div class="form-group">
          <div class="form-check">
            <input
                class="form-check-input"
                type="checkbox"
                v-model="clientData.config.meta_field_search"
                id="metaFieldSearch">
            <label class="form-check-label" for="metaFieldSearch">
              Reporting Meta Field Search Enabled
            </label>
          </div>
          <label for="metaFieldSearchTerm" class="form-label">Reporting Meta Field Search Term</label>
          <input
              type="text"
              class="form-control"
              id="metaFieldSearchTerm"
              v-model="clientData.config.meta_field_search_term"
              placeholder="Reporting Meta Field Search Term">
        </div>
      </div>
    </div>

    <div
      v-if="
        clientData.config.payment === 'propay' &&
        clientData.keystore.propay_mid != null
      "
      :style="{ marginTop: '24px' }"
    >
      <!-- only go here if the account is configured for propay and has a valid account number -->
      <h4 :style="{ float: 'left' }">ProPay</h4>
      <select
        :style="{ marginLeft: 'auto', display: 'block' }"
        @change="requestPropaySSOLink($event.target.value)"
      >
        <option
          v-for="(value, name, index) in propaySSOLinks"
          :value="value"
          :key="index"
        >
          {{ name }}
        </option>
      </select>
      <iframe
        height="480px"
        :style="{ width: '100%' }"
        :src="propaySSOLink"
      ></iframe>
    </div>
  </div>
</template>
<script setup>
import {
  onMounted,
  onBeforeMount,
  computed,
  ref,
  reactive,
  defineProps,
  watch
} from 'vue';
import http from '@/services/http';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import { useStore } from 'vuex';
import ViewClientNotes from '@/components/clients/ViewClientNotes.vue';
import ViewClientDocuments from '@/components/clients/ViewClientDocuments.vue';
import { useToast } from 'vue-toastification';
import Confirm from '@/components/ConfirmComponent.vue';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import BaseButton from '@/components/base/BaseButton.vue';
import { PERMISSION_PRIMITIVES } from '@/config/constants';

const toast = useToast();
let toastdefault = {
  position: 'top-center',
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: 'button',
  icon: true,
  rtl: false
};

const store = useStore();
const user = computed(() => store.state.user);

const hasPermissions = computed(() => store.getters.merchantHasPermissions);
const permissions = computed(() => store.state.permissions);

const userHasPaymentPagePermission = computed(
  () =>
    !hasPermissions.value ||
    (hasPermissions.value &&
      user.value?.role?.granted_permissions?.some(
        (permission) => permission.name === PERMISSION_PRIMITIVES.PAYMENT_PAGES
      ))
);

console.log(
  'userHasPaymentPagePermission 1',
  userHasPaymentPagePermission.value
);

watch(userHasPaymentPagePermission, (newValue, oldValue) => {
  console.log(
    'userHasPaymentPagePermission changed from',
    oldValue,
    'to',
    newValue
  );
});

const userHasEditMerchantPermissions = computed(
    () =>
        !hasPermissions.value ||
        (hasPermissions.value &&
            user.value?.role?.granted_permissions?.some(
                (permission) => permission.name === PERMISSION_PRIMITIVES.MERCHANT_ACCOUNTS
            ))
);

console.log(
    'userHasEditMerchantPermissions 1',
    userHasEditMerchantPermissions.value
);

watch(userHasEditMerchantPermissions, (newValue, oldValue) => {
  console.log(
      'userHasEditMerchantPermissions changed from',
      oldValue,
      'to',
      newValue
  );
});

const p = defineProps({
  clientid: { type: String }
});

const kyc = ref({});
const devName = ref('');
const devEmail = ref('');
const isEditing = ref(false);
const agentLink = ref('');
const paymentLink = ref('');
const parentLink = ref('');
const endpoints = ref([]);

/**
 * @typedef {Object} MccCode
 * @property {string} label
 * @property {string} mcc_code
 * @property {string} card_rate
 */

/** @type {Ref<MccCode[]>} */
const mccCodes = ref([]);
const events = ref([]);

/**
 * @typedef {Object} MccCode
 * @property {string} label
 * @property {string} mcc_code
 * @property {string} card_rate
 */

/** @type {Ref<MccCode[]>} */
const otherMccCodes = ref([]);

const clientData = reactive({
  city: '',
  tax_id: '',
  start_date: '',
  dba_name: '',
  logo: '',
  name: '',
  child: {
    onboard: false,
    velocity: 0,
    devices: 0,
    transactions: 0,
    same_day_credit_card_transactions: 0,
    end_of_day: '',

    // risk
    avg_ticket: 0,
    high_ticket: 0,
    monthly_volume: 0,
    monthly_high_volume: 0,

    enroll_email: false,
    pause_settlement: false,
    // rev_share: {},
    // smtp: {},
  },
  email: '',
  phone: '',
  state: '',
  lastupdated: '',
  api_key: '',
  public_key: '',
  enrollment_id: '',
  bank: {},
  config: {
    ach: false,
    ccs: 3,
    crypto: false,
    enroll: false,
    payout: 'split_settlement',
    timing: 60,
    devices: 5,
    enabled: true,
    payment: '',
    velocity: 100,
    terminals: '',
    high_ticket: 10000,
    enroll_email: true,
    transactions: 5,
    level_three_data_enabled: false,
    level_two_data_enabled: false,
    tax_enabled: false,
    meta_field_search: false,
    meta_field_search_term: null,
    rev_share_formula: null,
    rev_share_percentage: "0.00"
  },
  parent: {
    id: '',
    name: '',
    hashcode: ''
  },
  wallet: [],
  address: '',
  mccConfigs: [],
  billing: {
    mcc_code: '',
    dba_name: '',
    legal_name: '',
    start_date: '',
    business_type: '',
    ownership_type: '',
    billing_descriptor: ''
  },
  country: '',
  pricing: {
    etf: '500',
    ach_p2c: false,
    balance: 0,
    ach_rate: '',
    card_p2c: false,
    low_rate: '',
    tax_rate: '',
    card_rate: '',
    rev_share: 50,
    crypto_p2c: false,
    client_rate: 0,
    crypto_rate: '',
    client_balance: ''
  },
  business: {},
  principals: [],
  zipcode: '',
  address2: '',
  keystore: {
    xpress: '',
    curpayid: '',
    m_mid: '',
    m_key: '',
    m_pid: '',
    tsys: '',
    b_id: '|'
  },
  apicreds: {
    id: '',
    appid: '',
    token: '',
    version: '',
    acceptor: ''
  },
  iframekey: '',
  confirmation: {},
  whitelabel: {
    color: '',
    logo_url: '',
    entity_name: ''
  }
});

const selectedMccCode = ref('');
const otherSelectedMccCode = ref('');
const selectedMccConfig = ref(null);
const createdMccConfig = ref({});

function onMccCodeChange() {
  // Find the MCC config object with the same mcc_code as the selectedMccCode
  let mccConfig = clientData.mccConfigs.find(
    (config) => config.mcc_code === selectedMccCode.value
  );

  // If the MCC config object doesn't exist, create it
  if (!mccConfig) {
    // Find the default card_rate from mccCodes for the selected MCC code
    const foundConfig = mccCodes.value.find(
      (code) => code.mcc_code === selectedMccCode.value
    );
    const defaultCardRate = foundConfig?.card_rate || '';

    // Initialize the new MCC config object with default values
    mccConfig = {
      mcc_code: selectedMccCode.value,
      low_rate: '',
      etf: '',
      reg_fee: '',
      pci_fee: '',
      monthly_fee: '',
      monthly_minimum: '',
      annual_fee: '',
      chargeback_fee: '',
      ach_return: '',
      // Set the default card_rate from the mccCodes object
      card_rate: defaultCardRate,
      ach_rate: '',
      crypto_rate: ''
    };

    // Add the new MCC config object to clientData.mccConfigs
    clientData.mccConfigs.push(mccConfig);
  }

  // Now, mccConfig either found or created, you can use it for further operations
  // For example, if you need to update the UI or other elements based on this config
  selectedMccConfig.value = mccConfig;
}

const propaySSOLinks = ref({
  'Sweep Report': 'Report/SweepReport',
  'Advanced Transaction Search': 'Report/AdvancedTransactionSearch',
  'Consolidated Fees': 'Report/ConsolidatedFees',
  'Limits, Rates and Fees': 'Report/LimitsRatesAndFees',
  'Transaction Details': 'Report/TransactionDetails',
  'Transaction Report': 'Report/TransactionReport',
  'Order Additional Devices': 'Device/OrderAdditionalDevices',
  'Edit Business Info': 'Profile/EditBusinessInfo',
  'Update Address and Phone Number': 'Profile/UpdateAddressAndPhone',
  'Update PIN': 'Profile/UpdatePin',
  'Update Email': 'Profile/UpdateEmail',
  'Add / Update Checking Account': 'Account/AddUpdateCheckingAccount'
});

const propaySSOLink = ref('');

const requestPropaySSOLink = (selectedRoute) => {
  const accountNumber = clientData.keystore.propay_mid;
  http.get(`/api/sso/propay?client_id=${clientData.id}`).then((response) => {
    const authToken = response.data.authToken;
    const route = selectedRoute == null ? 'Report/SweepReport' : selectedRoute;
    propaySSOLink.value = `https://propay.merchant-portals.com/${route}?authToken=${authToken}&accountnum=${accountNumber}`;
  });
};

function bluesnapDecode(key) {
  if (key) {
    let x = key.split('|')[1];
    x = atob(x);
    x = x.replace(':', '<br>');
    return x;
  }
  return '';
}

onBeforeMount(() => {
  http.get('/api/client?id=' + p.clientid).then((data) => {
    console.log('Data from api client: ', data);
    Object.assign(clientData, data?.data?.message);
    agentLink.value = window.location.origin + `/contact/${clientData.id}`;
    paymentLink.value =
      window.location.origin + `/createlink/${clientData.api_key}`;
    parentLink.value =
      window.location.origin + `/account/${clientData.parent.id}`;

    requestPropaySSOLink();
  });
  http.get('/api/report/kyc?client_id=' + p.clientid).then((data) => {
    kyc.value = data.data.message;
  });
  http.get('/api/mcc').then((response) => {
    mccCodes.value = response.data.message;
  });
});

const editAccount = () => {
  isEditing.value = true;
};

const copyToClipboard = (text) => {
  const input = document.createElement('textarea');
  input.value = text;
  document.body.appendChild(input);
  input.select();
  document.execCommand('copy');
  document.body.removeChild(input);
};

const settlementData = ref({});

const getSettlementData = () => {
  http
    .get(`/api/mcc`)
    .then((response) => {
      otherMccCodes.value = response.data.message;
    })
    .catch((error) => {
      console.error(
        'An error occurred while fetching other mcc code data:',
        error
      );
    });
};

const addSubpricing = () => {
  const createdMccConfigValue = createdMccConfig.value;
  createdMccConfigValue.mcc_code = otherSelectedMccCode;
  createdMccConfigValue.client_id = p.clientid;
  console.log(
    'Calling addSubpricing with createdMccConfigValue: ',
    createdMccConfigValue
  );
  http
    .post(`/api/subpricing`, createdMccConfigValue)
    .then((response) => {
      if (response.status === 200) {
        toast.success('Subpricing Added', toastdefault);
      } else {
        console.error('Failed to add subpricing. Response:', response);
      }
    })
    .catch((error) => {
      console.error('An error occurred while updating settlement data:', error);
    });
};

const saveChanges = () => {
  toast(
    {
      component: Confirm,
      props: {
        body: 'Confirm Save?',
        btext: 'Confirm'
      },
      listeners: {
        confirm: () => {
          http
            .patch(`/api/client/${p.clientid}`, {
              meta: JSON.stringify(clientData)
            })
            .then((response) => {
              toast.clear();
              toast.success('Update successful.', toastdefault);
            })
            .catch((error) => {
              toast.clear();
              toast.error(
                'An error occurred while updating client data:',
                toastdefault
              );
            });
        }
      }
    },
    toastdefault
  );

  isEditing.value = false;
};

const cancelChanges = () => {
  isEditing.value = false;
};

// Deactivate, Delete, and Ticket Emails
function sendDeactivateEmail() {
  const deactivateEmail = `${user.value.name} has requested to DEACTIVATE ${clientData.id}`;
  const endpoint = '/api/email';
  const data = {
    body: deactivateEmail,
    to: 'devteam@tripleplaypay.com,csm@tripleplaypay.com',
    subject: 'Account Deactivation Request'
  };

  http
    .post(endpoint, data)
    .then((response) => {
      if (response.status === 200) {
        toast.success('Email sent successfully', toastdefault);
      } else {
        toast.error('Email failed to send', toastdefault);
      }
    })
    .catch((error) => {
      console.error('An error occurred while sending the email:', error);
    });
}

function sendDeleteEmail() {
  const deleteEmail = `${user.value.name} has requested to DELETE ${clientData.id}`;
  const endpoint = '/api/email';
  const data = {
    body: deleteEmail,
    to: 'devteam@tripleplaypay.com,csm@tripleplaypay.com',
    subject: 'Account Delete Request'
  };

  http
    .post(endpoint, data)
    .then((response) => {
      if (response.status === 200) {
        toast.success('Email sent successfully', toastdefault);
      } else {
        console.error('Failed to send the email');
      }
    })
    .catch((error) => {
      console.error('An error occurred while sending the email:', error);
    });
}

function sendTicketEmail() {
  const ticketMessage = document.getElementById('ticketMessage').value;
  const endpoint = '/api/email';
  const data = {
    body:
      `Ticket submitted for ${clientData.name} by ${user.value.name}: ` +
      ticketMessage,
    to: 'devteam@tripleplaypay.com,csm@tripleplaypay.com',
    subject: 'Account Ticket Submission'
  };

  http
    .post(endpoint, data)
    .then((response) => {
      if (response.status === 200) {
        toast.success('Email sent successfully', toastdefault);
      } else {
        console.error('Failed to send the email');
      }
    })
    .catch((error) => {
      console.error('An error occurred while sending the email:', error);
    });
}

// Re-run kyc
function returnToOnboarding() {
  toast(
    {
      component: Confirm,
      listeners: {
        confirm: () => {
          http
            .get(`/api/kyc/client2enroll?id=${clientData.id}`)
            .then((response) => {
              if (response.status === 200) {
                console.log('Returned to Onboarding', response);
                toast.success('Returned to Onboarding', toastdefault);
              } else {
                console.error('Failed to return');
              }
            })
            .catch((error) => {
              console.error('An error occurred while returning:', error);
            });
        }
      }
    },
    toastdefault
  );
}

// Create sandbox account
function createSandbox() {
  toast(
    {
      component: Confirm,
      listeners: {
        confirm: () => {
          http
            .post(
              `/api/createsandbox?id=${clientData.id}&dba_name=${clientData.name}`
            )
            .then(toast.success('Sandbox Created!', toastdefault));
        }
      }
    },
    toastdefault
  );
}

// Enroll Developer to begin Cert process
function enrollDev() {
  // Enroll dev as user in sandbox
  const sandUserEndpoint = 'https://sandbox.tripleplaypay.com/api/user/new?';
  // Enroll dev as user in production
  const prodUserEndpoint = '/api/user/new?';
  const emailEndpoint = '/api/email';
  const userData = {
    name: devName.value,
    email: devEmail.value,
    user_type: 'user',
    client: clientData.id
  };
  // Prepare email to send
  const emailData = {
    body: `<p style="font-size: 18px;">${devName.value},</p>
                <p style="font-size: 16px;">You have been enrolled in the Triple Play Pay Development Certification process by ${clientData.name}.</p>
                <p style="font-size: 16px;">Please complete <a href="https://tripleplaypay.com/registration.html" target="_blank">this form</a> to get started.</p>`,
    to: `devteam@tripleplaypay.com, ${devEmail.value}`,
    subject: 'Enrollment in Development Certification: Triple Play Pay'
  };

  toast(
    {
      component: Confirm,
      listeners: {
        confirm: () => {
          http
            .post(prodUserEndpoint, userData)
            .then((response) => {
              if (response.status === 200) {
                console.log('Dev enrolled successfully');
                toast.success('Dev enrolled in Production', toastdefault);
              } else {
                console.error('Failed to enroll');
              }
            })
            .catch((error) => {
              console.error('An error occurred while enrolling:', error);
            });

          http
            .post(`/api/createsandbox?id=${clientData.id}`)
            .then((response) => {
              if (response.status === 200) {
                console.log('Sandbox created');
                toast.success('Sandbox created', toastdefault);
                http
                  .post(sandUserEndpoint, userData)
                  .then((response) => {
                    if (response.status === 200) {
                      console.log('Dev enrolled in Sandbox successfully');
                      toast.success('Dev enrolled in Sandbox', toastdefault);
                    } else {
                      console.error('Failed to enroll in Sandbox');
                    }
                  })
                  .catch((error) => {
                    console.error(
                      'An error occurred while enrolling in Sandbox:',
                      error
                    );
                  });
              } else {
                console.error('Failed to enroll');
              }
            })
            .catch((error) => {
              console.error('An error occurred while enrolling:', error);
            });

          http
            .post(emailEndpoint, emailData)
            .then((response) => {
              if (response.status === 200) {
                console.log('Dev email sent successfully');
                toast.success('Dev email sent!', toastdefault);
              } else {
                console.error('Failed to send email');
              }
            })
            .catch((error) => {
              console.error('An error occurred while sending email:', error);
            });
        }
      }
    },
    toastdefault
  );
}

const getCert = () => {
  const devCertUrl = `https://sandbox.tripleplaypay.com/api/stats/cert?id=${clientData.id}`;

  http
    .get(devCertUrl)
    .then((response) => {
      endpoints.value = response.data.map((item) => ({
        requiredEndpoint: item[2],
        testResult: item[3]
      }));
    })
    .catch((error) => {
      console.error('An error occurred while fetching test results:', error);
    });
};

// Users/Contacts
const firstName = ref('');
const lastName = ref('');
const uEmail = ref('');
const user_type = ref('');
const uLabel = ref('');
const uPhone = ref('');
const users = ref([]);

const submitData = computed(() => {
  const data = {
    name: `${firstName.value}` + ' ' + `${lastName.value}`,
    email: uEmail.value.toLowerCase(),
    phone: uPhone.value,
    user_type: user_type.value,
    client_id: clientData.id,
    label: uLabel.value,
    is_locked_out: false
  };

  return data;
});

const submitNewUser = () => {
  const data = submitData.value;
  console.log('data to be submitted for new user: ', data);
  toast(
    {
      component: Confirm,
      listeners: {
        confirm: () => {
          http
            .post(`/api/user`, data)
            .then((response) => {
              if (response.status === 200) {
                toast.clear();
                console.log('Success', response);
                toast.success('Successfully added!', toastdefault);

                // Clear form fields
                firstName.value = '';
                lastName.value = '';
                uEmail.value = '';
                uPhone.value = '';
              } else {
                console.error('Failed to add');
              }
            })
            .catch((error) => {
              console.error('An error occurred while adding:', error);
            });
        }
      }
    },
    toastdefault
  );
};

// Fetch user data from the API and populate the users ref array
const fetchUserData = () => {
  http
    .get(`/api/client/users?id=${clientData.id}`)
    .then((response) => {
      console.log('response is here from client/users: ', response);
      console.log('response.data is here from client/users: ', response.data);
      console.log(
        'response.data.message is here from client/users: ',
        response.data.message
      );
      if (Array.isArray(response.data.message)) {
        users.value = response.data.message;
      }
    })
    .catch((error) => {
      console.error('Error fetching user data:', error);
    });
};

// Fetch user data from the API and populate the users ref array
// const fetchEnablementData = () => {
//   http
//     .get(`/api/events?client_id=${clientData.id}`)
//     .then((response) => {
//       console.log('response is here from api/events: ', response);
//       console.log('response.data is here from api/events: ', response.data);
//       console.log(
//         'response.data.message is here from api/events: ',
//         response.data.message
//       );
//       if (Array.isArray(response.data.message)) {
//         events.value = response.data.message;
//       }
//     })
//     .catch((error) => {
//       console.error('Error fetching user data:', error);
//     });
// };

const enrollIntoEPXForPayments = () => {
  // get the post body
  const postBody = {
    id: `${clientData.id}`
  };

  http
    .post(`/api/epx/enable`, postBody)
    .then((response) => {
      console.log('response is here from api/epx/enable: ', response);
      const merchantIdOrNull =
        response?.data?.message?.message?.epx_results?.third?.merchantId;
      if (merchantIdOrNull) {
        toast.success(
          `Merchant enabled successfully: ${merchantIdOrNull}`,
          toastdefault
        );
      } else {
        toast.error(
          'Error with merchant enablement, check event logs',
          toastdefault
        );
      }
      // fetchEnablementData(clientData.id);
    })
    .catch((error) => {
      console.error('Error enabling epx for payments:', error);
      toast.error(`Error enabling epx: ${error.message}`, toastdefault);
      // fetchEnablementData(clientData.id);
    });
};

const reApplyIntoEPXForPayments = () => {
  // get the post body
  const postBody = {
    id: `${clientData.id}`,
    re_apply: true
  };

  http
    .post(`/api/epx/enable`, postBody)
    .then((response) => {
      console.log('response is here from api/epx/enable: ', response);
      const merchantIdOrNull =
        response?.data?.message?.message?.epx_results?.third?.merchantId;
      if (merchantIdOrNull) {
        toast.success(
          `Merchant enabled successfully: ${merchantIdOrNull}`,
          toastdefault
        );
      } else {
        toast.error(
          'Error with merchant enablement, check event logs',
          toastdefault
        );
      }
      // fetchEnablementData(clientData.id);
    })
    .catch((error) => {
      console.error('Error enabling epx for payments:', error);
      toast.error(`Error enabling epx: ${error.message}`, toastdefault);
      // fetchEnablementData(clientData.id);
    });
};

const enrollIntoProPayForACH = () => {
  toast.info('Enrolling into propay ACH', toastdefault);

  // get the post body
  const postBody = {
    id: `${clientData.id}`,
    is_ach_only: true
  };

  http
    .patch(`/api/client/enablepropay`, postBody)
    .then((response) => {
      console.log('response is here from api/epx/enable: ', response);
      const message = response.data.message;
      if (message.pro_pay_status_code === '00') {
        toast.success('Parent changed successfully!', toastdefault);
        // fetchEnablementData(clientData.id);
      } else {
        toast.error(
          `Propay called failed with status code: ${message.pro_pay_status_code}`,
          toastdefault
        );
        // fetchEnablementData(clientData.id);
      }
    })
    .catch((error) => {
      console.error('Error fetching user data:', error);
      toast.error(
        `Error enrolling into propay: ${error.message}`,
        toastdefault
      );
      // fetchEnablementData(clientData.id);
    });
};

// New parent selection
const newParentName = ref(''); // Store the selected parent name
const parentOptions = ref([]); // Store the parent options from the API response

// Get the parent options
const getParentOptions = () => {
  http.get('/api/client/parent').then((response) => {
    console.log('api parent response: ', response.data);
    console.log('api parent response.message: ', response.data.message);
    parentOptions.value = response.data.message;
    console.log('parentOptions.value, ', parentOptions.value);
  });
};

// Update data with the selected parent
const saveNewParent = () => {
  if (newParentName.value) {
    console.log('saving newParentName.value: ', newParentName.value.legal_name);
    const requestData = {
      clientid: clientData.id,
      parent_legal_name: newParentName.value.legal_name
    };
    http.post('/api/client/hierarchy', requestData).then((response) => {
      console.log('response from hierarchy: ', response.data);
      if (response.status === 200) {
        toast.success('Parent changed successfully!', toastdefault);
        console.log(response);
        // window.location.reload();
      } else {
        toast.error('Failed to change parent. Please try again.', toastdefault);
      }
    });
  }
};
</script>
<style scoped>
.nav-link {
  color: black;
}
.section {
  margin-top: 20px;
}

h3 {
  font-size: 22px;
}

h5 {
  margin-top: 20px;
  margin-bottom: 0px;
  font-size: 18px;
}

p {
  margin-bottom: 0;
}

label {
  font-size: 14px;
}

.form-group {
  margin-top: 15px;
}

.save-btn .edit-btn {
  padding: 5px;
  width: 70px;
  font-size: 10px;
  margin: 0;
}

.vr {
  border-left: 1px solid black;
  background: none;
  padding: 0 7px;
}

.side-sub {
  margin-top: 15px;
  font-weight: 600;
  text-decoration: underline;
}

.small {
  color: gray;
  font-size: 14px;
  line-height: 110%;
  margin-bottom: 10px;
}

.ticket-container {
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f5f5f5;
}

.ticket-info {
  font-size: 14px;
  margin-bottom: 10px;
}

.ticket-textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: vertical;
}
</style>
