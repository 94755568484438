import useToast from '@/composables/common/toast/index.js';

function copyToClickboard(text) {
  window.navigator.clipboard.writeText(text);
}

export default function () {
  const { showMessage } = useToast();

  const copy = (data, message) => {
    copyToClickboard(data);
    showMessage({
      title: 'Success',
      text: message || 'Saved to clipboard',
      type: 'success'
    });
  };

  return {
    copy
  };
}
