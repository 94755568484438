import useService from '@/composables/common/services';
import { ref } from 'vue';

export default function (apikey) {
  const { apiService } = useService();
  const terminals = ref([]);
  const selectedTerminal = ref(null);
  const loadingTerminals = ref(false);

  const fetchTerminals = () => {
    loadingTerminals.value = true;
    return apiService
      .get('api/terminal', {
        headers: {
        }
      })
      .then((res) => {
        const data = res.message;

        delete data.ip;
        const els = [];

        for (let key in data) {
          els.push({
            ...data[key],
            status: data[key]?.connectionStatus?.status,
            name: key
          });
        }

        terminals.value = els
          .filter((el) => el.status === 'Open')
          .map((el) => {
            return {
              text: el.name,
              value: el.laneId
            };
          });

        if (terminals.value && terminals.value.length) {
          selectedTerminal.value = terminals.value[0].value;
        }

        loadingTerminals.value = false;
      })
      .catch(() => {
        terminals.value = [];
        loadingTerminals.value = false;
      });
  };

  return {
    terminals,
    selectedTerminal,
    loadingTerminals,

    fetchTerminals
  };
}
