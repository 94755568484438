import { onBeforeMount, onBeforeUnmount, ref } from 'vue';

const MAX_MOBILE_SCREEN_WIDTH = 1024;

export default function () {
  const isMobile = ref(false);

  const checkSize = () => {
    if (
      window.innerWidth > MAX_MOBILE_SCREEN_WIDTH ||
      window.innerHeight > MAX_MOBILE_SCREEN_WIDTH
    ) {
      isMobile.value = false;
      return;
    }

    isMobile.value = true;
  };

  onBeforeMount(() => {
    window.addEventListener('resize', checkSize);
    checkSize();
  });

  onBeforeUnmount(() => {
    window.removeEventListener('resize', checkSize);
  });

  return {
    isMobile
  };
}
