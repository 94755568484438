<template>
  <div class="login-page d-lg-flex justify-content-center align-items-center">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="login-container container-fluid p-lg-3">
            <div class="row h-100">
              <div class="col-12 col-lg-6 h-100">
                <div class="logo-title-container">
                  <h1
                    class="d-lg-none color-primary text-center fw-light logo-title"
                  >
                    <div class="logo text-center">
                      <img src="@/assets/images/LogoIconGradient.png" alt="" />
                    </div>

                    <span class="fw-bolder">TriplePlay</span
                    ><span class="fw-lighter">Pay</span>
                  </h1>
                </div>

                <div
                  class="h-100 d-flex align-items-center flex-column justify-content-center"
                >
                  <slot />
                </div>

                <!-- <div class="text-center create-btn-container">
                  <a
                    href="/createlink"
                    class="create-link-text color-dark fz-14"
                    target="_blank"
                  >
                    Payment Page
                  </a>
                </div> -->
              </div>

              <div class="col-12 col-lg-6 h-100 d-none d-lg-block">
                <div
                  class="p-1 bg-primary border-radius h-100 d-flex justify-content-center align-items-center"
                >
                  <h1 class="color-light fw-light fz-30">
                    <div class="logo text-center">
                      <img src="@/assets/images/LogoIconWhite.png" alt="" />
                    </div>
                    <span class="fw-bolder">TriplePlay</span
                    ><span class="fw-lighter">Pay</span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuthLayout'
};
</script>

<style lang="scss" scoped>
.create-btn-container {
  position: relative;
  top: -35px;
}

.login-page {
  min-height: 100vh;
  max-height: 100vh;
  background-color: var(--c-grey-lighten);
}

.login-container {
  @include has-border-radius;
  @include has-box-shadow;
  min-height: 80vh;
  max-height: 80vh;
  height: 80vh;
  background-color: var(--c-light);
}

.logo {
  img {
    width: 70px;
  }
}

.logo-title-container {
  padding-top: 10px;

  .logo-title {
    font-size: 18px !important;
  }
}

@media screen and (max-width: 768px) {
  .login-container {
    box-shadow: none;
  }

  .login-page {
    background-color: var(--c-light);
  }

  .logo {
    img {
      width: 50px;
    }
  }
}
</style>
