<template>
  <div :class="{ error, isFocused }" class="base-dropdown-container">
    <p v-if="label" class="label fw-normal color-grey-medium d-flex fz-14 m-0">
      <span class="vertical-align-middle">
        {{ label }}
      </span>

      <slot name="label"></slot>
    </p>

    <div class="base-dropdown position-relative" @mouseover="handleMouseOver">
      <div v-if="autocomplete" class="drop-input-container position-relative">
        <base-input
          v-model="searchValue"
          :placeholder="placeholder || 'Select or enter custom'"
          @click="onHandlerClick"
          @update:modelValue="isListVisible = true"
          @blur="handleInputBlur"
        />

        <span class="chevron">
          <slot name="icon">
            <chevron-down :size="14" />
          </slot>
        </span>
      </div>

      <div
        v-else
        class="value fz-14 d-flex align-items-center px-2"
        :class="{ disabled, unselected: !modelValue }"
        @click="onHandlerClick"
      >
        <slot name="value" :value="modelValue">
          <span class="text">
            {{ text }}
          </span>

          <span>
            <slot name="icon">
              <chevron-down :size="14" />
            </slot>
          </span>
        </slot>
      </div>

      <transition name="slide-y">
        <ul
          v-if="isListVisible && !disabled && !readonly"
          class="options position-absolute"
        >
          <li
            v-if="firstElSlot"
            key="first-element"
            class="option p-2"
            @click="stopPropagation"
          >
            <slot name="first-element"> </slot>
          </li>

          <li
            v-for="(option, index) in filteredOptions"
            :key="index"
            class="option p-2"
            :class="{ selected: modelValue === option.value }"
            @click="handleSelectOption(option, $event)"
          >
            <slot name="item" :dropdownOption="option">
              {{ optionTextFunc ? optionTextFunc(option) : option.text }}
            </slot>
          </li>
        </ul>
      </transition>

      <transition name="slide-y">
        <p
          v-if="error"
          class="error-text mt-1 fw-bolder pl-2"
          data-test="error"
        >
          {{ error }}
        </p>
      </transition>
    </div>
  </div>
</template>

<script>
import {
  computed,
  onBeforeUnmount,
  onMounted,
  ref,
  toRefs,
  watch
} from 'vue';

import { useSlots } from 'vue';
import ChevronDown from 'vue-material-design-icons/ChevronDown.vue';
import BaseInput from '@/components/base/BaseInput.vue';

const lookByDefault = (options, searchValue) => {
  return options.filter((v) => {
    const text = (`${v.text}` || '').toLowerCase();

    return text.includes(searchValue.toLowerCase());
  });
};

const defaultSelectedText = (option) => {
  if (!option) {
    return null;
  }

  return option.text;
};

export default {
  name: 'BaseDropdown',
  components: {
    ChevronDown,
    BaseInput
  },
  props: {
    modelValue: {
      type: [Object, String, Number, Boolean],
      default: null
    },
    options: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: 'Select'
    },
    label: {
      type: String,
      default: null
    },
    error: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: Boolean,
      default: false
    },
    allowCustomValue: {
      type: Boolean,
      default: true
    },
    allowClearValue: {
      type: Boolean,
      default: false
    },
    optionTextFunc: {
      type: Function,
      default: null
    },
    lookBy: {
      type: Function,
      default: lookByDefault
    },
    selectedTextValue: {
      type: Function,
      default: defaultSelectedText
    },
    preventHideAfterSelect: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'focus', 'blur'],
  setup(props, { emit }) {
    const {
      modelValue,
      options,
      placeholder,
      autocomplete,
      allowCustomValue,
      allowClearValue,
      lookBy,
      selectedTextValue,
      preventHideAfterSelect
    } = toRefs(props);
    const isListVisible = ref(false);
    const isFocused = ref(false);
    const searchValue = ref('');
    const slots = useSlots();

    const firstElSlot = computed(() => {
      return slots['first-element'];
    });

    const handleFocus = () => {
      isFocused.value = true;

      emit('focus');
    };

    const handleBlur = () => {
      isFocused.value = false;

      emit('blur');
    };

    const selectedOption = computed(() => {
      const asOption = options.value.find(
        (el) => el.value === modelValue.value
      );
      return asOption;
    });

    const selectedValue = computed(() => {
      if (typeof modelValue.value === 'object' && modelValue.value) {
        return modelValue.value.text;
      }

      if (selectedOption.value) {
        return selectedOption.value.text;
      }

      return placeholder.value;
    });

    const text = computed(() => {
      return selectedValue.value;
    });

    const filteredOptions = computed(() => {
      if (!autocomplete.value) {
        return options.value;
      }

      if (searchValue.value === selectedTextValue.value(selectedOption.value)) {
        return options.value;
      }

      if (!searchValue.value || !searchValue.value.trim()) {
        return options.value;
      }

      return lookBy.value(options.value, searchValue.value);
    });

    const stopPropagation = (e) => {
      e.stopPropagation();
    };

    const handleSelectOption = (option, e) => {
      stopPropagation(e);

      if (allowClearValue.value && modelValue.value === option.value) {
        emit('update:modelValue', null);
        searchValue.value = null;
        return;
      }

      if (!preventHideAfterSelect.value) {
        isListVisible.value = false;
      }

      if (typeof modelValue.value === 'object' && modelValue.value) {
        emit('update:modelValue', option);

        return;
      }

      emit('update:modelValue', option.value);
    };

    const handleBodyClick = () => {
      isListVisible.value = false;
    };

    const onHandlerClick = (e) => {
      e.stopPropagation();

      if (isListVisible.value) {
        handleBlur();
        isListVisible.value = false;
        return;
      }

      document.body.click();
      isListVisible.value = !isListVisible.value;
      handleFocus();
    };

    const handleMouseOver = (e) => {
      e.stopPropagation();
    };

    const handleInputBlur = () => {
      if (searchValue.value === text.value) {
        return;
      }

      if (allowCustomValue.value) {
        emit('update:modelValue', searchValue.value.trim().toLowerCase());
      }

      handleBlur();
    };

    onMounted(() => {
      document.body.addEventListener('click', handleBodyClick);

      if (!modelValue.value || !autocomplete.value) {
        return;
      }
    });

    watch(
      () => {
        return selectedOption.value;
      },
      () => {
        if (!autocomplete.value) {
          return;
        }

        if (selectedOption.value) {
          searchValue.value = selectedTextValue.value(selectedOption.value);
          return;
        }
      },
      {
        immediate: true
      }
    );

    onBeforeUnmount(() => {
      document.body.removeEventListener('click', handleBodyClick);
    });

    return {
      searchValue,
      firstElSlot,
      text,
      isListVisible,
      isFocused,
      filteredOptions,

      handleMouseOver,
      stopPropagation,
      handleSelectOption,
      onHandlerClick,
      handleFocus,
      handleBlur,
      handleInputBlur
    };
  }
};
</script>

<style lang="scss" scoped>
.base-dropdown {
  width: 100%;
}

.value {
  @include has-border-radius;
  outline: none;
  border: 1px solid var(--c-grey-light);
  background-color: var(--c-grey-lighten);
  height: 45px;
  padding: 0 0 0 10px;
  color: var(--c-dark);
  transition: border-color 0.2s ease, background-color 0.2s ease;
  width: 100%;
  cursor: pointer;

  &:focus {
    border-color: var(--c-primary);
  }

  &::placeholder {
    color: var(--c-grey-medium);
    font-size: 14px;
  }

  &.disabled {
    color: #c5c5c5;
  }
}

.options {
  @include has-box-shadow;
  list-style: none;
  padding: 0;
  margin: 0;
  top: 45px;
  left: 0;
  min-width: 100px;
  width: 100%;
  z-index: 10;
  border: 1px solid var(--c-grey);
  border-radius: 5px;
  max-height: 300px;
  overflow-y: auto;
  background-color: var(--c-white);
  min-width: var(--min-options-width);

  .option {
    background-color: var(--c-white);
    color: var(--c-grey-dark);
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      background-color: var(--c-grey-lighten);
    }
  }

  .selected {
    background-color: var(--c-grey-light);
  }
}

.text {
  white-space: nowrap;
  max-width: 90%;
  width: 90%;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chevron {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.error-text {
  color: var(--c-danger);
  font-size: 14px;
}

.error {
  .value {
    border-color: var(--c-danger);
    background-color: var(--c-danger-light);
  }
}
</style>
