import { onMounted, onBeforeUnmount, ref } from 'vue';
import { Quill } from '@vueup/vue-quill';

import { Hr } from '@/helpers/quill/divider.js';
const Size = Quill.import('attributors/style/size');

import '@vueup/vue-quill/dist/vue-quill.snow.css';

const SIZES = [
  '10px',
  '12px',
  '14px',
  '16px',
  '18px',
  '20px',
  '22px',
  '24px',
  '26px',
  '28px',
  '30px'
];
Size.whitelist = SIZES;
Quill.register(Size, true);
Quill.register({
  'formats/hr': Hr
});

export default function (description, savedDescriptionValue, clearDescription) {
  const editor = ref(null);
  const colorPickerVisible = ref(false);
  const currentColor = ref('#000000');
  const triggerColorChangeButton = ref(null);
  const mouseIsOverEditor = ref(false);
  const timerId = ref(null);

  const handleFocus = () => {
    if (!savedDescriptionValue.value) {
      return;
    }

    editor.value.setContents('\n');
    clearDescription.value();
  };

  onMounted(() => {
    if (!savedDescriptionValue.value) {
      return;
    }

    const data = savedDescriptionValue.value;

    description.value = data;
    editor.value.setContents(data);

    const quill = editor.value.getQuill();

    quill.root.addEventListener('focus', handleFocus);
  });

  onBeforeUnmount(() => {
    const quill = editor.value.getQuill();

    quill.root.removeEventListener('focus', handleFocus);
  });

  const handleColorChange = (v) => {
    const currentValue = currentColor.value || '';

    if (currentValue.includes(v.colors.hex)) {
      return;
    }

    currentColor.value = v.colors.hex;
    triggerColorChangeButton.value.click();
  };

  const handleMousePositionOnEditor = (type) => {
    if (type === 'on') {
      mouseIsOverEditor.value = true;
      return;
    }

    mouseIsOverEditor.value = false;
  };

  const globalOptions = {
    modules: {
      toolbar: {
        container: '#toolbar',
        handlers: {
          hr() {
            // get the position of the cursor
            const range = this.quill.getSelection();

            if (range) {
              // insert the <hr> where the cursor is
              this.quill.insertEmbed(range.index, 'hr', 'null');
              this.quill.setSelection(range.index + 2, Quill.sources.SILENT);
            }
          },
          handleColorPickerChange() {
            this.quill.format('color', currentColor.value);
            this.quill.enable(false);
            clearTimeout(timerId.value);
            timerId.value = setTimeout(() => {
              this.quill.enable(true);
            }, 10);
          },
          toggleColorPicker() {
            colorPickerVisible.value = !colorPickerVisible.value;
          }
        }
      }
    },
    placeholder: 'Description...',
    theme: 'snow'
  };

  return {
    editor,
    globalOptions,
    colorPickerVisible,
    currentColor,
    triggerColorChangeButton,
    handleColorChange,
    handleMousePositionOnEditor
  };
}
