<template>
  <div>
    <h1 class="color-dark text-center fz-14 fw-500">
      Are you sure you want to refund?
    </h1>

    <div class="d-flex mt-4">
      <base-button
        class="mr-2 w-50"
        :color="fullRefund ? 'primary' : 'grey-light'"
        @click="handleRefundOptionClick(true)"
      >
        Full refund ${{
          transaction &&
          transaction.amount &&
          Number(transaction.amount).toFixed(2)
        }}
      </base-button>

      <base-button
        class="w-50"
        :color="fullRefund ? 'grey-light' : 'primary'"
        @click="handleRefundOptionClick(false)"
      >
        Partial Refund(max ${{
          transaction &&
          transaction.amount &&
          Number(transaction.amount).toFixed(2)
        }})
      </base-button>
    </div>

    <div class="d-flex mt-2">
      <base-input
        v-model="amount"
        class="mr-2 w-100"
        placeholder="Amount"
        amount="Amount"
        type="number"
        label="Amount, $"
        :min="0"
        :max="maxAmount"
        :disabled="fullRefund"
        :error="amountError"
        step="0.01"
      />
    </div>

    <base-button class="w-100 mt-4" @click="submit"> Refund </base-button>
  </div>
</template>

<script>
import { computed, ref, toRefs } from 'vue';
import BaseInput from '@/components/base/BaseInput.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import { watch } from 'vue';
import useToast from '@/composables/common/toast';
import useService from '@/composables/common/services';
import http from '@/services/http';

import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  amount: yup.number().when('maxAmount', (maxAmount, schema) => {
    return schema.test(
      'test less than',
      `Refund can not be greater than transaction amount`,
      (value) => {
        return value <= maxAmount;
      }
    );
  }),
  maxAmount: yup.number().nullable()
});

export default {
  name: 'RefundModal',
  components: {
    BaseInput,
    BaseButton
  },
  props: {
    transaction: {
      type: Object,
      default: () => null
    }
  },
  emits: ['update'],
  setup(props, { emit }) {
    const { showMessage } = useToast();
    const { apiService } = useService();
    const { validate } = useForm({
      validationSchema
    });

    const fullRefund = ref(true);
    const loading = ref(false);
    const { transaction } = toRefs(props);

    const { value: amount, errorMessage: amountError } = useField(
      'amount',
      null,
      {
        validateOnValueUpdate: false
      }
    );

    const { value: maxAmount } = useField('maxAmount', null);

    watch(
      () => transaction.value,
      () => {
        if (!transaction.value) {
          maxAmount.value = null;
          amount.value = null;

          return;
        }

        maxAmount.value = transaction.value?.amount || 0;
        amount.value = maxAmount.value;
      },
      {
        deep: true,
        immediate: true
      }
    );

    const data = computed(() => {
      if (fullRefund.value || amount.value === maxAmount.value) {
        return {
          id: transaction.value.id
        };
      }

      return {
        id: transaction.value.id,
        amount: amount.value
      };
    });

    const handleRefundOptionClick = (value) => {
      fullRefund.value = value;

      if (fullRefund.value) {
        amount.value = maxAmount.value;
      }
    };

    const submit = async () => {
      loading.value = true;

      // http
      // .post(`/api/refund?amount=${amount.value}&id=${transaction.value.id}`)
      // .then((response) => {
      //   console.log('Refund API Response:', response.data);
      //     if (response.data.status === true) {
      //         alert('Refund was successful.');
      //     } else {
      //         console.error('Refund API returned a non-successful status.');
      //         alert('Refund failed.');
      //     }
      // })
      // .catch((error) => {
      //   console.error('Error refunding the transactino:', error);
      //   alert('Refund error. Please try again.');
      // });

      apiService
        .post('api/refund', data.value)
        .then(() => {
          loading.value = false;

          alert('Refund was successful.');

          emit('update');
        })
        .catch(() => {
          alert('Refund failed. Please try again.');

          loading.value = false;
        });
    };

    return {
      fullRefund,
      amount,
      amountError,
      maxAmount,

      handleRefundOptionClick,
      submit
    };
  }
};
</script>

<style lang="scss" scoped></style>
