<template>
  <form @submit.prevent="submit">
    <base-spinner v-if="loading" class="loader" />

    <h1 class="fz-14 fw-600 text-center color-dark">Add a Terminal</h1>
    <base-input
      v-if="showDejavooDetails"
      v-model="serialNumber"
      label="Serial Number"
      placeholder="Serial Number"
      :error="serialNumberError"
    />
    <base-input
      v-if="showDejavooDetails"
      v-model="authenticationKey"
      label="Authentication Key"
      placeholder="Authentication Key"
      class="mt-2"
      :error="authenticationKeyError"
    />
    <base-input
      v-if="showDejavooDetails"
      v-model="accessKey"
      label="Access Key"
      placeholder="Access Key"
      class="mt-2"
      :error="accessKeyError"
    />
    <base-input
      v-model="activationCode"
      label="Activation Code"
      placeholder="Activation Code"
      class="mt-2"
      :error="activationCodeError"
    />
    <base-input
      v-model="terminalId"
      label="Terminal ID"
      placeholder="Terminal ID"
      class="mt-2"
      :error="terminalIdError"
    />
    <base-input
      v-if="showDejavooDetails"
      v-model="fileName"
      label="File Name"
      placeholder="File Name"
      class="mt-2"
      :error="fileNameError"
    />
    <!-- <base-input
      v-model="activationCode"
      label="Activation Code"
      placeholder="Activation Code"
      :error="activationCodeError"
    />
    <base-input
      v-model="terminalId"
      label="Terminal name"
      placeholder="Terminal name"
      class="mt-2"
      :error="terminalIdError"
    /> -->
    <!-- <base-input
      v-model="description"
      label="Description"
      placeholder="Description"
      textarea
      class="mt-2"
      :error="descriptionError"
    /> -->

    <base-button type="submit" class="mt-2 w-100"> Submit </base-button>

    <div v-if="showDejavooDetails">
      <p class="fz-12 hint-btn color-dark mt-2 text-center" @click="toggleHint">
        Dejavoo Terminal Guide
      </p>
    </div>

    <transition name="slide-y">
      <div v-if="showHint" class="pt-4">
        <h2 class="fz-16 color-dark text-center">
          How to setup a Dejavoo Terminal:
        </h2>

        <ol class="how-list">
          <li>
            Set up ethernet or WI-FI connection <br />
            Open MARKET. If needed, PIN is 1234.
          </li>

          <li>Install any available updates.</li>

          <li>Re-open MARKET and install DVPAY.</li>

          <li>Go to home screen and open Aura.</li>

          <li>
            Download TPN: Core Menu > Utility > Software Download > Connect >
            Network > Full > Enter the TPN #
          </li>
        </ol>
      </div>
    </transition>
  </form>
</template>

<script>
import { ref, computed } from 'vue';
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import useToast from '@/composables/common/toast';
import useService from '@/composables/common/services';
import { useStore } from 'vuex';

import BaseButton from '@/components/base/BaseButton.vue';
import BaseInput from '@/components/base/BaseInput.vue';
import BaseSpinner from '@/components/base/BaseSpinner.vue';

const validationSchema = yup.object().shape({
  type: yup.string().nullable(),
  terminalId: yup.string().required('Field is required'),
  activationCode: yup.string().required('Field is required'),
  fileName: yup.string().when('type', (type, schema) => {
    if (type === 'dejavooo') {
      return schema.required('Field is required').nullable();
    }

    return schema.nullable();
  }),
  serialNumber: yup.string().when('type', (type, schema) => {
    if (type === 'dejavooo') {
      return schema.required('Field is required').nullable();
    }

    return schema.nullable();
  }),
  authenticationKey: yup.string().when('type', (type, schema) => {
    if (type === 'dejavooo') {
      return schema.required('Field is required').nullable();
    }

    return schema.nullable();
  }),
  accessKey: yup.string().when('type', (type, schema) => {
    if (type === 'dejavooo') {
      return schema.required('Field is required').nullable();
    }

    return schema.nullable();
  })
});

export default {
  name: 'AddTerminal',
  components: {
    BaseInput,
    BaseButton,
    BaseSpinner
  },
  emits: ['update'],
  setup(_, { emit }) {
    const { showMessage } = useToast();
    const { apiService } = useService();
    const store = useStore();

    const loading = ref(false);
    const showHint = ref(false);

    const { validate } = useForm({
      validationSchema
    });

    const showDejavooDetails = computed(() => {
      return (
        store.state.clientDetails?.config?.terminals === 'dejavooo'
      );
    });

    useField('type', null, {
      initialValue: store.state.clientDetails?.config?.terminals
    });

    const { value: serialNumber, errorMessage: serialNumberError } = useField(
      'serialNumber',
      null,
      {}
    );

    const { value: authenticationKey, errorMessage: authenticationKeyError } =
      useField('authenticationKey', null, {});

    const { value: accessKey, errorMessage: accessKeyError } = useField(
      'accessKey',
      null,
      {}
    );

    const { value: activationCode, errorMessage: activationCodeError } =
      useField('activationCode', null, {});

    const { value: terminalId, errorMessage: terminalIdError } = useField(
      'terminalId',
      null,
      {
        initialValue: ''
      }
    );

    const { value: fileName, errorMessage: fileNameError } = useField(
      'fileName',
      null,
      {
        initialValue: ''
      }
    );

    const data = computed(() => {
      if (showDejavooDetails.value) {
        return {
          serialNumber: serialNumber.value,
          authenticationKey: authenticationKey.value,
          activationCode: activationCode.value,
          terminalId: terminalId.value,
          fileName: fileName.value
        };
      }

      return {
        activationCode: activationCode.value,
        terminalId: terminalId.value
      };
    });

    const submit = async () => {
      const result = await validate();

      console.log(result);

      if (!result.valid) {
        return;
      }

      loading.value = true;
      apiService
        .post('api/terminal', data.value)
        .then(() => {
          loading.value = false;

          showMessage({
            title: 'Success',
            text: 'Terminal was successfully added',
            type: 'success'
          });

          emit('update');
        })
        .catch(() => {
          loading.value = false;

          showMessage({
            title: 'Error',
            text: 'Something went wrong',
            type: 'danger'
          });
        });
    };

    const toggleHint = () => {
      showHint.value = !showHint.value;
    };

    return {
      authenticationKey,
      serialNumber,
      activationCode,
      fileName,
      terminalId,
      loading,
      showHint,
      showDejavooDetails,
      accessKey,

      accessKeyError,
      serialNumberError,
      authenticationKeyError,
      activationCodeError,
      terminalIdError,
      fileNameError,

      submit,
      toggleHint
    };
  }
};
</script>

<style lang="scss" scoped>
.hint-btn {
  text-decoration: underline;
  cursor: pointer;
}

.how-list {
  color: dark;
  font-size: 12px;
  padding: 0 0 0 15px;
}
</style>
