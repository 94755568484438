<template>
  <div class="reporting management-page">
    <div class="title" style="color: #383838; font-size: 24px;">Costing Estimates</div>
    <AgGrid
        :apiurl = "apiUrl"
        ref = "agRef"
        :sideBar = true
        :aggFunc = "aggFunc"
        :defineDefs = "columnDefs"
        :excel-styles="excelStyles"
        groupPanel = ''
        :excelOptions = "{fileName:'Costing.xlsx'}"
        height="88%"
        @filterChanged = "filterModified"
    ></AgGrid>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import AgGrid from '@/components/AgGrid.vue';

const excelStyles = [
  {
    id: 'dateType',
    dataType: 'dateTime',
    numberFormat: { format: 'yyyy-mm-dd hh:mm:ss' }
  }
];
const start = ref(
    new Date(new Date().getTime() - 90 * 24 * 60 * 60 * 1000)
        .toISOString()
        .split('T')[0]
);
const end = ref(new Date().toISOString().split('T')[0]);

const apiUrl = ref(`/api/costing?start=${start.value}&end=${end.value}`)
const agRef = ref(null);


onMounted(() => {});

const filterModified = (model) => {
  console.log('REPORTING: filterModified function called with model: ', model);
  const dates = model[1];
  console.log('REPORTING: dates extracted from model: ', dates);
  start.value = dates?.dateFrom?.split(' ')[0];
  end.value = dates?.dateTo?.split(' ')[0];
  console.log('REPORTING: start and end values set: ', start.value, end.value);
  // todo make timestamps
  if (start.value && end.value) {
    apiUrl.value = `/api/v1/costing?start=${start.value}&end=${end.value}`;
    if (agRef.value)
      agRef.value.refreshAPI(`/api/v1/costing?start=${start.value}&end=${end.value}`);
    else
      console.error('no <AgGrid /> ref');
  }
}

const columnDefs = [
  {
    headerName: 'Parent',
    filter: 'agNumberColumnFilter',
    valueGetter: (p) => {
      if (!p.data) return null;
      return p.data.parent;
    }
  },
  {
    headerName: 'Merchant',
    filter: 'agNumberColumnFilter',
    valueGetter: (p) => {
      if (!p.data) return null;
      return p.data.dba_name;
    }
  },
  {
    headerName: 'MCC Code',
    filter: 'agNumberColumnFilter',
    valueGetter: (p) => {
      if (!p.data) return null;
      return p.data.mcc_code;
    }
  },
  {
    headerName: 'Created At',
    cellClass: 'dateType',
    filter: 'agDateColumnFilter',
    valueGetter: (p) => {
      if (!p.data) return '';
      return new Date(p.data.created_at).toISOString();
    },
    valueFormatter: (p) => {
      if (!p.data) return '';
      let x = new Date(Date.parse(p.data.created_at));
      return x.toLocaleString('en-US');
    },
    filterParams: {
      filterOptions: ['inRange'],
      inRangeInclusive: true
    },
    sort: 'desc',
  },
  {
    headerName: 'Payment Method',
    filter: 'agNumberColumnFilter',
    valueGetter: (p) => {
      if (!p.data) return null;
      return p.data.payment_method_used;
    }
  },
  {
    headerName: 'Processor',
    filter: 'agNumberColumnFilter',
    valueGetter: (p) => {
      if (!p.data) return null;
      return p.data.processor;
    }
  },
  {
    headerName: 'Type',
    filter: 'agNumberColumnFilter',
    valueGetter: (p) => {
      if (!p.data) return null;
      return p.data.type;
    }
  },
  {
    headerName: 'Action',
    filter: 'agNumberColumnFilter',
    valueGetter: (p) => {
      if (!p.data) return null;
      return p.data.action;
    }
  },
  {
    headerName: 'Status',
    filter: 'agNumberColumnFilter',
    valueGetter: (p) => {
      if (!p.data) return null;
      return p.data.status;
    }
  },
  {
    headerName: 'Card Brand',
    filter: 'agNumberColumnFilter',
    valueGetter: (p) => {
      if (!p.data) return null;
      return p.data.card_brand;
    }
  },
  {
    headerName: 'Card Type',
    filter: 'agNumberColumnFilter',
    valueGetter: (p) => {
      if (!p.data) return null;
      return p.data.card_type;
    }
  },
  {
    headerName: 'Amount',
    filter: 'agNumberColumnFilter',
    aggFunc: 'sum',
    valueGetter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.amount) || 0.00; // amount from Tortoise ORM model
    },
    valueFormatter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.amount).toFixed(2) || 0.00;
    },
  },
  {
    headerName: 'Fee',
    filter: 'agNumberColumnFilter',
    valueGetter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.fee) || 0.00; // fee from Tortoise ORM model
    },
    valueFormatter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.fee).toFixed(2) || 0.00;
    },
  },
  {
    headerName: 'Settlement Amount',
    filter: 'agNumberColumnFilter',
    aggFunc: 'sum',
    valueGetter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.settlement_amount) || 0.00; // amount from Tortoise ORM model
    },
    valueFormatter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.settlement_amount).toFixed(2) || 0.00;
    },
  },
  {
    headerName: 'Settlement Date',
    cellClass: 'dateType',
    valueGetter: (p) => {
      if (!p.data.settlement_date) return 'n/a';
      return new Date(p.data.settlement_date).toISOString().split('T')[0];
    },
    valueFormatter: (p) => {
      if (!p.data.settlement_date) return 'n/a';
      let x = new Date(Date.parse(p.data.settlement_date));
      return x.toLocaleDateString('en-US');
    },
  },
  {
    headerName: 'Estimated Costing',
    filter: 'agNumberColumnFilter',
    valueGetter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.costing_total) || 0.00; // costing_total from Tortoise ORM model
    },
    valueFormatter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.costing_total).toFixed(2) || 0.00;
    },
  },
  {
    headerName: 'Estimated Profit',
    filter: 'agNumberColumnFilter',
    valueGetter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.profit) || 0.00; // profit from Tortoise ORM model
    },
    valueFormatter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.profit).toFixed(2) || 0.00;
    },
  },
  {
    headerName: 'Triple Tip',
    filter: 'agNumberColumnFilter',
    valueGetter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.triple_tip) || 0.00;
    },
    valueFormatter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.triple_tip).toFixed(2) || 0.00;
    }
  },
  {
    headerName: 'Triple Surcharge',
    filter: 'agNumberColumnFilter',
    valueGetter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.triple_surcharge) || 0.00;
    },
    valueFormatter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.triple_surcharge).toFixed(2) || 0.00;
    }
  },
  {
    headerName: 'Triple Platform Fee',
    filter: 'agNumberColumnFilter',
    valueGetter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.triple_platform_fee) || 0.00;
    },
    valueFormatter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.triple_platform_fee).toFixed(2) || 0.00;
    }
  },
  {
    headerName: 'Triple Cash Discount',
    filter: 'agNumberColumnFilter',
    valueGetter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.triple_cash_discount) || 0.00;
    },
    valueFormatter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.triple_cash_discount).toFixed(2) || 0.00;
    }
  },
  {
    headerName: 'Triple Convenience Fee',
    filter: 'agNumberColumnFilter',
    valueGetter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.triple_convenience_fee) || 0.00;
    },
    valueFormatter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.triple_convenience_fee).toFixed(2) || 0.00;
    }
  },
  {
    headerName: 'Triple Initial Amount',
    filter: 'agNumberColumnFilter',
    valueGetter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.triple_initial_amount) || 0.00;
    },
    valueFormatter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.triple_initial_amount).toFixed(2) || 0.00;
    }
  },
  {
    headerName: 'Triple Charge Rate',
    filter: 'agTextColumnFilter',
    valueGetter: (p) => {
      if (!p.data) return null;
      return p.data.triple_charge_rate;
    }
  },
  {
    headerName: 'Triple Charge Rate Type',
    filter: 'agTextColumnFilter',
    valueGetter: (p) => {
      if (!p.data) return null;
      return p.data.triple_charge_rate_type;
    }
  },
  {
    headerName: 'FIS Split Fee',
    filter: 'agNumberColumnFilter',
    valueGetter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.fis_split_fee) || 0.00;
    },
    valueFormatter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.fis_split_fee).toFixed(2) || 0.00;
    }
  },
  {
    headerName: 'FIS Interchange Amount',
    filter: 'agNumberColumnFilter',
    valueGetter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.fis_interchange_amount) || 0.00;
    },
    valueFormatter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.fis_interchange_amount).toFixed(2) || 0.00;
    }
  },
  {
    headerName: 'FIS Surcharge Amount',
    filter: 'agNumberColumnFilter',
    valueGetter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.fis_surcharge_amount) || 0.00;
    },
    valueFormatter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.fis_surcharge_amount).toFixed(2) || 0.00;
    }
  },
  {
    headerName: 'FIS Convenience Fee Amount',
    filter: 'agNumberColumnFilter',
    valueGetter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.fis_convenience_fee_amount) || 0.00;
    },
    valueFormatter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.fis_convenience_fee_amount).toFixed(2) || 0.00;
    }
  },
  {
    headerName: 'FIS Fee',
    filter: 'agNumberColumnFilter',
    valueGetter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.fis_fee) || 0.00;
    },
    valueFormatter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.fis_fee).toFixed(2) || 0.00;
    }
  },
  {
    headerName: 'FIS Dues and Assessments',
    filter: 'agNumberColumnFilter',
    valueGetter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.fis_dues_and_assessments) || 0.00;
    },
    valueFormatter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.fis_dues_and_assessments).toFixed(2) || 0.00;
    }
  },
  {
    headerName: 'Bluesnap Fee',
    filter: 'agNumberColumnFilter',
    valueGetter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.bluesnap_fee) || 0.00;
    },
    valueFormatter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.bluesnap_fee).toFixed(2) || 0.00;
    }
  },
  {
    headerName: 'Bluesnap Dues and Assessments',
    filter: 'agNumberColumnFilter',
    valueGetter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.bluesnap_dues_and_assessments) || 0.00;
    },
    valueFormatter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.bluesnap_dues_and_assessments).toFixed(2) || 0.00;
    }
  },
  {
    headerName: 'Bluesnap Interchange Amount',
    filter: 'agNumberColumnFilter',
    valueGetter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.bluesnap_interchange_amount) || 0.00;
    },
    valueFormatter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.bluesnap_interchange_amount).toFixed(2) || 0.00;
    }
  },
  {
    headerName: 'Bluesnap Percent of Remaining Margin',
    filter: 'agNumberColumnFilter',
    valueGetter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.bluesnap_percent_of_remaining_margin) || 0.00;
    },
    valueFormatter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.bluesnap_percent_of_remaining_margin).toFixed(4) || 0.0000;
    }
  },
  {
    headerName: 'ProPay BIN Fee',
    filter: 'agNumberColumnFilter',
    valueGetter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.propay_bin_fee) || 0.00;
    },
    valueFormatter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.propay_bin_fee).toFixed(2) || 0.00;
    }
  },
  {
    headerName: 'ProPay ACH Fee',
    filter: 'agNumberColumnFilter',
    valueGetter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.propay_ach_fee) || 0.00;
    },
    valueFormatter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.propay_ach_fee).toFixed(2) || 0.00;
    }
  },
  {
    headerName: 'ProPay Split Pay Fee',
    filter: 'agNumberColumnFilter',
    valueGetter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.propay_split_pay_fee) || 0.00;
    },
    valueFormatter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.propay_split_pay_fee).toFixed(2) || 0.00;
    }
  },
  {
    headerName: 'ProPay Interchange Fee',
    filter: 'agNumberColumnFilter',
    valueGetter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.propay_interchange_fee) || 0.00;
    },
    valueFormatter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.propay_interchange_fee).toFixed(2) || 0.00;
    }
  },
  {
    headerName: 'ProPay Authorization Fee',
    filter: 'agNumberColumnFilter',
    valueGetter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.propay_authorization_fee) || 0.00;
    },
    valueFormatter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.propay_authorization_fee).toFixed(2) || 0.00;
    }
  },
  {
    headerName: 'EPX Estimated Fee',
    filter: 'agNumberColumnFilter',
    valueGetter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.epx_estimated_fee) || 0.00;
    },
    valueFormatter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.epx_estimated_fee).toFixed(2) || 0.00;
    }
  },
  {
    headerName: 'EPX Estimated Dues and Assessments',
    filter: 'agNumberColumnFilter',
    valueGetter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.epx_estimated_dues_and_assessments) || 0.00;
    },
    valueFormatter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.epx_estimated_dues_and_assessments).toFixed(2) || 0.00;
    }
  },
  {
    headerName: 'EPX Estimated Interchange Amount',
    filter: 'agNumberColumnFilter',
    valueGetter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.epx_estimated_interchange_amount) || 0.00;
    },
    valueFormatter: (p) => {
      if (!p.data) return null;
      return parseFloat(p.data.epx_estimated_interchange_amount).toFixed(2) || 0.00;
    }
  }
];

</script>

<style lang="scss" scoped>
[v-cloak] {
  display: none;
}
.loader {
  z-index: 2;
}

.table {
  height: 70vh !important;
}

.subs-btn {
  height: 40px;
  padding: 0;
  background: #fff;
  border-color: #f6951e !important;
  color: #f6951e !important;

  &:hover {
    background: var(--c-grey-light) !important;
  }
}

@media screen and (max-width: 768px) {
  .reporting-filters {
    flex-direction: column;
  }

  .reporting-filters-container {
    flex-direction: column-reverse;
  }

  .report-input {
    width: 50%;

    &:last-child {
      margin-right: 0 !important;
    }
  }
}

::v-deep(.report-buttons) {
  @media screen and (max-width: 768px) {
    .btn {
      width: 50%;
    }
  }
}
</style>

<style lang="scss" >
@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-balham.min.css";
.reporting {
  .status-value {
    &.Failed {
      background: #ffe4e4;
      color: #b3322c;
    }

    &.Success {
      color: var(--c-success);
      background: #ddffef;
    }
  }

  .actions {
    height: 100% !important;
  }

  .action-btn {
    line-height: initial;
    width: 30px !important;
    height: 30px !important;
    padding: 2px 1px !important;
    font-size: 14px !important;
    border-radius: 50% !important;

    .icon-container {
      width: auto !important;

      svg {
        width: 19px !important;
      }
    }

    &.success {
      color: #fff !important;
    }
  }
}
</style>
