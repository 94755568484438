<template>
  <div class="management-page">
    <div style="color: #383838; font-size: 24px">Recurring Charges</div>
    <div
      v-if="role === 'admin' || role === 'parent'"
      class="form-floating mb-2 ms-2"
    >
      <select
        class="form-select"
        id="merchantDropdown"
        v-model="selectedMerchantId"
        @change="handleMerchantChange"
        style="width: 300px"
      >
        <option value="" disabled>Select a Merchant</option>
        <option
          v-for="merchant in merchants"
          :key="merchant.id"
          :value="merchant.id"
        >
          {{ merchant.name }}
        </option>
      </select>
      <label for="merchantDropdown">Viewing Recurring Charges For:</label>
    </div>
    <AgGrid
      ref="agref"
      :apiurl="apiurl"
      :columns="[]"
      :defineDefs="columnDefs"
      :sideBar="true"
      :statusBar="statusBar"
      groupPanel=""
      @row2Selected="rowDetails"
      :excelOptions="{ fileName: 'RecurringCharges.xlsx' }"
      height="88vh"
    ></AgGrid>
  </div>
  <div
    class="modal fade"
    tabindex="-1"
    id="myModal"
    ref="myModal"
    aria-labelledby="subsLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <h5 class="modal-title" id="subsLabel">Recurring Charge Series</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <!-- Modal Body -->
        <div class="modal-body">
          <!-- Settings Section -->
          <div class="row">
            <div class="col-md-6">
              <h6 class="font-weight-bold">Settings & Details</h6>
              <div id="settingsData">
                <!-- Placeholder for settings data generated in JS -->
              </div>
              <!-- Reactivate This Series link -->
              <p class="mt-2 mb-2">
                <span v-if="seriesStatus === true">
                  <a href="#" @click="cancelSeries">End This Series</a>
                  <!-- <a :href="unsubscribeURL" target="_blank">Sharable Link to End This Series</a> -->
                </span>
                <span v-if="seriesStatus !== true">
                  <a href="#" @click="reactivateSeries"
                    >Reactivate This Series</a
                  >
                </span>
              </p>

              <!-- Reactivate Series section -->
              <div v-if="showReactivateDiv">
                <h6 class="font-weight-bold">Reactivate Series</h6>
                <!-- Date Picker -->
                <div class="form-group">
                  <label for="reactivateDate">Select a Start Date</label>
                  <input
                    type="date"
                    class="form-control"
                    id="reactivateDate"
                    v-model="newStartDate"
                  />
                </div>
                <!-- Buttons -->
                <div class="my-2">
                  <button class="btn btn-danger me-1" @click="cancelReactivate">
                    Cancel
                  </button>
                  <button class="btn btn-primary" @click="submitReactivate">
                    Submit
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-6 mt-3 mt-md-0">
              <h6 class="font-weight-bold">Add a Charge</h6>
              <div class="form-group">
                <label for="paymentMethod">Select Payment Method</label>
                <select
                  class="form-select"
                  id="paymentMethod"
                  v-model="selectedPaymentMethod"
                >
                  <option
                    v-for="(method, index) in paymentMethods"
                    :value="method.fullMethod"
                    :key="index"
                  >
                    {{ method.simplifiedMethod }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="whenToCharge">When to Process the Charge:</label>
                <select
                  class="form-select"
                  id="whenToCharge"
                  v-model="whenToCharge"
                >
                  <option value="now">Charge Now</option>
                  <option value="nextScheduled">
                    With Next Scheduled Charge
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="amountToCharge">Amount to Charge</label>
                <input
                  type="text"
                  class="form-control"
                  id="amountToCharge"
                  v-model="amountToCharge"
                />
              </div>
              <button class="btn btn-primary mt-2" @click="confirmCharge">
                Confirm
              </button>
            </div>
          </div>

          <!-- Payment History Section TODO: Add this back in -->
          <!-- <div class="row mt-4">
            <div class="col-12">
              <h6 class="font-weight-bold">Payment History</h6>
              <table class="table table-bordered" id="paymentHistoryTable">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Receipt</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(payment, index) in responseData[3]" :key="index">
                    <td>{{ index.slice(0, 10) }}</td>
                    <td v-if="payment.end_reason">{{ payment.end_reason }}</td>
                    <td v-else>{{ payment?.message?.amount }}</td>
                    <td v-if="payment?.end_reason"></td>
                    <td v-else>
                      {{ payment?.status ? 'Approved' : 'Declined' }}
                    </td>
                    <td>
                      <a
                        v-if="payment.status"
                        :href="'/receipt/' + payment.id"
                        target="_blank"
                        class="text-decoration-none"
                        >Receipt</a
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, reactive, defineProps, onMounted } from 'vue';
import { useStore } from 'vuex';

import http from '@/services/http';
import AgGrid from '@/components/AgGrid.vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import Modal from 'bootstrap/js/dist/modal';
import { useToast } from 'vue-toastification';

const toast = useToast();
let toastdefault = {
  position: 'top-center',
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: 'button',
  icon: true,
  rtl: false
};

const p = defineProps({
  id: { type: String }
});

const statusBar = {
  statusPanels: [
    {
      statusPanel: 'agAggregationComponent',
      align: 'right'
    }
  ]
};

const store = useStore();
const role = computed(() => {
  return store.getters['role'];
});

const agref = ref();

const merchantName = store.getters.defaultClientName;
const merchantId = store.getters.defaultClientid;

console.log("RecurCharges, merchantname: ",  merchantName);
console.log("RecurCharges, merchantId: ",  merchantId);

// Initialize selectedMerchantId and selectedMerchantName with default values
const selectedMerchantId = ref(merchantId);
const selectedMerchantName = ref(merchantName);

const merchants = ref([]);
const apiurl = computed(
  () => `/api/subscription`
);

const myModal = ref(null);
const paymentMethods = ref([]);
const selectedPaymentMethod = ref(null);
const whenToCharge = ref('now');
const amountToCharge = ref('');
const responseData = ref([]);
const seriesStatus = ref(false);
const unsubscribeURL = ref('');

const showReactivateDiv = ref();
const newStartDate = ref('');

const reactivateSeries = () => {
  showReactivateDiv.value = true;
};

const cancelReactivate = () => {
  showReactivateDiv.value = false;
};

const rowDetails = (row) => {
  responseData.value = row[0].data;
  console.log(responseData);
  console.log("responseData is above!!!!!");

  const methodArray = [];
  const method = responseData.value.token;
  const parts = method.split('-');
  const simplifiedMethod = parts.length > 1 ? parts[1].trim() : method.trim();
  const paymentMethod = {
      fullMethod: method.trim(),
      simplifiedMethod,
  };
  methodArray.push(paymentMethod);
  paymentMethods.value = methodArray;

  // Populate the settingsData div with the desired keys and data
  const settingsDataDiv = document.getElementById('settingsData');

  seriesStatus.value = Boolean(responseData.value.status === "true");

  settingsDataDiv.innerHTML = `
            <p><strong>Active:</strong> ${responseData.value.status}</p>
            <p><strong>Amount:</strong> ${responseData.value.amount}</p>
            <p><strong>Start Date:</strong> ${responseData.value.start}</p>
            <p><strong>End Date:</strong> ${
              responseData.value.end || 'Until Ended'
            }</p>
            <p><strong>Next Charge:</strong> ${responseData.value.next}</p>
            <p><strong>Cycles:</strong> ${
              responseData.value.cycles || 'Total'
            }</p>
            <p><strong>Interval:</strong> ${responseData.value.interval}</p>
            <p><strong>Payment Method:</strong> ${
              responseData.value.token.split('-')[1] ||
              responseData.value.token
            }</p>

        `;

  const modalInstance = new Modal(myModal.value);
  modalInstance.show();
};

// Handle merchant selection change
const handleMerchantChange = () => {
  // Update selectedMerchantName when the user selects a merchant
  const selectedMerchant = merchants.value.find(
    (merchant) => merchant.id === selectedMerchantId.value
  );
  if (selectedMerchant) {
    selectedMerchantName.value = selectedMerchant.name;
  }
  // Update the API URL when the user selects a merchant
  apiurl.value = `/api/subscription?clientid=${selectedMerchantId.value}`;
  console.log(apiurl.value);
  // Refresh the data in the AgGrid
  agref.value.refreshAPI(apiurl.value);
};

// Function to load merchants from the API
const loadMerchants = async () => {
  try {
    const response = await http.get(
      `/api/clients?clientid=${selectedMerchantId.value}`
    );
    if (response.data.status) {
      const unsortedMerchants = response.data.message.map((merchant) => ({
        id: merchant[0],
        name: merchant[3]
      }));

      // Sort the merchants array alphabetically by name
      merchants.value = unsortedMerchants
        .slice()
        .sort((a, b) => (a.name && b.name ? a.name.localeCompare(b.name) : 0));
    }
  } catch (error) {
    console.error('Error loading merchants:', error);
  }
};

onMounted(() => {
  loadMerchants();
});

const confirmCharge = () => {
  if (whenToCharge.value === 'now') {
    toast.info('Processing charge...', toastdefault);
    const postData = {
      amount: amountToCharge.value,
      token: selectedPaymentMethod.value,
      subscription_id: responseData.value.id
    };
    http
      .post(
        `/api/charge`, postData
      )
      .then((response) => {
        console.log('Charge API Response:', response.data);
        if (response.data.status === true) {
          amountToCharge.value = '';
          toast.clear();
          toast.success('Payment was successful.', toastdefault);
        } else {
          console.error('Charge API returned a non-successful status.');
          toast.clear();
          alert('Payment failed.');
        }
      })
      .catch((error) => {
        console.error('Error making charge API call:', error);
        alert('Payment failed to process. Please try again.');
      });
  } else if (whenToCharge.value === 'nextScheduled') {
    toast.info('Adding charge...', toastdefault);
    const patchBody = {
      subscription_id: responseData.value.id,
      next: responseData.value.next
    };
    http
      .patch(
        `/api/subscription/override`, patchBody
      )
      .then((response) => {
        console.log('Subscription API Response:', response.data);
        if (response.data.status === true) {
          amountToCharge.value = '';
          toast.clear();
          toast.success('Successfully added.', toastdefault);
          agref.value.refreshAPI(apiurl.value);
        } else {
          console.error('Charge API returned a non-successful status.');
          toast.clear();
          alert('Payment failed to schedule.');
        }
      })
      .catch((error) => {
        console.error('Error making subscription API call:', error);
        alert('Payment failed to schedule. Please try again.');
      });
  }
};

const cancelSeries = () => {
  if (
    window.confirm(
      'Are you sure you want to end this subscription? Ending the subscription will NOT refund completed transactions.'
    )
  ) {

    http
      .patch('/api/subscription/unsubscribe', {
        subscription_id: responseData.value.id,
        reason: 'Ended by merchant'
      })
      .then((response) => {
        console.log('Subscription ended successfully:', response);
        toast.success('Successfully ended.', toastdefault);
        agref.value.refreshAPI(apiurl.value);
      })
      .catch((error) => {
        console.error('Failed to end subscription:', error);
        toast.error('Error. Please try again.', toastdefault);
      });
  } else {
    console.log('Subscription cancellation aborted.');
  }
};
const submitReactivate = () => {
  http
    .patch('/api/subscription/reactivate', {
      subscription_id: responseData.value.id,
      next: newStartDate.value,
    })
    .then((response) => {
      console.log('Subscription reactivated successfully:', response);
      toast.success('Successfully reactivated.', toastdefault);
      agref.value.refreshAPI(apiurl.value);
      showReactivateDiv.value = false;
    })
    .catch((error) => {
      console.error('Failed to reactivated subscription:', error);
      toast.error('Error. Please try again.', toastdefault);
    });
};

const columnDefs = [
  // {
  //   headerName: 'Series Id',
  //   filter: 'agSetColumnFilter',
  //   valueGetter: (params) => {
  //     if (!params.data.message) return '';
  //     return params.data.email;
  //   }
  // },
  {
    headerName: 'Status',
    filter: 'agSetColumnFilter',
    valueGetter: (params) => {
      if (!params.data) return '';
      return params.data.status;
    },
    valueFormatter: (params) => {
      return params.value === 'true' ? 'Active' : 'Inactive';
    }
  },
  {
    headerName: 'Series Details',
    children: [
      {
        headerName: 'Email',
        filter: 'agSetColumnFilter',
        valueGetter: (params) => {
          if (!params.data) return '';
          return params.data.email;
        }
      },
      {
        headerName: 'Amount',
        filter: 'agNumberColumnFilter',
        valueGetter: (params) => {
          if (!params.data) return '';
          return params.data.amount;
        }
      },
      {
        headerName: 'First Charge',
        filter: 'agDateColumnFilter',
        valueGetter: (params) => {
          if (!params.data) return '';
          return params.data.start;
        }
      },
      {
        headerName: 'Next Charge',
        filter: 'agDateColumnFilter',
        valueGetter: (params) => {
          if (!params.data) return '';
          return params.data.next;
        }
      },
      {
        headerName: 'Cycles Remaining',
        filter: 'agNumberColumnFilter',
        valueGetter: (params) => {
          if (!params.data) return '';
          return params.data.cycles;
        }
      },
      {
        headerName: 'End Reason',
        filter: 'agSetColumnFilter',
        valueGetter: (params) => {
          if (!params.data) return '';
          if (params.data.status === 'false') {
            // Check if "reason" exists in the last object
            return params.data.end_reason || '';
          }
          return '';
        }
      }
    ]
  }
];
</script>

<style lang="scss" scoped>
.loader {
  z-index: 2;
}

.modal-header {
  background-color: #6c757d;
  color: white;
  border-bottom: none;
}

.modal-header .modal-title {
  font-size: 20px;
  font-weight: 700;
}

.modal-body {
  padding: 20px;
}

.modal-body h6 {
  font-size: 18px;
  font-weight: 700;
}

.modal-body p a {
  color: black;
  text-decoration: underline;
}

.modal-body .form-group label {
  font-weight: 600;
}
</style>
