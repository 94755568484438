import { ref } from 'vue';

export default function () {
  const showFullName = ref(false);
  const showBillingAddress = ref(false);
  const showShippingAddress = ref(false);
  const showDescription = ref(false);
  const emailAddressOption = ref('optional');
  const phoneOption = ref(false);
  const showEmailOptIn = ref(false);
  const allowCustomerToSaveCreditCards = ref(false);
  const saveMode = ref(false);

  return {
    showFullName,
    showBillingAddress,
    showShippingAddress,
    showDescription,
    emailAddressOption,
    phoneOption,
    showEmailOptIn,
    allowCustomerToSaveCreditCards,
    saveMode
  };
}
