<template>
  <label
    class="form-check d-flex align-items-center base-checkbox"
    :class="{ disabled: disabled, checked }"
  >
    <input
      v-if="Array.isArray(modelValue)"
      class="form-check-input mr-2 checkbox-primary"
      type="checkbox"
      :checked="checked"
      :disabled="disabled"
      @input="handleSelect(value)"
    />

    <input
      v-else
      class="form-check-input mr-2"
      type="checkbox"
      :checked="checked"
      :disabled="disabled"
      @input="handleSelect(value)"
    />

    <slot name="label">
      <span class="form-check-label fz-14 color-dark">
        {{ label }}
      </span>
    </slot>
  </label>
</template>

<script>
import { toRefs } from 'vue';
import { computed } from 'vue';

export default {
  name: 'BaseCheckbox',
  props: {
    value: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    modelValue: {
      type: [Boolean, Array],
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { modelValue, value } = toRefs(props);

    const handleSelect = (v) => {
      if (!Array.isArray(modelValue.value)) {
        emit('update:modelValue', v);
        return;
      }

      const index = modelValue.value.findIndex((curr) => curr === v);

      if (index < 0) {
        emit('update:modelValue', [...modelValue.value, v]);
        return;
      }

      const newValue = [...modelValue.value];
      newValue.splice(index, 1);
      emit('update:modelValue', newValue);
    };

    const checked = computed(() => {
      if (Array.isArray(modelValue.value)) {
        return modelValue.value.includes(value.value);
      }

      return modelValue.value === value.value;
    });

    return {
      checked,

      handleSelect
    };
  }
};
</script>

<style lang="scss" scoped>
.form-check {
  cursor: pointer;
}

.form-check {
  position: relative;

  &::before,
  &::after {
    height: 13px;
    width: 13px;
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    border-radius: 3px;
    transform: translateY(-50%);
    transition: all 0.2s ease;
  }

  &::before {
    background-color: #e9ecef;
  }

  &.checked {
    &::after {
      background: var(--c-primary);
      background-image: url('./../../assets/icons/check.svg');
      background-size: 11px;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

.checkbox-primary {
  opacity: 0;
}
</style>

<style lang="scss">
.base-checkbox {
  &.disabled {
    .form-check-label {
      color: #adadad !important;
    }
  }
}
</style>
