class ApiService {
  constructor() {
    this.classes = [];
    this.services = [];
    this.serviceIndex = null;
  }

  setServices(serviceClasses) {
    this.classes = [...serviceClasses];
  }

  init(params) {
    this.classes.forEach((ServiceClass, index) => {
      const service = new ServiceClass(params);
      service._serviceIndex = index;

      service.init();

      this.services.push(service);
    });
  }

  getAvailable() {
    const service = this.services.find((s) => s.available);

    if (!service) {
      throw new Error('No service available');
    }

    return service;
  }

  post(url, data) {
    const service = this.getAvailable();

    let currentUrl;

    if (Array.isArray(url)) {
      currentUrl = url[service._serviceIndex];
    } else {
      currentUrl = url;
    }

    return service.post(currentUrl, data);
  }

  get(url, params = {}) {
    const service = this.getAvailable();
    let currParams = params;

    if (typeof params !== 'object' || !params) {
      currParams = {};
    }

    let currentUrl;

    if (Array.isArray(url)) {
      currentUrl = url[service._serviceIndex];
    } else {
      currentUrl = url;
    }

    return service.get(currentUrl, currParams);
  }
}

const apiService = new ApiService();

export default apiService;
