<template>
  <div class="actions d-flex align-items-center">
    <template v-if="actions.length">
      <component
        :is="action.component || 'base-tooltip'"
        v-for="action in visibleActions"
        :key="action.type"
        :title="action.name"
        :data="params.data"
        class="mr-2"
      >
        <base-button class="action-btn" @click="handleClick(action.type)">
          <template #icon-only> <component :is="action.icon" /></template>
        </base-button>
      </component>
    </template>

    <base-tooltip v-else title="View" class="mr-2">
      <base-button class="action-btn" @click="handleClick('view')">
        <template #icon-only> <eye /> </template>
      </base-button>
    </base-tooltip>
  </div>
</template>

<script>
import { computed, toRef } from 'vue';
import BaseButton from '@/components/base/BaseButton.vue';
import Eye from 'vue-material-design-icons/Eye.vue';
import Email from 'vue-material-design-icons/Email.vue';
import Close from 'vue-material-design-icons/Close.vue';
import NoteText from 'vue-material-design-icons/NoteText.vue';
import AccountEye from 'vue-material-design-icons/AccountEye.vue';
import CashRefund from 'vue-material-design-icons/CashRefund.vue';
import Download from 'vue-material-design-icons/Download.vue';
import FileDocument from 'vue-material-design-icons/FileDocument.vue';
import Draw from 'vue-material-design-icons/Draw.vue';

import BaseTooltip from '@/components/base/BaseTooltip.vue';

export default {
  name: 'OnboardingActions',
  components: {
    BaseButton,
    Eye,
    Email,
    BaseTooltip,
    Close,
    AccountEye,
    NoteText,
    CashRefund,
    Download,
    FileDocument,
    Draw
  },
  props: {
    params: {
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    const params = toRef(props, 'params');
    const actions = computed(() => {
      return params.value.actions || [];
    });

    const visibleActions = computed(() => {
      return actions.value.filter((action) => {
        const isVisible = action.isVisible;

        if (!isVisible) {
          return true;
        }

        const value = action.isVisible(params.value.data);

        return value;
      });
    });

    const handleClick = (type) => {
      params.value.onClick(params.value.value, type, params.value);
    };

    return {
      handleClick,
      actions,
      visibleActions
    };
  }
};
</script>

<style lang="scss" scoped></style>
