<template>
  <base-modal
    :model-value="show"
    class="view-modal"
    @update:modelValue="$emit('update:modelValue', $event)"
  >
    <base-spinner v-if="loading" fixed />

    <div class="container">
      <div class="row">
        <div class="col">
          <div class="mb-2">
            <status :params="statusValue" class="reject-status" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <base-tab title="Details">
            <div class="row">
              <div class="col-lg-3">
                <div>
                  <p class="title">Transaction ID</p>
                  <input :value="details[1].id" readonly class="value" />
                </div>
              </div>

              <div class="col-lg-3">
                <div>
                  <p class="title">Date</p>
                  <input :value="details[0]" readonly class="value" />
                </div>
              </div>

              <div class="col-lg-3">
                <div>
                  <p class="title">Amount</p>
                  <input :value="details[1].amount" readonly class="value" />
                </div>
              </div>

              <div class="col-lg-3">
                <div>
                  <p class="title">Fee</p>
                  <input :value="details[1].fee" readonly class="value" />
                </div>
              </div>

              <div class="row">
                <div class="col-lg-3">
                  <div>
                    <p class="title">User</p>
                    <input :value="details[2]" readonly class="value" />
                  </div>
                </div>

                <div class="col-lg-3">
                  <div>
                    <p class="title">Message</p>
                    <input :value="details[1].message" readonly class="value" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 my-3">
              <div>
                <button @click="openReceipt" class="btn btn-primary">Open Receipt</button>
              </div>
            </div>
          </base-tab>
        </div>
      </div>

      <base-tab title="JSON" class="mt-2" :initial-open="false">
        <div class="row">
          <div class="col-lg-12">
            <vue-json-pretty :data="detailsWithoutCustomKeys" class="pretty" />
          </div>
        </div>
      </base-tab>
    </div>
  </base-modal>
</template>

<script setup>
import BaseModal from '@/components/base/BaseModal.vue';
import BaseTab from '@/components/base/BaseTab.vue';
import { computed, ref, defineProps } from 'vue';
import Status from '@/components/onboarding/table/OnboardingStatus.vue';
import BaseSpinner from '@/components/base/BaseSpinner.vue';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
  // emits: ['update:modelValue', 'update-status'],
    const p = defineProps({
    show: {
      type: Boolean,
      default: false
    },
    details: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  })
    const selectedType = ref(null);
    const message = ref(null);

    const statusValue = computed(() => {
      return { value: p.details.status };
    });

    const openReceipt = () => {
      const receiptId = p.details[1].id;
      const baseUrl = window.location.origin;
      const receiptUrl = `${baseUrl}/receipt?id=${receiptId}`;

      window.open(receiptUrl, '_blank');
    };

    const detailsWithoutCustomKeys = computed(() => {
      const cp = p.details[1] ;

      delete cp.actions;
      delete cp.index;

      return cp;
    });
</script>

<style lang="scss" scoped>
.title {
  font-weight: 500;
  margin: 0;
  font-size: 14px;
  color: #4a4a4a;
}

.value {
  background: #fbfbfb;
  padding: 10px;
  border-radius: 8px;
  color: #4a4a4a;
  border: 1px solid #efefef;
  min-height: 46px;
  width: 100%;
  margin-bottom: 10px;
  outline: none;

  &.empty-field {
    background: #ffe4e4;
    border-color: #b3322c;
  }
}

.section-title {
  font-size: 20px;
  color: #121212;
}

.success {
  color: #fff !important;
}

.action {
  height: 35px;
  padding-top: 3px;
  border-radius: 5px !important;
  font-weight: 500;
  box-shadow: none !important;

  span {
    vertical-align: middle;
  }
}

.textarea-container {
  width: 350px;
}

.textarea {
  width: 100%;
}

::v-deep(.textarea) {
  textarea {
    height: 100px;
    padding-top: 10px;
  }
}

.reject-status {
  height: auto !important;
  max-width: 400px;
}
</style>
