import { AwsRum } from 'aws-rum-web';

const initialize = () => {
  try {
    const config = {
      sessionSampleRate: 1,
      identityPoolId: `${process.env.VUE_APP_RUM_POOL_ID}`,
      endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
      telemetries: ["performance","errors","http"],
      allowCookies: true,
      enableXRay: false
    };

    const APPLICATION_ID = process.env.VUE_APP_RUM_APP_ID;
    const APPLICATION_VERSION = '1.0.0';
    const APPLICATION_REGION = 'us-east-1';

    if (!APPLICATION_ID) {
      console.log('no RUM config, so skipping this')
      return;
    }

    const awsRum = new AwsRum(
      APPLICATION_ID,
      APPLICATION_VERSION,
      APPLICATION_REGION,
      config
    );

    console.log(awsRum);
    console.log('awsRum object above');
    const consoleLog = console.log;

    window.console = {
      ...console,
      log: function() {
        consoleLog.apply(null, arguments);
        let jsonArgs = null;
        try {
          jsonArgs = JSON.parse(JSON.stringify(arguments));
        } catch (ignored) {
          // it doesn't matter
        }
        awsRum.recordEvent('console_log', { jsonArgs })
      }
    }

  } catch (error) {
    console.log(error);
    console.log("Above is an error associated with loading AWS RUM.");
  }
}

export default initialize;
