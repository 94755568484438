<template>
  <div
    class="base-input-container"
    :class="{ error, textarea, showSuffix, showPrefix, isFocused, disabled }"
    data-test="input"
  >
    <label class="w-100">
      <div
        v-if="label || showLabel"
        class="label fw-normal color-grey-medium d-flex"
      >
        <span class="vertical-align-middle">
          {{ label }}
        </span>

        <slot name="label"></slot>
      </div>

      <div class="d-flex align-items-center">
        <div
          v-if="showPrefix"
          class="prefix d-flex align-items-center justify-content-center px-2"
        >
          <slot name="prefix" />
        </div>
        <component
          :is="currComponent"
          ref="inputEl"
          :maxlength="maxlength"
          class="input w-100"
          v-bind="componentProps"
          @input="handleInput"
          @focus="handleFocus"
          @blur="handleBlur"
        >
          <template v-if="select">
            <option
              v-for="(option, index) in options"
              :key="index"
              :value="option.value"
            >
              {{ option.title }}
            </option>
          </template>
        </component>
        <div
          v-if="showSuffix"
          class="suffix d-flex align-items-center justify-content-center px-2"
        >
          <slot name="suffix" />
        </div>
      </div>
    </label>

    <transition name="slide-y">
      <p v-if="error" class="error-text mt-1 fw-bolder pl-2" data-test="error">
        {{ error }}
      </p>
    </transition>
  </div>
</template>

<script>
import { computed, ref, toRef } from 'vue';
/** BaseInput component instead of simple input
 *
 *  @example
 *  <base-input
 *   v-model="email"
 *   placeholder="Email"
 *   label="Email"
 *   type="text"
 *   error="Email is not valid"
 *  />
 *
 */

export default {
  name: 'BaseInput',
  props: {
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'text'
    },
    modelValue: {
      type: [String, Number, Boolean],
      default: null
    },
    min: {
      type: [String, Number],
      default: null
    },
    maxlength: {
      type: [String, Number],
      default: null
    },
    max: {
      type: [String, Number],
      default: null
    },
    error: {
      type: String,
      default: null
    },
    pattern: {
      type: String,
      default: null
    },
    step: {
      type: String,
      default: null
    },
    textarea: {
      type: Boolean,
      default: null
    },
    select: {
      type: Boolean,
      default: null
    },
    disabled: {
      type: Boolean,
      default: null
    },
    showLabel: {
      type: Boolean,
      default: null
    },
    readonly: {
      type: Boolean,
      default: null
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  emits: ['update:modelValue', 'blur', 'focus'],
  setup(props, { emit, slots }) {
    const textarea = toRef(props, 'textarea');
    const select = toRef(props, 'select');
    const showSuffix = slots.suffix;
    const showPrefix = slots.prefix;
    const isFocused = ref(false);
    const inputEl = ref(null);

    const placeholder = toRef(props, 'placeholder');
    const type = toRef(props, 'type');
    const value = toRef(props, 'modelValue');
    const disabled = toRef(props, 'disabled');
    const readonly = toRef(props, 'readonly');
    const min = toRef(props, 'min');
    const max = toRef(props, 'max');
    const step = toRef(props, 'step');
    const pattern = toRef(props, 'pattern');
    const modelValue = toRef(props, 'modelValue');

    const handleInput = (e) => {
      const value = e.target.value;

      if (!pattern.value) {
        emit('update:modelValue', value);
        return;
      }

      const regex = new RegExp(pattern.value);
      const isValid = regex.test(value);

      if (!isValid) {
        emit('update:modelValue', modelValue.value);
        inputEl.value.value = modelValue.value;
        return;
      }

      emit('update:modelValue', value);
    };

    const currComponent = computed(() => {
      if (textarea.value) {
        return 'textarea';
      }

      if (select.value) {
        return 'select';
      }

      return 'input';
    });

    const handleFocus = (e) => {
      isFocused.value = true;

      emit('focus', e);
    };

    const handleBlur = () => {
      isFocused.value = false;

      emit('blur');
    };

    const componentProps = computed(() => {
      if (!select.value) {
        return {
          type: type.value,
          placeholder: placeholder.value,
          disabled: disabled.value,
          readonly: readonly.value,
          min: min.value,
          max: max.value,
          value: value.value,
          step: step.value
        };
      }

      return {
        placeholder: placeholder.value,
        value: value.value,
        disabled: disabled.value,
        readonly: readonly.value
      };
    });

    return {
      handleInput,
      currComponent,
      showSuffix,
      isFocused,
      handleFocus,
      handleBlur,
      componentProps,
      showPrefix,
      inputEl
    };
  }
};
</script>

<style lang="scss" scoped>
.input {
  @include has-border-radius;
  outline: none;
  border: 1px solid var(--c-grey-light);
  background-color: var(--c-grey-lighten);
  height: 45px;
  padding: 0 0 0 10px;
  color: var(--c-dark);
  transition: border-color 0.2s ease, background-color 0.2s ease;
  resize: none;

  &:focus {
    border-color: var(--c-primary);
  }

  &::placeholder {
    color: var(--c-grey-medium);
    font-size: 14px;
  }
}

.disabled {
  .input {
    background-color: var(--c-grey-light);
    border: 1px solid var(--c-grey-medium);
  }
}

.prefix,
.suffix {
  height: 45px;
  color: var(--c-grey-dark);
  transition: all 0.2s;
  border: 1px solid var(--c-grey-light);
  background-color: var(--c-grey-lighten);
}

.prefix {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  border-right: none;
}

.suffix {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  border-left: none;
}

.textarea {
  .prefix,
  .suffix {
    height: 100px;

    align-items: flex-start !important;
    padding-top: 8px !important;
  }
}

.isFocused {
  .suffix,
  .prefix {
    border-color: var(--c-primary);
  }
}

.textarea {
  .input {
    padding-top: 10px;
    height: 100px;
  }
}

.label {
  font-size: 14px;
  margin-bottom: 0;
  min-height: 21px;
}

.showPrefix {
  .input {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-left: none !important;
  }
}

.showSuffix {
  .input {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right: none !important;
  }
}

.error-text {
  color: var(--c-danger);
  font-size: 14px;
}

.error {
  .input {
    border-color: var(--c-danger);
    background-color: var(--c-danger-light);
  }

  .prefix,
  .suffix {
    border-color: var(--c-danger);
    background-color: var(--c-danger-light);
  }
}
</style>
