<template>
  <div class="statement management-page">
    <div class="title" style="color: #383838; font-size: 24px;">
      Statement
    </div>
    <small>Statements are posted on the first day of each month for the prior month.</small>
      <div class="mt-3 d-flex mb-2">
          <div v-if="role === 'admin' || role === 'parent'" class="form-floating mb-2 ms-2">
            <select
              class="form-select"
              id="merchantDropdown"
              v-model="selectedMerchantId"
              @change="handleMerchantChange"
              style="width: 300px;"
            >
              <option value="" disabled>Select an Account</option>
              <option
                v-for="merchant in merchants"
                :key="merchant.id"
                :value="merchant.id"
              >
                {{ merchant.name }}
              </option>
            </select>
            <label for="merchantDropdown">Statement For</label>
          </div>
            <!-- Month Selection Dropdown -->
          <div class="form-floating mb-2 ms-2">
            <select
              class="form-select"
              id="monthDropdown"
              v-model="selectedMonth"
              @change="handleMonthChange"
              style="width: 200px;"
            >
              <option
                v-for="monthOption in MONTHS_OPTIONS"
                :key="monthOption.value"
                :value="monthOption.value"
              >
                {{ monthOption.text }}
              </option>
            </select>
            <label for="monthDropdown">Select Month</label>
          </div>
          <a
              href="#"
              style="text-decoration: none; font-weight: 600;"
              class="subs-btn flex-fill align-self-center align-content-end ps-5"
              @click="generatePdf">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
              </svg>
              Download .pdf
            </a>

      </div>
              
   
      <AgGrid
        ref="agref"
        :apiurl="apiurl"
        :defineDefs = "columnDefs"
        groupPanel = ''
        pagination="false"
        @row2Selected = "rowDetails"
        :excelOptions = "{fileName:'Statement.xlsx'}"
        height="84vh"
    ></AgGrid>

      <div>
        <p class="bottom-text mb-0">Notes: {{ formattedStatementNote }}</p>
      </div>

      <div class="bottom-text mt-3">
        <p class="disclaimer color-grey-medium">
          If you have questions about your statement please call #TPP-3-PAYFAC
        </p>
      </div>
    </div>
</template>

<script setup>
import { computed, ref, reactive, defineProps, onMounted } from 'vue';
import { useStore } from 'vuex';
import http from '@/services/http';
import useService from '@/composables/common/services';
import AgGrid from '@/components/AgGrid.vue';
import { MONTHS_OPTIONS } from '@/config/constants.js';
import { DateTime } from 'luxon';
import useExcel from '@/composables/common/excel';

const { apiService } = useService();
// Get user data
const store = useStore();
const defaultClientName = store.getters.defaultClientName;
const defaultClientid = store.getters.defaultClientid;
const role = store.getters.role;
const { writePdf } = useExcel(name.value);

const merchants = ref([]);
const apiurl = ref(`/api/report/statement`);

// Initialize selectedMerchantId and selectedMerchantName with default values
const selectedMerchantId = ref(defaultClientid);
const selectedMerchantName = ref(defaultClientName);
const selectedMonth = ref(DateTime.now().minus({ months: 1 }).toFormat('MM'));

const clientDetails = ref({});
const agref = ref();
const details = ref({})

// Method, Sales Volume, # Transactions, Rate, Per Item, % Cost, Total Fees, Net Total
const columnDefs = [
    {
      headerName: 'Account',
      children: [
        {
          headerName: 'Type',
          filter: 'agSetColumnFilter',
          sort:'asc',
          valueGetter: (params) => {
            // console.log(params.data)
          if (!params.data) return '';
          return params.data.type;
          },
        },
        {
          headerName: 'Pricing (%+¢)',
          filter: 'agSetColumnFilter',
          valueGetter: (params) => {
              if (!params.data) return '';
              return params.data.pricing;
          }
        },]},
    {
      headerName: 'Volume',
      children: [
      {
          headerName: '# Transactions',
          filter: 'agSetColumnFilter',
          valueGetter: (params) => {
              if (!params.data) return '';
              return params.data.transactions;
          }
        },
        {
          headerName: 'Gross',
          filter: 'agTextColumnFilter',
          valueGetter: (params) => {
            // console.log(params.data)
          if (!params.data) return '';
          return params.data.gross;
          },
        },
        {
          headerName: 'Fees',
          filter: 'agSetColumnFilter',
          valueGetter: (params) => {
              if (!params.data) return '';
              return params.data.fees;
          }
        },
        {
          headerName: 'Flat Fee (%)',
          filter: 'agSetColumnFilter',
          valueGetter: (params) => {
              if (!params.data) return '';
              return params.data.flatfee;
          }
        },
        {
          headerName: 'Item Fee (¢)',
          filter: 'agSetColumnFilter',
          valueGetter: (params) => {
              if (!params.data) return '';
              return params.data.itemfee;
          }
        },
        {
          headerName: 'Net',
          filter: 'agSetColumnFilter',
          valueGetter: (params) => {
              if (!params.data) return '';
              return params.data.net;
          }
        },
      ]
    },
]

onMounted(() => {
  // Calculate the last month
  const lastMonth = DateTime.now().minus({ months: 1 }).toFormat('MM');

  // Initialize the API URL with the month parameter
  apiurl.value = `/api/report/statement?month=${lastMonth}`;

  loadMerchants();
});


const generatePdf = () => {
  const months = {'01':'January', '02':'February', '03':'March', '04':'April', '05':'May', '06':'June', '07':'July', '08':'August', '09':'September','1':'January', '2':'February', '3':'March', '4':'April', '5':'May', '6':'June', '7':'July', '8':'August', '9':'September', '10':'October', '11':'November', '12':'December'}
  console.log(selectedMonth.value.toString())
  let month = months[selectedMonth.value.toString()]
  details.value['name'] = selectedMerchantName.value+' '+month
  details.value['subtitle'] = statementNote.value
  details.value['header'] = ['Type', 'Pricing (%+¢)', '# Transactions', 'Gross', 'Fees', 'Flat Fee(%)', 'Item Fee(¢)', 'Net']
  const g = agref.value.getData()
  const data = []
  for (let i in g) {
    const l = [g[i].type, g[i].pricing, g[i].transactions, g[i].gross, g[i].fees, g[i].flatfee, g[i].itemfee, g[i].net]
    data.push(l)
  }
  writePdf(data, details.value);
};


// Handle merchant selection change
const handleMerchantChange = () => {
  // Update selectedMerchantName when the user selects a merchant
  const selectedMerchant = merchants.value.find(merchant => merchant.id === selectedMerchantId.value);
  if (selectedMerchant) {
    selectedMerchantName.value = selectedMerchant.name;
  }
  // Update the API URL when the user selects a merchant
  apiurl.value = `/api/report/statement?clientid=${selectedMerchantId.value}`;
  // console.log("apiurl on change", apiurl.value);
  // Refresh the data in the AgGrid
  agref.value.refreshAPI(apiurl.value);
};

// Handle month selection change
const handleMonthChange = () => {
  // Update the API URL when the user selects a month
  console.log(selectedMonth.value)
  apiurl.value = `/api/report/statement?month=${selectedMonth.value}&clientid=${selectedMerchantId.value}`;
  // console.log("apiurl on change", apiurl.value);

  // Refresh the data in the AgGrid
  agref.value.refreshAPI(apiurl.value);
};

// Account selection
const loadMerchants = async () => {
  try {
    const response = await http.get('/api/clients');
    // console.log("dropdown", response);
    if (response.data.status) {
      const unsortedMerchants = response.data.message.map(merchant => ({
        id: merchant[0],
        name: merchant[3],
      }));

      // Sort the merchants array alphabetically by name
      merchants.value = unsortedMerchants
          .slice()
          .sort((a, b) => (a.name && b.name ? a.name.localeCompare(b.name) : 0));
    }
  } catch (error) {
    console.error('Error loading merchants:', error);
  }
};

// Statement Notes
const statementNote = ref('');

const fetchStatementNote = () => {
  return apiService
    .get(`api/notes/statement`)
    .then((res) => {
      statementNote.value = res[0] || '';
      console.log(res[0]);
    })
    .catch((error) => {
      console.error('Error fetching statementNote:', error);
    });
};

fetchStatementNote();

// Format the date for the Statement Note
const formattedStatementNote = computed(() => {
  const note = statementNote.value[3] || 'No Note Posted';
  const date = statementNote.value[4] || 'N/A';

  return `${note} / Note posted on: ${date}`;
});



</script>

<style lang="scss" scoped>
::v-deep(.month-select) {
  .value {
    height: 45px !important;
    min-width: 120px;
  }

  .unselected {
    color: var(--c-grey-medium) !important;
  }
}

.rightpush {float:right}

.loader {
  z-index: 2;
}

.add-fee {
  height: 40px !important;
}

.stats-col {
  padding: 10px 20px;
  min-width: 400px;

  &:first-child {
    padding-left: 0;
  }
}

.table {
  height: 340px !important;
}

.bottom-text {
  padding-left: 17px;
  padding-right: 17px;
  font-size: 14px;
  color: #181d1f;
}
.notes-input {
  min-width: 500px;
}

.disclaimer {
  font-style: italic;
}

.title {
  color: #383838;
  font-size: 24px;
}

@media screen and (max-width: 768px) {
  .notes-input {
    min-width: 100px;
  }
}
</style>

<style lang="scss">
.fees-modal {
  .modal-dialog {
    min-width: 700px !important;
  }
}
</style>
