<template>
  <div class="position-relative">
    <base-spinner v-if="loading" class="upload-spinner" />
    <base-dropdown
      v-if="!removeTag"
      v-model="selectedTag"
      :options="tags"
      :error="selectedTagError"
      label="Tag"
      placeholder="Enter Tag"
      class="mb-3"
      autocomplete
    ></base-dropdown>

    <upload-document-zone @upload="handleUpload" />

    <selected-files
      v-if="selectedFilesValue && selectedFilesValue.length"
      :files="selectedFilesValue"
      @remove="removeFile"
    />

    <base-input
      v-model="note"
      :error="noteError"
      textarea
      label="Note"
      placeholder="Note"
      class="mt-2"
      maxlength="200"
    />

    <div class="d-flex justify-content-center">
      <base-button
        class="upload-btn w-50 mt-3"
        :disabled="!selectedFilesValue || !selectedFilesValue.length"
        @click="submit"
      >
        Upload {{ uploadText }}
      </base-button>
    </div>
  </div>
</template>

<script>
import useDocuments from '@/composables/documents/documents.js';
import useService from '@/composables/common/services';
import useToast from '@/composables/common/toast';
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';

import UploadDocumentZone from '@/components/clients/UploadDocumentZone.vue';
import BaseDropdown from '@/components/base/BaseDropdown.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import BaseSpinner from '@/components/base/BaseSpinner.vue';
import BaseInput from '@/components/base/BaseInput.vue';
import SelectedFiles from '@/components/clients/SelectedFiles.vue';
import { computed, ref, toRefs } from 'vue';
import { onMounted } from 'vue';

const validationSchema = yup.object().shape({
  selectedTag: yup.string().when('tagDisabled', (tagDisabled, schema) => {
    if (tagDisabled) {
      return schema.nullable();
    }

    return schema.required('Field is required').nullable();
  }),
  tagDisabled: yup.bool().nullable(),
  note: yup.string().nullable().max(200)
});

export default {
  name: 'UploadDocument',
  components: {
    UploadDocumentZone,
    BaseDropdown,
    BaseButton,
    SelectedFiles,
    BaseSpinner,
    BaseInput
  },
  props: {
    clientDetails: {
      type: Object,
      default: () => {}
    },
    removeTag: {
      type: Boolean,
      default: false
    }
  },
  emits: ['success'],
  setup(props, { emit }) {
    const { apiService } = useService();
    const { showMessage } = useToast();

    const { clientDetails, removeTag } = toRefs(props);
    const loading = ref(false);
    const tags = ref([]);
    const selectedFilesValue = ref([]);

    const { validate, resetForm } = useForm({
      validationSchema
    });

    const { value: tagDisabled } = useField('tagDisabled');
    const { value: note, errorMessage: noteError } = useField('note');

    const { value: selectedTag, errorMessage: selectedTagError } =
      useField('selectedTag');

    tagDisabled.value = removeTag.value;

    const { getTags } = useDocuments();

    const uploadText = computed(() => {
      if (!selectedFilesValue.value.length) {
        return '';
      }

      if (selectedFilesValue.value.length === 1) {
        return '1 file';
      }

      return `${selectedFilesValue.value.length} files`;
    });

    const handleUpload = (files) => {
      selectedFilesValue.value = files;
    };

    const uploadFile = (file) => {
      const data = new FormData();

      if (clientDetails.value && clientDetails.value.id) {
        data.append('clientid', clientDetails.value.id);
      }

      if (!removeTag.value) {
        data.append('_tag_', selectedTag.value);
      }

      if (note.value) {
        data.append('note', note.value);
      }

      data.append('file', file.file);

      return apiService.post(`api/document`, data);
    };

    const removeFile = (index) => {
      selectedFilesValue.value.splice(index, 1);
    };

    const submit = async () => {
      const result = await validate();

      if (!result.valid) {
        return;
      }

      loading.value = true;
      const promises = [];
      for (let i = 0; i < selectedFilesValue.value.length; i++) {
        promises.push(uploadFile(selectedFilesValue.value[i]));
      }

      Promise.allSettled(promises)
        .then(() => {
          resetForm();
          selectedFilesValue.value = [];
          loading.value = false;

          emit('success');

          showMessage({
            title: 'Success',
            text: 'Files were successfully uploaded!',
            type: 'success'
          });

          apiService.post('api/email', {
            subject: `Documents uploaded: ${clientDetails.value.name}`,
            body: `Documents were uploaded for client ${clientDetails.value.name}`,
            to: ' CSM@tripleplaypay.com'
          });
        })
        .catch(() => {
          loading.value = false;

          showMessage({
            title: 'Error',
            text: 'Something went wrong!',
            type: 'danger'
          });
        });
    };

    onMounted(() => {
      if (removeTag.value) {
        return;
      }

      getTags().then((res) => {
        tags.value = res;
      });
    });

    return {
      loading,
      tags,
      selectedTag,
      selectedTagError,
      note,
      noteError,
      selectedFilesValue,
      uploadText,

      handleUpload,
      submit,
      removeFile
    };
  }
};
</script>

<style lang="scss" scoped>
::v-deep(.docs-tab) {
  .title-container {
    background: var(--c-grey-light);
    padding: 5px 10px;
    border-radius: 5px;
  }

  .section-title {
    font-size: 16px;
  }
}

.upload-btn {
  border-radius: 55px !important;
}

.upload-spinner {
  z-index: 2;
}
</style>
