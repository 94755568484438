<template>
  <component
    :is="currentTag"
    class="btn base-button"
    :type="type"
    :class="{
      hasIconOnly,
      rounded,
      [`btn-${color}`]: true,
      'is-link': currentTag === 'a'
    }"
    :style="btnStyle"
    :href="href"
    :disabled="disabled"
    data-test="button"
    :target="target"
  >
    <slot name="default" />

    <span v-if="hasIconOnly" class="icon-container d-inline-block">
      <slot name="icon-only" />
    </span>
  </component>
</template>

<script>
import { computed, toRef, toRefs } from 'vue';
const DEFAULT_SIZE = 50;
const DEFAULT_COLOR = 'primary';

/** Button component instead of simple button
 *
 *  @example
 *  <base-button
 *   @click="handleClick"
 *   type="submit"
 *   color="danger"
 *  >
 *   Click me!
 *  </base-button>
 *
 *  <base-button
 *   @click="handleClick"
 *   type="submit"
 *   color="danger"
 *   size="55px"
 *   rounded
 *  >
 *   <template #icon-only>
 *      <img src="..icon" alt="icon" />
 *   </template>
 *  </base-button>
 *
 *  @remark
 *  Please note, property "size" is applied
 *  only if "rounded" exists
 *
 */

export default {
  name: 'BaseButton',
  props: {
    //html type of button
    type: {
      type: String,
      default: null
    },
    //rounded button or not
    rounded: {
      type: Boolean,
      default: false
    },
    //disabled button or not
    disabled: {
      type: Boolean,
      default: false
    },
    //height/width of rounded button
    size: {
      type: Number,
      default: DEFAULT_SIZE
    },
    //any color taken from variables.scss
    color: {
      type: String,
      default: DEFAULT_COLOR
    },
    //custom color
    mainColor: {
      type: String,
      default: null
    },
    //background color
    backgroundColor: {
      type: String,
      default: null
    },
    //border color
    borderColor: {
      type: String,
      default: null
    },
    href: {
      type: String,
      default: null
    },
    target: {
      type: String,
      default: '_blank'
    }
  },
  setup(props, { slots }) {
    const hasIconOnly = slots['icon-only'];
    const href = toRef(props, 'href');
    const { mainColor, backgroundColor, borderColor } = toRefs(props);

    const btnStyle = computed(() => {
      const style = {};

      if (mainColor.value) {
        style.color = `${mainColor.value} !important`;
      }

      if (backgroundColor.value) {
        style.backgroundColor = `${backgroundColor.value} !important`;
      }

      if (borderColor.value) {
        style.borderColor = `${borderColor.value} !important`;
      }

      if (!props.rounded) {
        return style;
      }

      return {
        width: `${props.size}px`,
        height: `${props.size}px`,
        padding: '0px',
        ...style
      };
    });

    const currentTag = computed(() => {
      if (href.value) {
        return 'a';
      }

      return 'button';
    });

    return {
      hasIconOnly,
      btnStyle,
      currentTag
    };
  }
};
</script>

<style lang="scss" scoped>
.base-button {
  @include has-border-radius;
  height: 50px;
  font-weight: 400;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: center;
}

.btn.base-button {
  box-shadow: none;

  &:active {
    box-shadow: none;
  }
}

button.base-button[disabled] {
  opacity: 0.7 !important;
}

.icon-container {
  width: 30px;
}

.rounded {
  border-radius: 50% !important;
}

::v-deep(.icon-container) {
  img {
    width: 100%;
  }
}

.is-link {
  padding-top: 12px !important;
}
</style>
