<template>
  <div class="clients management-page">
    <div class="mt-1 position-relative">
      <base-spinner v-if="loadingList" class="loader" />
      <div class="row g-3">
        <div class="col-auto">
          <div class="title d-flex" style="color: #383838; font-size: 24px">
            Operations
          </div>
        </div>
        <div class="col-auto">
          <div class="dropdown">
            <a
              class="btn btn-secondary dropdown-toggle"
              href="#"
              role="button"
              id="dropdownMenuLink"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Report links
            </a>

            <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <li>
                <router-link class="dropdown-item" to="/report/revshare"
                  >RevShare Report</router-link
                >
              </li>
              <li>
                <router-link class="dropdown-item" to="/report/payout"
                  >Payouts</router-link
                >
              </li>
              <li>
                <router-link class="dropdown-item" to="/report/subpricing"
                  >Subpricing</router-link
                >
              </li>
              <li>
                <router-link class="dropdown-item" to="/report/mcc"
                  >MCC Codes</router-link
                >
              </li>
              <li>
                <router-link class="dropdown-item" to="/report/documents"
                  >All Documents</router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end top">
        <report-buttons
          :list="itemsList"
          :schema="schema"
          name="Operations"
          hide-pdf
        />
      </div>
      <AgGrid
        ref="agref"
        :apiurl="apiUrl"
        :columns="[]"
        :defineDefs="columnDefs"
        :sideBar="true"
        :statusBar="statusBar"
        :excelStyles="excelStyles"
        :filterOptions="filterOptions"
        pagination="false"
        groupPanel=""
        :excelOptions="{ fileName: 'Operations Report.xlsx' }"
        height="88vh"
        @filterChanged="filterModified"
      ></AgGrid>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import AgGrid from '@/components/AgGrid.vue';

const apiUrl = ref(`/api/report/operations`);
const columnDefs = [
  {
    headerName: 'id',
    filter: 'agTextColumnFilter',
    hide: true,
    valueGetter: (p) => {
      if (!p.data) return '';
      return p.data[0];
    }
  },
  {
    headerName: 'Name',
    filter: 'agTextColumnFilter',
    valueGetter: (p) => {
      if (!p.data) return '';
      return p.data[1];
    }
  },
  {
    headerName: 'Type',
    filter: 'agTextColumnFilter',
    valueGetter: (p) => {
      if (!p.data) return '';
      return p.data[2];
    }
  },
  {
    headerName: 'Result',
    filter: 'agTextColumnFilter',
    width: 500,
    wrapText: true,
    autoHeight: true,
    valueGetter: (p) => {
      if (!p.data) return '';
      return p.data[3];
    }
  },
  {
    headerName: 'Created',
    filter: 'agTextColumnFilter',
    sort: 'desc',
    valueGetter: (p) => {
      if (!p.data) return '';
      return p.data[4];
    }
  }
];
onMounted(() => {});
</script>

<style lang="scss" scoped>
.loader {
  z-index: 2;
}
</style>

<style lang="scss">
.clients {
  .actions {
    height: 100% !important;
  }

  .action-btn {
    line-height: initial;
    width: 30px !important;
    height: 30px !important;
    padding: 2px 1px !important;
    font-size: 14px !important;
    border-radius: 50% !important;

    .icon-container {
      width: auto !important;

      svg {
        width: 19px !important;
      }
    }

    &.success {
      color: #fff !important;
    }
  }
}
</style>
