<template>
  <div class="container-fluid my-2">
    <strong class="text-muted">{{ formname.toUpperCase() }}</strong
    >&nbsp;&nbsp;&nbsp;<strong class="text-primary">{{ client }}</strong
    ><br />
    <div v-if="client !== 'TriplePlayPay'">
      <button class="btn btn-sm btn-light float-right" @click="copySub">
        <strong class="text-muted">Copy From</strong>
      </button>
      <select id="psubs" v-model="selectedParent" class="float-right">
        <option v-for="(pname, pid) in parents" :key="pid" :value="pid">
          {{ pname }}
        </option>
      </select>
    </div>
    <div
      v-show="showAlert"
      id="myAlert"
      class="alert alert-success alert-dismissible w-50 mx-auto"
      role="alert"
      style="position: fixed; left: 25%; z-index: 1"
    >
      Information Saved
    </div>
    <table
      id="tablex"
      class="table table-sm table-striped dt-responsive nowrap"
      width="100%"
    >
      <thead>
        <tr>
          <th v-for="column in columns" :key="column">{{ column }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in dataSet.subpricing" :key="row.id">
          <td v-for="column in columns" :key="column">{{ row[column] }}</td>
        </tr>
      </tbody>
    </table>
    <button
      type="submit"
      class="btn btn-sm btn-light float-right border border-primary"
      @click="submitHandler"
    >
      <strong class="text-muted"> Save Changes</strong>
    </button>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import http from '@/services/http';
import { useStore } from 'vuex';

const store = useStore();
const defaultClientid = computed(() => store.getters.defaultClientid);

const formname = ref('');
const client = ref('');
const parents = ref([]);
const selectedParent = ref(null);
const showAlert = ref(false);
const columns = ref([
  'label',
  'mccCode',
  'card_rate',
  'ach_rate',
  'crypto_rate',
  'card_p2c',
  'ach_p2c',
  'crypto_p2c',
  'avg_ticket',
  'high_ticket'
]);
const dataSet = ref({
  subpricing: []
});

onMounted(async () => {
  try {
    await getParentOptions();

    const response = await fetch(
      `/api/client/subpricing?apikey=login&clientid=${defaultClientid.value}`
    );
    const data = await response.json();
    console.log('subpricing data', data);
    dataSet.value = data;
  } catch (error) {
    console.error('subpricing error', error);
  }
});

const copySub = async () => {
  // const response = await fetch(
  //   `/api/copysubpricing?apikey=login&id=${option.value}&clientid=${selectedParent.value}`
  // );
};

const getParentOptions = async () => {
  const response = await http.get('/api/client/parent');
  parents.value = response.data;
};

const submitHandler = () => {
  showAlert.value = true;
  setTimeout(() => {
    showAlert.value = false;
  }, 3000);
};
</script>
