import { onBeforeUnmount, onMounted, ref } from 'vue';
import { debounce } from '@/helpers';

export default function () {
  const gridApi = ref(null);

  const handleResize = debounce(() => {
    if (!gridApi.value) {
      return;
    }

    gridApi.value.sizeColumnsToFit();
  }, 500);

  onMounted(() => {
    window.addEventListener('resize', handleResize);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('resize', handleResize);
  });

  return {
    handleResize,
    gridApi
  };
}
