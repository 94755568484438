<template>
  <div>
    <base-spinner v-if="loading" fixed />

    <div v-else class="pt-5 m5-5">
      <p class="text-center fz-20 color-success fw-500">Success</p>
      <p class="text-center fz-16 color-dark">
        You successfully unsubscribed from service
      </p>
    </div>
  </div>
</template>

<script>
import BaseSpinner from '@/components/base/BaseSpinner.vue';
import { useRoute } from 'vue-router';
import { onMounted, ref } from 'vue';
import http from '@/services/http';

export default {
  name: 'UnsusbscribePage',
  components: {
    BaseSpinner
  },
  setup() {
    const route = useRoute();
    const loading = ref(true);

    // TODO: Fix the code below
    onMounted(() => {
      loading.value = false;
      return;
      http.get(
        `/unsubscribe?clientid=${route.query.clientid}&email=${route.query.email}`
      );
    });

    return {
      loading
    };
  }
};
</script>
