<template>
  <form @submit.prevent="submit">
    <base-spinner v-if="loading" />
    <base-dropdown
      v-model="timezone"
      :options="timezones"
      :allow-custom-value="false"
      autocomplete
      label="Timezone"
      placeholder="Select timezone"
    />

    <base-button class="w-100 mt-4"> Save </base-button>
  </form>
</template>

<script>
import useService from '@/composables/common/services';

import BaseDropdown from '@/components/base/BaseDropdown.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import BaseSpinner from '@/components/base/BaseSpinner.vue';
import { onMounted, ref } from 'vue';
import useToast from '@/composables/common/toast';

export default {
  name: 'UserConfig',
  components: {
    BaseDropdown,
    BaseButton,
    BaseSpinner
  },
  setup() {
    const { apiService } = useService();
    const { showMessage } = useToast();
    const timezone = ref(null);
    const loading = ref(false);
    const timezones = ref([]);

    onMounted(() => {
      apiService.get('api/timezone').then((res) => {
        timezone.value = res.message;
      });

      apiService.get('api/timezones').then((res) => {
        timezones.value = res.message.map((el) => {
          return {
            text: el[0],
            value: el[0]
          };
        });
      });
    });

    const submit = () => {
      loading.value = true;
      apiService
        .post('api/timezone', {
          timezone: timezone.value
        })
        .then(() => {
          loading.value = false;

          showMessage({
            title: 'Success',
            text: 'Timezone was successfully updated',
            type: 'success'
          });
        })
        .catch(() => {
          loading.value = false;

          showMessage({
            title: 'Error',
            text: 'Something went wrong',
            type: 'danger'
          });
        });
    };

    return {
      timezone,
      timezones,
      loading,

      submit
    };
  }
};
</script>
